<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import StartProcessDialog from "@/app/process/components/action/start/StartProcessDialog.vue";
import { useBlockedActionStore } from "@/app/process/stores/action/BlockedActionStore.ts";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseSelectButton, {
  type BaseSelectButtonOption,
} from "@/base/components/button/BaseSelectButton.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type BaseDialogProps,
  type StartProcessDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { type ActivityEto } from "@/base/graphql/generated/types.ts";

const props = defineProps<{ rootActivity: ActivityEto }>();

const { t } = useI18n();
const router = useRouter();
const dialog = useBaseDialog();
const blockerStore = useBlockedActionStore();
const activityService = useActivityService();
const { copy, copied } = useClipboard();

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "revise-draft-button",
    visible: current.value && activityService.isReleased(current.value.id),
    label: t("action.edit"),
    icon: "mdi mdi-pencil",
    command: revise,
  },
  {
    testId: "release-draft-button",
    visible: !activityService.isReleased(props.rootActivity.id),
    label: t("action.release"),
    severity: "primary",
    icon: "mdi mdi-check",
    command: () =>
      activityService.releaseProcessTemplate(props.rootActivity.id),
  },
  {
    testId: "start-process-button",
    visible: activityService.isReleased(props.rootActivity.id),
    label: t("processes.startButton.startProcess"),
    icon: "mdi mdi-play",
    command: showStartProcessDialog,
  },
  {
    label: t("processes.showGraphButton.title"),
    icon: "mdi mdi-graph-outline",
    command: navigateToGraphView,
  },
]);

const versionOptions = computed(() => {
  return [
    {
      testId: "released-version-option",
      label: t("processes.released"),
      value: "released",
    },
    {
      testId: "draft-version-option",
      label: t("processes.draftAlternative"),
      value: "draft",
    },
  ];
});

const selectedVersion = computed(() => {
  return isReleased.value
    ? versionOptions.value.find((v) => v.value === "released")
    : versionOptions.value.find((v) => v.value === "draft");
});

const rootActivityBlocked = computed(() =>
  blockerStore.isBlocked(props.rootActivity.id),
);

const current = computed(() => {
  const mid = activityService.getMid(props.rootActivity.id);
  return mid ? activityService.getCurrentVersion(mid) : undefined;
});

const lastReleased = computed(() => {
  const mid = activityService.getMid(props.rootActivity.id);
  return mid ? activityService.getLastReleased(mid) : undefined;
});

const isReleased = computed(() => {
  return activityService.isReleased(props.rootActivity.id);
});

async function revise() {
  try {
    await activityService.reviseProcessTemplate(
      current.value?.id ?? "undefined",
    );
    await router.push({
      name: "processTemplate",
      params: {
        rootActivityId: current.value?.id ?? "undefined",
      },
    });
  } catch (error) {
    console.error(error);
  }
}

function showStartProcessDialog() {
  const headerTitle = t("processes.startButton.startNamedProcess", {
    name: props.rootActivity.name ?? "",
  });

  const startProcessDialogData: StartProcessDialogData = {
    showProcessTemplateSelect: false,
    rootActivity: props.rootActivity,
  };

  const startProcessDialogProps: BaseDialogProps = {
    header: headerTitle,
    props: startProcessDialogData,
  };

  dialog.open(StartProcessDialog, startProcessDialogProps);
}

async function navigateToGraphView() {
  await router.push({
    name: "activityGraph",
    params: {
      rootActivityId: props.rootActivity.id,
    },
  });
}

async function navigateBetweenVersions(option: BaseSelectButtonOption) {
  if (!current.value || !lastReleased.value) {
    console.error("No current or last released version found");
    return;
  }

  const rootActivityId =
    option.value === "draft" ? current.value.id : lastReleased.value.id;

  await router.replace({
    name: "processTemplate",
    params: {
      rootActivityId,
    },
  });
}

const avatarIcon = computed(() => {
  if (copied.value) {
    return "mdi mdi-check";
  }

  if (isReleased.value) {
    return "mdi mdi-play-box";
  } else {
    return "mdi mdi-hammer-screwdriver";
  }
});

const avatarColor = computed(() => {
  if (copied.value) {
    return "bg-primary-400! text-white!";
  }

  if (isReleased.value) {
    return "";
  } else {
    return "bg-sky-100! text-sky-700!";
  }
});
</script>

<template>
  <div class="flex flex-row justify-between items-start gap-6">
    <div class="flex flex-row gap-4 items-baseline w-full">
      <div class="flex flex-row gap-4 items-start">
        <BaseAvatar
          v-tooltip.bottom="t('action.copyIdTooltip')"
          class="mt-1 text-caeli6 cursor-pointer transition-all duration-400 ease-out"
          :class="avatarColor"
          :icon="avatarIcon"
          @click="copy(rootActivity.id)"
        />

        <p
          class="text-3xl/10 font-bold"
          :class="isReleased ? `` : `text-sky-700!`"
        >
          {{ rootActivity.name }}
        </p>
      </div>

      <PTag
        v-if="isReleased && !current?.released"
        severity="primary"
        class="max-h-6!"
        data-testid="released-tag"
        :value="t('processes.released')"
      >
        <template #icon>
          <i class="mdi mdi-check-bold" />
        </template>
      </PTag>

      <PTag
        v-if="!isReleased"
        severity="info"
        :value="t('processes.draftAlternative')"
      >
        <template #icon>
          <i class="mdi mdi-pencil" />
        </template>
      </PTag>
    </div>

    <BaseSelectButton
      v-if="lastReleased && current?.id !== lastReleased.id"
      class="mt-1"
      optionLabel="label"
      :modelValue="selectedVersion"
      :options="versionOptions"
      @valueChange="navigateBetweenVersions"
    />

    <BaseActionsButton
      :model="actionsMenuItems"
      :loading="rootActivityBlocked"
      :disabled="rootActivityBlocked"
    />
  </div>
</template>

<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import CheckPlaceholderReplacementValuesForDocumentDialog from "@/app/document/components/dialog/CheckPlaceholderReplacementValuesForDocumentDialog.vue";
import GenerateDocumentDialog from "@/app/document/components/dialog/GenerateDocumentDialog.vue";
import GenerateDocumentVariantDialog from "@/app/document/components/dialog/GenerateDocumentVariantDialog.vue";
import DocumentParameterTable from "@/app/document/components/DocumentParameterTable.vue";
import { useDocumentService } from "@/app/document/services/DocumentService";
import DocumentFileField from "@/app/process/components/field/DocumentFileField.vue";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type BaseDialogProps,
  type DocumentGenerationPlaceholderReplacementValuesCheckDialogData,
  type GenerateDocumentDialogData,
  type GenerateDocumentVariantData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { useTitle } from "@/base/composables/useTitle";
import { type EntityType } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n";
import { useDialogService } from "@/base/services/DialogService.ts";

const props = defineProps<{
  documentId: string;
}>();

const documentService = useDocumentService();

const router = useRouter();
const { t } = useI18n();
const dialogService = useDialogService();
const showDialog = useBaseDialog();

const document = computed(() => documentService.getById(props.documentId));

useTitle(document.value?.name ?? t("document.document", 1));
const { copy, copied } = useClipboard();

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "document-edit",
    label: t("action.edit"),
    icon: "mdi mdi-pencil",
    command: navigateToEditPage,
  },
  {
    separator: true,
    class: "my-1 opacity-50",
  },
  {
    testId: "document-generate",
    label: t("document.generate"),
    icon: "mdi mdi-file-sign",
    disabled: !document.value,
    command: () => {
      if (!document.value) {
        return;
      }
      //From Old DialogService
      dialogService.dialogEntityId = document.value.id;
      dialogService.dialogEntityType = document.value.entityType;
      showGenerateDocumentDialog(false);
    },
  },
  {
    testId: "document-check-replacement-values",
    label: t("document.checkValues"),
    icon: "mdi mdi-file-question-outline",
    disabled: !document.value,
    command: () => {
      if (!document.value) {
        return;
      }
      dialogService.dialogEntityId = document.value.id;
      dialogService.dialogEntityType = document.value.entityType;
      showPlaceholderReplacementValuesCheckDialog(false);
    },
  },
  {
    testId: "document-variant",
    label: t("document.generateVariant"),
    icon: "mdi mdi-source-branch-plus",
    disabled: !document.value,
    command: () => {
      if (!document.value) {
        return;
      }
      showGenerateDialogVariant(
        document.value.id,
        document.value.entityType,
        document.value.name,
      );
    },
  },
]);

async function navigateToEditPage() {
  await router.push({
    name: "documentEdit",
    params: { documentId: props.documentId },
  });
}

function showGenerateDocumentDialog(
  canChooseDoc: boolean,
  preselectedItem?: string,
) {
  const generateDocumentProps: GenerateDocumentDialogData = {
    preselectedItem,
    canChooseDoc,
  };

  const generateDialogProps: BaseDialogProps = {
    header: t("document.generate"),
    props: generateDocumentProps,
    onClose: () => {
      documentService.resetDocumentGeneration();
    },
  };
  showDialog.open(GenerateDocumentDialog, generateDialogProps);
}

function showPlaceholderReplacementValuesCheckDialog(
  canChooseDoc: boolean,
  preselectedItem?: string,
) {
  const checkValuesProps: DocumentGenerationPlaceholderReplacementValuesCheckDialogData =
    {
      preselectedItem,
      canChooseDoc,
    };

  const generateDialogProps: BaseDialogProps = {
    header: t("document.checkValues"),
    props: checkValuesProps,
    maximizable: true,
    onClose: () => {
      documentService.resetPlaceholderReplacementValuesCheck();
    },
  };
  showDialog.open(
    CheckPlaceholderReplacementValuesForDocumentDialog,
    generateDialogProps,
  );
}

function showGenerateDialogVariant(
  documentId: string,
  entityType: EntityType,
  documentName: string,
) {
  const generateVariantProps: GenerateDocumentVariantData = {
    documentId,
    entityType,
    documentName,
    generateDescription: t("document.generateVariantDialog.description", {
      fileName: documentName,
    }),
  };

  const generateDocumentVariantDialogProps: BaseDialogProps = {
    header: `${t("document.generateVariantDialog.title")}`,
    props: generateVariantProps,
    onClose: () => {
      documentService.resetDocumentVariantGeneration();
    },
  };

  showDialog.open(
    GenerateDocumentVariantDialog,
    generateDocumentVariantDialogProps,
  );
}
</script>

<template>
  <BaseCard class="h-full p-4!" flat noShadow :rounded="false">
    <template #title>
      <div class="flex flex-row justify-between items-start gap-4">
        <div class="flex flex-row gap-4 items-start text-nowrap truncate">
          <BaseAvatar
            v-tooltip.bottom="t('action.copyIdTooltip')"
            class="mt-1 shrink-0 text-caeli6 cursor-pointer transition-all duration-400 ease-out"
            :icon="copied ? 'mdi mdi-check' : 'mdi mdi-file-document-multiple'"
            :pt:root="copied ? 'bg-primary-400!' : undefined"
            @click="copy(document?.id ?? '')"
          />

          <p
            data-testid="document-view-name"
            class="text-3xl/10 font-bold truncate"
            :title="document?.name ?? ''"
          >
            {{ document?.name ?? t("document.document") }}
          </p>
        </div>

        <BaseActionsButton :model="actionsMenuItems" />
      </div>
    </template>

    <div class="flex flex-col gap-4">
      <BaseAccordion
        v-if="document"
        icon="mdi mdi-information-outline"
        :expandable="false"
        :label="t('document.documentInformation')"
      >
        <div class="flex flex-col gap-4">
          <ReadonlyField
            :label="t('document.entityType')"
            :value="translateEnum('entityTypes', document.entityType)"
            data-testid="document-view-entity-type"
          />

          <ReadonlyField
            v-if="document.iterator"
            :label="t('document.iterator.label')"
            :value="document?.iterator ?? ''"
            data-testid="document-view-iterator"
          />

          <TextDisplay
            v-if="document.description"
            :label="t('document.description')"
            :value="document?.description ?? ''"
            data-testid="document-view-description"
            markdown
          />

          <ReadonlyField :label="t('document.file')">
            <DocumentFileField
              readonly
              :entityId="document.id"
              :fileId="document.templateFileId"
              data-testid="document-view-template-file"
            />
          </ReadonlyField>
        </div>
      </BaseAccordion>

      <BaseAccordion
        v-if="document"
        icon="mdi mdi-code-json"
        :label="t('document.documentParameter')"
      >
        <DocumentParameterTable :entityId="document.id" :editable="false" />
      </BaseAccordion>
    </div>
  </BaseCard>
</template>

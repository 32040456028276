<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import RenameTagDialog from "@/app/process/components/field/action/RenameTagDialog.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseDivider from "@/base/components/divider/BaseDivider.vue";
import {
  type BaseDialogProps,
  type RenameTagDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import {
  type EntityType,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = withDefaults(
  defineProps<{
    entityId: string;
    entityType?: EntityType;
    size?: "small" | "medium" | "large";
    readonly?: boolean;
  }>(),
  {
    size: "small",
    entityType: undefined,
  },
);

const { t } = useI18n();
const fieldService = useFieldService();
const dialog = useBaseDialog();

const fieldValues = computed(() => {
  return fieldService.getTagFieldValues(props.entityId) ?? [];
});

const availableTags = computed(() => {
  if (!props.entityType) {
    return [];
  }
  return fieldService.getTagFieldKeys(props.entityType).filter((tag) => {
    return !fieldValues.value
      .map((fieldValue) => fieldValue.fieldKeyId)
      .includes(tag.id);
  });
});

const sizeClass = computed(() => {
  switch (props.size) {
    case "small":
      return "h-6!";
    case "medium":
      return "h-8!";
    case "large":
      return "h-10!";
    default:
      return "h-6!";
  }
});

function addTag(fieldKeyId: string) {
  fieldService.createOrUpdateFieldValue(
    {
      id: uuidv4(),
      entityId: props.entityId,
      fieldKeyId,
    } as FieldValueDto,
    { noSuccessPrompt: true },
  );
}

function removeTag(fieldValueId: string) {
  fieldService.deleteFieldValue(fieldValueId);
}

function openTagEditDialog() {
  const dialogProps: RenameTagDialogData = {
    entityType: props.entityType,
  };

  const renameTagDialogProps: BaseDialogProps = {
    header: t("processes.manageTags"),
    props: dialogProps,
  };

  dialog.open(RenameTagDialog, renameTagDialogProps);
}
</script>

<template>
  <div class="flex flex-col gap-2 justify-center">
    <div class="flex flex-row flex-wrap gap-2" data-testid="tags-display">
      <div class="flex flex-row flex-wrap gap-2">
        <BaseButton
          v-for="fieldValue in fieldValues"
          :key="fieldValue.id"
          rounded
          pt:root:class="text-sm"
          pt:label:class="max-w-60 overflow-x-hidden! text-ellipsis!"
          :label="fieldService.getFieldKey(fieldValue.fieldKeyId)?.name"
          :pt:root:style="{
            background: `#${fieldService.getFieldKey(fieldValue.fieldKeyId)?.colorHex}`,
            'border-color': `#${fieldService.getFieldKey(fieldValue.fieldKeyId)?.colorHex}`,
          }"
          :class="readonly ? `pointer-events-none ${sizeClass}` : sizeClass"
          @click="removeTag(fieldValue.id)"
        />

        <BaseButton
          v-for="tag in readonly ? [] : availableTags"
          :key="tag.id"
          rounded
          severity="secondary"
          pt:root:class="text-sm"
          :label="tag.name"
          :pt="{
            root: {
              class: 'border border-surface!',
            },
            label: 'max-w-60 overflow-x-hidden! text-ellipsis!',
          }"
          :class="sizeClass"
          @click="addTag(tag.id)"
        />
      </div>
    </div>

    <BaseDivider
      v-if="!readonly && (availableTags.length > 0 || fieldValues.length > 0)"
      class="my-1"
    />

    <BaseButton
      v-if="!readonly"
      class="mb-1"
      icon="mdi mdi-plus"
      severity="contrast"
      data-testid="tags-manage"
      :label="t('processes.manageTags')"
      :class="sizeClass"
      @click="openTagEditDialog"
    />
  </div>
</template>

import {
  type DataTableFilterMeta,
  type DataTableFilterMetaData,
  type DataTableOperatorFilterMetaData,
} from "primevue/datatable";
import { type Reactive } from "vue";

import {
  type FilterEntry,
  mapMatchModeForDataTable,
  mapOperatorForDataTable,
} from "@/base/components/filterdatatable/useDataTableFilter.ts";
import { type FieldType } from "@/base/graphql/generated/types.ts";

type FilterValue = Record<
  string,
  string | DataTableFilterMetaData | DataTableOperatorFilterMetaData
>;

export interface DataTableColumn {
  name: string;
  type: FieldType;
  key?: string;
  isFilterable?: boolean;
  isSortable?: boolean;
  dynamicWidth?: boolean;
  width?: "large" | "medium" | "small" | "default";
  options?: {
    id: string;
    value: string;
  }[];
}

export interface EditableDataTableColumn<T> extends DataTableColumn {
  editKey: keyof T;
  autofocus?: boolean;
}

export function isEditableColumn<T>(
  column: DataTableColumn | undefined,
): column is EditableDataTableColumn<T> {
  if (!column) {
    return false;
  }
  return "editKey" in column;
}

export function createFilters(
  availableColumns: DataTableColumn[],
  technicalKeys: Reactive<Map<string, string>>,
  listFilter: FilterEntry[],
): DataTableFilterMeta {
  return (
    availableColumns?.reduce((filterRecord, column) => {
      const entryName = `cells.${column.key ?? column.name}.content`;
      technicalKeys.set(entryName, column.key ?? column.name);
      const existingEntry = listFilter.find(
        (entry) => entry.technicalKey === column.key,
      );

      if (existingEntry) {
        filterRecord[entryName] = {
          operator: mapOperatorForDataTable(existingEntry.operator),
          constraints: existingEntry.constraints.map((constraint) => {
            return {
              value: constraint.value,
              matchMode: mapMatchModeForDataTable(constraint.matchMode),
            };
          }),
        };
      } else {
        filterRecord[entryName] = {
          operator: "and",
          constraints: [{ value: null, matchMode: "contains" }],
        };
      }
      return filterRecord;
    }, {} as FilterValue) ?? {}
  );
}

export function isDataTableFilterMetaData(
  item: object,
): item is DataTableFilterMetaData {
  return "value" in item;
}

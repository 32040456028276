<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import LoadingIndicator from "@/base/components/loading/LoadingIndicator.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
  type FileEto,
} from "@/base/graphql/generated/types.ts";
import { useFileService } from "@/base/services/file/FileService.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const fileStore = useFileService();

const pendingFile = ref<FileEto | undefined>();
const file = computed<File | undefined>(() => {
  if (pendingFile.value?.name) {
    // We need to create a dummy file in place to show the new file name in the file-input
    return new File([], pendingFile.value.name);
  }
  return fileStore.getById(props.fieldValue?.id ?? "");
});

onMounted(async () => {
  if (props.fieldValue?.id) {
    await fileStore.fetch(
      props.fieldValue.id,
      props.fieldValue.value?.valueEntityId,
    );
  }
});

async function downloadFile() {
  await fileStore.download(props.fieldValue?.value?.valueEntityId ?? "");
}
</script>

<template>
  <ReadonlyField :label="props.label">
    <div class="flex break-all text-sm">
      <a @click.exact="downloadFile">
        <LoadingIndicator v-if="!file" />
        <span
          v-else
          class="text-primary-500 hover:text-primary-300 cursor-pointer"
          >{{ file.name }}</span
        >
      </a>
    </div>
  </ReadonlyField>
</template>

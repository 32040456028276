<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { useFile } from "@/app/process/composables/useFile.ts";
import { useFormattedProcessOutputValue } from "@/app/process/composables/useFormattedProcessOutputValue.ts";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import LoadingIndicator from "@/base/components/loading/LoadingIndicator.vue";
import {
  type ActivityOutputDto,
  ActivityOutputType,
  type ProcessOutputDto,
} from "@/base/graphql/generated/types.ts";
import { extractDomain } from "@/base/utils/string.ts";

const props = defineProps<{
  activityOutput: ActivityOutputDto;
  processOutput: ProcessOutputDto | undefined;
}>();

const { t } = useI18n();

const {
  file,
  downloadFile,
  isLoading: isFetchingFile,
} = useFile(props.processOutput?.value?.valueEntityId ?? undefined);

const formattedOutputValue = useFormattedProcessOutputValue(
  props.processOutput,
  props.activityOutput.type ?? undefined,
);
</script>

<template>
  <div v-if="props.activityOutput.type === ActivityOutputType.String">
    <TextDisplay :value="formattedOutputValue" markdown />
  </div>

  <div v-else-if="props.activityOutput.type === ActivityOutputType.Url">
    <div v-if="formattedOutputValue" class="flex flex-row items-center gap-1">
      <a
        v-if="formattedOutputValue"
        class="text-primary-500 hover:text-primary-300 cursor-pointer"
        :href="formattedOutputValue"
        target="_blank"
        >{{
          extractDomain(
            formattedOutputValue,
            props.activityOutput.name ??
              t("processes.singleView.outputTypes.URL"),
          )
        }}
      </a>
      <i class="mdi mdi-open-in-new" />
    </div>
  </div>

  <div v-else-if="props.activityOutput.type === ActivityOutputType.File">
    <a @click="downloadFile">
      <LoadingIndicator v-if="isFetchingFile" />
      <span
        v-else
        v-tooltip.bottom="t('action.download')"
        class="text-primary-500 hover:text-primary-300 cursor-pointer"
        >{{ file?.name }}</span
      >
    </a>
  </div>

  <span v-else>
    {{ formattedOutputValue }}
  </span>
</template>

import { usePersonStore } from "@/app/person/stores/PersonStore.ts";
import {
  FieldType,
  type FieldValueAddressV1,
  type FieldValueBankAccountV1,
  type FieldValueDto,
  type FieldValueEto,
} from "@/base/graphql/generated/types.ts";

// custom type FieldValueDto | FieldValueEto
type FieldValue = FieldValueDto | FieldValueEto;

export function addressValueToString(value: FieldValueDto | FieldValueEto) {
  const address = JSON.parse(
    value.value?.valueJson ?? "{}",
  ) as FieldValueAddressV1;
  const country = address?.country
    ? (address.country as string).toString() + "-"
    : "";
  return `${country}${address?.zipCode ?? ""} ${address?.province ?? ""} ${
    address?.city ?? ""
  } - ${address?.street ?? ""}`;
}

export function bankAccountValueToString(value: FieldValueDto | FieldValueEto) {
  const bankAccount = JSON.parse(
    value.value?.valueJson ?? "{}",
  ) as FieldValueBankAccountV1;

  return `${bankAccount.bic} - ${bankAccount?.iban ?? ""} ${bankAccount?.institute ?? ""} ${
    bankAccount?.accountHolder ?? ""
  }`;
}

export const fieldTypeToMappingFunction = {
  [FieldType.Person]: (value: FieldValue) => value.value?.valueEntityId,
  [FieldType.String]: (value: FieldValue) => value.value?.valueString,
  [FieldType.Date]: (value: FieldValue) => value.value?.valueDate,
  [FieldType.Boolean]: (value: FieldValue) => value.value?.valueBoolean,
  [FieldType.Number]: (value: FieldValue) => value.value?.valueNumber,
  [FieldType.Url]: (value: FieldValue) => value.value?.valueString,
  [FieldType.Address]: (value: FieldValue) => addressValueToString(value),
  [FieldType.BankAccount]: (value: FieldValue) =>
    bankAccountValueToString(value),
  [FieldType.Json]: (value: FieldValue) =>
    JSON.parse(value.value?.valueJson ?? "{}"),
  [FieldType.Tag]: (value: FieldValue) => value.fieldKeyId,
  [FieldType.File]: (value: FieldValue) => value.value?.valueEntityId,
  [FieldType.People]: (value: FieldValue) => value.value?.valueEntityIds,
  [FieldType.Phase]: (value: FieldValue) => value.value?.valueString,
};

export function getFieldValue(
  fieldType: FieldType,
  fieldValue: FieldValueDto | FieldValueEto,
  booleanTrueValue: string,
  booleanFalseValue: string,
): string {
  const mappingFunction = fieldTypeToMappingFunction[fieldType];

  if (!mappingFunction) {
    console.error(`No mapping function for field type ${fieldType}`);
    return "";
  }
  const mappedValue = mappingFunction(fieldValue);

  if (FieldType.Boolean === fieldType && mappedValue !== undefined) {
    return mappedValue ? booleanTrueValue : booleanFalseValue;
  }

  if (FieldType.Person === fieldType && typeof mappedValue === "string") {
    const personStore = usePersonStore();
    const person = personStore.getById(mappedValue);
    return person?.name ?? "";
  }

  if (!mappedValue) {
    return "";
  }

  return mappedValue.toString();
}

export function parseBankAccountValue(
  value: FieldValueDto | FieldValueEto,
  separator: string,
) {
  const bankAccountParts: string[] = [];
  try {
    if (value.value?.valueJson !== undefined) {
      const parsedBankAccount = JSON.parse(
        value.value?.valueJson,
      ) as FieldValueBankAccountV1;

      function parsePartOfBankAccount(part: keyof FieldValueBankAccountV1) {
        if (parsedBankAccount[part]) {
          bankAccountParts.push(parsedBankAccount[part].toString());
        }
      }

      parsePartOfBankAccount("bic");
      parsePartOfBankAccount("iban");
      parsePartOfBankAccount("institute");
      parsePartOfBankAccount("accountHolder");
    }
  } catch (e) {
    console.error(`Error parsing bank account value ${value.value?.valueJson}`);
    console.error(e);
  }
  return bankAccountParts.join(separator);
}

export function parseAddressValue(value: FieldValueDto | FieldValueEto) {
  const parsedAddress = JSON.parse(
    value.value?.valueJson,
  ) as FieldValueAddressV1;
  const addressParts: string[] = [];

  function parsePartOfAddress(part: keyof FieldValueAddressV1) {
    if (parsedAddress[part]) {
      addressParts.push(parsedAddress[part].toString());
    }
  }

  parsePartOfAddress("country");
  parsePartOfAddress("zipCode");
  parsePartOfAddress("province");
  parsePartOfAddress("city");
  parsePartOfAddress("street");

  return addressParts.join(" - ");
}

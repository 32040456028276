<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { MenuItemCommandEvent } from "primevue/menuitem";
import { useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import { getSeverityColor } from "@/base/components/button/button.utils.ts";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import BaseTieredMenu from "@/base/components/tieredmenu/BaseTieredMenu.vue";

withDefaults(
  defineProps<{
    model: BaseMenuItem[];
    disabled?: boolean;
    loading?: boolean;
    noPadding?: boolean;
    appendTo?: "self" | "body";
  }>(),
  {
    disabled: false,
    loading: false,
    noPadding: false,
    appendTo: "body",
  },
);

const { t } = useI18n();
const moreMenuRef = useTemplateRef("moreMenuRef");
</script>

<template>
  <BaseButton
    v-tooltip="t('ui.actions')"
    data-testid="more-button"
    icon="mdi mdi-dots-vertical"
    text
    severity="secondary"
    :disabled="disabled"
    :loading="loading"
    :pt="{
      root: noPadding ? 'p-0! w-fit!' : '',
    }"
    @click.stop="moreMenuRef?.toggle($event)"
  />

  <BaseTieredMenu
    ref="moreMenuRef"
    :appendTo="appendTo"
    :model="model"
    :pt="{
      itemIcon: ({ context }) => ({
        class: getSeverityColor(context.item.severity),
      }),
      itemLabel: ({ context }) => ({
        class: getSeverityColor(context.item.severity),
      }),
      item: ({ context }) => ({
        'data-testid': context.item.testId,
      }),
    }"
  >
    <template #item="{ item, props }">
      <div
        class="p-2!"
        @click.stop="
          (event) => {
            const commandEvent: MenuItemCommandEvent = {
              originalEvent: event,
              item,
            };
            return item.command?.(commandEvent);
          }
        "
      >
        <span v-bind="props.icon" :class="item.icon" />
        <span v-bind="props.label" class="ml-2">
          {{ item.label }}
        </span>
      </div>
    </template>
  </BaseTieredMenu>
</template>

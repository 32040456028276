import { defineStore } from "pinia";

import { useDocumentGenerationRunStore } from "@/app/document/stores/DocumentGenerationRunStore.ts";
import {
  type DocumentGenerationPlaceholderReplacementValuesCheckEto,
  type DocumentGenerationRunDto,
  GetDocumentGenerationPlaceholderReplacementValuesCheckDocument,
} from "@/base/graphql/generated/types.ts";
import { apolloClient } from "@/base/plugins/apollo/apollo.ts";
import { useFileService } from "@/base/services/file/FileService.ts";

export const useDocumentGenerationRunService = defineStore(
  "DocumentGenerationRunService",
  () => {
    const documentGenerationRunStore = useDocumentGenerationRunStore();

    const fileService = useFileService();

    function getById(id: string) {
      return documentGenerationRunStore.getById(id);
    }

    function getAll(entityId: string) {
      return documentGenerationRunStore.getAll({ entityId });
    }

    function markRefetch(entityId: string) {
      documentGenerationRunStore.markRefetch(undefined, { entityId });
    }

    async function downloadDocuments(documentGenerationRunId: string) {
      const documentGenerationRun = documentGenerationRunStore.getById(
        documentGenerationRunId,
      );
      if (!documentGenerationRun) {
        return;
      }

      const generationResults = documentGenerationRun.results ?? [];
      if (generationResults.length === 1 && generationResults[0].resultFileId) {
        await fileService.download(generationResults[0].resultFileId);
      }

      if (generationResults.length > 1) {
        await fileService.downloadMultiple(
          generationResults
            .map((r) => r.resultFileId)
            .filter((s) => s) as string[],
        );
      }
    }

    async function checkPlaceholderReplacementValues(
      documentId: string,
      entityId?: string | null,
    ): Promise<DocumentGenerationPlaceholderReplacementValuesCheckEto> {
      const { data } = await apolloClient.query({
        query: GetDocumentGenerationPlaceholderReplacementValuesCheckDocument,
        variables: { dto: { documentId, entityId } },
        fetchPolicy: "no-cache",
      });
      return data.documentGenerationPlaceholderReplacementValuesCheck;
    }

    function createOrUpdate(
      documentGenerationRunDto: DocumentGenerationRunDto,
    ) {
      return documentGenerationRunStore.createOrUpdate(
        documentGenerationRunDto,
      );
    }

    return {
      createOrUpdate,
      getById,
      getAll,
      markRefetch,
      downloadDocuments,
      checkPlaceholderReplacementValues,
    };
  },
);

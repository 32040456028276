<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { usePersonServiceV2 } from "@/app/person/services/PersonServiceV2.ts";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import { useTitle } from "@/base/composables/useTitle.ts";
import { SortDirection } from "@/base/graphql/generated/types.ts";

const { t } = useI18n();
const personService = usePersonServiceV2();

useTitle(t("person.person", 2));

const { availableTags, displayColumns, listRowItems, isLoading } =
  storeToRefs(personService);

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "create-person",
    label: t("person.create"),
    icon: "mdi mdi-plus",
    command: async () =>
      await personService.createOrUpdate(
        { id: uuidv4(), name: "Neu" },
        { navigateToEditView: true },
      ),
  },
]);
</script>

<template>
  <CFilteredDataTable
    class="p-4!"
    stripedRows
    :contextKey="DataTableContextKeys.personList"
    :sortField="personService.mandatoryColumns[0].name"
    :sortDirection="SortDirection.Asc"
    :rowItems="listRowItems"
    :availableTags="availableTags"
    :exposedColumns="displayColumns"
    :mandatoryColumns="personService.mandatoryColumns"
    :isLoading="isLoading"
  >
    <template #afterHeader>
      <BaseActionsButton :model="actionsMenuItems" />
    </template>
  </CFilteredDataTable>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import FieldCard from "@/app/process/components/field/FieldCard.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import LoadingIndicator from "@/base/components/loading/LoadingIndicator.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
  type FileEto,
} from "@/base/graphql/generated/types.ts";
import { useFileService } from "@/base/services/file/FileService.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const emits = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "delete"): void;
}>();

const { t } = useI18n();
const fileStore = useFileService();

const pendingFile = ref<FileEto | undefined>();
const file = computed<File | undefined>(() => {
  if (pendingFile.value?.name) {
    // We need to create a dummy file in place to show the new file name in the file-input
    return new File([], pendingFile.value.name);
  }
  return fileStore.getById(props.fieldValue?.id ?? "");
});

onMounted(async () => {
  if (props.fieldValue?.id) {
    await fileStore.fetch(
      props.fieldValue.id,
      props.fieldValue.value?.valueEntityId,
    );
  }
});

function emitUpdate(value: string) {
  emits("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueEntityId: value,
    },
  });
}

async function downloadFile() {
  await fileStore.download(props.fieldValue?.value?.valueEntityId ?? "");
}

async function uploadFile(newFile: File | File[]) {
  if (newFile instanceof Array) {
    newFile = newFile[0];
  }

  const fileEto = await fileStore.upload(newFile);

  if (props.fieldValue?.value?.valueEntityId) {
    fileStore.removeById(props.fieldValue.value?.valueEntityId);
  }

  if (!fileEto) {
    return;
  }

  pendingFile.value = fileEto;
  emitUpdate(fileEto.id);
}

function deleteFile(fileId: string | undefined) {
  if (!fileId) {
    return;
  }
  fileStore.removeById(fileId);
  pendingFile.value = undefined;
}
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <LoadingIndicator v-if="!file" />

      <p
        v-else
        class="block text-base truncate text-primary-500! hover:text-primary-300! pointer-events-auto"
        @click.stop="downloadFile"
      >
        {{ file.name }}
      </p>
    </template>

    <template #edit>
      <PFileUpload
        v-if="!file"
        mode="basic"
        auto
        class="w-full"
        :chooseLabel="t('ui.selectFile')"
        :fileLimit="1"
        :chooseButtonProps="{
          size: 'small',
          severity: 'secondary',
        }"
        :customUpload="true"
        :pt="{
          root: 'justify-start!',
        }"
        @select="uploadFile($event.files)"
      />

      <BaseButton
        v-else
        v-tooltip.bottom="t('ui.deleteFileTooltip')"
        fluid
        icon="mdi mdi-file-remove-outline"
        iconPos="right"
        severity="danger"
        outlined
        :pt="{
          label: 'truncate',
        }"
        :label="file.name"
        @click="deleteFile(props.fieldValue?.id)"
      />
    </template>
  </FieldCard>
</template>

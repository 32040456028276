<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import { ActivityOutputType } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";

interface AutoCompleteOption {
  name: string;
  value: string;
}

const props = defineProps<{
  outputId: string;
}>();

const { t } = useI18n();

const processService = useProcessService();
const activityService = useActivityService();

const output = computed(() => {
  return activityService.getOutput(props.outputId);
});

function getActivityOutputTypes() {
  return Object.values(ActivityOutputType).map((rawType) => {
    return {
      name: translateEnum("processes.singleView.outputTypes", rawType),
      value: rawType,
    };
  });
}

function toActivityOutputType(type: string): ActivityOutputType {
  switch (type) {
    case ActivityOutputType.Boolean.toUpperCase():
      return ActivityOutputType.Boolean;
    case ActivityOutputType.Date.toUpperCase():
      return ActivityOutputType.Date;
    case ActivityOutputType.DateTime.toUpperCase():
      return ActivityOutputType.DateTime;
    case ActivityOutputType.File.toUpperCase():
      return ActivityOutputType.File;
    case ActivityOutputType.Document.toUpperCase():
      return ActivityOutputType.Document;
    case ActivityOutputType.Number.toUpperCase():
      return ActivityOutputType.Number;
    case ActivityOutputType.String.toUpperCase():
      return ActivityOutputType.String;
    case ActivityOutputType.Url.toUpperCase():
      return ActivityOutputType.Url;
    case ActivityOutputType.Person.toUpperCase():
      return ActivityOutputType.Person;
    case ActivityOutputType.People.toUpperCase():
      return ActivityOutputType.People;
    case ActivityOutputType.Json.toUpperCase():
      return ActivityOutputType.Json;
    default:
      throw new Error(`"${type}" is not a valid ActivityOutputType`);
  }
}

const selectedItem = ref<AutoCompleteOption>({
  name: translateEnum(
    "processes.singleView.outputTypes",
    output.value?.type ?? ActivityOutputType.String,
  ),
  value: output.value?.type ?? ActivityOutputType.String,
});

async function createOrUpdateOutput(value: AutoCompleteOption) {
  try {
    await activityService.createOrUpdateOutput(
      {
        id: props.outputId,
        type: toActivityOutputType(value.value),
      },
      output.value?.activityId ?? "undefined",
    );

    // if the output type of custom activity is changed, delete the existing output instance
    // from the cache. the backend deletes the output instance from the database.
    // this is necessary because custom activities have no versioning
    processService.deleteCustomOutputInstancesFromCache(props.outputId);
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <BaseAutoComplete
    fluid
    optionLabel="name"
    :modelValue="selectedItem"
    :placeholder="t('processes.singleView.outputTypeLabel')"
    :options="getActivityOutputTypes()"
    @optionSelect="createOrUpdateOutput"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import StartProcessDialog from "@/app/process/components/action/start/StartProcessDialog.vue";
import DataTableStatusColumn from "@/app/process/components/output/DataTableStatusColumn.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import { RestService } from "@/app/process/services/RestService.ts";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import {
  createFieldCellsByValues,
  DataTableContextKeys,
} from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import {
  type CellContent,
  type RowItem,
} from "@/base/components/filterdatatable/TableTypes.ts";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type BaseDialogProps,
  type StartProcessDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { useTitle } from "@/base/composables/useTitle.ts";
import {
  EntityType,
  FieldType,
  type ProcessEto,
  ProcessState,
} from "@/base/graphql/generated/types.ts";
import { SortDirection } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const fieldService = useFieldService();
const activityService = useActivityService();
const processService = useProcessService();
const dialog = useBaseDialog();

const { processes, isLoadingComputed } = storeToRefs(processService);

const { t } = useI18n();
const router = useRouter();

useTitle(t("processes.process", 2));

const i18n = {
  processTitle: t("processes.process"),
  processTemplateTitle: t("processes.processTemplate"),
  yes: t("boolean.yes"),
  no: t("boolean.no"),
  progressTitle: t("processes.progress"),
};

const mandatoryColumns: DataTableColumn[] = [
  { key: "name", name: i18n.processTitle, type: FieldType.String },
  {
    key: "activity_name",
    name: i18n.processTemplateTitle,
    type: FieldType.String,
  },
  {
    key: "state",
    name: i18n.progressTitle,
    type: FieldType.String,
    options: Object.values(ProcessState).map((key, index) => ({
      id: index.toString(),
      value: translateEnum("processes.state", key),
    })),
    width: "small",
  },
];

const selectedTab = ref(t("processes.listView.goToProcesses"));
const isDownloading = ref<boolean>(false);

function showStartProcessDialog() {
  const headerTitle = t("processes.startButton.startProcess");

  const startProcessDialogData: StartProcessDialogData = {
    showProcessTemplateSelect: true,
    rootActivity: undefined,
  };

  const startProcessDialogProps: BaseDialogProps = {
    header: headerTitle,
    props: startProcessDialogData,
  };

  dialog.open(StartProcessDialog, startProcessDialogProps);
}

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    label: t("processes.startButton.startProcess"),
    icon: "mdi mdi-play",
    command: () => showStartProcessDialog(),
  },
  {
    testId: "download-json-data",
    label: t("processes.listView.downloadJSON"),
    icon: "mdi mdi-download",
    command: async () => await downloadProcessListStats(),
  },
]);

const displayColumns = computed<DataTableColumn[]>(() => {
  return [...mandatoryColumns].concat(
    fieldService
      .getNonTagFieldKeys(EntityType.Process)
      .sort((a, b) => a.name.localeCompare(b.name))
      .reverse()
      .map((fieldKey) => {
        return {
          name: fieldKey.name,
          key: fieldKey.key,
          type: fieldKey.type,
        };
      }),
  );
});

const availableTags = computed(() =>
  fieldService.getTagFieldKeys(EntityType.Process),
);

const rowItems = computed((): RowItem[] => {
  return processes.value.map((process) => {
    const cells: Record<string, CellContent> = {
      ...createTitleCell(process),
      ...createTemplateCell(process),
      ...createProgressCell(process),
      ...createFieldCellsByValues(process.fields ?? []),
    };

    return {
      key: process.id,
      cells,
      tags: [],
      to: {
        name: "processView",
        params: { processId: process.id },
      },
    };
  });
});

function createTitleCell(process: ProcessEto): Record<string, CellContent> {
  return {
    ["name"]: {
      content: process.name ?? "",
      props: { class: "pointer" },
    },
  };
}

function createTemplateCell(process: ProcessEto): Record<string, CellContent> {
  return {
    ["activity_name"]: {
      content:
        activityService.getActivity(process.startActivityId ?? "undefined")
          ?.name ?? "",
      to: {
        name: "processTemplate",
        params: { rootActivityId: process.startActivityId },
      },
    },
  };
}

function createProgressCell(process: ProcessEto): Record<string, CellContent> {
  return {
    ["state"]: {
      component: DataTableStatusColumn,
      props: {
        state: process.state.toString(),
        outputPresentCount: process.status?.outputPresentCount ?? 0,
        outputAllCount: process.status?.outputAllCount ?? 0,
        enumTranslationPath: "processes.state",
      },
      mappedContent: translateEnum("processes.state", process.state),
      content: Object.values(ProcessState).indexOf(process.state).toString(),
    },
  };
}

async function downloadProcessListStats() {
  isDownloading.value = true;
  try {
    await RestService.downloadProcessListStats();
  } catch (error) {
    console.error(error);
  } finally {
    isDownloading.value = false;
  }
}

async function navigateToProcessTemplateList(value: string) {
  if (value === t("processes.listView.goToTemplates")) {
    await router.push({ name: "processTemplateList" });
  }
}
</script>

<template>
  <CFilteredDataTable
    v-if="selectedTab === t('processes.listView.goToProcesses')"
    class="!p-4"
    stripedRows
    :contextKey="DataTableContextKeys.processList"
    :sortField="mandatoryColumns[0].key"
    :sortDirection="SortDirection.Asc"
    :rowItems="rowItems"
    :availableTags="availableTags"
    :exposedColumns="displayColumns"
    :mandatoryColumns="mandatoryColumns"
    :isLoading="isLoadingComputed"
  >
    <template #afterHeader>
      <div class="flex flex-row gap-4 items-center">
        <PSelectButton
          v-model="selectedTab"
          size="small"
          :allowEmpty="false"
          :options="[
            t('processes.listView.goToProcesses'),
            t('processes.listView.goToTemplates'),
          ]"
          :pt:root="'h-8!'"
          @valueChange="navigateToProcessTemplateList"
        >
          <template #option="{ option, index }">
            <span
              :data-testid="
                index === 1 ? 'go-to-process-templates' : 'go-to-processes'
              "
              >{{ option }}</span
            >
          </template>
        </PSelectButton>

        <BaseActionsButton
          :model="actionsMenuItems"
          :loading="isDownloading"
          :disabled="isDownloading"
        />
      </div>
    </template>
  </CFilteredDataTable>
</template>

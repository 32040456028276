<script lang="ts" setup>
import dayjs from "dayjs";
import { computed, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import DocumentGenerationRunListActions from "@/app/document/components/generation/DocumentGenerationRunListActions.vue";
import { useShowGenerateDocumentDialog } from "@/app/document/composables/useShowGenerateDocumentDialog.ts";
import { useDocumentGenerationRunService } from "@/app/document/services/DocumentGenerationRunService.ts";
import PersonField from "@/app/process/components/field/PersonField.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type CellContent } from "@/base/components/filterdatatable/TableTypes.ts";
import {
  type DocumentGenerationRunEto,
  EntityType,
  FieldType,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  entityId: string;
}>();

const { t } = useI18n();
const documentGenerationRunService = useDocumentGenerationRunService();
const { open: showGenerateDocumentDialog } = useShowGenerateDocumentDialog(
  true,
  props.entityId,
  EntityType.Process,
);

const i18n = {
  nameTitle: t("documentGenerationRun.name"),
  createdDateTitle: t("documentGenerationRun.createdDate"),
  authorTitle: t("documentGenerationRun.author"),
  actionsTitle: t("ui.actions"),
};

watchEffect(() => documentGenerationRunService.markRefetch(props.entityId));

const documentGenerationRuns = computed(() =>
  documentGenerationRunService.getAll(props.entityId),
);

const rowItems = computed(() => {
  return documentGenerationRuns.value.map((documentGenerationRun) => {
    const cells: Record<string, CellContent> = {
      ...createNameCell(documentGenerationRun),
      ...createCreatedCell(documentGenerationRun),
      ...createAuthorCell(documentGenerationRun),
      ...createActionsCell(documentGenerationRun),
    };

    return {
      key: documentGenerationRun.id,
      cells,
      tags: [],
    };
  });
});

function createNameCell(documentGenerationRun: DocumentGenerationRunEto) {
  return {
    [i18n.nameTitle]: {
      content: documentGenerationRun.name,
    },
  };
}

function createCreatedCell(documentGenerationRun: DocumentGenerationRunEto) {
  return {
    [i18n.createdDateTitle]: {
      content: documentGenerationRun.created
        ? dayjs(documentGenerationRun.created).format("YYYY-MM-DD, HH:mm")
        : "",
    },
  };
}

function createAuthorCell(documentGenerationRun: DocumentGenerationRunEto) {
  return {
    [i18n.authorTitle]: {
      component: PersonField,
      content: documentGenerationRun.authorId ?? "",
      props: {
        initialValue: documentGenerationRun.authorId,
        readonly: true,
      },
    },
  };
}

function createActionsCell(
  documentGenerationRun: DocumentGenerationRunEto,
): Record<string, CellContent> {
  return {
    [i18n.actionsTitle]: {
      component: DocumentGenerationRunListActions,
      props: {
        documentGenerationRunId: documentGenerationRun.id,
        class: "justify-end",
      },
    },
  };
}
const mandatoryColumns: DataTableColumn[] = [
  {
    key: i18n.nameTitle,
    name: i18n.nameTitle,
    type: FieldType.String,
    dynamicWidth: true,
  },
  {
    key: i18n.createdDateTitle,
    name: i18n.createdDateTitle,
    type: FieldType.Date,
    dynamicWidth: true,
  },
  {
    key: i18n.authorTitle,
    name: i18n.authorTitle,
    type: FieldType.String,
    dynamicWidth: true,
  },
  {
    key: i18n.actionsTitle,
    name: i18n.actionsTitle,
    type: FieldType.String,
    isFilterable: false,
    isSortable: false,
    width: "small",
  },
];
</script>

<template>
  <CFilteredDataTable
    clientSide
    stripedRows
    :contextKey="DataTableContextKeys.processViewDocuments"
    :rowItems="rowItems"
    :mandatoryColumns="mandatoryColumns"
    :exposedColumns="mandatoryColumns"
    :availableTags="[]"
  >
    <template #empty>
      <span>{{ t("document.generationTable.empty") }}</span>

      <BaseButton
        text
        class="ml-2"
        :label="t('document.generate')"
        @click="showGenerateDocumentDialog"
      />
    </template>
  </CFilteredDataTable>
</template>

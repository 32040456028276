<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { usePersonService } from "@/app/person/services/PersonService.ts";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey?: FieldKeyDto;
  fieldValue?: FieldValueDto;
  initialValue?: string[];
  label?: string;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: FieldValueDto | string[]): void;
  (e: "delete"): void;
}>();

const personService = usePersonService();
const { t } = useI18n();

const valueOverrides: Partial<FieldValueDto> = {
  // Old values must be cleared, if eg. the field switched to an option field.
  optionId: null,
  value: {
    valueString: null,
    valueDate: null,
    valueNumber: null,
    valueBoolean: null,
    valueEntityId: null,
    valueEntityIds: null,
    valueJson: null,
  },
};

const allPersons = computed(() => personService.getAll());
const selectedIds = computed(
  () => props.fieldValue?.value?.valueEntityIds ?? props.initialValue ?? [],
);

const selectedPersons = computed(() =>
  selectedIds.value
    .map((id) => personService.getById(id))
    .filter((it) => it !== undefined)
    .sort((a, b) => (a?.name ?? "").localeCompare(b?.name ?? "")),
);

function emitUpdate(selectedPersonIds?: string[]) {
  if (!selectedPersonIds) {
    return;
  }

  if (props.fieldValue) {
    emit("update", {
      ...props.fieldValue,
      ...valueOverrides,
      value: {
        valueEntityIds: selectedPersonIds,
      },
    });
  } else {
    if (selectedPersonIds.length === 0) {
      emit("delete");
      return;
    }
    emit("update", selectedPersonIds);
  }
}
</script>

<template>
  <div class="flex flex-col">
    <div v-if="!props.readonly">
      <PFloatLabel :variant="'on'">
        <PMultiSelect
          id="person-multiselect"
          data-testid="person-field-multiselect"
          filter
          size="small"
          fluid
          :disabled="readonly"
          :filterMatchMode="'contains'"
          :maxSelectedLabels="3"
          :modelValue="selectedIds"
          :options="allPersons"
          :resetFilterOnHide="true"
          :optionLabel="(option) => option.name"
          :optionValue="(option) => option.id"
          :showToggleAll="false"
          :showClear="false"
          :virtualScrollerOptions="{ itemSize: 38 }"
          @update:modelValue="emitUpdate($event)"
        >
          <template #value>
            <div class="flex flex-row gap-2">
              <span class="mdi mdi-human-greeting"></span>
              <span class="ml-1">
                {{ t("person.personsSelected", selectedIds.length) }}
              </span>
            </div>
          </template>
        </PMultiSelect>

        <label for="person-multiselect">{{ label }}</label>
      </PFloatLabel>

      <ReadonlyField
        v-if="selectedIds.length > 0"
        :label="props.label ?? ''"
        class="mt-2"
      >
        <div class="flex flex-wrap gap-1">
          <PSplitButton
            v-for="person in selectedPersons"
            :key="person?.id"
            size="small"
            severity="secondary"
            dropdownIcon="mdi mdi-close"
            rounded
            data-testid="person-field-chip"
            :pt="{
              pcMenu: {
                root: 'invisible!',
              },
              pcButton: {
                root: 'border-1! border-surface! border-r-0!',
              },
              pcDropdown: {
                root: {
                  class: '-ml-1! border-1! border-surface!',
                  onClick: () => {
                    emitUpdate(selectedIds.filter((id) => id !== person?.id));
                  },
                },
              },
            }"
          >
            <RouterLink
              class="text-start"
              :to="{
                name: 'personView',
                params: { personId: person?.id },
              }"
            >
              <span class="text-xs">{{ person?.name }}</span>
            </RouterLink>

            <template #dropdownicon>
              <i class="mdi mdi-close mr-1"></i>
            </template>
          </PSplitButton>
        </div>
      </ReadonlyField>
    </div>

    <p v-else>
      {{ t("person.personsSelected", selectedIds.length) }}
    </p>
  </div>
</template>

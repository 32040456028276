<script setup lang="ts">
import { ControlButton } from "@vue-flow/controls";

import BaseButton from "@/base/components/button/BaseButton.vue";

const props = defineProps<{
  icon: string;
  tooltip?: string;
}>();

const emits = defineEmits<(e: "click") => void>();
</script>

<template>
  <ControlButton @click="emits('click')">
    <BaseButton
      v-tooltip.right="props.tooltip"
      text
      severity="secondary"
      :icon="`mdi ${props.icon}`"
    />
  </ControlButton>
</template>

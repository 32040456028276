<script setup lang="ts">
import { isValid } from "iban-ts";
import { computed, nextTick, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import FieldCard from "@/app/process/components/field/FieldCard.vue";
import { parseBankAccountValue } from "@/app/process/components/field/FieldHelper.ts";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import BaseMessage from "@/base/components/message/BaseMessage.vue";
import {
  type FieldKeyDto,
  type FieldValueBankAccountV1,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const { t } = useI18n();

const emits = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "delete"): void;
}>();

const bankAccount: FieldValueBankAccountV1 = reactive(
  props.fieldValue?.value?.valueJson
    ? JSON.parse(props.fieldValue?.value?.valueJson)
    : {
        _type: "FieldValueBankAccountV1",
        bic: undefined,
        iban: undefined,
        institute: undefined,
        accountHolder: undefined,
      },
);

const isValidIban = ref(
  bankAccount.iban === undefined ? true : isValid(bankAccount.iban ?? ""),
);
const bankAccountReadOnly = computed(() => {
  return props.fieldValue.value?.valueJson
    ? parseBankAccountValue(props.fieldValue, " ")
    : "";
});

async function emitUpdate(closeCallback?: () => void) {
  const bankAccountJson = JSON.stringify(bankAccount);

  emits("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueJson: bankAccountJson,
    },
  });

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  await nextTick();
  setTimeout(() => closeCallback?.(), 300);
}
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <p class="block text-base text-ellipsis overflow-hidden">
        {{ bankAccountReadOnly }}
      </p>
    </template>

    <template #edit="{ closeCallback }">
      <div class="flex flex-col gap-4" @keydown.enter="closeCallback">
        <BaseTextField
          :initialValue="bankAccount.institute ?? undefined"
          :label="t('bankAccount.institute')"
          @update="
            (value) => {
              bankAccount.institute = value;
              emitUpdate();
            }
          "
        />

        <BaseTextField
          :initialValue="bankAccount.bic ?? undefined"
          :label="t('bankAccount.bic')"
          @update="
            (value) => {
              bankAccount.bic = value;
              emitUpdate();
            }
          "
        />

        <BaseTextField
          :initialValue="bankAccount.iban ?? ''"
          :label="t('bankAccount.iban')"
          :invalid="!isValidIban"
          @keyDown="
            (value) => {
              isValidIban = isValid(value);
            }
          "
          @update="
            (value) => {
              if (isValidIban) {
                bankAccount.iban = value;
                emitUpdate();
              }
            }
          "
        />

        <BaseMessage
          v-if="!isValidIban"
          class="-mt-4"
          severity="error"
          size="small"
          variant="simple"
        >
          {{ t("bankAccount.ibanInvalid") }}
        </BaseMessage>

        <BaseTextField
          :initialValue="bankAccount.accountHolder ?? ''"
          :label="t('bankAccount.accountHolder')"
          @update="
            (value) => {
              bankAccount.accountHolder = value;
              emitUpdate();
            }
          "
        />
      </div>
    </template>
  </FieldCard>
</template>

import { type RouteRecordRaw } from "vue-router";

import ProcessTemplateRootView from "@/app/process/views/ProcessTemplateRootView.vue";
import { i18n } from "@/base/i18n/i18n.ts";

const { t } = i18n.global;

export const activitiesRoutes: RouteRecordRaw[] = [
  {
    path: "/templates",
    component: ProcessTemplateRootView,
    children: [
      {
        name: "processTemplateList",
        path: "list",
        meta: {
          breadcrumb: t("processes.processTemplate", 2),
        },
        children: [],
      },
      {
        name: "processTemplate",
        path: ":rootActivityId",
        props: true,
        meta: {
          breadcrumbParent: "processTemplateList",
          breadcrumb: null,
        },
        children: [
          {
            name: "activity",
            path: "activity/:activityId",
            props: true,
            meta: {
              breadcrumb: null,
            },
            children: [],
          },
          {
            name: "activityGraph",
            path: "graph",
            props: true,
            meta: {
              breadcrumb: t("processes.graphViewer.title"),
            },
            children: [],
          },
        ],
      },
    ],
  },
];

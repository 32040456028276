export function getSeverityColor(
  severity: "primary" | "secondary" | "warn" | "danger" | "info" | undefined,
): string {
  switch (severity) {
    case "primary":
      return "text-primary!";
    case "secondary":
      return "text-surface-500!";
    case "warn":
      return "text-orange-500!";
    case "danger":
      return "text-red-500!";
    case "info":
      return "text-info!";
    default:
      return "";
  }
}

<script setup lang="ts">
/* eslint-disable */
// @ts-nocheck

import { ref } from "vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";

const model = defineModel<string[]>({ required: true, default: [""] });

const itemCount = ref<number>(1);

function addItem() {
  itemCount.value++;
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col gap-1">
      <p class="font-bold">Options:</p>
      <p class="text-xs text-surface-400!">
        Add options for the field key. Options can be used to define a set of
        possible values for a field key. Keep this box empty if you don't want
        options
      </p>
    </div>

    <div
      v-for="index in itemCount"
      :key="index"
      class="flex flex-row gap-2 mt-2"
    >
      <BaseTextField
        class="w-full"
        :autofocus="itemCount > 1"
        :initialValue="model[index - 1]"
        :label="`Option ${index}`"
        @update="
          (value) => {
            model[index - 1] = value;
          }
        "
        @keydown.enter="addItem"
      />

      <BaseButton
        v-if="index === itemCount"
        text
        icon="mdi mdi-plus"
        severity="contrast"
        @click="addItem"
      />
    </div>
  </div>
</template>

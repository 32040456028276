<script setup lang="ts">
import { refThrottled, useStorage } from "@vueuse/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import logoIcon from "@/base/assets/img/logoIcon.svg";
import logoText from "@/base/assets/img/logoText.svg";
import BaseBreadcrumbs from "@/base/components/breadcrumbs/BaseBreadcrumbs.vue";
import LoadingIndicator from "@/base/components/loading/LoadingIndicator.vue";
import useBreadcrumbs from "@/base/composables/useBreadcrumbs.ts";
import { StorageKeys } from "@/config.ts";

const { t } = useI18n();
const { breadcrumbs } = useBreadcrumbs();
const debouncedBreadcrumbs = refThrottled(breadcrumbs, 0);

const home = ref({
  icon: "mdi mdi-home",
});

const isDrawerExpanded = useStorage(StorageKeys.app.drawerExpanded.key, true);

const enhancedBreadcrumbs = computed(() => {
  let spinnerShown = false;
  return debouncedBreadcrumbs.value.map((breadcrumb) => {
    if (breadcrumb.label === null && !spinnerShown) {
      spinnerShown = true;
      return { ...breadcrumb, showSpinner: true };
    }

    return { ...breadcrumb, showSpinner: false };
  });
});
</script>

<template>
  <BaseBreadcrumbs
    :home
    :model="enhancedBreadcrumbs"
    pt:root:class="h-8 text-sm! mb-1 -ml-1! overflow-y-hidden"
    pt:list:class="h-1"
    pt:separator:class="h-2 w-2"
  >
    <template #item="{ item, props }">
      <RouterLink
        v-if="item.path"
        :to="item.path"
        data-testid="link-breadcrumb"
      >
        <LoadingIndicator v-if="item.showSpinner" :showText="false" />
        <p
          v-else
          v-bind="props"
          class="max-w-36! md:max-w-50! lg:max-w-100! xl:max-w-128! text-nowrap! text-ellipsis! overflow-hidden!"
          :title="item.label as string"
        >
          {{ item.label }}
        </p>
      </RouterLink>

      <RouterLink
        v-else
        data-testid="home-breadcrumb"
        class="flex flex-row gap-2 transition-all duration-200 ease-in"
        :to="{ name: 'dashboard' }"
      >
        <img
          id="pisys-logo-icon"
          :src="logoIcon"
          class="h-5 transition-all duration-200 ease-in-out"
          :class="breadcrumbs.length === 0 && !isDrawerExpanded ? 'ml-1' : ''"
          alt="Logo"
        />
        <img
          id="pisys-logo-text"
          class="pt-1 h-4 transition-all ease-in-out duration-200"
          :class="
            breadcrumbs.length === 0 && !isDrawerExpanded
              ? 'opacity-0'
              : 'opacity-100'
          "
          :src="logoText"
          :alt="t('app.logoText')"
        />
      </RouterLink>
    </template>
  </BaseBreadcrumbs>
</template>

<style scoped></style>

<script setup lang="ts">
import ActivityListView from "@/app/activity/views/ActivityListView.vue";
import ActivitySingleView from "@/app/activity/views/ActivitySingleView.vue";
import GraphViewerView from "@/app/graphviewer/views/GraphViewerView.vue";
import ProcessTemplateListView from "@/app/process/views/ProcessTemplateListView.vue";
</script>

<template>
  <RouterView v-slot="{ route }">
    <ProcessTemplateListView v-if="route.name === 'processTemplateList'" />
    <ActivityListView
      v-else-if="route.name === 'processTemplate'"
      :rootActivityId="route.params.rootActivityId as string"
    />
    <ActivitySingleView
      v-else-if="route.name === 'activity'"
      :activityId="route.params.activityId as string"
      :processId="undefined"
    />
    <GraphViewerView
      v-else-if="route.name === 'activityGraph'"
      :rootActivityId="route.params.rootActivityId as string"
      :processId="undefined"
    />
  </RouterView>
</template>

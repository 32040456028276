<script setup lang="ts">
export interface BaseMessageProps {
  severity?:
    | "primary"
    | "success"
    | "info"
    | "warn"
    | "error"
    | "secondary"
    | "contrast";
  closable?: boolean;
  size?: "small" | "large";
  variant?: "simple" | "outlined";
}

withDefaults(defineProps<BaseMessageProps>(), {
  severity: "success",
  closable: false,
  variant: undefined,
  size: undefined,
});

const emits = defineEmits<(e: "close") => void>();
</script>

<template>
  <PMessage :severity :closable :variant :size @close="() => emits('close')">
    <slot></slot>
  </PMessage>
</template>

import { type RouteRecordRaw } from "vue-router";

import DocumentRootView from "@/app/document/views/DocumentRootView.vue";
import { i18n } from "@/base/i18n/i18n.ts";

const { t } = i18n.global;

export const documentRoutesNames = {
  documentList: "documentList",
  documentView: "documentView",
  documentEdit: "documentEdit",
};

export const documentRoutes: RouteRecordRaw[] = [
  {
    path: "/document",
    component: DocumentRootView,
    children: [
      {
        name: "documentList",
        path: "list",
        meta: {
          breadcrumb: t("document.document", 2),
        },
        children: [],
      },
      {
        name: "documentView",
        path: ":documentId",
        props: true,
        meta: {
          breadcrumbParent: "documentList",
          breadcrumb: null,
        },
        children: [
          {
            name: "documentEdit",
            path: "edit",
            props: true,
            meta: {
              breadcrumb: t("action.edit"),
            },
            children: [],
          },
        ],
      },
    ],
  },
];

import { type ApolloError } from "@apollo/client/core";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { useDocumentGenerationRunService } from "@/app/document/services/DocumentGenerationRunService.ts";
import { type TreeNode } from "@/app/document/services/TreeNodeTypes.ts";
import { useDocumentParameterStore } from "@/app/document/stores/DocumentParameterStore.ts";
import { useDocumentStore } from "@/app/document/stores/DocumentStore.ts";
import { useMessageService } from "@/app/message/services/MessageService.ts";
import { usePersonService } from "@/app/person/services/PersonService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import useBreadcrumbs from "@/base/composables/useBreadcrumbs.ts";
import {
  type DocumentDto,
  type DocumentEto,
  type DocumentGenerationOptionsDto,
  type DocumentGenerationPlaceholderReplacementValuesCheckEto,
  type DocumentGenerationResult,
  type DocumentGenerationRunEto,
  type DocumentParameterDto,
  EntityType,
  type MessageEto,
  type PersonEto,
  type PlaceholderReplacementInformation,
  ValidationCode,
  ValidationLevel,
  type ValidationResult,
} from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";
import { useDialogService } from "@/base/services/DialogService.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";

export enum GenerationStatusEnum {
  SUCCESS = "success",
  WARN = "warn",
  ERROR = "error",
}

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

class Message {
  constructor(
    public severity: GenerationStatusEnum,
    public message?: string,
    public code?: ValidationCode,
  ) {}
}

export const useDocumentService = defineStore("DocumentService", () => {
  const documentStore = useDocumentStore();
  const documentParameterStore = useDocumentParameterStore();
  const documentGenerationRunService = useDocumentGenerationRunService();
  const activityService = useActivityService();
  const personService = usePersonService();
  const messageService = useMessageService();
  const promptService = usePromptService();
  const dialogService = useDialogService();
  const { overwrite } = useBreadcrumbs();
  const router = useRouter();
  const { t } = useI18n();

  const processService = useProcessService();

  const unknownErrorMessage = t("common.unknownErrorMessage");

  const translations = {
    name: t("document.name"),
    description: t("document.description"),
    entityType: t("document.entityType"),
    generate: t("document.generate"),
  };

  function deleteAndGoToList(id: string) {
    documentStore.deleteById(id).then(
      () => {
        promptService.success(Action.DELETE);
        void router.push({
          name: "documentList",
        });
      },
      (reason) => promptService.failure(Action.DELETE, reason),
    );
  }

  function deleteParameter(id: string | undefined) {
    if (!id) {
      promptService.failure(Action.DELETE, "No document id");
      return;
    }
    documentParameterStore.deleteById(id).then(
      () => {
        promptService.success(Action.DELETE);
      },
      (reason) => promptService.failure(Action.DELETE, reason),
    );
  }

  function getEntityItemsForDropdown(entityType: EntityType) {
    let items: { id: string; name: string }[];

    switch (entityType) {
      case EntityType.Activity:
        items = activityService
          .getAllActivitiesWithoutArchived()
          .map((item) => ({
            id: item.id,
            name: item.name,
          }));
        break;
      case EntityType.Person:
        items = personService.getAll().map((item: PersonEto) => ({
          id: item.id,
          name: item.name ?? "",
        }));
        break;
      case EntityType.Message:
        items = messageService.getAll().map((item: MessageEto) => ({
          id: item.id,
          name: item.title,
        }));
        break;
      case EntityType.Process:
      default:
        items = processService
          .getProcesses()
          .sort((a, b) => a.name.localeCompare(b.name));
        break;
    }

    return items.map((item) => ({
      text: item.name, // Use the name for display
      value: item.id, // Use the id as the value
    }));
  }

  const mapDocumentEtoToTreeNode = (document: DocumentEto): TreeNode => {
    const treeNode: TreeNode = {
      key: document.id,
      entityType: document.entityType,
      data: {
        name: document.name,
        entityType: translateEnum("entityTypes", document.entityType),
      },
      children: [],
    };
    if (document.parentId) {
      treeNode.parentId = document.parentId;
    }
    return treeNode;
  };

  const getTreeNodes = (documents?: DocumentEto[]): TreeNode[] => {
    const allDocuments = documents ?? documentStore.getAll();

    const rootNodes = allDocuments
      .filter((rootNode) => !rootNode.parentId)
      .map(mapDocumentEtoToTreeNode);
    const childNodes = allDocuments
      .filter((rootNode) => rootNode.parentId)
      .map(mapDocumentEtoToTreeNode);

    const findNode = (nodes: TreeNode[], id?: string) =>
      nodes.find((node) => node.key === id);

    childNodes.forEach((childNode) => {
      const parentInRoot = findNode(rootNodes, childNode.parentId);
      if (parentInRoot?.children) {
        parentInRoot.children.push(childNode);
        return;
      }

      const parentInChild = findNode(childNodes, childNode.parentId);
      if (parentInChild?.children) {
        parentInChild.children.push(childNode);
        return;
      }

      rootNodes.push(childNode);
    });

    return rootNodes;
  };

  const entityTypeLabel = computed(() => {
    if (!dialogService.dialogEntityType) {
      return "";
    }
    switch (dialogService.dialogEntityType) {
      case EntityType.Activity:
        return t("entityTypes.ACTIVITY");
      case EntityType.Person:
        return t("entityTypes.PERSON");
      case EntityType.Message:
        return t("entityTypes.MESSAGE");
      case EntityType.Process:
      default:
        return t("entityTypes.PROCESS");
    }
  });

  function markParameterRefetch(documentId: string) {
    documentParameterStore.markRefetch(undefined, {
      documentIds: [documentId],
    });
  }

  const displayColumns = computed((): string[] => {
    return [translations.name, translations.entityType];
  });

  function createOrUpdate(documentDto: DocumentDto) {
    documentStore.createOrUpdate(documentDto).then(
      () =>
        promptService.success(Action.SAVE, documentDto.name ?? documentDto.id),
      (reason) => promptService.failure(Action.SAVE, reason),
    );
    return documentDto.id;
  }

  function createOrUpdateParameter(
    documentParameterDto: AtLeast<DocumentParameterDto, "id">,
  ) {
    documentParameterStore
      .createOrUpdate(documentParameterDto)
      .then((value) => {
        promptService.success(
          Action.SAVE,
          documentParameterDto.name ?? documentParameterDto.id,
        );
        markParameterRefetch(value.documentId);
      })
      .catch((reason) => promptService.failure(Action.SAVE, reason));
    return documentParameterDto.id;
  }

  function getParametersForDocument(documentId: string) {
    return documentParameterStore.getAll({
      documentIds: [documentId],
    });
  }

  async function generateDocument(
    entityId?: string,
    documentGenerationOptions: DocumentGenerationOptionsDto = {},
  ): Promise<DocumentGenerationRunEto> {
    if (!dialogService.dialogEntityId) {
      return Promise.reject(new Error("No document selected"));
    }

    const document = documentStore.getById(dialogService.dialogEntityId);

    if (!document) {
      return Promise.reject(new Error(t("document.notFound")));
    }

    return documentGenerationRunService
      .createOrUpdate({
        id: uuidv4(),
        documentId: document.id,
        entityId,
        documentGenerationOptions,
        templateFileId: document.templateFileId,
      })
      .catch((error) => {
        promptService.failure(Action.GENERATE, error);
        return Promise.reject(
          new Error(promptService.extractErrorDescription(error)),
        );
      });
  }

  const generateDocumentVariant = async (
    documentId: string,
    variantName?: string,
  ): Promise<DocumentEto> => {
    const document = documentId && documentStore.getById(documentId);

    if (!document) {
      console.log(t("document.notFound"));
      return Promise.reject(new Error(t("document.notFound")));
    }
    const documentCopy: DocumentDto = {
      id: uuidv4(),
      name: variantName,
      parentId: document.id,
      entityType: document.entityType,
      templateFileId: document.templateFileId,
      iterator: document.iterator,
    };

    try {
      const documentCreated = await documentStore.createOrUpdate(documentCopy, {
        optionsDto: {
          createFileCopyOfParentDocumentAndAttach: true,
        },
      });
      promptService.success(
        Action.GENERATE_VARIANT,
        documentCreated.name ?? documentCreated.id,
      );
      documentVariantGenerationResult.value = { document: documentCreated };
      return documentCreated;
    } catch (error) {
      let errorMessage: string;
      if ((error as ApolloError).graphQLErrors) {
        errorMessage = (error as ApolloError).graphQLErrors?.[0]?.message;
      }
      errorMessage ??= unknownErrorMessage;
      promptService.failure(Action.GENERATE_VARIANT, errorMessage);
      return Promise.reject(new Error(errorMessage));
    }
  };

  function generateValidationMessage(validation: ValidationResult): string {
    if (validation.code === ValidationCode.IteratorHasNoData) {
      return t("document.validation.iteratorHasNoData", {
        iteratorName: validation.params?.at(0),
      });
    } else if (validation.code === ValidationCode.NotFound) {
      return `${t("document.validation.placeholderNotFound")}\n\t${validation.params?.join(",\n\t")}`;
    } else if (validation.code === ValidationCode.InvalidFormat) {
      return `${t("document.validation.invalidFormat")}\n\t${validation.params?.join(",\n\t")}`;
    } else {
      return unknownErrorMessage;
    }
  }

  function determineStatus(
    validationResults: ValidationResult[],
  ): GenerationStatusEnum {
    if (validationResults.some((val) => val.level === ValidationLevel.Error)) {
      return GenerationStatusEnum.ERROR;
    }
    if (validationResults.some((val) => val.level === ValidationLevel.Warn)) {
      return GenerationStatusEnum.WARN;
    }
    return GenerationStatusEnum.SUCCESS;
  }

  const documentGenerationRun = ref<DocumentGenerationRunEto | null>(null);
  const documentGenerationIsGenerating = ref(false);
  const documentGenerationGenerateStatus = ref<GenerationStatusEnum | null>(
    null,
  );
  const documentGenerationFeedbackBlockMessages = ref("");
  const documentGenerationMessages = ref<Message[]>([]);

  const documentVariantGenerationResult = ref<{ document?: DocumentEto }>({});
  const documentVariantGenerationIsGenerating = ref(false);
  const documentVariantGenerationGenerateStatus =
    ref<GenerationStatusEnum | null>(null);
  const documentVariantGenerationFeedbackBlockMessages = ref("");

  const placeholderReplacementValuesCheckResult = ref<
    PlaceholderReplacementInformation[] | null
  >(null);
  const placeholderReplacementValuesCheckIsLoading = ref(false);

  function reduceValidationMessages(
    value: DocumentGenerationResult[],
  ): ValidationResult[] {
    return value.reduce((acc, v) => {
      v.validation?.forEach((validationEntry) => acc.push(validationEntry));
      return acc;
    }, [] as ValidationResult[]);
  }

  async function startPlaceholderReplacementValuesCheck(
    entityId?: string | null,
  ): Promise<void> {
    if (!dialogService.dialogEntityId) {
      return;
    }
    placeholderReplacementValuesCheckIsLoading.value = true;

    const document = documentStore.getById(dialogService.dialogEntityId);

    if (!document) {
      return;
    }

    const result = await documentGenerationRunService
      .checkPlaceholderReplacementValues(document.id, entityId)
      .catch((error) => {
        promptService.failure(Action.CHECK_REPLACEMENT_VALUES, error);
        return Promise.reject(new Error(error));
      })
      .finally(
        () => (placeholderReplacementValuesCheckIsLoading.value = false),
      );

    placeholderReplacementValuesCheckResult.value =
      mapPlaceholderValueCheckResultToFlatPlaceholderReplacementInformation(
        result,
      );
  }

  function mapPlaceholderValueCheckResultToFlatPlaceholderReplacementInformation(
    result: DocumentGenerationPlaceholderReplacementValuesCheckEto,
  ): PlaceholderReplacementInformation[] {
    return result.placeholderReplacementInformation.reduce((prev, cur) => {
      if (Array.isArray(cur.value)) {
        cur.value.forEach((c, idx) => {
          prev.push({
            placeholder: `${cur.placeholder} (${idx + 1})`,
            descriptions: cur.descriptions,
            value: c,
          });
        });
      } else {
        prev.push(cur);
      }
      return prev;
    }, [] as PlaceholderReplacementInformation[]);
  }

  async function startDocumentGeneration(
    entityId?: string,
    documentGenerationOptions: DocumentGenerationOptionsDto = {},
  ) {
    documentGenerationIsGenerating.value = true;
    documentGenerationGenerateStatus.value = null;
    documentGenerationFeedbackBlockMessages.value = "";

    try {
      documentGenerationRun.value = await generateDocument(
        entityId,
        documentGenerationOptions,
      );

      const validationResults = reduceValidationMessages(
        documentGenerationRun.value.results ?? [],
      ).filter((s) => s != null);
      const generatedValidationMessages = validationResults.map(
        (validationResult) =>
          new Message(
            GenerationStatusEnum[validationResult.level],
            generateValidationMessage(validationResult),
            validationResult.code,
          ),
      );

      documentGenerationFeedbackBlockMessages.value = [
        ...generatedValidationMessages.filter(
          (m) => m.code === ValidationCode.IteratorHasNoData,
        ),
        ...generatedValidationMessages.filter(
          (m) => m.code === ValidationCode.InvalidFormat,
        ),
      ]
        .map((d) => d.message)
        .join("\n");

      if (
        generatedValidationMessages.filter(
          (m) => m.code === ValidationCode.NotFound,
        )
      ) {
        documentGenerationMessages.value.push(
          new Message(
            GenerationStatusEnum.WARN,
            t("document.generateDialog.warn.notFoundMessage"),
            ValidationCode.NotFound,
          ),
        );
      }

      documentGenerationGenerateStatus.value =
        determineStatus(validationResults);
      documentGenerationMessages.value.push(
        new Message(
          GenerationStatusEnum.SUCCESS,
          t("document.generateDialog.success", {
            count: documentGenerationRun?.value.results.length,
          }),
        ),
      );
    } catch (error) {
      documentGenerationGenerateStatus.value = GenerationStatusEnum.ERROR;
      if (error instanceof Error) {
        documentGenerationFeedbackBlockMessages.value =
          error?.message ?? unknownErrorMessage;
      } else {
        documentGenerationFeedbackBlockMessages.value = unknownErrorMessage;
      }
      documentGenerationMessages.value.push(
        new Message(
          GenerationStatusEnum.ERROR,
          t("document.generateDialog.error", {
            count: documentGenerationRun?.value?.results.length,
          }),
        ),
      );
    } finally {
      documentGenerationIsGenerating.value = false;
    }
  }

  async function startDocumentVariantGeneration(
    documentId: string,
    variantName: string,
  ) {
    if (!variantName || !documentId) {
      documentVariantGenerationGenerateStatus.value =
        GenerationStatusEnum.ERROR;
      return;
    }
    documentVariantGenerationIsGenerating.value = true;
    documentVariantGenerationGenerateStatus.value = null;
    documentVariantGenerationFeedbackBlockMessages.value = "";

    try {
      await generateDocumentVariant(documentId, variantName);
      documentVariantGenerationGenerateStatus.value =
        GenerationStatusEnum.SUCCESS;
    } catch (error) {
      documentVariantGenerationGenerateStatus.value =
        GenerationStatusEnum.ERROR;
      if (error instanceof Error) {
        documentVariantGenerationFeedbackBlockMessages.value = error.message;
      } else {
        documentVariantGenerationFeedbackBlockMessages.value =
          unknownErrorMessage;
      }
    } finally {
      documentVariantGenerationIsGenerating.value = false;
    }
  }

  async function downloadGeneratedDocument() {
    if (!documentGenerationRun.value) {
      return;
    }
    await documentGenerationRunService.downloadDocuments(
      documentGenerationRun.value.id,
    );
  }

  const entityItems = computed(() => {
    if (dialogService.dialogEntityType) {
      return getEntityItemsForDropdown(dialogService.dialogEntityType);
    }
    return [];
  });

  function resetDocumentGeneration() {
    documentGenerationRun.value = null;
    documentGenerationIsGenerating.value = false;
    documentGenerationGenerateStatus.value = null;
    documentGenerationFeedbackBlockMessages.value = "";
    documentGenerationMessages.value = [];
  }

  const resetDocumentVariantGeneration = () => {
    documentVariantGenerationIsGenerating.value = false;
    documentVariantGenerationGenerateStatus.value = null;
    documentVariantGenerationFeedbackBlockMessages.value = "";
  };

  const resetPlaceholderReplacementValuesCheck = () => {
    placeholderReplacementValuesCheckResult.value = null;
    placeholderReplacementValuesCheckIsLoading.value = false;
  };

  return {
    startDocumentGeneration,
    documentGenerationRun,
    documentGenerationIsGenerating,
    documentGenerationGenerateStatus,
    documentGenerationFeedbackBlockMessages,
    documentGenerationMessages,
    downloadGeneratedDocument,
    resetDocumentGeneration,

    startDocumentVariantGeneration,
    documentVariantGenerationResult,
    documentVariantGenerationIsGenerating,
    documentVariantGenerationGenerateStatus,
    documentVariantGenerationFeedbackBlockMessages,
    resetDocumentVariantGeneration,

    startPlaceholderReplacementValuesCheck,
    placeholderReplacementValuesCheckResult,
    placeholderReplacementValuesCheckIsLoading,
    resetPlaceholderReplacementValuesCheck,

    entityItems,
    entityTypeLabel,
    isLoading: (id?: string) => documentStore.isLoading(id),
    getById: (id: string) => {
      const document = documentStore.getById(id);
      if (document) {
        overwrite("documentView", document?.name);
      }
      return document;
    },
    getAll: () => documentStore.getAll(),
    getParametersForDocument,
    createOrUpdate,
    createOrUpdateParameter,
    deleteAndGoToList,
    deleteParameter,
    getTreeNodes,
    displayColumns,
    markRefetch: (id: string) => documentStore.markRefetch(id),
  };
});

<script setup lang="ts">
import { type Popover, type PopoverPassThroughOptions } from "primevue";
import { ref } from "vue";

withDefaults(defineProps<{ pt?: PopoverPassThroughOptions }>(), {
  pt: undefined,
});

const popoverRef = ref<InstanceType<typeof Popover> | null>(null);

defineExpose({
  toggle: (event: Event) => {
    popoverRef.value?.toggle(event);
  },
  show: (event: Event) => {
    popoverRef.value?.show(event);
  },
  hide: () => {
    popoverRef.value?.hide();
  },
});
</script>

<template>
  <PPopover ref="popoverRef" :pt>
    <slot />
  </PPopover>
</template>

import {
  type ActivityEto,
  type EntityType,
} from "@/base/graphql/generated/types.ts";

export type ValidDialogProps =
  | BaseDialogProps
  | DeleteDialogProps
  | CreateDialogProps;

export type ValidDialogData =
  | DeleteDialogData
  | CreateTemplateDialogData
  | UpgradeProcessData
  | RenameTagDialogData
  | StartProcessDialogData
  | ErrorDialogData
  | GenerateDocumentVariantData
  | DocumentGenerationParameterDialogData
  | DocumentGenerationPlaceholderReplacementValuesCheckDialogData
  | SelectTableDialogData
  | GenerateDocumentDialogData
  | AddFieldDialogData
  | ResetListFilterDialogData;

export interface AddFieldDialogData {
  entityId: string;
  entityType: EntityType[];
}

export interface DeleteDialogData {
  deleteDescription: string;
}

export interface ResetListFilterDialogData {
  contextKey: string;
}

export interface CreateTemplateDialogData {
  createDescription: string;
}

export interface RenameTagDialogData {
  entityType?: EntityType;
}

export interface StartProcessDialogData {
  showProcessTemplateSelect: boolean;
  rootActivity: ActivityEto | undefined;
}

export interface ErrorDialogData {
  capturedError?: Error;
}

export interface GenerateDocumentVariantData {
  documentId: string;
  entityType: EntityType;
  documentName: string;
  generateDescription: string;
}

export interface DocumentGenerationParameterDialogData {
  dialogEntityId: string;
}

export interface SelectTableDialogData {
  activityId: string;
}

export interface GenerateDocumentDialogData {
  preselectedItem?: string;
  canChooseDoc: boolean;
}

export interface DocumentGenerationPlaceholderReplacementValuesCheckDialogData {
  preselectedItem?: string | null;
  canChooseDoc: boolean;
}

export interface UpgradeProcessData {
  processId: string;
}

/*
  These props relate to the primevue Dialog props: https://primevue.org/dynamicdialog/#api.dialog.props
 */
export interface BaseDialogProps<T = unknown> {
  header: string;
  draggable?: boolean;
  modal?: boolean;
  dismissableMask?: boolean;
  // The dialog blocks interaction with the background, however this seems to be based
  // on the z-index, if we leave it at the default value the floating action buttons are still
  // clickable, allowing the user to open multiple dialogs.
  // Avoid this by adding a higher value as baseZIndex:
  baseZIndex?: number;
  onCancel?: () => void;
  onClose?: (r: T) => void;
  props: ValidDialogData;
  maximizable?: boolean;
}

export interface DeleteDialogProps extends BaseDialogProps {
  onDelete: (id?: string) => void | Promise<void>;
}

export interface CreateDialogProps extends BaseDialogProps {
  onCreate: (v: string) => Promise<void>;
}

export function isDeleteDialog(
  dialogProps: ValidDialogProps,
): dialogProps is DeleteDialogProps {
  return typeof (dialogProps as DeleteDialogProps).onDelete === "function";
}

export function isCreateDialog(
  dialogProps: ValidDialogProps,
): dialogProps is CreateDialogProps {
  return typeof (dialogProps as CreateDialogProps).onCreate === "function";
}

<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { computed, inject, type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

import {
  GenerationStatusEnum,
  useDocumentService,
} from "@/app/document/services/DocumentService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import BaseTextArea from "@/base/components/form/BaseTextArea.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import type { GenerateDocumentVariantData } from "@/base/composables/dialog/dialogData.ts";
import { useDialogService } from "@/base/services/DialogService.ts";

const { t } = useI18n();

const documentService = useDocumentService();
const dialogService = useDialogService();
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: GenerateDocumentVariantData = dialogRef?.value.data;

const getVariantNameDefault = (originDocumentName: string) =>
  `${originDocumentName} - ${t("document.generateVariantDialog.variantNameInputDefaultValueSuffix")}`;

const variantName = ref<string>(getVariantNameDefault(props.documentName));

dialogService.dialogEntityId = props.documentId;
dialogService.dialogEntityType = props.entityType;
dialogService.dialogEntityFileName = props.documentName;

const emit = defineEmits<(e: "confirm") => void>();

const variantGenerationHasError = computed(() => {
  return (
    documentService.documentVariantGenerationGenerateStatus ===
    GenerationStatusEnum.ERROR
  );
});

async function startVariantGeneration() {
  await documentService.startDocumentVariantGeneration(
    dialogService.dialogEntityId ?? "",
    variantName.value,
  );

  if (
    documentService.documentVariantGenerationGenerateStatus ===
    GenerationStatusEnum.SUCCESS
  ) {
    closeDialog();
  }
}

function closeDialog() {
  documentService.resetDocumentVariantGeneration();

  emit("confirm");
  dialogRef?.value.close();
}
</script>
<template>
  <BaseAppDialog class="w-full h-fit">
    <template #content>
      <div class="w-full">
        <p class="mb-4" :hidden="variantGenerationHasError">
          {{ props.generateDescription }}
        </p>

        <p class="mb-4" :hidden="!variantGenerationHasError">
          {{ t("document.generateVariantDialog.error") }}
        </p>

        <template v-if="documentService.documentVariantGenerationIsGenerating">
          <p class="mdi mdi-loading mdi-spin mr-2"></p>
        </template>

        <BaseTextArea
          disabled
          :modelValue="
            documentService.documentVariantGenerationFeedbackBlockMessages
          "
          :rows="4"
          :hidden="
            documentService.documentVariantGenerationFeedbackBlockMessages ===
              undefined ||
            documentService.documentVariantGenerationFeedbackBlockMessages ===
              ''
          "
        />

        <BaseTextField
          autofocus
          :disabled="documentService.documentVariantGenerationIsGenerating"
          :hidden="variantGenerationHasError"
          :label="t('document.generateVariantDialog.variantNameInputLabel')"
          :initialValue="variantName"
          @update="variantName = $event"
        />
      </div>
    </template>

    <template #footer>
      <div class="flex flex-row justify-end pt-4 gap-4">
        <BaseButton
          severity="danger"
          :hidden="!variantGenerationHasError"
          :label="t('action.close')"
          @click="closeDialog"
        />

        <BaseButton
          severity="primary"
          :disabled="
            !variantName ||
            variantName.length === 0 ||
            documentService.documentVariantGenerationIsGenerating
          "
          :hidden="variantGenerationHasError"
          :label="t('document.generateVariantDialog.generate')"
          @click="async () => startVariantGeneration()"
        />
      </div>
    </template>
  </BaseAppDialog>
</template>

<style scoped></style>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import logoIcon from "@/base/assets/img/logoIcon.svg";
import logoText from "@/base/assets/img/logoText.svg";
import AppHeaderBreadcrumbs from "@/base/components/header/AppHeaderBreadcrumbs.vue";
import AppHeaderButtons from "@/base/components/header/AppHeaderButtons.vue";
import { useBreakpoints } from "@/base/composables/useBreakpoints.ts";

const { t } = useI18n();
const isDesktop = useBreakpoints("greaterOrEqual", "sm");
</script>

<template>
  <PToolbar
    pt:root="rounded-none! border-l-0! border-t-0! border-r-0! px-0! py-1!"
  >
    <template #start>
      <AppHeaderBreadcrumbs v-if="isDesktop" />

      <RouterLink
        v-else
        data-testid="home-breadcrumb"
        class="flex flex-row gap-2 ml-2"
        :to="{ name: 'dashboard' }"
      >
        <img id="pisys-logo-icon" :src="logoIcon" class="h-5" alt="Logo" />
        <img
          id="pisys-logo-text"
          class="pt-1 h-4"
          :src="logoText"
          :alt="t('app.logoText')"
        />
      </RouterLink>
    </template>

    <template #end>
      <AppHeaderButtons />
    </template>
  </PToolbar>
</template>

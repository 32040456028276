<script setup lang="ts">
import "@toast-ui/editor/dist/i18n/de-de";

import TUIEditor from "@toast-ui/editor";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";

const emit = defineEmits<(e: "saveContent", content: string) => void>();
const props = defineProps<{
  previousContent?: string;
  containerId: string;
  label?: string;
  readonly?: boolean;
  withHeader?: boolean;
}>();

const initialContent = ref(props.previousContent ?? "");
const editorContent = ref(initialContent.value);

let lastMouseDownTarget: EventTarget;

let editor: TUIEditor;
const hasFocus = ref(false);

watch(
  () => props.previousContent,
  (newContent) => {
    if (newContent === undefined) {
      initialContent.value = "";
      editorContent.value = "";
      editor?.setMarkdown("", false);
      return;
    }
    if (newContent !== initialContent.value) {
      initialContent.value = newContent;
      editorContent.value = newContent;
      editor?.setMarkdown(newContent, false);
    }
  },
);

const onSaveContent = () => {
  const markdown = editor?.getMarkdown();
  if (markdown !== undefined && markdown !== initialContent.value) {
    emit("saveContent", markdown);
    initialContent.value = markdown;
  }
};

let focusinHandler: (this: HTMLElement, ev: FocusEvent) => void;
let mouseDownHandler: (evt: Event) => void;
let mouseUpHandler: (evt: Event) => void;

const isInsideTextEditor = (element: HTMLElement): boolean => {
  if (!element) {
    return false;
  }

  if (element.id === props.containerId) {
    return true;
  }
  return !element.parentElement
    ? false
    : isInsideTextEditor(element.parentElement);
};

const colorSyntaxOptions = {
  preset: ["#3C707B", "#B71C1C", "#0D47A1", "#33691E", "#00CA91"],
};

onMounted(() => {
  const container = document.getElementById(props.containerId);
  if (!container) {
    return;
  }

  focusinHandler = () => {
    hasFocus.value = true;
  };
  container.addEventListener("focusin", focusinHandler);
  editor = new TUIEditor({
    el: container,
    toolbarItems: [
      ["heading", "bold", "italic", "strike", "link"],
      ["ul", "ol", "indent", "outdent"],
    ],
    initialEditType: "wysiwyg",
    hideModeSwitch: true,
    minHeight: "40px",
    height: "auto",
    autofocus: false,
    initialValue: props.previousContent ?? "",
    linkAttributes: { target: "_blank" },
    usageStatistics: false,
    plugins: [[colorSyntax, colorSyntaxOptions]],
  });

  if (props.withHeader) {
    const editorElement = container.querySelector(".toastui-editor-defaultUI");

    if (editorElement) {
      editorElement.classList.remove("toastui-editor-defaultUI");
    }
  }

  mouseDownHandler = (evt: Event) => {
    if (evt.target) {
      lastMouseDownTarget = evt.target;
    }
  };

  mouseUpHandler = (evt: Event) => {
    if (!hasFocus.value) {
      return;
    }

    if (
      isInsideTextEditor(lastMouseDownTarget as HTMLElement) ||
      isInsideTextEditor(evt.target as HTMLElement)
    ) {
      hasFocus.value = true;
      return;
    }
    onSaveContent();
    editor.setHeight("auto");
    hasFocus.value = false;
  };

  editorPopupObserver.observe(container, {
    childList: true,
    subtree: true,
  });

  window.addEventListener("mousedown", mouseDownHandler);
  window.addEventListener("mouseup", mouseUpHandler);
});

onUnmounted(() => {
  window.removeEventListener("mousedown", mouseDownHandler);
  window.removeEventListener("mouseup", mouseUpHandler);
  const container = document.getElementById(props.containerId);
  if (!container) {
    return;
  }
  container.removeEventListener("focusin", focusinHandler);
  editorPopupObserver.disconnect();
});

function isVisible(popup: Element | null) {
  if (popup) {
    const computedStyle = window.getComputedStyle(popup);
    return computedStyle.display !== "none";
  }
  return false;
}

const popupsSelectors = [
  ".toastui-editor-popup.toastui-editor-popup-color",
  ".toastui-editor-popup.toastui-editor-popup-add-link",
];

const editorPopupObserver = new MutationObserver((mutations) => {
  // since we are observing one node, we are only interested in the first mutation
  const mutation = mutations[0];

  if (mutation.type !== "childList") {
    return;
  }

  const isPopupVisible = popupsSelectors.some((selector) => {
    const popup = document.querySelector(selector);
    return isVisible(popup);
  });

  // if no visible popup is found, reset the editor's height
  editor.setHeight(isPopupVisible ? "275px" : "auto");
});

const classes = computed(() => {
  const classList: string[] = [];
  classList.push(hasFocus.value ? "has-focus" : "no-focus");
  if (props.label) {
    classList.push("mt-n4");
  }
  return classList.join(" ");
});
</script>

<template>
  <PAccordion v-if="withHeader" value="editor">
    <PAccordionPanel
      value="editor"
      :pt="{
        root: {
          class: 'overflow-hidden! border-[0.1rem]! rounded-md! mt-2!',
        },
      }"
    >
      <PAccordionHeader
        :pt="{
          root: {
            class: 'bg-surface-100! border-b-[0.1rem]! h-auto! p-2!',
          },
        }"
      >
        <p class="text-caeli6">
          {{ props.label }}
        </p>
      </PAccordionHeader>

      <PAccordionContent :pt="{ content: 'p-0!' }">
        <div :id="containerId" class="border-0! w-full! h-full!"></div>
      </PAccordionContent>
    </PAccordionPanel>
  </PAccordion>

  <div v-else :class="classes" spellcheck="false">
    <span v-if="props.label" class="editor-label text-xs text-surface-500!">{{
      props.label
    }}</span>
    <div :id="containerId"></div>
  </div>
</template>

<style>
.toastui-editor-contents {
  font-family: "Poppins", sans-serif !important;
}

.toastui-editor-defaultUI {
  border-color: #bcd;
  border-radius: 0.375rem;
  overflow: auto;
}

.toastui-editor-defaultUI:hover {
  border-color: #789;
}

.no-focus .toastui-editor-toolbar {
  display: none;
}

.toastui-editor-defaultUI-toolbar {
  background-color: white;
  padding: 0 6px;
}

.toastui-editor-toolbar-divider {
  margin: 10px 12px;
}

.toastui-editor-defaultUI-toolbar button:hover {
  background-color: #eef !important;
}

.toastui-editor-ww-container .toastui-editor-contents,
.toastui-editor-defaultUI .ProseMirror {
  padding: 8px 16px;
}

.editor-label {
  background-color: #fff;
  opacity: 1;

  display: inline-block;
  position: relative;
  margin: -14px 12px;
  line-height: 1rem;
  transform: translateY(0.6rem);
  z-index: 10;
}

.has-focus .editor-label {
  color: rgb(var(--color-caeli6));
}

.no-focus .editor-label {
  color: rgba(var(--color-caeli6), 0.6);
}

.toastui-editor-contents p,
.toastui-editor-contents li,
.toastui-editor-contents h1,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6 {
  color: var(--color-caeli6) !important;
}
</style>

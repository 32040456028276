<script lang="ts" setup>
import { computed, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";

import TagsDisplay from "@/app/process/components/field/TagsDisplay.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BasePopover from "@/base/components/popover/BasePopover.vue";
import { type EntityType } from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = defineProps<{
  entityId: string;
  entityType: EntityType;
  readonly?: boolean;
}>();

const { t } = useI18n();

const fieldService = useFieldService();

const tagsPopoverRef = useTemplateRef("tagsPopover");

const fieldValues = computed(() => {
  return fieldService.getTagFieldValues(props.entityId) ?? [];
});

const isLoading = computed(() => {
  return fieldService.isLoadingFieldKey() || fieldService.isLoadingFieldValue();
});
</script>

<template>
  <div
    v-if="fieldValues.length > 0 || !readonly"
    class="flex flex-row flex-wrap gap-2"
    data-testid="tags-popover"
  >
    <BaseButton
      rounded
      severity="secondary"
      :label="t('processes.tag', 2)"
      :badge="fieldValues.length.toString()"
      icon="mdi mdi-tag-multiple-outline"
      @click="tagsPopoverRef?.toggle"
    />

    <BasePopover ref="tagsPopover">
      <div
        class="max-w-80 flex flex-row flex-wrap gap-2"
        :class="readonly ? 'pointer-events-none' : ''"
      >
        <TagsDisplay
          v-if="!isLoading"
          :entityId="entityId"
          :entityType="entityType"
          :readonly="readonly"
        />

        <PSkeleton v-else width="10rem" height="2rem" />
      </div>
    </BasePopover>
  </div>
</template>

import { defineStore } from "pinia";
import { ref } from "vue";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import {
  type ActivityDto,
  type EntityType,
} from "@/base/graphql/generated/types.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";

export const useDialogService = defineStore("processUi", () => {
  const promptService = usePromptService();
  const processService = useProcessService();
  const activityService = useActivityService();

  const dialogActivityList = ref<ActivityDto[]>([]);
  const dialogEntityId = ref<string>();
  const dialogEntityType = ref<EntityType | null>(null);
  const dialogEntityFileName = ref<string>("");

  function deleteNextActivity(remainingActivities: ActivityDto[]) {
    if (remainingActivities.length === 0) {
      return;
    }
    const activityToDelete = remainingActivities[0];

    // if the activity to be deleted is a custom activity we also need to delete the processActivity of it
    if (activityToDelete.custom && dialogEntityId.value) {
      const processActivityToDelete =
        processService.getProcessActivityByTemplate(
          dialogEntityId.value,
          activityToDelete.id,
        );
      if (processActivityToDelete) {
        processService
          .deleteProcessActivity(processActivityToDelete.id)
          .catch((reason) => promptService.failure(Action.DELETE, reason));
      }
    }

    activityService
      .deleteActivity(remainingActivities[0].id)
      .then(() => {
        deleteNextActivity(remainingActivities.slice(1));
      })
      .catch((reason) => promptService.failure(Action.DELETE, reason));
  }

  return {
    dialogActivityList,
    dialogEntityId,
    dialogEntityType,
    dialogEntityFileName,
    deleteNextActivity,
  };
});

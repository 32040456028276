import { type RouteRecordRaw } from "vue-router";

import FieldKeyOverviewView from "@/app/admin/views/FieldKeyOverviewView.vue";
import { i18n } from "@/base/i18n/i18n.ts";

const { t } = i18n.global;

export const adminRoutes: RouteRecordRaw[] = [
  {
    name: "fieldKeyOverview",
    path: "/fieldKeyOverview",
    component: FieldKeyOverviewView,
    meta: {
      breadcrumb: t("admin.fieldKeyOverviewTitle"),
    },
  },
];

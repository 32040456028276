import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";

import GrafanaPage from "@/app/dashboard/GrafanaPage.vue";
import { activitiesRoutes } from "@/base/router/activities.routes.ts";
import { adminRoutes } from "@/base/router/admin.routes.ts";
import { documentRoutes } from "@/base/router/document.routes.ts";
import { messageRoutes } from "@/base/router/message.routes.ts";
import { personRoutes } from "@/base/router/person.routes.ts";
import { processRoutes } from "@/base/router/process.routes.ts";
import ErrorView from "@/base/views/ErrorView.vue";

export const routes: RouteRecordRaw[] = [
  {
    name: "dashboard",
    path: "/",
    component: GrafanaPage,
  },
  ...activitiesRoutes,
  ...processRoutes,
  ...personRoutes,
  ...messageRoutes,
  ...documentRoutes,
  ...adminRoutes,
  {
    name: "error",
    path: "/error",
    component: ErrorView,
  },
  //   Fallback route, must be the last route :)
  {
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return {
        path: "/error",
        query: {
          error: 404,
          invalidPath: to.fullPath,
        },
      };
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior: (to, from) => {
    const routeSlotEl = document.getElementById("routeSlot");

    if (!routeSlotEl || to.name === from.name) {
      return;
    }

    routeSlotEl.scrollTop = 0;
  },
});

router.beforeEach((to, from, next) => {
  if (from.name === to.name && from.params === to.params) {
    return;
  }
  next();
});

export default router;

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import ActivityListViewHeader from "@/app/activity/views/ActivityListViewHeader.vue";
import ProcessTemplateActivityActions from "@/app/process/components/action/ProcessTemplateActivityActions.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import { createFieldCells } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import {
  type ActivityEto,
  EntityType,
  FieldType,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { deduplicate } from "@/base/utils/array.ts";

const props = defineProps<{
  rootActivityId: string;
}>();

const { t } = useI18n();
const router = useRouter();
const activityService = useActivityService();
const fieldService = useFieldService();

const i18n = {
  startActivity: t("processes.startActivity"),
  processActivityColumnTitle: t("processes.activity"),
  actionsTitle: t("processes.singleView.actions.buttonTitle"),
};

const mandatoryColumns: DataTableColumn[] = [
  {
    key: i18n.processActivityColumnTitle,
    name: i18n.processActivityColumnTitle,
    type: FieldType.String,
  },
  {
    key: i18n.actionsTitle,
    name: i18n.actionsTitle,
    type: FieldType.String,
    isFilterable: false,
    isSortable: false,
  },
];

const rootActivity = computed(() => {
  return activityService.getActivity(props.rootActivityId, {
    overwriteBreadcrumbs: "processTemplate",
  });
});

const current = computed(() => {
  const mid = activityService.getMid(props.rootActivityId);
  return mid ? activityService.getCurrentVersion(mid) : undefined;
});

const graph = computed(
  (): ActivityEto[] => activityService.getGraph(props.rootActivityId) ?? [],
);

const rowItems = computed(() => {
  return (
    graph.value?.flatMap((activity) => {
      if (activity.archived) {
        return [];
      }
      return {
        key: activity.id,
        class: {
          released: activityService.isReleased(activity.id),
          "root-activity": activity.id === props.rootActivityId,
        },
        cells: {
          ...createActivityCell(activity),
          ...createFieldCells(activity.id),
          ...createActionsCell(activity),
        },
        tags: fieldService.getActiveTagNames(activity.id),
      };
    }) ?? []
  );
});

const displayColumns = computed<DataTableColumn[]>(() => {
  const fieldColumns: DataTableColumn[] = deduplicate([
    ...fieldService.getNonTagFieldKeys(EntityType.Activity),
  ]).map((fieldKey) => ({
    name: fieldKey.name,
    type: fieldKey.type,
    key: fieldKey.key,
  }));

  return [...mandatoryColumns, ...fieldColumns];
});

function createActivityCell(activity: ActivityEto) {
  const isRoot = activity.id === props.rootActivityId;
  return {
    [i18n.processActivityColumnTitle]: {
      content: isRoot
        ? `${i18n.startActivity}: ${activity.name}`
        : (activity.name ?? ""),
      props: {
        class: isRoot ? "cursor-pointer text-caeli5" : "cursor-pointer",
      },
      events: {
        click: () =>
          router.push({
            name: "activity",
            params: { activityId: activity.id },
          }),
      },
    },
  };
}

function createActionsCell(activity: ActivityEto) {
  const isRoot = activity.id === props.rootActivityId;
  return {
    [i18n.actionsTitle]: {
      component: ProcessTemplateActivityActions,
      props: {
        activityId: activity.id,
        isRoot,
        class: "justify-end",
      },
    },
  };
}
</script>

<template>
  <BaseCard
    v-if="rootActivity && current"
    class="h-full p-4!"
    flat
    noShadow
    :rounded="false"
  >
    <template #title>
      <ActivityListViewHeader :rootActivity />
    </template>

    <CFilteredDataTable
      clientSide
      :contextKey="`graph-${props.rootActivityId}`"
      :rowItems="rowItems"
      :availableTags="fieldService.getTagFieldKeys(EntityType.Activity)"
      :mandatoryColumns="mandatoryColumns"
      :exposedColumns="displayColumns"
    />
  </BaseCard>
</template>

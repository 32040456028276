<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import TagsPopoverButton from "@/app/process/components/field/TagsPopoverButton.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import AddFieldDialog from "@/base/components/fields/AddFieldDialog.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type AddFieldDialogData,
  type BaseDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import {
  EntityType,
  type ProcessActivityEto,
  ProcessActivityState,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  processActivity: ProcessActivityEto;
}>();

const { t } = useI18n();
const processService = useProcessService();
const dialog = useBaseDialog();
const { copy, copied } = useClipboard();

const togglingSkippedState = ref(false);

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "add-field-button",
    label: t("action.addSomething", { name: t("processes.field") }),
    icon: "mdi mdi-plus",
    command: () => {
      showAddFieldDialog(props.processActivity.id, [EntityType.Activity]);
    },
  },
  {
    label: isProcessActivitySkipped.value
      ? t("processes.singleView.unsetSkipped")
      : t("processes.singleView.setSkipped"),
    icon: isProcessActivitySkipped.value ? "mdi mdi-check" : "mdi mdi-cancel",
    command: toggleSkippedState,
  },
]);

const isProcessActivitySkipped = computed(
  () => props.processActivity?.status.state === ProcessActivityState.Skipped,
);

const pageIcon = computed(() => {
  if (copied.value) {
    return "mdi mdi-check";
  }
  if (isProcessActivitySkipped.value) {
    return "mdi mdi-checkbox-blank-off-outline";
  }
  return "mdi mdi-play-box";
});

function toggleSkippedState() {
  if (!props.processActivity?.activity?.id) {
    return;
  }

  togglingSkippedState.value = true;

  processService
    .createOrUpdateProcessActivity({
      id: props.processActivity?.id,
      activityId: props.processActivity?.activity?.id,
      processId: props.processActivity?.processId,
      skipped: !isProcessActivitySkipped.value,
    })
    .catch((reason) => console.error(reason))
    .finally(() => (togglingSkippedState.value = false));
}

function showAddFieldDialog(entityId: string, entityType: EntityType[]) {
  const addFieldDialogData: AddFieldDialogData = { entityId, entityType };

  const showAddFieldDialogProps: BaseDialogProps = {
    header: `${t("action.addSomething", { name: t("processes.field") })}`,
    props: addFieldDialogData,
  };

  dialog.open(AddFieldDialog, showAddFieldDialogProps);
}
</script>

<template>
  <div class="flex flex-row justify-between items-start">
    <div class="flex flex-row gap-4 items-start">
      <BaseAvatar
        v-tooltip.bottom="t('action.copyIdTooltip')"
        class="mt-1 text-caeli6 cursor-pointer transition-all duration-400 ease-out"
        :class="{
          'opacity-60': isProcessActivitySkipped,
        }"
        :icon="pageIcon"
        :pt:root="copied ? 'bg-primary-400!' : undefined"
        @click="copy(processActivity.id)"
      />

      <p
        class="text-3xl/10 font-bold"
        :class="{
          'line-through': isProcessActivitySkipped,
          'opacity-60': isProcessActivitySkipped,
        }"
      >
        {{ processActivity?.activity?.name }}
      </p>
    </div>

    <div class="flex flex-row gap-6 items-center">
      <TagsPopoverButton
        readonly
        :entityId="processActivity.activity.id"
        :entityType="EntityType.Process"
      />

      <BaseActionsButton
        :model="actionsMenuItems"
        :disabled="togglingSkippedState"
        :loading="togglingSkippedState"
      />
    </div>
  </div>
</template>

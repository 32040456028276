<script setup lang="ts" generic="T extends { id: string }">
import { type TimelinePassThroughOptions } from "primevue";

defineProps<{
  items: T[];
  pt?: TimelinePassThroughOptions;
}>();
</script>

<template>
  <PTimeline :value="items" layout="vertical" dataKey="id" :pt>
    <template #content="slotProps">
      <slot name="content" v-bind="slotProps" />
    </template>

    <template #marker="slotProps">
      <slot name="marker" v-bind="slotProps" />
    </template>
  </PTimeline>
</template>

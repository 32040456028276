<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { v4 as uuidv4 } from "uuid";
import { computed, inject, onMounted, type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import OutputTable from "@/app/process/components/output/OutputTable.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import BaseMultiSelect from "@/base/components/select/BaseMultiSelect.vue";
import BaseSelect from "@/base/components/select/BaseSelect.vue";
import type { SelectTableDialogData } from "@/base/composables/dialog/dialogData.ts";
import {
  type ActivityOutputEto,
  ActivityOutputType,
} from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";
import { deduplicate } from "@/base/utils/array.ts";

const { t } = useI18n();

const activityService = useActivityService();
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: SelectTableDialogData = dialogRef?.value.data;

const descendantActivityIds = computed(() =>
  activityService
    .getDescendantActivities(props.activityId)
    .map((activity) => activity.id),
);
const descendantOutputIds = computed(() =>
  activityService
    .getDescendantActivities(props.activityId)
    .flatMap((activity) => activityService.getOutputIds(activity.id, false)),
);

const templateFilter = ref<string | null>(null);
const templateFilterItems = computed(() =>
  activityService.unreleasedRootActivities.filter(
    (rootActivity) => rootActivity.id !== props.activityId,
  ),
);
const activityFilter = ref<string[]>([]);
const activityFilterItems = computed(() => {
  if (!templateFilter.value) {
    return activityService
      .getAllActivities()
      .filter((activity) => !descendantActivityIds.value.includes(activity.id));
  }
  if (activityFilter.value === null) {
    return [];
  }
  const activities = deduplicate(
    activityService.getDescendantActivities(templateFilter.value, true).concat(
      activityFilter.value.flatMap(
        (activityId) =>
          activityService.getActivity(activityId, {
            overwriteBreadcrumbs: false,
          }) ?? [],
      ),
    ),
  );
  return activities.filter(
    (activity) =>
      activity.id !== props.activityId &&
      !descendantActivityIds.value.includes(activity.id),
  );
});
const typeFilter = ref<ActivityOutputType[]>([]);
const typeFilterItems = Object.values(ActivityOutputType).map((outputType) => ({
  outputType,
  name: translateEnum("processes.singleView.outputTypes", outputType),
}));

function initializeFilters() {
  const rootActivity = activityService.getRootActivity(props.activityId);
  if (rootActivity && rootActivity.id !== props.activityId) {
    templateFilter.value = rootActivity.id;
  }
  activityFilter.value = [];
  typeFilter.value = [];
  selectedOutputIds.value = [];
  activeOutputs.value = activityService
    .getInputs(props.activityId, false)
    .flatMap((input) => activityService.getOutput(input.outputId) ?? []);
}

onMounted(initializeFilters);

const activeOutputs = ref<ActivityOutputEto[]>([]);
const canRemove = computed(() => activeOutputs.value.length > 1);
const activeOutputIds = computed(() =>
  activeOutputs.value.map((output) => output.id),
);

const ownOutputIds = computed(() =>
  activityService.getOutputIds(props.activityId, false),
);

function getBaseSetOfOutputIds() {
  const baseSelection: string[] = selectedOutputIds.value.slice();
  if (activityFilter.value?.length) {
    activityFilter.value.forEach((activityId) =>
      baseSelection.push(...activityService.getOutputIds(activityId, false)),
    );
  } else if (templateFilter.value) {
    baseSelection.push(
      ...(
        activityService.linearizedActivityGraphs.find(
          (graph) => graph[0].id === templateFilter.value,
        ) ?? []
      ).flatMap((activity) => activityService.getOutputIds(activity.id, false)),
    );
  } else {
    baseSelection.push(
      ...activityService
        .getAllActivities()
        .flatMap((activity) =>
          activityService.getOutputIds(activity.id, false),
        ),
    );
  }
  return deduplicate(baseSelection);
}

function filterBySelectedTypes(idFilteredOutputs: ActivityOutputEto[]) {
  return idFilteredOutputs.filter((output) =>
    typeFilter.value.some(
      (type) => type.toLowerCase() === output?.type?.toLowerCase(),
    ),
  );
}

const selectableOutputs = computed(() => {
  const idFilteredOutputs = getBaseSetOfOutputIds()
    .filter(
      (outputId) =>
        !ownOutputIds.value.includes(outputId) &&
        !descendantOutputIds.value.includes(outputId) &&
        !activeOutputIds.value.includes(outputId),
    )
    .flatMap((outputId) => activityService.getOutput(outputId) ?? []);
  return typeFilter.value.length
    ? filterBySelectedTypes(idFilteredOutputs)
    : idFilteredOutputs;
});

const selectedOutputIds = ref<string[]>([]);

function addSelectedToActiveOutputs() {
  activeOutputs.value.push(
    ...selectedOutputIds.value.flatMap(
      (outputId) => activityService.getOutput(outputId) ?? [],
    ),
  );
  selectedOutputIds.value = [];
}

function removeActiveOutput(outputId: string) {
  activeOutputs.value = activeOutputs.value.filter(
    (output) => output.id !== outputId,
  );
}

function applySelection() {
  const currentInputs = activityService.getInputs(props.activityId, false);
  const inputsToRemove = currentInputs.filter(
    (input) => !activeOutputIds.value.includes(input.outputId),
  );
  const outputIdsToAdd = activeOutputIds.value.filter(
    (outputId) => !currentInputs.some((input) => input.outputId === outputId),
  );
  outputIdsToAdd.forEach((outputId) =>
    activityService.createOrUpdateInput({
      id: uuidv4(),
      activityId: props.activityId,
      outputId,
    }),
  );
  inputsToRemove.forEach((input) => activityService.removeInput(input.id));
}
</script>

<template>
  <BaseAppDialog class="max-w-6xl w-full h-fit">
    <template #content>
      <div class="px-5 pb-2 flex flex-col gap-3">
        <span>{{ t("processes.filterLabel") }}:</span>

        <BaseSelect
          id="processTemplateSelect"
          optionLabel="name"
          optionValue="id"
          disabled
          :pt="{
            root: { class: 'w-full' },
          }"
          :modelValue="templateFilter"
          :options="templateFilterItems"
          :placeholder="t('processes.processTemplate')"
        />

        <BaseMultiSelect
          id="taskInputSelect"
          v-model="activityFilter"
          optionValue="id"
          optionLabel="name"
          showClear
          fluid
          :options="activityFilterItems"
          :placeholder="t('processes.activity')"
        />

        <BaseMultiSelect
          id="taskInputSelect"
          v-model="typeFilter"
          optionValue="outputType"
          optionLabel="name"
          showClear
          :pt="{
            root: { class: 'w-full' },
          }"
          :options="typeFilterItems"
          :placeholder="t('processes.singleView.outputTypeLabel')"
        />

        <div class="text-center">
          <BaseMultiSelect
            id="taskInputSelect"
            v-model="selectedOutputIds"
            optionValue="id"
            optionLabel="name"
            showClear
            :pt="{
              root: { class: 'w-full' },
            }"
            :options="selectableOutputs"
            :placeholder="t('processes.input', 2)"
          />

          <BaseButton
            class="my-4"
            :disabled="!selectedOutputIds.length"
            :label="
              t('action.addSomething', {
                name: t('processes.input', selectedOutputIds.length),
              })
            "
            :block="true"
            @click="addSelectedToActiveOutputs"
          />
        </div>
        <OutputTable
          contextKey="outputSelection"
          :outputs="activeOutputs"
          :canRemove="canRemove"
          height="12rem"
          @remove="removeActiveOutput"
        />
      </div>
    </template>

    <template #footer>
      <div class="flex flex-row justify-end pt-4 gap-4">
        <BaseButton
          severity="primary"
          @click="
            () => {
              addSelectedToActiveOutputs();
              applySelection();
              dialogRef?.close();
            }
          "
        >
          {{ t("action.confirmSelection") }}
        </BaseButton>
      </div>
    </template>
  </BaseAppDialog>
</template>

<style scoped></style>

<script setup lang="ts">
import {
  type BadgePassThroughOptions,
  type OverlayBadgeSharedPassThroughMethodOptions,
} from "primevue";

export interface BaseOverlayBadgeProps {
  value: string | number;
  severity: "success" | "info" | "warn" | "danger" | "contrast" | "secondary";
  size?: "small" | "large" | "xlarge";
  pt?: BadgePassThroughOptions<OverlayBadgeSharedPassThroughMethodOptions>;
}

withDefaults(defineProps<BaseOverlayBadgeProps>(), {
  size: "small",
  pt: undefined,
});
</script>

<template>
  <POverlayBadge
    :size
    :value
    :severity
    :pt="{
      pcBadge: pt,
    }"
  >
    <slot />
  </POverlayBadge>
</template>

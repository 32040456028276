import { caeliColors } from "@/base/plugins/primevue/colors.ts";

export const progressSpinnerDt = {
  progressspinner: {
    colorScheme: {
      light: {
        root: {
          colorOne: caeliColors.primary,
          colorTwo: caeliColors.primary,
          colorThree: caeliColors.primary,
          colorFour: caeliColors.primary,
        },
      },
      dark: {
        root: {
          color: {
            one: caeliColors.primary,
            two: caeliColors.primary,
            three: caeliColors.primary,
            four: caeliColors.primary,
          },
        },
      },
    },
  },
};

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import FieldCard from "@/app/process/components/field/FieldCard.vue";
import BaseCheckbox from "@/base/components/checkbox/BaseCheckbox.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const emits = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "delete"): void;
}>();

const { t } = useI18n();

function emitUpdate(value: boolean | undefined | null) {
  emits("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueBoolean: value,
    },
  });
}
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <i
        class="mdi opacity-90"
        :class="
          props.fieldValue?.value?.valueBoolean ? 'mdi-check' : 'mdi-close'
        "
      />

      <p v-if="props.fieldValue?.value?.valueBoolean === true">
        {{ t("fields.booleanTrue") }}
      </p>

      <p v-if="props.fieldValue?.value?.valueBoolean === false">
        {{ t("fields.booleanFalse") }}
      </p>

      <p v-else-if="props.fieldValue?.value?.valueBoolean === undefined">
        {{ "-" }}
      </p>
    </template>

    <template #edit>
      <div class="flex flex-row gap-2 pt-1 pb-2">
        <BaseCheckbox
          data-testid="boolean-field-checkbox"
          binary
          :indeterminate="fieldValue?.value?.valueBoolean === undefined"
          :modelValue="fieldValue?.value?.valueBoolean"
          @update:modelValue="emitUpdate"
        />

        <p v-if="props.fieldValue?.value?.valueBoolean === true">
          {{ t("fields.booleanTrue") }}
        </p>

        <p v-if="props.fieldValue?.value?.valueBoolean === false">
          {{ t("fields.booleanFalse") }}
        </p>
      </div>
    </template>
  </FieldCard>
</template>

<script setup lang="ts">
import Textarea, { type TextareaPassThroughOptions } from "primevue/textarea";

export interface BaseTextAreaProps {
  name?: string;
  autoResize?: boolean;
  size?: "small" | "large";
  variant?: "outlined" | "filled";
  invalid?: boolean;
  fluid?: boolean;
  unstyled?: boolean;
  pt?: TextareaPassThroughOptions;
  rows?: number;
  cols?: number;
}

withDefaults(defineProps<BaseTextAreaProps>(), {
  name: "",
  autoResize: false,
  size: "small",
  variant: "outlined",
  invalid: false,
  fluid: false,
  unstyled: false,
  rows: 5,
  cols: 30,
  pt: undefined,
});

const model = defineModel<string>();
</script>

<template>
  <Textarea
    v-model="model"
    :name="name"
    :autoResize="autoResize"
    :size="size"
    :invalid="invalid"
    :fluid="fluid"
    :unstyled="unstyled"
    :variant="variant"
    :rows="rows"
    :cols="cols"
    :pt="{
      ...pt,
    }"
  />
</template>

<style scoped></style>

<script lang="ts" setup>
import { Editor } from "@toast-ui/editor";
import { ref, watchEffect } from "vue";

const props = defineProps<{
  label?: string;
  value?: string;
  markdown?: boolean;
}>();

const markdownContainer = ref<HTMLElement | null>();

watchEffect(() => {
  if (!markdownContainer.value) {
    return;
  }
  Editor.factory({
    el: markdownContainer.value,
    viewer: true,
    initialValue: props.value?.trim(),
    linkAttributes: {
      target: "_blank",
    },
  });
});
</script>

<template v-if="props.value && props.value.length">
  <div class="flex flex-col gap-2">
    <p v-if="props.label" class="text-sm font-bold">
      {{ props.label }}
    </p>

    <div v-if="markdown" ref="markdownContainer" class="-mt-2! -mb-2!">
      <div></div>
    </div>

    <p v-else class="text-caeli6 text-sm">
      {{ props.value }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { useValidators } from "@/base/components/form/validators.ts";
import BaseMessage from "@/base/components/message/BaseMessage.vue";
import useErrors from "@/base/composables/useErrors.ts";

export interface BaseTextFieldProps {
  initialValue?: string;
  disabled?: boolean;
  label?: string;
  noLabel?: boolean;
  required?: boolean;
  technicalKey?: boolean;
  autofocus?: boolean;
  icon?: string;
  size?: "small" | "large";
  type?: "text" | "number" | "url" | "date";
  invalid?: boolean;
}

const props = withDefaults(defineProps<BaseTextFieldProps>(), {
  initialValue: undefined,
  label: undefined,
  size: "small",
  type: "text",
  icon: undefined,
});
const emits =
  defineEmits<(event: "update" | "keyDown", value: string) => void>();

const validators = useValidators();
const { id, errors, resetErrorForId } = useErrors();

const lastEmitted = ref(props.initialValue ?? "");
const valueString = ref(props.initialValue ?? "");

watch(
  () => props.initialValue,
  (newValue) => (valueString.value = newValue ?? ""),
);

function isValid(value: string): boolean | string {
  if (!props.required) {
    return true;
  }
  return validators.required(value);
}

function isTechnicalKey(value: string): boolean | string {
  if (!props.technicalKey) {
    return true;
  }
  return validators.technicalKey(value);
}

function emitValue() {
  if (
    isValid(valueString.value) !== true ||
    isTechnicalKey(valueString.value) !== true
  ) {
    return;
  }
  if (lastEmitted.value === valueString.value) {
    return;
  }
  emits("update", valueString.value);
  lastEmitted.value = valueString.value;
}

function emitKeypress() {
  emits("keyDown", valueString.value);
}
</script>

<template>
  <div class="flex flex-col">
    <PIconField>
      <PFloatLabel variant="on">
        <PInputIcon v-if="icon" :class="icon" />
        <PInputText
          :id
          v-model="valueString"
          fluid
          autocomplete="off"
          :invalid="errors.length > 0 || invalid"
          :type
          :autofocus
          :size
          :disabled
          :pt="{
            root: 'text-caeli6',
          }"
          @blur="emitValue"
          @keydown.enter="emitValue"
          @valueChange="emitKeypress"
          @update:modelValue="resetErrorForId"
        >
          <slot />
        </PInputText>

        <label
          v-if="label"
          class="text-sm"
          :class="disabled ? 'bg-transparent text-surface-400!' : ''"
          :for="id"
          >{{ label }}</label
        >
      </PFloatLabel>
    </PIconField>

    <div class="mt-1 ml-1">
      <BaseMessage
        v-for="error in errors"
        :key="error.id"
        size="small"
        severity="error"
        variant="simple"
      >
        {{ error.message }}
      </BaseMessage>
    </div>
  </div>
</template>

<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { inject, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import type { DeleteDialogData } from "@/base/composables/dialog/dialogData.ts";

const { t } = useI18n();
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: DeleteDialogData = dialogRef?.value.data;
const emit = defineEmits(["delete"]);
</script>

<template>
  <BaseAppDialog>
    <template #content>
      {{ props.deleteDescription }}
    </template>
    <template #footer>
      <div class="flex flex-row justify-end pt-4 gap-4">
        <BaseButton
          data-testid="dialog-delete-button"
          severity="danger"
          :label="t('action.delete')"
          @click="emit('delete')"
        />
      </div>
    </template>
  </BaseAppDialog>
</template>

<style scoped></style>

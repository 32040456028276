<script lang="ts" setup>
import dayjs from "dayjs";
import { computed, useId } from "vue";

const props = defineProps<{
  dateValue?: string;
  clearable?: boolean;
  label?: string;
  readonly?: boolean;
  withTime?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: string): void;
  (e: "clear"): void;
}>();

const id = useId();

const dateFormatted = computed<Date | undefined>(() => {
  return formatDate(props.dateValue);
});

function formatDate(dateString?: string): Date | undefined {
  if (!dateString) {
    return undefined;
  }
  return dayjs(dateString).toDate();
}

function updateDate(date: Date) {
  const format = props.withTime ? "YYYY-MM-DDTHH:mm:ssZ" : "YYYY-MM-DD";
  emit("update", dayjs(date).format(format));
}
</script>

<template>
  <PFloatLabel variant="on">
    <PDatePicker
      showIcon
      fluid
      size="small"
      hourFormat="24"
      :showTime="withTime"
      :modelValue="dateFormatted"
      :disabled="readonly"
      @dateSelect="updateDate($event)"
    />

    <label
      v-if="label"
      class="text-sm"
      :class="readonly ? 'bg-transparent text-surface-400!' : ''"
      :for="id"
      >{{ label }}</label
    >
  </PFloatLabel>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import DocumentRunGenerationParameter from "@/app/document/components/dialog/DocumentRunGenerationParameter.vue";
import { useDocumentGenerationRunService } from "@/app/document/services/DocumentGenerationRunService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import {
  type BaseDialogProps,
  type DocumentGenerationParameterDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";

const props = defineProps<{
  documentGenerationRunId: string;
}>();

const { t } = useI18n();

const documentGenerationRunService = useDocumentGenerationRunService();
const dialog = useBaseDialog();

function showDocumentGenerationParameters(dialogEntity: string) {
  const documentGenerationParameterProps: DocumentGenerationParameterDialogData =
    {
      dialogEntityId: dialogEntity,
    };
  const dialogProps: BaseDialogProps = {
    header: t("documentGenerationRun.parameterView.title"),
    props: documentGenerationParameterProps,
  };

  dialog.open(DocumentRunGenerationParameter, dialogProps);
}
</script>

<template>
  <div class="flex flex-row items-center gap-2 h-5">
    <BaseButton
      v-tooltip.left="t('documentGenerationRun.actions.download')"
      data-testid="download-document-button"
      text
      severity="secondary"
      icon="mdi mdi-download"
      @click.stop.prevent="
        documentGenerationRunService.downloadDocuments(
          props.documentGenerationRunId,
        )
      "
    />

    <BaseButton
      v-tooltip.left="t('documentGenerationRun.actions.viewParameter')"
      data-testid="view-parameter-button"
      text
      severity="secondary"
      icon="mdi mdi-database-eye"
      @click.stop.prevent="
        showDocumentGenerationParameters(props.documentGenerationRunId)
      "
    />
  </div>
</template>

import { type TooltipDirectivePassThroughOptions } from "primevue";

export const tooltipPt: Record<string, TooltipDirectivePassThroughOptions> = {
  tooltip: {
    text: {
      class: "bg-surface-600! text-sm! p-1!",
    },
    arrow: ({ context }) => {
      if (context.top) {
        return {
          class: "border-t-surface-600!",
        };
      } else if (context.bottom) {
        return {
          class: "border-b-surface-600!",
        };
      } else if (context.left) {
        return {
          class: "border-s-surface-600!",
        };
      } else {
        return {
          class: "border-e-surface-600!",
        };
      }
    },
  },
};

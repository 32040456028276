//
import { definePreset } from "@primeuix/themes";
import Aura from "@primeuix/themes/aura";

import { caeliColors } from "@/base/plugins/primevue/colors.ts";
import { progressSpinnerDt } from "@/base/plugins/primevue/designtokens/progressSpinner.dt.ts";

export const caeliTheme = definePreset(Aura, {
  semantic: {
    colorScheme: {
      light: {
        primary: {
          color: caeliColors.primary,
        },
        secondary: {
          color: caeliColors.secondary,
        },
        warn: {
          color: caeliColors.warn,
        },
      },
    },
  },
  // @ts-expect-error PrimeVue typings are not quite right
  components: {
    ...progressSpinnerDt,
  },
});

<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import { useClipboard } from "@vueuse/core";
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import SelectFromTableDialog from "@/app/activity/components/dialog/SelectFromTableDialog.vue";
import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import TagsPopoverButton from "@/app/process/components/field/TagsPopoverButton.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import AddFieldDialog from "@/base/components/fields/AddFieldDialog.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type AddFieldDialogData,
  type BaseDialogProps,
  type DeleteDialogData,
  type DeleteDialogProps,
  type SelectTableDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import {
  type ActivityEto,
  ActivityOutputType,
  EntityType,
} from "@/base/graphql/generated/types.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";

const props = defineProps<{
  activity: ActivityEto;
  entityTypes: EntityType[];
}>();

const { t } = useI18n();
const router = useRouter();
const promptService = usePromptService();
const dialog = useBaseDialog();
const activityService = useActivityService();
const { copy, copied } = useClipboard();

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    visible: !isReleased.value && !props.activity.custom,
    label: t("processes.selectInputs"),
    icon: "mdi mdi-plus",
    command: () => showSelectInputsDialog(),
  },
  {
    testId: "activity-create-output-button",
    visible: !isReleased.value,
    label: isRootActivity.value
      ? t("processes.singleView.createStartparameterButtonLabel")
      : t("processes.singleView.createOutputButtonLabel"),
    icon: "mdi mdi-plus",
    command: () => {
      activityService.createOrUpdateOutput(
        {
          id: uuidv4(),
          type: ActivityOutputType.String,
          description: "",
        },
        props.activity.id,
      );
    },
  },
  {
    testId: "activity-create-task-button",
    visible: !isReleased.value && !isRootActivity.value,
    label: t("processes.singleView.createTaskButtonLabel"),
    icon: "mdi mdi-plus",
    command: () => {
      activityService.createOrUpdateTask(
        {
          id: uuidv4(),
          description: "",
        },
        props.activity.id,
      );
    },
  },
  {
    testId: "add-field-button",
    label: t("action.addSomething", { name: t("processes.field") }),
    icon: "mdi mdi-plus",
    command: () => {
      showAddFieldDialog(props.activity.id, props.entityTypes);
    },
  },
  {
    visible: isReleased.value && !current.value?.released,
    label: t("processes.draft"),
    icon: "mdi mdi-pencil",
    command: () => {
      router.push({
        name: "activity",
        params: { activityId: current.value?.id },
      });
    },
  },
  {
    separator: true,
  },
  {
    label: t("action.delete"),
    icon: "mdi mdi-delete",
    severity: "danger",
    command: () => showDeleteDialog(),
  },
]);

const current = computed(() =>
  activityService.getCurrentVersion(
    activityService.getMid(props.activity.id) ?? "undefined",
  ),
);

const isReleased = computed(() => {
  return activityService.isReleased(props.activity.id);
});

const isRootActivity = computed(() => {
  return !props.activity.inputs.length && !props.activity.custom;
});

function showAddFieldDialog(entityId: string, entityType: EntityType[]) {
  const addFieldDialogData: AddFieldDialogData = { entityId, entityType };

  const showAddFieldDialogProps: BaseDialogProps = {
    header: `${t("action.addSomething", { name: t("processes.field") })}`,
    props: addFieldDialogData,
  };

  dialog.open(AddFieldDialog, showAddFieldDialogProps);
}

function showDeleteDialog() {
  const deleteDialogData: DeleteDialogData = {
    deleteDescription: t("processes.listView.deleteActivityText", {
      name: props.activity.name,
    }),
  };

  const deleteDialogProps: DeleteDialogProps = {
    header: t("processes.listView.deleteActivity"),
    props: deleteDialogData,
    onDelete: () => {
      router.go(-1);
      activityService.deleteActivity(props.activity.id).then(
        () => promptService.success(Action.DELETE),
        () =>
          promptService.failure(
            Action.DELETE,
            t("processes.templateProbablyInUse"),
          ),
      );

      dialogRef.close();
    },
  };

  const dialogRef: DynamicDialogInstance = dialog.open(
    DeleteDialog,
    deleteDialogProps,
  );
}

function showSelectInputsDialog() {
  const selectTableDialogData: SelectTableDialogData = {
    activityId: props.activity.id,
  };

  const dialogData: BaseDialogProps = {
    header: t("processes.input", 2),
    props: selectTableDialogData,
    onCancel: () => {
      dialogRef.close();
    },
  };
  const dialogRef = dialog.open(SelectFromTableDialog, dialogData);
}
</script>

<template>
  <div class="flex flex-row justify-between items-start gap-6">
    <div class="flex flex-row gap-4 items-baseline w-full">
      <div
        class="flex flex-row gap-4 items-start"
        :class="{ 'w-full': !isReleased }"
      >
        <BaseAvatar
          v-tooltip.bottom="t('action.copyIdTooltip')"
          class="mt-1 text-caeli6 cursor-pointer transition-all duration-400 ease-out"
          :icon="copied ? 'mdi mdi-check' : 'mdi mdi-play-box'"
          :pt:root="copied ? 'bg-primary-400!' : undefined"
          @click="copy(activity.id)"
        />

        <p v-if="isReleased" class="text-3xl/10 font-bold">
          {{ activity.name }}
        </p>

        <BaseTextField
          v-else
          data-testid="activity-single-view-name"
          class="w-full"
          :initialValue="activity.name"
          :label="t('processes.nameLabel')"
          @update="
            (value: string) =>
              activityService.createOrUpdateActivity({
                id: activity.id,
                name: value,
              })
          "
        />
      </div>

      <PTag
        v-if="isReleased"
        severity="primary"
        class="max-h-6!"
        :value="t('processes.released')"
      >
        <template #icon>
          <i class="mdi mdi-check" />
        </template>
      </PTag>
    </div>

    <div v-if="!isReleased" class="flex flex-row gap-6 items-center">
      <TagsPopoverButton
        :entityId="activity.id"
        :entityType="EntityType.Process"
      />

      <BaseActionsButton :model="actionsMenuItems" />
    </div>
  </div>
</template>

import { type DocumentNode } from "@apollo/client/core";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryReturnType,
} from "@tanstack/vue-query";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import {
  useApplyPaginationInfo,
  useGetListFilter,
} from "@/base/components/filterdatatable/useDataTableFilter.ts";
import {
  type BaseDialogProps,
  type ResetListFilterDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import useErrors, {
  type GraphQLErrorData,
  type MappedAPIError,
} from "@/base/composables/useErrors.ts";
import { type Eto } from "@/base/graphql/generated/types.ts";
import {
  graphQl,
  rawRequest,
  type ResultWithExtensions,
} from "@/base/plugins/apollo/graphQlClient.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";
import ResetListFilterDialog from "@/base/stores/ResetListFilterDialog.vue";

export interface EntityStoreConfig {
  key: string;
  singleViewRouteParam: string;
  contextKey: string;
  listQuery: DocumentNode;
  singleQuery: DocumentNode;
  createOrUpdateMutation: DocumentNode;
  deleteMutation: DocumentNode;
}

const queryErrors: string[] = ["bad sql grammar"];

export function defineEntityStoreV2<E extends Eto, D extends { id: string }>(
  config: EntityStoreConfig,
) {
  return defineStore(config.key, () => {
    const fieldService = useFieldService();
    const queryClient = useQueryClient();
    const router = useRouter();
    const dialog = useBaseDialog();
    const { t } = useI18n();
    const { listFilter, resetListFilter } = useGetListFilter(config.contextKey);
    const promptService = usePromptService();
    const { setErrors } = useErrors();
    const { applyPaginationInfo } = useApplyPaginationInfo(config.contextKey);

    const listKey = `${config.contextKey}List`;
    const singleKey = `${config.contextKey}Single`;

    const selectedEntityId = ref<string | undefined>(undefined);

    const entities = computed(() => listQuery.data.value ?? []);
    const selectedEntity = computed(() => singleQuery.data.value);

    const isLoading = computed<boolean>(
      () =>
        listQuery.isLoading.value ||
        listQuery.isRefetching.value ||
        singleQuery.isLoading.value ||
        singleQuery.isRefetching.value,
    );

    // TODO: Add back staleTime with 15s (make it configurable for e2e tests)
    const listQuery = useQuery({
      queryKey: [listKey, listFilter],
      queryFn: () => fetchAll(),
      placeholderData: keepPreviousData,
    });

    const singleQuery = useQuery({
      queryKey: [singleKey, selectedEntityId],
      queryFn: () => fetchOne(selectedEntityId.value),
      enabled: computed(() => selectedEntityId.value !== undefined),
    });

    const createOrUpdateMutation = useMutation<
      E | undefined,
      GraphQLErrorData,
      D
    >({
      mutationFn: (dto: D) => createOrUpdate(dto),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [listKey] });
        await queryClient.invalidateQueries({
          queryKey: [singleKey, selectedEntityId],
        });
      },
    });

    const deleteMutation = useMutation({
      mutationFn: (id: string) => deleteEntity(id),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [listKey] });
        await queryClient.invalidateQueries({
          queryKey: [singleKey, selectedEntityId],
        });
      },
    });

    watch(router.currentRoute, setSelectedIdFromRoute, { immediate: true });

    watch(createOrUpdateMutation.error, () => {
      setErrors(
        createOrUpdateMutation.error.value?.response?.errors ?? [],
        showErrorPrompt,
      );
    });

    function showErrorPrompt(errors: MappedAPIError[]) {
      errors.forEach((error) => {
        promptService.failure(Action.SAVE, error.message);
      });
    }

    watch(listQuery.error, handleListQueryErrors);

    async function fetchAll(): Promise<E[]> {
      const result = await rawRequest(config.listQuery, {
        filter: {
          listFilter: {
            ...listFilter.value,
            pagination: {
              pageNumber: listFilter.value.pagination?.pageNumber ?? 0,
              pageSize: listFilter.value.pagination?.pageSize ?? 25,
            },
          },
        },
      });

      applyPaginationInfo(result.extensions?.pagination);

      const resultKey = getResultKey(result, `${config.key}-listQuery`);

      if (!resultKey) {
        return [];
      }

      // TODO: Replace this with our new implementation
      const fieldValues = result.data?.[resultKey]?.flatMap(
        (item) => item?.fields ?? [],
      );
      fieldValues.forEach((fieldValue) =>
        fieldService.getFieldKey(fieldValue?.fieldKeyId),
      );
      fieldService.registerLoadedFieldValues(fieldValues);

      return result.data?.[resultKey];
    }

    async function fetchOne(id: string | undefined): Promise<E> {
      if (id === undefined) {
        throw Error("No id provided to fetch call");
      }

      const result = await rawRequest<E>(config.singleQuery, {
        filter: { ids: [id] },
      });

      const resultKey = getResultKey(result, `${config.key}-singleQuery`);

      if (!resultKey) {
        throw Error("No result key found");
      }

      const entity = result.data?.[resultKey]?.find((item) => item.id === id);
      fieldService.registerLoadedFieldValues(entity?.fields ?? []);

      if (!entity) {
        throw Error(`Could not find entity with id ${id}`);
      }

      return entity;
    }

    async function createOrUpdate(dto: {
      id: string;
      [key: string]: unknown;
    }): Promise<E | undefined> {
      const result = await rawRequest<E>(config.createOrUpdateMutation, {
        dto,
      });

      selectedEntityId.value = dto.id;
      const resultKey = getResultKey(result, `${config.key}-singleQuery`);

      if (!resultKey) {
        return undefined;
      }

      return result.data?.[resultKey] as E;
    }

    async function deleteEntity(id: string): Promise<string> {
      await graphQl.request(config.deleteMutation, { id });
      selectedEntityId.value = undefined;
      return id;
    }

    function getById(id: string): UseQueryReturnType<E | undefined, Error> {
      selectedEntityId.value = id;
      return singleQuery;
    }

    function setSelectedIdFromRoute(): string | undefined {
      const id =
        router.currentRoute.value.params?.[config.singleViewRouteParam];

      if (!id) {
        return undefined;
      }

      const entityId = id as string;

      if (entityId !== selectedEntityId.value) {
        selectedEntityId.value = entityId;
      }

      return entityId;
    }

    function getResultKey(
      result: ResultWithExtensions<unknown>,
      logPrefix?: string,
    ): string | undefined {
      const resultKey = Object.keys(result?.data ?? {})?.[0];

      if (!resultKey) {
        const prefix = logPrefix ? `[${logPrefix}]` : "";
        console.warn(`${prefix}No data found in result, wrong key?`);
      }

      return resultKey;
    }

    function handleListQueryErrors() {
      const errorMessage = listQuery.error.value?.message.toLowerCase();

      if (
        errorMessage &&
        queryErrors.some((error) => errorMessage.includes(error))
      ) {
        const dialogData: ResetListFilterDialogData = {
          contextKey: config.contextKey,
        };

        const dialogProps: BaseDialogProps = {
          header: t("dialogs.resetListFilterDialog.title"),
          props: dialogData,
          onClose: async (params) => {
            const success = (params as { data?: boolean })?.data;
            if (success) {
              await resetListFilter();
              listQuery.refetch();
            }
          },
        };

        dialog.open(ResetListFilterDialog, dialogProps);
      }
    }

    return {
      listKey,
      singleKey,
      createOrUpdateMutation,
      deleteMutation,
      isLoading,
      entities,
      selectedEntity,
      getById,
    };
  });
}

<script lang="ts" setup>
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import ValueField from "@/app/process/components/field/ValueField.vue";
import ValueFieldCard from "@/app/process/components/field/ValueFieldCard.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import { type EntityFieldsCardItem } from "@/base/components/fields/EntityFieldsCard.types.ts";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { type FieldValueDto } from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = defineProps<{
  entityId: string;
  items: EntityFieldsCardItem[];
  cards?: boolean;
  readonly?: boolean;
  edit?: boolean;
}>();

const { t } = useI18n();
const fieldService = useFieldService();
const showDialog = useBaseDialog();

const sortedItems = computed(() => {
  return [...props.items].sort(
    (a, b) => a.key.name?.localeCompare(b.key.name ?? "") ?? 0,
  );
});

function showDeleteDialog(fieldValue: FieldValueDto) {
  const deleteDialogData: DeleteDialogData = {
    deleteDescription: t("fields.delete", {
      field: fieldService.getFieldKey(fieldValue?.fieldKeyId ?? "")?.name ?? "",
    }),
  };
  const deleteDialogProps: DeleteDialogProps = {
    header: `${t("fields.deleteTitle")}`,
    props: deleteDialogData,
    onDelete: () => {
      fieldService.deleteFieldValue(fieldValue.id);
      dialogRef.close();
    },
  };
  const dialogRef: DynamicDialogInstance = showDialog.open(
    DeleteDialog,
    deleteDialogProps,
  );
}
</script>

<template>
  <div>
    <div
      class="flex justify-start"
      :class="{
        'flex-col gap-4': !cards,
        'flex-row flex-wrap gap-y-2 mt-2': cards,
        'gap-x-4': cards && edit,
      }"
    >
      <template v-for="field in sortedItems" :key="field.value.id">
        <Component
          :is="cards ? ValueFieldCard : ValueField"
          :targetEntityId="props.entityId"
          :fieldValueId="[field.value.id]"
          :readonly="props.readonly || field.readonly"
          :edit="props.edit"
          @update="
            (newValue) => fieldService.createOrUpdateFieldValue(newValue)
          "
          @delete="showDeleteDialog(field.value)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import Image from "primevue/image";
import Message from "primevue/message";
import { type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";

/* Props */
export interface BaseLazyImageProps {
  loadLabel: string;
  disabled?: boolean;
  content: Ref<string>;
  errorMessage: Ref<string>;
}
const props = withDefaults(defineProps<BaseLazyImageProps>(), {
  disabled: false,
});

/* Emits */
const emits = defineEmits<(e: "load") => void>();

/* Composables (use...) */
const { t } = useI18n();

/* Refs */
const visible = ref(false);

/* Computeds */

/* Lifecycle (OnMounted, Watch, ..) */

/* Functions */
const showImageClicked = () => {
  visible.value = true;
  emits("load");
};
</script>

<template>
  <BaseButton
    variant="text"
    icon="mdi mdi-magnify"
    class="text-primary-500! hover:text-primary-300!"
    :label="props.loadLabel"
    @click="showImageClicked()"
  />
  <Dialog
    v-model:visible="visible"
    modal
    :closable="false"
    :dismissableMask="true"
    :showHeader="!!errorMessage.value"
    :header="t('lazyImage.error.header')"
    :blockScroll="true"
    :draggable="false"
  >
    <Image v-if="content?.value" :src="content?.value" />
    <Message v-if="errorMessage.value" severity="error" class="mt-2!">
      {{ errorMessage.value }}
    </Message>
  </Dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { usePersonServiceV2 } from "@/app/person/services/PersonServiceV2.ts";
import TagsPopoverButton from "@/app/process/components/field/TagsPopoverButton.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import AddFieldDialog from "@/base/components/fields/AddFieldDialog.vue";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type AddFieldDialogData,
  type BaseDialogProps,
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { TitleMode, useTitle } from "@/base/composables/useTitle.ts";
import { EntityType, type PersonDto } from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  personId: string;
}>();

const { t } = useI18n();

const personServiceV2 = usePersonServiceV2();
const dialog = useBaseDialog();

const { selectedPerson } = storeToRefs(personServiceV2);

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "entity-add-field",
    label: t("action.addSomething", { name: t("processes.field") }),
    icon: "mdi mdi-plus",
    command: () => showAddFieldDialog(props.personId, [EntityType.Person]),
  },
  {
    separator: true,
    class: "my-1 opacity-50",
  },
  {
    testId: "person-delete",
    label: t("action.delete"),
    icon: "mdi mdi-trash-can-outline",
    severity: "danger",
    command: () => showDeleteDialog(),
  },
]);

const tabTitle = computed(
  () => selectedPerson.value?.name ?? t("person.person", 2),
);

useTitle(tabTitle, { titleMode: TitleMode.EDIT });

async function updatePerson(update: Partial<PersonDto>) {
  if (!selectedPerson.value) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { references, fields, ...personWithoutFields } = selectedPerson.value;
  await personServiceV2.createOrUpdate({ ...personWithoutFields, ...update });
}

function showDeleteDialog() {
  if (selectedPerson?.value !== undefined) {
    const deleteDialogData: DeleteDialogData = {
      deleteDescription: t("person.deletePersonText", {
        name: selectedPerson.value.name,
      }),
    };

    const deleteDialogProps: DeleteDialogProps = {
      header: t("person.deletePerson"),
      props: deleteDialogData,
      onDelete: async () => {
        await personServiceV2.deletePerson(props.personId);
        dialogRef.close();
      },
    };
    const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
  }
}

function showAddFieldDialog(entityId: string, entityType: EntityType[]) {
  const addFieldDialogData: AddFieldDialogData = { entityId, entityType };
  const showAddFieldDialogProps: BaseDialogProps = {
    header: `${t("action.addSomething", { name: t("processes.field") })}`,
    props: addFieldDialogData,
  };
  dialog.open(AddFieldDialog, showAddFieldDialogProps);
}
</script>

<template>
  <BaseCard
    v-if="selectedPerson"
    class="h-full p-4!"
    flat
    noShadow
    :rounded="false"
  >
    <template #title>
      <div class="flex flex-row gap-4 justify-between items-start">
        <BaseTextField
          id="person.name"
          data-testid="person-name"
          class="w-full"
          :label="t('person.name')"
          :initialValue="selectedPerson.name"
          @update="(value) => updatePerson({ name: value })"
        />

        <div class="flex flex-row gap-4 items-center">
          <TagsPopoverButton
            :entityId="props.personId"
            :entityType="EntityType.Person"
          />
          <BaseActionsButton outlined :model="actionsMenuItems" />
          <BaseButton
            data-testid="person-edit-close"
            icon="mdi mdi-check"
            :label="t('action.close')"
            :to="{
              name: 'personView',
              params: { personId: props.personId },
            }"
          />
        </div>
      </div>
    </template>

    <EntityFieldsCard
      cards
      grouped
      edit
      :entityType="[EntityType.Person]"
      :entityId="props.personId"
    />
  </BaseCard>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseDrawer from "@/base/components/drawer/BaseDrawer.vue";
import EntityHistoryDrawerFilter from "@/base/components/drawer/entityevent/EntityHistoryDrawerFilter.vue";
import LoadingIndicator from "@/base/components/loading/LoadingIndicator.vue";
import BaseMessage from "@/base/components/message/BaseMessage.vue";
import BaseTimeline from "@/base/components/timeline/BaseTimeline.vue";
import { useEntityHistoryDrawer } from "@/base/composables/useEntityHistoryDrawer.ts";
import { EntityEventAction } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";

const { t } = useI18n();
const { isOpen, isLoading, events, header } = useEntityHistoryDrawer();

const filter = ref<EntityEventAction[]>([]);
const sort = ref<"ASC" | "DSC">("DSC");

const mappedEvents = computed(() => {
  return events.value
    .map((event) => ({
      id: event.id,
      action: event.action,
      formattedAction: translateEnum("entityEvent.actions", event.action),
      timestamp: event.timestamp,
      formattedTimestamp: dayjs(event.timestamp).format("DD.MM.YYYY - HH:mm"),
      author: event.author,
    }))
    .filter((event) => {
      if (filter.value.length === 0) {
        return true;
      }
      return filter.value.includes(event.action);
    })
    .sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);

      if (sort.value === "ASC") {
        return dateA.getTime() - dateB.getTime();
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });
});
</script>

<template>
  <BaseDrawer v-model:visible="isOpen" :header="header" position="right">
    <EntityHistoryDrawerFilter
      v-model:filter="filter"
      v-model:sort="sort"
      :numberOfEntries="mappedEvents.length"
      c
      class="mb-8"
    />

    <LoadingIndicator v-if="isLoading" class="mb-8" />

    <BaseMessage
      v-else-if="mappedEvents.length === 0"
      severity="warn"
      class="text-center"
    >
      {{ t("drawers.eventHistory.noEvents") }}
    </BaseMessage>

    <BaseTimeline
      v-else
      :items="mappedEvents"
      class="w-full"
      :pt="{ event: { class: '-ml-60!' } }"
    >
      <template #content="slotProps">
        <div data-testid="entity-history-item">
          <p class="text-sm font-bold">
            {{ slotProps.item.formattedAction }}
          </p>

          <p class="text-sm font-thin">
            {{ slotProps.item.formattedTimestamp }}
          </p>

          <RouterLink
            v-if="slotProps.item.author"
            :to="{
              name: 'personView',
              params: { personId: slotProps.item.author.id },
            }"
          >
            <p
              class="text-sm font-thin text-primary-500! hover:text-primary-300 cursor-pointer"
            >
              {{ slotProps.item.author.name }}
            </p>
          </RouterLink>
        </div>
      </template>

      <template #marker="slotProps">
        <BaseAvatar
          :icon="
            slotProps.item.action === EntityEventAction.Created
              ? 'mdi mdi-plus'
              : slotProps.item.action === EntityEventAction.Updated
                ? 'mdi mdi-update'
                : 'mdi mdi-trash-can'
          "
          :class="
            slotProps.item.action === EntityEventAction.Created
              ? 'bg-primary-100/70!'
              : slotProps.item.action === EntityEventAction.Updated
                ? 'bg-orange-100/70!'
                : 'bg-red-100/70!'
          "
        />
      </template>
    </BaseTimeline>
  </BaseDrawer>
</template>

<script setup lang="ts">
import AppHeader from "@/base/components/header/AppHeader.vue";
import AppMenu from "@/base/components/navigation/AppMenu.vue";
</script>

<template>
  <div class="flex flex-col h-screen">
    <AppHeader />

    <div class="flex flex-row w-full overflow-hidden">
      <AppMenu class="h-screen" />

      <div id="routeSlot" class="grow min-h-0 overflow-x-auto">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick } from "vue";

import COptionField from "@/app/process/components/field/COptionField.vue";
import FieldCard from "@/app/process/components/field/FieldCard.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);

async function emitOptionUpdate(value: string, closeCallback?: () => void) {
  emit("update", { ...props.fieldValue, optionId: value });

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  if (!props.edit) {
    await nextTick();
    setTimeout(() => closeCallback?.(), 300);
  }
}
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <p class="block text-base truncate whitespace-pre-wrap break-all">
        {{ props.fieldValue?.value?.valueString ?? "-" }}
      </p>
    </template>

    <template #edit="{ closeCallback }">
      <COptionField
        v-if="isOptionField"
        :fieldKey="props.fieldKey"
        :fieldValue="props.fieldValue"
        :label="props.fieldKey.name"
        itemTitle="valueString"
        @update="(value) => emitOptionUpdate(value, closeCallback)"
      />

      <BaseTextField
        v-else
        v-focustrap
        data-testid="string-field-input"
        :initialValue="props.fieldValue?.value?.valueString ?? undefined"
        @update="
          (value) => {
            emit('update', {
              ...props.fieldValue,
              value: {
                valueString: value,
              },
            });
            if (!edit) {
              closeCallback();
            }
          }
        "
      />
    </template>
  </FieldCard>
</template>

<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import Message from "primevue/message";
import { computed, inject, type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

import CheckPlaceholderReplacementValuesForDocumentDialog from "@/app/document/components/dialog/CheckPlaceholderReplacementValuesForDocumentDialog.vue";
import {
  GenerationStatusEnum,
  useDocumentService,
} from "@/app/document/services/DocumentService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCheckbox from "@/base/components/checkbox/BaseCheckbox.vue";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import BaseDivider from "@/base/components/divider/BaseDivider.vue";
import BaseTextArea from "@/base/components/form/BaseTextArea.vue";
import BaseRadioButton from "@/base/components/radio/BaseRadioButton.vue";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import type {
  BaseDialogProps,
  DocumentGenerationPlaceholderReplacementValuesCheckDialogData,
  GenerateDocumentDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import {
  type DocumentEto,
  type DocumentGenerationOptionsDto,
  DocumentGenerationOptionsHighlightColor,
  ValidationCode,
} from "@/base/graphql/generated/types.ts";
import { useDialogService } from "@/base/services/DialogService.ts";
import { useFileService } from "@/base/services/file/FileService.ts";

/* Props */

/* Emits */
defineEmits(["confirm", "cancel", "close"]);

/* Composables (use...) */
const documentService = useDocumentService();
const dialogService = useDialogService();
const fileService = useFileService();
const showDialog = useBaseDialog();
const processService = useProcessService();

const { t } = useI18n();

/* Refs */
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: GenerateDocumentDialogData = dialogRef?.value.data;

const chosenItemId = ref<string | undefined>(props.preselectedItem);

const documentGenerationOptions = ref({
  ignoreReplacementValuesSelected: false,
  replacedValuesHighlightColorSelected: true,
  missingValuesForPlaceholderHandlingPrefixSelected: true,
  missingValuesForPlaceholderHandlingSelectionValue:
    "missingValuesHighlightColorSelected",
  applyIterator: true,
});

/* Computeds */
const selectedDocumentId = computed({
  get: () => dialogService.dialogEntityId,
  set: (newVal) => (dialogService.dialogEntityId = newVal),
});

const documentItems = computed(() =>
  documentService.getAll().map((item: DocumentEto) => ({
    id: item.id,
    name: item.name,
  })),
);
const processItems = computed(() => {
  return processService
    .getProcesses()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => ({
      value: item.id,
      text: item.name,
    }));
});

const loadingProcesses = computed(() => processItems.value.length === 0);

/* Lifecycle (OnMounted, Watch, ..) */

/* Functions */
const closeDialog = () => {
  dialogRef?.value.close(documentService.documentGenerationRun?.id ?? "");
};

const getChosenDocumentGenerationOptionsDto =
  (): DocumentGenerationOptionsDto => {
    const options = documentGenerationOptions.value;
    const generationOptions: DocumentGenerationOptionsDto = {
      replacedValuesHighlightColor: options.replacedValuesHighlightColorSelected
        ? DocumentGenerationOptionsHighlightColor.Yellow
        : undefined,
      missingValuesHighlightColor:
        options.missingValuesForPlaceholderHandlingPrefixSelected &&
        options.missingValuesForPlaceholderHandlingSelectionValue ===
          "missingValuesHighlightColorSelected"
          ? DocumentGenerationOptionsHighlightColor.Red
          : undefined,
      missingValuesFallbackValue:
        options.missingValuesForPlaceholderHandlingPrefixSelected &&
        options.missingValuesForPlaceholderHandlingSelectionValue ===
          "missingValuesFallbackValueSelected"
          ? "XXX"
          : undefined,
      applyIterator:
        !options.ignoreReplacementValuesSelected &&
        !!(chosenItemId.value && options.applyIterator),
    };

    if (options.ignoreReplacementValuesSelected) {
      generationOptions.ignoreReplacementValues =
        options.ignoreReplacementValuesSelected;
      generationOptions.missingValuesHighlightColor =
        DocumentGenerationOptionsHighlightColor.CaeliGreen;
      generationOptions.missingValuesFallbackValue = undefined;
    }

    return generationOptions;
  };

const showDocumentGenerationPlaceholderReplacementValuesCheckDialog = (
  canChooseDoc: boolean,
  preselectedItem?: string | null,
) => {
  if (!dialogService.dialogEntityId) {
    return;
  }
  const checkValuesProps: DocumentGenerationPlaceholderReplacementValuesCheckDialogData =
    {
      preselectedItem,
      canChooseDoc,
    };

  const generateDialogProps: BaseDialogProps = {
    header: t("document.checkValues"),
    props: checkValuesProps,
    maximizable: true,
    onClose: () => {
      documentService.resetPlaceholderReplacementValuesCheck();
    },
  };
  showDialog.open(
    CheckPlaceholderReplacementValuesForDocumentDialog,
    generateDialogProps,
  );
};
</script>

<template>
  <BaseAppDialog>
    <template #content>
      <div class="w-full">
        <div
          class="flex flex-col gap-2 h-full overflow-y-hidden overflow-x-hidden"
        >
          <p class="text-caeli6">
            {{ t("document.generateDialog.description") }}
          </p>

          <div class="flex flex-col gap-y-4">
            <BaseAutoComplete
              v-if="props.preselectedItem === undefined"
              fluid
              dropdown
              data-testid="document-entity-select"
              optionLabel="text"
              :options="processItems"
              :placeholder="documentService.entityTypeLabel"
              :loading="loadingProcesses"
              :disabled="
                documentService.documentGenerationGenerateStatus !== null ||
                loadingProcesses
              "
              :modelValue="
                documentService.entityItems.find(
                  (p) => p.value === props.preselectedItem,
                )
              "
              @optionSelect="(option) => (chosenItemId = option.value)"
            />

            <BaseAutoComplete
              v-if="props.canChooseDoc"
              fluid
              dropdown
              data-testid="document-template-select"
              optionLabel="name"
              :options="documentItems"
              :placeholder="t('document.field.select')"
              :disabled="
                documentService.documentGenerationGenerateStatus !== null
              "
              :modelValue="
                documentItems.find((p) => p.id === dialogService.dialogEntityId)
              "
              @optionSelect="(option) => (selectedDocumentId = option.id)"
            />
          </div>
        </div>

        <div class="flex flex-col gap-2 mt-4">
          <p class="font-bold">
            {{ t("document.generateDialog.generateOptionsTitle") }}
          </p>

          <BaseCheckbox
            v-model="documentGenerationOptions.ignoreReplacementValuesSelected"
            :disabled="
              documentService.documentGenerationGenerateStatus !== null ||
              loadingProcesses
            "
            :label="
              t(
                'document.generateDialog.generateOptions.dontReplacePlaceholderWithValues',
              )
            "
          />

          <BaseCheckbox
            v-model="
              documentGenerationOptions.replacedValuesHighlightColorSelected
            "
            :disabled="
              documentGenerationOptions.ignoreReplacementValuesSelected ||
              documentService.documentGenerationGenerateStatus !== null ||
              loadingProcesses
            "
            :label="
              t(
                'document.generateDialog.generateOptions.highlightReplacedValues',
              )
            "
          />

          <div class="flex flex-col gap-2">
            <BaseCheckbox
              v-model="
                documentGenerationOptions.missingValuesForPlaceholderHandlingPrefixSelected
              "
              :disabled="
                documentGenerationOptions.ignoreReplacementValuesSelected ||
                documentService.documentGenerationGenerateStatus !== null ||
                loadingProcesses
              "
              :label="
                t('document.generateDialog.generateOptions.missingValuesPrefix')
              "
            />

            <div class="flex flex-row gap-4 ml-4">
              <BaseRadioButton
                v-model="
                  documentGenerationOptions.missingValuesForPlaceholderHandlingSelectionValue
                "
                value="missingValuesHighlightColorSelected"
                :disabled="
                  documentGenerationOptions.ignoreReplacementValuesSelected ||
                  !documentGenerationOptions.missingValuesForPlaceholderHandlingPrefixSelected ||
                  documentService.documentGenerationGenerateStatus !== null ||
                  loadingProcesses
                "
                :label="
                  t(
                    'document.generateDialog.generateOptions.highlightMissingValues',
                  )
                "
              />

              <BaseRadioButton
                v-model="
                  documentGenerationOptions.missingValuesForPlaceholderHandlingSelectionValue
                "
                value="missingValuesFallbackValueSelected"
                :disabled="
                  documentGenerationOptions.ignoreReplacementValuesSelected ||
                  !documentGenerationOptions.missingValuesForPlaceholderHandlingPrefixSelected ||
                  documentService.documentGenerationGenerateStatus !== null ||
                  loadingProcesses
                "
                :label="
                  t(
                    'document.generateDialog.generateOptions.useMissingValuesFallback',
                  )
                "
              />
            </div>
          </div>

          <BaseCheckbox
            v-model="documentGenerationOptions.applyIterator"
            :disabled="
              !chosenItemId ||
              documentGenerationOptions.ignoreReplacementValuesSelected ||
              documentService.documentGenerationGenerateStatus !== null ||
              loadingProcesses
            "
            :label="t('document.generateDialog.generateOptions.applyIterator')"
          />
        </div>
      </div>

      <div>
        <BaseDivider v-if="documentService.documentGenerationMessages.length" />
        <div class="flex flex-col gap-4">
          <Message
            v-for="message in documentService.documentGenerationMessages"
            :key="message.code"
            :severity="message.severity"
          >
            <i
              v-if="message.severity === GenerationStatusEnum.ERROR"
              class="pr-2 mdi mdi-alert"
            />
            <i
              v-if="message.severity === GenerationStatusEnum.WARN"
              class="pr-2 mdi mdi-check-circle"
            />
            <i
              v-if="message.severity === GenerationStatusEnum.SUCCESS"
              class="pr-2 mdi mdi-check-circle"
            />
            {{ message.message }}
            <BaseButton
              v-if="
                message.severity === GenerationStatusEnum.WARN &&
                message.code === ValidationCode.NotFound
              "
              variant="text"
              icon="mdi mdi-list-status"
              :label="
                t(
                  'document.generateDialog.warn.openCheckValuesDialogButtonLabel',
                )
              "
              @click="
                showDocumentGenerationPlaceholderReplacementValuesCheckDialog(
                  false,
                  chosenItemId || null,
                )
              "
            />
          </Message>

          <BaseTextArea
            v-if="documentService.documentGenerationFeedbackBlockMessages"
            v-model="documentService.documentGenerationFeedbackBlockMessages"
            disabled
            fluid
            class="mt-4"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex flex-row justify-between">
        <BaseButton
          v-if="!documentService.documentGenerationGenerateStatus"
          severity="secondary"
          data-testid="generate-document-dialog-check-placeholder-replacement-values-button"
          :label="t('document.generateDialog.openCheckValuesDialogButtonLabel')"
          :disabled="
            !dialogService.dialogEntityId ||
            documentService.documentGenerationIsGenerating
          "
          @click="
            () => {
              showDocumentGenerationPlaceholderReplacementValuesCheckDialog(
                false,
                chosenItemId || null,
              );
            }
          "
        />

        <BaseButton
          v-if="!documentService.documentGenerationGenerateStatus"
          severity="primary"
          data-testid="generate-document-dialog-button"
          :label="t('document.generateDialog.generate')"
          :loading="documentService.documentGenerationIsGenerating"
          :disabled="documentService.documentGenerationIsGenerating"
          @click="
            () => {
              documentService.startDocumentGeneration(
                chosenItemId,
                getChosenDocumentGenerationOptionsDto(),
              );
            }
          "
        />

        <BaseButton
          v-if="
            documentService.documentGenerationGenerateStatus ===
              GenerationStatusEnum.SUCCESS ||
            documentService.documentGenerationGenerateStatus ===
              GenerationStatusEnum.WARN
          "
          color="primary"
          class="ml-auto"
          :loading="fileService.isLoading"
          :disabled="fileService.isLoading"
          :label="
            t('document.generateDialog.download', {
              count: documentService.documentGenerationRun?.results.length,
            })
          "
          @click="
            async () => {
              await documentService.downloadGeneratedDocument();
              closeDialog();
            }
          "
        />
      </div>
    </template>
  </BaseAppDialog>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { computed, reactive, ref } from "vue";

import FieldKeyCreationOptions from "@/app/admin/components/FieldKeyCreationOptions.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCheckbox from "@/base/components/checkbox/BaseCheckbox.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import {
  EntityType,
  type FieldKeyDto,
  FieldType,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
/* eslint-disable */
// @ts-nocheck
const emits = defineEmits<(e: "onSaveSuccess") => void>();

const fieldService = useFieldService();

const options = ref<string[]>([]);
const newFieldKey = reactive<FieldKeyDto>({
  id: uuidv4(),
  name: null,
  colorHex: "000000",
  type: null,
  unique: false,
  entityTypes: null,
  options: [],
  prefix: null,
  description: null,
});

const isValid = computed(() => {
  return (
    newFieldKey.name &&
    newFieldKey.name.length > 0 &&
    newFieldKey.entityTypes &&
    newFieldKey.entityTypes.length > 0 &&
    newFieldKey.type &&
    newFieldKey.key &&
    newFieldKey.key.length > 0
  );
});

async function save() {
  let counter = 0;
  addOptions();
  fieldService.createOrUpdateFieldKey(newFieldKey, {
    onSuccess() {
      const keyOptions = newFieldKey.options || [];
      if (keyOptions.length === 0) {
        emits("onSaveSuccess");
        cleanup();
        return;
      }
      for (const option of keyOptions) {
        fieldService.createOrUpdateFieldValue(option, {
          noPrompt: true,
          async onSuccess() {
            counter++;
            if (counter === keyOptions.length) {
              emits("onSaveSuccess");
              cleanup();
            }
          },
        });
      }
    },
  });
}

function addOptions() {
  newFieldKey.options = options.value.map((option) => {
    return {
      id: uuidv4(),
      fieldKeyId: newFieldKey.id,
      entityId: newFieldKey.id,
      value: {
        valueString: option,
      },
    } as FieldValueDto;
  });
}

function cleanup() {
  newFieldKey.id = uuidv4();
  newFieldKey.options = [];
  newFieldKey.name = null;
  newFieldKey.colorHex = "000000";
  newFieldKey.type = null;
  newFieldKey.unique = false;
  newFieldKey.entityTypes = null;
  newFieldKey.key = null;
  newFieldKey.prefix = null;
  newFieldKey.description = null;
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <BaseButton
      label="Save"
      icon="mdi mdi-content-save-edit"
      :severity="isValid ? 'primary' : 'secondary'"
      :disabled="!isValid"
      @click="save()"
    />

    <div class="flex flex-col gap-4">
      <BaseTextField
        :initialValue="newFieldKey.name ?? undefined"
        label="Name"
        @update="newFieldKey.name = $event"
      />

      <BaseTextField
        :initialValue="newFieldKey.key ?? undefined"
        label="Technical key name (xyz.abc)"
        @update="newFieldKey.key = $event"
      />

      <BaseAutoComplete
        :modelValue="newFieldKey.type ?? undefined"
        placeholder="Type"
        fluid
        :options="Object.values(FieldType)"
        @update:modelValue="newFieldKey.type = $event"
      />

      <BaseAutoComplete
        :modelValue="newFieldKey.entityTypes ?? []"
        placeholder="Entity Types"
        multiple
        fluid
        :options="Object.values(EntityType)"
        @update:modelValue="newFieldKey.entityTypes = $event"
      />

      <BaseTextField
        :initialValue="newFieldKey.prefix ?? undefined"
        label="Prefix (mdi- für Icons auch möglich)"
        @update="newFieldKey.prefix = $event"
      />

      <BaseTextField
        :initialValue="newFieldKey.description ?? undefined"
        label="Description"
        @update="newFieldKey.description = $event"
      />

      <BaseCheckbox
        :modelValue="newFieldKey.unique ?? false"
        label="Unique"
        @update:modelValue="newFieldKey.unique = $event"
      />

      <div class="flex flex-row gap-4">
        <PColorPicker
          inline
          :modelValue="newFieldKey.colorHex"
          @update:modelValue="newFieldKey.colorHex = $event"
        />

        <div v-if="newFieldKey.type === FieldType.String">
          <FieldKeyCreationOptions v-model="options" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useId } from "vue";

export interface BaseRadioButtonProps {
  label?: string;
  value: string;
  disabled?: boolean;
}

withDefaults(defineProps<BaseRadioButtonProps>(), {
  label: undefined,
  disabled: false,
});

const id = useId();
const model = defineModel<string>();
</script>

<template>
  <div class="flex flex-row gap-2 items-center">
    <PRadioButton
      v-model="model"
      :disabled
      :value
      :inputId="id"
      :pt="{
        root: 'w-[16px]! h-[16px]!',
        box: 'w-[16px]! h-[16px]!',
        icon: 'w-[8px]! h-[8px]!',
      }"
    />
    <label v-if="label" :class="disabled ? 'text-surface-400' : ''" :for="id">{{
      label
    }}</label>
  </div>
</template>

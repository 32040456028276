<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import COptionField from "@/app/process/components/field/COptionField.vue";
import FieldCard from "@/app/process/components/field/FieldCard.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
  edit?: boolean;
}>();

const { n } = useI18n();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);

const displayValue = computed(() =>
  props.fieldValue?.value?.valueNumber
    ? n(props.fieldValue?.value?.valueNumber)
    : "-",
);
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <p class="block text-lg text-ellipsis overflow-hidden">
        {{ displayValue }}
      </p>
    </template>

    <template #edit="{ closeCallback }">
      <COptionField
        v-if="isOptionField"
        :fieldKey="props.fieldKey"
        :fieldValue="props.fieldValue"
        :label="props.label"
        itemTitle="valueString"
        @update="
          (value) => emit('update', { ...props.fieldValue, optionId: value })
        "
      />

      <BaseTextField
        v-else
        v-focustrap
        data-testid="string-field-input"
        :initialValue="
          props.fieldValue?.value?.valueNumber?.toString() ?? undefined
        "
        @update="
          (value) => {
            emit('update', {
              ...props.fieldValue,
              value: {
                valueNumber: Number.parseFloat(value),
              },
            });
            if (!edit) {
              closeCallback();
            }
          }
        "
      />
    </template>
  </FieldCard>
</template>

<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";

import FieldByOutputType from "@/app/process/components/output/FieldByOutputType.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import {
  type ActivityOutputDto,
  type ProcessOutputDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  activityOutput: ActivityOutputDto;
  processOutput: ProcessOutputDto | undefined;
  processActivityId: string;
}>();

const processService = useProcessService();

async function updateProcessOutputValue(
  valuePartial: Partial<ProcessOutputDto>,
) {
  await processService.createOrUpdateOutput({
    id: props.processOutput?.id ?? uuidv4(),
    ...valuePartial,
    processActivityId: props.processActivityId,
    activityOutputId: props.activityOutput.id,
  });
}

async function deleteOutput() {
  if (!props.processOutput?.id) {
    return;
  }
  const id = props.processOutput.id;
  await processService.deleteOutput(id);
}
</script>

<template>
  <div v-if="activityOutput" class="flex flex-row items-center gap-4 w-full">
    <div class="flex flex-row justify-between gap-1 w-full">
      <FieldByOutputType
        :key="`output-${processOutput?.id || 'none'}`"
        :contextKey="`${processOutput?.id}.${activityOutput.id}`"
        :outputType="activityOutput.type!"
        :processOutputDto="processOutput"
        @change="(changedFields) => updateProcessOutputValue(changedFields)"
        @delete="deleteOutput"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useMessageServiceV2 } from "@/app/message/services/MessageServiceV2.ts";
import TagsPopoverButton from "@/app/process/components/field/TagsPopoverButton.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import AddFieldDialog from "@/base/components/fields/AddFieldDialog.vue";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import TextEditor from "@/base/components/form/TextEditor.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type AddFieldDialogData,
  type BaseDialogProps,
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { TitleMode, useTitle } from "@/base/composables/useTitle.ts";
import { EntityType, type MessageDto } from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  messageId: string;
}>();

const { t } = useI18n();

const messageServiceV2 = useMessageServiceV2();
const dialog = useBaseDialog();

useTitle(t("message.message", 1), { titleMode: TitleMode.EDIT });

const { selectedMessage: message } = storeToRefs(messageServiceV2);

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "entity-add-field",
    label: t("action.addSomething", { name: t("processes.field") }),
    icon: "mdi mdi-plus",
    command: () => showAddFieldDialog(props.messageId, [EntityType.Message]),
  },
  {
    separator: true,
    class: "my-1 opacity-50",
  },
  {
    testId: "message-delete",
    label: t("action.delete"),
    icon: "mdi mdi-trash-can-outline",
    severity: "danger",
    command: () => showDeleteDialog(),
  },
]);

async function updateMessage(update: Partial<MessageDto>) {
  if (!message.value) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { created, fields, authorId, ...messageWithoutCreated } = message.value;
  await messageServiceV2.createOrUpdate({
    ...messageWithoutCreated,
    ...update,
  });
}

function showDeleteDialog() {
  if (message?.value !== undefined) {
    const deleteDialogData: DeleteDialogData = {
      deleteDescription: t("message.deleteMessageText", {
        name: message.value.title,
      }),
    };

    const deleteDialogProps: DeleteDialogProps = {
      header: t("message.deleteMessage"),
      props: deleteDialogData,
      onDelete: async () => {
        await messageServiceV2.deleteMessage(props.messageId);
        dialogRef.close();
      },
    };
    const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
  }
}

function showAddFieldDialog(entityId: string, entityType: EntityType[]) {
  const addFieldProps: AddFieldDialogData = { entityId, entityType };

  const showAddFieldDialogProps: BaseDialogProps = {
    header: `${t("action.addSomething", { name: t("processes.field") })}`,
    props: addFieldProps,
  };

  dialog.open(AddFieldDialog, showAddFieldDialogProps);
}
</script>

<template>
  <BaseCard v-if="message" class="h-full p-4!" flat noShadow :rounded="false">
    <template #title>
      <div class="flex flex-row gap-4 justify-between items-start">
        <BaseTextField
          id="message.title"
          data-testid="message-title"
          class="w-full"
          :label="t('message.title')"
          :initialValue="message.title"
          @update="(value) => updateMessage({ title: value })"
        />

        <div class="flex flex-row gap-4 items-center">
          <TagsPopoverButton
            :entityId="props.messageId"
            :entityType="EntityType.Message"
          />
          <BaseActionsButton outlined :model="actionsMenuItems" />
          <BaseButton
            data-testid="person-edit-close"
            icon="mdi mdi-check"
            :label="t('action.close')"
            :to="{
              name: 'messageView',
              params: { messageId: props.messageId },
            }"
          />
        </div>
      </div>
    </template>

    <div class="flex flex-col gap-4">
      <EntityFieldsCard
        cards
        grouped
        edit
        :entityType="[EntityType.Message]"
        :entityId="props.messageId"
      />

      <TextEditor
        withHeader
        :containerId="messageId"
        :previousContent="message.body ?? undefined"
        :label="t('message.body')"
        data-testid="message-body"
        @saveContent="
          (content: string) => {
            updateMessage({ body: content });
          }
        "
      />
    </div>
  </BaseCard>
</template>

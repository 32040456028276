import { type VueQueryPluginOptions } from "@tanstack/vue-query";

export const vueQueryConfig: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        experimental_prefetchInRender: true,
      },
    },
  },
};

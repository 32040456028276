<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const { n } = useI18n();

const valueNumber = ref(props.fieldValue?.value?.valueNumber);
const displayValue = computed(() =>
  valueNumber.value ? n(valueNumber.value) : "-",
);
</script>

<template>
  <ReadonlyField :label="props.label" :value="displayValue" />
</template>

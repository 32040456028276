import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";

import {
  type EntityType,
  type FieldKeyDto,
  type FieldKeyEto,
  FieldType,
  type FieldValueDto,
  type FieldValueEto,
} from "@/base/graphql/generated/types.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";
import { compareByName, withoutTypename } from "@/base/services/utils.ts";
import { useFieldKeyStore } from "@/base/stores/field/FieldKeyStore.ts";
import { useFieldValueStore } from "@/base/stores/field/FieldValueStore.ts";

export const useFieldService = defineStore("FieldService", () => {
  const fieldKeyStore = useFieldKeyStore();
  const fieldValueStore = useFieldValueStore();
  const promptService = usePromptService();

  function getFieldKey(id: string) {
    return fieldKeyStore.getById(id);
  }

  function createOrUpdateFieldValue(
    fieldValue: FieldValueDto,
    params?: {
      onSuccess?: (id: string) => void;
      onFail?: (id: string, reason: string) => void;
      noPrompt?: boolean;
      noSuccessPrompt?: boolean;
      noFailPrompt?: boolean;
    },
  ): string {
    const existing = getFieldValue(fieldValue.id);
    fieldValueStore
      .createOrUpdate({
        ...existing,
        ...fieldValue,
        value: withoutTypename(fieldValue.value ?? {}),
      })
      .then(
        () => {
          if (params?.onSuccess) {
            params?.onSuccess(fieldValue.id);
          }
          if (!params?.noPrompt && !params?.noSuccessPrompt) {
            promptService.success(
              Action.SAVE,
              fieldValue
                ? (fieldKeyStore.getById(fieldValue.fieldKeyId)?.name ?? "")
                : "",
            );
          }
        },
        (reason) => {
          if (params?.onFail) {
            params.onFail(fieldValue.id, reason);
          }
          if (!params?.noPrompt && !params?.noFailPrompt) {
            promptService.failure(Action.SAVE, reason);
          }
        },
      );
    return fieldValue.id;
  }

  function getFieldValue(id: string) {
    return fieldValueStore.getById(id);
  }

  function deleteFieldValue(id: string) {
    fieldValueStore.deleteById(id).then(
      () => {
        console.debug(`Field value ${id} deleted successfully`);
      },
      (reason) => promptService.failure(Action.DELETE, reason),
    );
  }

  function getTagFieldValues(entityId: string) {
    return getFieldValues(entityId).filter(
      (fieldValue) =>
        getFieldKey(fieldValue.fieldKeyId)?.type === FieldType.Tag,
    );
  }

  function getActiveTagNames(entityId: string) {
    return getTagFieldValues(entityId).flatMap(
      (fieldValue) => getFieldKey(fieldValue.fieldKeyId)?.name ?? [],
    );
  }

  function getFieldValues(entityId: string) {
    return fieldValueStore.getFiltered("entityId", entityId);
  }

  function getNonTagFieldValues(entityId: string) {
    return getFieldValues(entityId).filter(
      (fieldValue) =>
        getFieldKey(fieldValue.fieldKeyId)?.type !== FieldType.Tag,
    );
  }

  function createOrUpdateFieldKey(
    fieldKey: FieldKeyDto,
    params?: {
      onSuccess?: (id: string) => void;
      onFail?: (id: string, reason: string) => void;
      noPrompt?: boolean;
      noSuccessPrompt?: boolean;
      noFailPrompt?: boolean;
    },
  ) {
    const existing = getFieldKey(fieldKey.id);
    fieldKeyStore.createOrUpdate({ ...existing, ...fieldKey }).then(
      () => {
        if (params?.onSuccess) {
          params.onSuccess(fieldKey.id);
        }
        if (!params?.noPrompt && !params?.noSuccessPrompt) {
          promptService.success(Action.SAVE, fieldKey.name ?? "");
        }
      },
      (reason) => {
        if (params?.onFail) {
          params.onFail(fieldKey.id, reason);
        }
        if (!params?.noPrompt && !params?.noFailPrompt) {
          promptService.failure(Action.SAVE, reason);
        }
      },
    );
    return fieldKey.id;
  }

  async function deleteFieldWithInstances(fieldKeyId: string) {
    return fieldKeyStore.deleteById(fieldKeyId).then(
      () => promptService.success(Action.DELETE),
      (reason) => promptService.failure(Action.DELETE, reason),
    );
  }

  function getTagFieldKeys(entityType?: EntityType) {
    return fieldKeyStore
      .getAll()
      .filter(
        (fieldKey) =>
          (!entityType || fieldKey.entityTypes?.includes(entityType)) &&
          fieldKey.type === FieldType.Tag,
      )
      .sort(compareByName);
  }

  function getNonTagFieldKeys(entityType: EntityType) {
    return fieldKeyStore
      .getAll()
      .filter(
        (fieldKey) =>
          fieldKey.type !== FieldType.Tag &&
          fieldKey.entityTypes?.includes(entityType),
      );
  }

  function getFieldKeysWithValues(
    entityId: string,
  ): Map<FieldKeyEto, FieldValueEto[]> {
    const fieldValues = getFieldValues(entityId);

    const fieldValuesByKey = new Map<FieldKeyEto, FieldValueEto[]>();
    for (const fieldValue of fieldValues) {
      const fieldKey = getFieldKey(fieldValue.fieldKeyId);
      if (fieldKey) {
        const values = fieldValuesByKey.get(fieldKey) ?? [];
        values.push(fieldValue);
        fieldValuesByKey.set(fieldKey, values);
      }
    }
    return fieldValuesByKey;
  }

  function isUniqueKeyAlreadySet(
    fieldKey: FieldKeyDto,
    entityId: string,
  ): boolean {
    const fieldValue = fieldValueStore
      .getFiltered("entityId", entityId)
      .find((value) => value.fieldKeyId === fieldKey.id);

    return (fieldKey.unique ?? false) && fieldValue !== undefined;
  }

  function registerLoadedFieldKeys(fieldKeys: FieldKeyEto[]) {
    fieldKeyStore.registerAllLoaded(fieldKeys);
  }

  function registerLoadedFieldValues(fieldValues: FieldValueEto[]) {
    fieldValueStore.registerAllLoaded(fieldValues);
  }

  function addPendingFieldForEntity(fieldKeyId: string, entityId: string) {
    const eto: FieldValueEto = {
      id: uuidv4(),
      entityId,
      fieldKeyId,
    };
    // A pending value just get registered in the store, but only persisted once the user fills it.
    fieldValueStore.registerAllLoaded([eto]);
  }

  return {
    isLoadingFieldKey: (id?: string) => fieldKeyStore.isLoading(id),
    isLoadingFieldValue: (id?: string) => fieldValueStore.isLoading(id),
    getFieldKey,
    createOrUpdateFieldKey,

    getNonTagFieldKeys,
    getTagFieldKeys,
    getActiveTagNames,
    getFieldKeysWithValues,

    getFieldValue,

    getNonTagFieldValues,
    createOrUpdateFieldValue,
    deleteFieldValue,
    deleteFieldWithInstances,
    isUniqueKeyAlreadySet,

    registerLoadedFieldKeys,
    registerLoadedFieldValues,

    getTagFieldValues,

    addPendingFieldForEntity,
  };
});

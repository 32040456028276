<script setup lang="ts">
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import ProcessOutputField from "@/app/process/components/output/ProcessOutputField.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseMoreButton from "@/base/components/button/BaseMoreButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { useEntityHistoryDrawer } from "@/base/composables/useEntityHistoryDrawer.ts";
import {
  type ProcessActivityEto,
  ProcessActivityState,
  type ProcessOutputEto,
  ProcessOutputState,
} from "@/base/graphql/generated/types.ts";
import {
  compareBySortOrder,
  iconifyType,
  isValueProvided,
} from "@/base/services/utils.ts";

const props = defineProps<{
  processActivity: ProcessActivityEto;
}>();

const { t } = useI18n();
const dialog = useBaseDialog();
const { open } = useEntityHistoryDrawer();
const activityService = useActivityService();
const processService = useProcessService();

function moreButtonItems(item): BaseMenuItem[] {
  return [
    {
      disabled:
        props.processActivity.status.state === ProcessActivityState.Skipped,

      label: getSkippedTooltip(item.processOutput),
      icon: item.isSkipped
        ? "mdi mdi-skip-next-circle"
        : "mdi mdi-skip-next-outline",
      command: () => toggleSkippedState(item.id, item.processOutput),
    },
    {
      visible: item.processOutput !== undefined,
      label: t("processes.outputFavorite"),
      severity: "warn",
      icon: item.isImportant ? "mdi mdi-star" : "mdi mdi-star-outline",
      command: () => toggleImportant(item.id, item.processOutput),
    },
    {
      separator: true,
      visible: item.isSet,
    },
    {
      visible: item.isSet,
      label: t("action.delete"),
      icon: "mdi mdi-trash-can-outline",
      severity: "danger",
      command: () => showDeleteDialog(item),
    },
  ];
}

const activityTasks = computed(() => {
  return activityService
    .getTasks(props.processActivity.activity.id ?? "undefined")
    .slice()
    .sort(compareBySortOrder);
});

const activityOutputs = computed(() => {
  return activityService.getOutputs(
    props.processActivity.activity.id ?? "undefined",
  );
});

const items = computed(() => {
  return [...activityTasks.value, ...activityOutputs.value].map((item) => {
    const processOutput = processService.getProcessOutput(
      props.processActivity.id,
      item.id,
    );

    const processTask = processService.getTask(
      item.id,
      props.processActivity.id,
    );

    const isTask = !("type" in item);

    return {
      id: item.id,
      activityOutput: !isTask ? item : undefined,
      name: !isTask ? item.name : item.title,
      sortOrder: item.sortOrder,
      description: item.description,
      isImportant: !isTask
        ? (processOutput?.important ?? item.important ?? false)
        : false,
      isSet:
        (processOutput && isValueProvided(item, processOutput)) ??
        processTask !== undefined,
      isSkipped: processOutput?.state === ProcessOutputState.Skipped,
      isTask,
      processOutput,
      processTask,
    };
  });
});

const setOutputsLabel = computed(() => {
  const totalSetOutputs = items.value.filter((item) => item.isSet).length;
  return `${totalSetOutputs} / ${items.value.length}`;
});

const allOutputsReady = computed(() => {
  if (items.value.length === 0) {
    return false;
  }
  return items.value.every((item) => item.isSet);
});

async function toggleImportant(
  activityOutputId: string,
  processOutput: ProcessOutputEto | undefined,
) {
  if (!processOutput) {
    return;
  }

  try {
    await processService.createOrUpdateOutput({
      id: processOutput.id,
      important: !processOutput.important,
      processActivityId: props.processActivity.id,
      activityOutputId,
    });
  } catch (error) {
    console.error(error);
  }
}

async function toggleSkippedState(
  activityOutputId: string,
  processOutput: ProcessOutputEto | undefined,
) {
  try {
    await processService.createOrUpdateOutput({
      id: processOutput?.id ?? uuidv4(),
      activityOutputId,
      skipped: processOutput?.state !== ProcessOutputState.Skipped,
      processActivityId: props.processActivity.id,
    });
  } catch (error) {
    console.error(error);
  }
}

function getSkippedTooltip(processOutput: ProcessOutputEto | undefined) {
  if (props.processActivity.status.state === ProcessActivityState.Skipped) {
    return t("processes.skippedActivity");
  }
  if (processOutput?.state === ProcessOutputState.Skipped) {
    return t("processes.unsetSkipped");
  }
  return t("processes.setSkipped");
}

function showDeleteDialog(item) {
  const deleteDialogData: DeleteDialogData = item.processOutput
    ? {
        deleteDescription: t("processes.deleteOutputText", {
          name: item.name,
        }),
      }
    : {
        deleteDescription: t("processes.deleteTaskText", {
          name: item.name,
        }),
      };

  const deleteDialogProps: DeleteDialogProps = {
    header: t("action.delete"),
    props: deleteDialogData,
    onDelete: async () => {
      if (item.processOutput) {
        const id = item.processOutput.id;
        await processService.deleteOutput(id);
      }

      if (item.processTask) {
        const id = item.processTask.id;
        await processService.deleteTask(id);
      }

      dialogRef.close();
    },
  };

  const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
}

function icon(item) {
  if (
    item.isSkipped ||
    props.processActivity.status.state === ProcessActivityState.Skipped
  ) {
    return "mdi mdi-skip-next";
  }

  if (item.activityOutput?.type) {
    return `mdi ${iconifyType(item.activityOutput.type)}`;
  }

  if (item.isTask && item.isSet) {
    return "mdi mdi-check";
  }

  return "mdi mdi-circle-outline";
}
</script>

<template>
  <BaseAccordion
    v-if="items.length > 0"
    :label="t('processes.output', 2)"
    :icon="allOutputsReady ? 'mdi mdi-check-bold' : 'mdi mdi-upload'"
    :color="allOutputsReady ? 'primary' : 'default'"
  >
    <template #end> {{ setOutputsLabel }} </template>

    <PDataView
      dataKey="id"
      lazy
      :value="items"
      :sortOrder="1"
      :sortField="(item) => item.sortOrder"
    >
      <template #list="slotProps">
        <div class="flex flex-col gap-4">
          <BaseAccordion
            v-for="item in slotProps.items"
            :key="item.id"
            defaultState="collapsed"
            color="none"
            :headerClass="
              item.isImportant ? 'border-l-3! border-l-orange-400!' : ''
            "
          >
            <template #title>
              <div class="flex flex-col gap-2">
                <div
                  class="flex flex-row gap-4 items-center"
                  :class="{
                    'opacity-50':
                      processActivity.status.state ===
                        ProcessActivityState.Skipped || item.isSkipped,
                  }"
                >
                  <BaseAvatar
                    class="transition-all ease-in duration-200"
                    :class="item.isImportant ? 'mr-2 ml-1' : 'mx-2'"
                    :icon="icon(item)"
                    :pt:root="item.isSet ? 'bg-primary-500! text-white!' : ''"
                  />

                  <div class="flex flex-col gap-1 grow overflow-hidden">
                    <div class="flex flex-row gap-2 items-center">
                      <i
                        v-if="item.isImportant"
                        class="mdi mdi-star text-orange-500"
                      />
                      <p class="text-sm">
                        {{ item.name }}
                      </p>
                    </div>

                    <div class="flex flex-row gap-2">
                      <p class="text-sm font-thin">
                        {{
                          item.isTask
                            ? t("processes.activity", 1)
                            : t("processes.output", 1)
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="flex flex-row scale-115 mr-1">
                    <BaseButton
                      v-if="item.isSet"
                      icon="mdi mdi-history"
                      text
                      severity="secondary"
                      @click.stop="
                        open(
                          item.isTask
                            ? item.processTask.id
                            : item.processOutput.id,
                          item.name,
                        )
                      "
                    />

                    <BaseMoreButton :model="moreButtonItems(item)" />
                  </div>
                </div>
              </div>
            </template>

            <div class="flex flex-col gap-4 p-4! pl-15!">
              <TextDisplay
                v-if="item.description"
                :label="t('processes.singleView.descriptionLabel')"
                :value="item.description ?? ''"
                markdown
              />

              <ProcessOutputField
                v-if="!item.isTask"
                :activityOutput="item.activityOutput"
                :processOutput="item.processOutput"
                :processActivityId="processActivity.id"
              />

              <BaseButton
                v-if="item.isTask && !item.processTask"
                class="self-end"
                icon="mdi mdi-check"
                label="Erledigt"
                @click="
                  () => {
                    processService.createTask({
                      activityTaskId: item.id,
                      processActivityId: processActivity.id,
                      id: uuidv4(),
                    });
                  }
                "
              />
            </div>
          </BaseAccordion>
        </div>
      </template>
    </PDataView>
  </BaseAccordion>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import CreateTemplateDialog from "@/app/process/components/action/dialog/CreateTemplateDialog.vue";
import ProcessTemplateRootActions from "@/app/process/components/action/ProcessTemplateRootActions.vue";
import ProcessTemplateListEntry from "@/app/process/components/templatelist/ProcessTemplateListEntry.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type RowItem } from "@/base/components/filterdatatable/TableTypes.ts";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type CreateDialogProps,
  type CreateTemplateDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import {
  type ActivityDto,
  EntityType,
  FieldType,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { compareByName } from "@/base/services/utils.ts";
import { deduplicate } from "@/base/utils/array.ts";

const { t } = useI18n();
const router = useRouter();

const activityService = useActivityService();

const fieldService = useFieldService();

const i18n = {
  processTemplateTitle: t("processes.processTemplate"),
  actionsTitle: t("processes.singleView.actions.buttonTitle"),
};

const isLoading = computed(
  () =>
    activityService.isLoading() ||
    fieldService.isLoadingFieldKey() ||
    fieldService.isLoadingFieldValue(),
);

const dialog = useBaseDialog();
function showCreateTemplateDialog() {
  const createTemplateDialogData: CreateTemplateDialogData = {
    createDescription: t("processes.nameLabel"),
  };

  const createDialogProps: CreateDialogProps = {
    header: `${t("processes.createProcessTemplate")}`,
    props: createTemplateDialogData,
    onCreate: async (templateName: string) => {
      try {
        const createdId =
          await activityService.createNewProcessTemplate(templateName);

        if (!createdId) {
          console.error("No ID returned from createNewProcessTemplate");
          return;
        }

        await router.push({
          name: "processTemplate",
          params: { rootActivityId: createdId },
        });
      } catch (e) {
        console.error(e);
      }
      dialogRef.close();
    },
  };
  const dialogRef = dialog.open(CreateTemplateDialog, createDialogProps);
}

const selectedTab = ref(t("processes.listView.goToTemplates"));
const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "create-process-template",
    label: t("processes.createProcessTemplate"),
    icon: "mdi mdi-plus",
    command: () => showCreateTemplateDialog(),
  },
]);

function stateLabel(activity: ActivityDto) {
  const current = activityService.getCurrentVersion(
    activityService.getMid(activity.id) ?? "undefined",
  );
  const lastReleased = activityService.getLastReleased(
    activityService.getMid(activity.id) ?? "undefined",
  );
  if (!lastReleased) {
    return "draft";
  }
  if (lastReleased.id === current?.id) {
    return "released";
  }
  return "draftReleased";
}

const activityChains = computed(() => {
  return activityService.linearizedActivityGraphs
    .concat()
    .sort((a, b) => compareByName(a[0], b[0]))
    .sort((a, b) => stateLabel(a[0]).localeCompare(stateLabel(b[0])));
});

const rootActivityMids = computed((): string[] =>
  deduplicate(
    activityChains.value.flatMap(
      (activityChain) => activityService.getMid(activityChain[0].id) ?? [],
    ),
  ),
);

const mandatoryColumns: DataTableColumn[] = [
  {
    key: "activity_name",
    name: i18n.processTemplateTitle,
    type: FieldType.String,
  },
  {
    key: "actions",
    name: i18n.actionsTitle,
    type: FieldType.String,
    isFilterable: false,
    isSortable: false,
  },
];

const availableTags = computed(() =>
  fieldService.getTagFieldKeys(EntityType.Process),
);

const rowItems = computed(() =>
  rootActivityMids.value.flatMap((rootActivityMid) => {
    const current = activityService.getCurrentVersion(rootActivityMid);
    if (!current) {
      return [];
    }
    const lastReleased = activityService.getLastReleased(rootActivityMid);
    if (
      activityService.isArchived(current.id) &&
      (!lastReleased || activityService.isArchived(lastReleased.id))
    ) {
      return [];
    }
    const result: RowItem = {
      key: rootActivityMid,
      class: { released: Boolean(lastReleased) },
      cells: {
        ["activity_name"]: {
          content: current.name ?? "",
          component: ProcessTemplateListEntry,
          props: {
            rootActivityMid,
          },
        },
        ["actions"]: {
          component: ProcessTemplateRootActions,
          props: {
            class: "justify-end",
            rootActivityMid,
          },
        },
      },
      tags: deduplicate(
        activityChains.value
          .find((activityChain) => activityChain[0].id === current.id)
          ?.flatMap((activity) => fieldService.getActiveTagNames(activity.id)),
      ),
    };
    return result;
  }),
);

async function navigateToProcessList(value: string) {
  if (value === t("processes.listView.goToProcesses")) {
    await router.push({ name: "processList" });
  }
}
</script>

<template>
  <CFilteredDataTable
    class="!p-4"
    stripedRows
    clientSide
    :contextKey="DataTableContextKeys.processTemplateList"
    :rowItems="rowItems"
    :availableTags="availableTags"
    :mandatoryColumns="mandatoryColumns"
    :exposedColumns="mandatoryColumns"
    :isLoading="isLoading"
  >
    <template #afterHeader>
      <div class="flex flex-row gap-4 items-center">
        <PSelectButton
          v-model="selectedTab"
          size="small"
          :allowEmpty="false"
          :options="[
            t('processes.listView.goToProcesses'),
            t('processes.listView.goToTemplates'),
          ]"
          :pt:root="'h-8!'"
          @valueChange="navigateToProcessList"
        >
          <template #option="{ option, index }">
            <span
              :data-testid="
                index === 1 ? 'go-to-process-templates' : 'go-to-processes'
              "
              >{{ option }}</span
            >
          </template>
        </PSelectButton>

        <BaseActionsButton :model="actionsMenuItems" />
      </div>
    </template>
  </CFilteredDataTable>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    label?: string;
    size?: "normal" | "large" | "xlarge";
    shape?: "circle" | "square";
    icon?: string;
    iconClass?: string;
  }>(),
  {
    label: undefined,
    size: "normal",
    shape: "circle",
    icon: undefined,
    iconClass: undefined,
  },
);
</script>

<template>
  <PAvatar :label :size :shape class="shrink-0">
    <template v-if="icon" #icon>
      <span :class="[icon, iconClass]"></span>
    </template>
  </PAvatar>
</template>

<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed, type Ref, ref, toRaw, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useMessageService } from "@/app/message/services/MessageService.ts";
import { usePersonServiceV2 } from "@/app/person/services/PersonServiceV2.ts";
import TagsPopoverButton from "@/app/process/components/field/TagsPopoverButton.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type RowItem } from "@/base/components/filterdatatable/TableTypes.ts";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import { useTitle } from "@/base/composables/useTitle.ts";
import {
  EntityType,
  FieldType,
  SortDirection,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = defineProps<{
  personId: string;
}>();

const personServiceV2 = usePersonServiceV2();
const messageService = useMessageService();
const fieldService = useFieldService();
const router = useRouter();
const { t } = useI18n();

const tab = ref(t("person.person", 2));

const { selectedPerson, personReferenceListRowItems } =
  storeToRefs(personServiceV2);

const tabTitle = computed(
  () => selectedPerson.value?.name ?? t("person.person", 2),
);

useTitle(tabTitle);
const { copy, copied } = useClipboard();

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "person-edit",
    label: t("action.edit"),
    icon: "mdi mdi-pencil",
    command: navigateToEditPage,
  },
]);

const personReferencesColumns: DataTableColumn[] = [
  {
    key: t("person.reference"),
    name: t("person.reference"),
    type: FieldType.String,
    width: "small",
  },
  {
    key: t("person.referenceName"),
    name: t("person.referenceName"),
    type: FieldType.String,
  },
  {
    key: t("person.referenceType"),
    name: t("person.referenceType"),
    type: FieldType.String,
    width: "small",
  },
];

const messageReferenceColumns: DataTableColumn[] = [
  {
    key: t("message.creationDate"),
    name: t("message.creationDate"),
    type: FieldType.Date,
    width: "small",
  },
  {
    key: t("message.title"),
    name: t("message.title"),
    type: FieldType.String,
  },
];

const messageReferencesRowItems: Ref<RowItem[]> = ref([]);

watch(selectedPerson, (newValue) => {
  newValue?.fields?.forEach((field) => {
    fieldService.getFieldValue(toRaw(field).id);
  });
});

watch(
  [tab, () => props.personId],
  async ([newTab]) => {
    switch (newTab) {
      case t("person.person", 2):
        break;
      case t("message.message", 2):
        messageReferencesRowItems.value =
          await messageService.getMessageReferenceRowItems(props.personId);
        break;
      default:
        throw Error(`Unknown tab type ${newTab}`);
    }
  },
  { immediate: true },
);

async function navigateToEditPage() {
  await router.push({
    name: "personEdit",
    params: { personId: props.personId },
  });
}
</script>

<template>
  <BaseCard class="h-full p-4!" flat noShadow :rounded="false">
    <template #title>
      <div class="flex flex-row justify-between items-start">
        <div class="flex flex-row gap-4 items-start">
          <BaseAvatar
            v-tooltip.bottom="t('action.copyIdTooltip')"
            class="mt-1 text-caeli6-500! cursor-pointer transition-all duration-400 ease-out"
            :icon="copied ? 'mdi mdi-check' : 'mdi mdi-account-multiple'"
            :pt:root="copied ? 'bg-primary-400!' : undefined"
            @click="copy(personId)"
          />

          <p data-testid="person-view-name" class="text-3xl/10 font-bold">
            {{ selectedPerson?.name ?? "" }}
          </p>
        </div>

        <div class="flex flex-row gap-6 items-center">
          <TagsPopoverButton
            readonly
            :entityId="props.personId"
            :entityType="EntityType.Person"
          />
          <BaseActionsButton :model="actionsMenuItems" />
        </div>
      </div>
    </template>

    <div class="flex flex-col gap-4">
      <EntityFieldsCard
        cards
        grouped
        :entityType="[EntityType.Person]"
        :entityId="props.personId"
        readonly
      />

      <PAccordion class="mt-2" value="references">
        <PAccordionPanel
          value="references"
          :pt="{
            root: {
              class: 'overflow-hidden! border-[0.1rem]! rounded-md!',
            },
          }"
        >
          <PAccordionHeader
            :pt="{
              root: {
                class: 'bg-surface-100! border-b-[0.1rem]! h-auto! p-2!',
              },
            }"
          >
            <p class="text-caeli6">
              {{ t("person.reference", 2) }}
            </p>
          </PAccordionHeader>

          <PAccordionContent>
            <div class="mt-4">
              <CFilteredDataTable
                v-if="tab === t('person.person', 2)"
                data-testid="person-references"
                stripedRows
                clientSide
                paginatorPosition="bottom"
                :contextKey="DataTableContextKeys.personViewPersonReferences"
                :rowItems="personReferenceListRowItems"
                :exposedColumns="personReferencesColumns"
                :mandatoryColumns="personReferencesColumns"
                :availableTags="[]"
              >
                <template #beforeHeader>
                  <PSelectButton
                    v-model="tab"
                    size="small"
                    :allowEmpty="false"
                    :options="[t('person.person', 2), t('message.message', 2)]"
                  >
                    <template #option="{ option, index }">
                      <span
                        :data-testid="
                          index === 0
                            ? 'person-references-person'
                            : 'person-references-message'
                        "
                        >{{ option }}</span
                      >
                    </template>
                  </PSelectButton>
                </template>
              </CFilteredDataTable>

              <CFilteredDataTable
                v-else-if="tab === t('message.message', 2)"
                data-testid="message-references"
                stripedRows
                clientSide
                paginatorPosition="bottom"
                :contextKey="DataTableContextKeys.personViewMessageReferences"
                :rowItems="messageReferencesRowItems"
                :exposedColumns="messageReferenceColumns"
                :mandatoryColumns="messageReferenceColumns"
                :sortField="messageReferenceColumns[0].key"
                :sortDirection="SortDirection.Desc"
                :availableTags="[]"
              >
                <template #beforeHeader>
                  <PSelectButton
                    v-model="tab"
                    size="small"
                    :allowEmpty="false"
                    :options="[t('person.person', 2), t('message.message', 2)]"
                  >
                    <template #option="{ option, index }">
                      <span
                        :data-testid="
                          index === 0
                            ? 'person-view-person'
                            : 'person-view-message'
                        "
                        >{{ option }}</span
                      >
                    </template>
                  </PSelectButton>
                </template>
              </CFilteredDataTable>
            </div>
          </PAccordionContent>
        </PAccordionPanel>
      </PAccordion>
    </div>
  </BaseCard>
</template>

<script lang="ts" setup>
import dayjs from "dayjs";
import { computed } from "vue";

import COptionField from "@/app/process/components/field/COptionField.vue";
import FieldCard from "@/app/process/components/field/FieldCard.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const emits = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "delete"): void;
}>();

const dateFormatted = computed(() => {
  return formatDate(props.fieldValue?.value?.valueDate ?? undefined);
});

function formatDate(dateString?: string | null) {
  if (!dateString) {
    return "-";
  }
  return dayjs(dateString).format("YYYY-MM-DD");
}

// We need to wrap the options in a computed property to show the formatted date
const availableOptions = computed(() => {
  return props.fieldKey?.options?.map((option) => {
    return {
      id: option.id,
      title: formatDate(option.value?.valueDate),
    };
  });
});

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <p class="block text-base text-ellipsis overflow-hidden">
        {{ dateFormatted }}
      </p>
    </template>

    <template #edit="{ closeCallback }">
      <COptionField
        v-if="isOptionField"
        :fieldKey="props.fieldKey"
        :fieldValue="props.fieldValue"
        :options="availableOptions"
        itemTitle="valueString"
        @update="
          (value) => emits('update', { ...props.fieldValue, optionId: value })
        "
      />

      <BaseTextField
        v-else
        v-focustrap
        data-testid="date-field-input"
        type="date"
        :initialValue="dateFormatted"
        :label="fieldKey.name"
        @update="
          (value) => {
            emits('update', {
              ...props.fieldValue,
              value: {
                valueDate: value ? formatDate(value) : null,
              },
            });
            if (!edit) {
              closeCallback();
            }
          }
        "
      />
    </template>
  </FieldCard>
</template>

import { type PrimeVueConfiguration } from "primevue";

import { caeliTheme } from "@/base/plugins/primevue/caeli.theme.ts";
import { columnPt } from "@/base/plugins/primevue/passthrough/column.pt.ts";
import { tooltipPt } from "@/base/plugins/primevue/passthrough/tooltip.pt.ts";

export const primeVueConfig: PrimeVueConfiguration = {
  pt: {
    ...columnPt,
    directives: {
      ...tooltipPt,
    },
  },
  ripple: false,
  theme: {
    preset: caeliTheme,
    options: {
      darkModeSelector: false,
      order: "tailwind-theme, tailwind-base, primevue, tailwind-utilities",
    },
  },
};

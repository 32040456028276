<script setup lang="ts">
import { computed, h } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import ProcessOutputValue from "@/app/process/components/output/ProcessOutputValue.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import { createFieldCells } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type EditableDataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type CellContent } from "@/base/components/filterdatatable/TableTypes.ts";
import {
  type ActivityOutputDto,
  ActivityOutputType,
  EntityType,
  FieldType,
  type ProcessOutputDto,
  type ProcessOutputEto,
  SortDirection,
} from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { iconifyType } from "@/base/services/utils.ts";

const props = defineProps<{
  outputs: ActivityOutputDto[];
  contextKey: string;
  height?: string;
  processId?: string;
  processOutputs?: ProcessOutputEto[];
  canRemove?: boolean;
  showTemplate?: boolean;
  showType?: boolean;
}>();

const emits = defineEmits<(event: "remove", outputId: string) => void>();
const { t } = useI18n();

const activityService = useActivityService();
const processService = useProcessService();
const fieldService = useFieldService();

const i18n = {
  activityTitle: t("processes.activity"),
  outputTitle: t("processes.output"),
  valueTitle: t("processes.value"),
  typeTitle: t("processes.singleView.outputTypeLabel"),
};

function createActivityCell(activityOutput: ActivityOutputDto) {
  if (!activityOutput.activityId) {
    console.error("Activity output without activity ID", activityOutput);

    return {
      [i18n.activityTitle]: {
        content: "",
      },
    };
  }

  const activityName =
    activityService.getActivity(activityOutput.activityId)?.name ?? "";
  const processActivity = props.processId
    ? processService.getProcessActivityByTemplate(
        props.processId,
        activityOutput.activityId,
      )
    : undefined;
  const className = processActivity ? "pointer" : "";
  return {
    [i18n.activityTitle]: {
      content: activityName,
      props: { class: className },
      to: processActivity
        ? {
            name: "processActivity",
            params: {
              processId: processActivity?.processId,
              processActivityId: processActivity?.id,
            },
          }
        : undefined,
    },
  };
}

function createValueCell(
  activityOutput: ActivityOutputDto,
  processOutput: ProcessOutputDto | undefined,
) {
  return {
    [i18n.valueTitle]: {
      component: ProcessOutputValue,
      props: {
        activityOutput,
        processOutput,
      },
    },
  };
}
function createTypeCell(activityOutput: ActivityOutputDto) {
  const icon = activityOutput.type ? iconifyType(activityOutput.type) : "";
  return {
    [i18n.typeTitle]: {
      content: activityOutput.type?.toString() ?? "",
      component: h("p", { class: `mdi ${icon}` }),
    },
  };
}

function createOutputCell(activityOutput: ActivityOutputDto) {
  return {
    [i18n.outputTitle]: {
      content: activityOutput.name ?? "",
    },
  };
}

const rowItems = computed(() => {
  if (props.outputs.length === 0) {
    return [];
  }

  return props.outputs.map((activityOutput) => {
    const processOutput = props.processOutputs?.find(
      (output) => output.activityOutputId === activityOutput.id,
    );

    const cells: Record<string, CellContent> = {
      ...createActivityCell(activityOutput),
      ...createOutputCell(activityOutput),
      ...createValueCell(activityOutput, processOutput),
      ...createTypeCell(activityOutput),
      ...createFieldCells(activityOutput.activityId),
    };

    return {
      key: activityOutput.id,
      cells,
      tags: [],
    };
  });
});

const mandatoryColumns: EditableDataTableColumn<
  ActivityOutputDto | ProcessOutputDto
>[] = [
  {
    key: i18n.activityTitle,
    name: i18n.activityTitle,
    type: FieldType.String,
    editKey: "id",
  },
  {
    key: i18n.outputTitle,
    name: i18n.outputTitle,
    type: FieldType.String,
    width: "small",
    dynamicWidth: true,
    editKey: "id",
  },
  {
    key: i18n.valueTitle,
    name: i18n.valueTitle,
    type: FieldType.String,
    isSortable: false,
    dynamicWidth: true,
    editKey: "id",
  },
  {
    key: i18n.typeTitle,
    name: i18n.typeTitle,
    isSortable: false,
    type: FieldType.String,
    options: Object.entries(ActivityOutputType).map(([key, value]) => ({
      id: key,
      value: translateEnum("processes.singleView.outputTypes", value),
    })),
    dynamicWidth: true,
    width: "small",
    editKey: "id",
  },
];

function deleteRow(id: string | undefined) {
  if (id === undefined) {
    console.error("id is undefined, cant delete row");
  } else {
    emits("remove", id);
  }
}
const availableTags = fieldService.getTagFieldKeys(EntityType.Activity);
</script>

<template>
  <CFilteredDataTable
    clientSide
    stripedRows
    :contextKey="props.contextKey"
    :availableTags="availableTags"
    :sortDirection="SortDirection.Desc"
    :rowItems="rowItems"
    :exposedColumns="mandatoryColumns"
    :mandatoryColumns="mandatoryColumns"
    :withDelete="canRemove"
    @delete:row="deleteRow"
  />
</template>

<script setup lang="ts">
/* eslint-disable */
// @ts-nocheck

import { ref } from "vue";
import FieldKeyOverviewTab from "@/app/admin/components/FieldKeyOverviewTab.vue";
import FieldKeyCreationTab from "@/app/admin/components/FieldKeyCreationTab.vue";
import { useRouter } from "vue-router";
import BaseCard from "@/base/components/card/BaseCard.vue";

const router = useRouter();
const activeTab = ref<"Overview" | "Create">("Overview");

function refresh() {
  activeTab.value = "Overview";
}
</script>

<template>
  <BaseCard class="h-full" noShadow :rounded="false">
    <template #title>
      <div class="flex flex-row justify-between items-center">
        <p class="text-3xl/10 font-bold">FieldKeyOverview</p>

        <PSelectButton
          v-model="activeTab"
          size="small"
          :allowEmpty="false"
          :options="['Overview', 'Create']"
        />
      </div>
    </template>

    <FieldKeyOverviewTab v-if="activeTab === 'Overview'" />
    <FieldKeyCreationTab v-else @onSaveSuccess="refresh" />
  </BaseCard>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import GenerateDocumentDialog from "@/app/document/components/dialog/GenerateDocumentDialog.vue";
import { useDocumentGenerationRunService } from "@/app/document/services/DocumentGenerationRunService.ts";
import {
  GenerationStatusEnum,
  useDocumentService,
} from "@/app/document/services/DocumentService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import {
  type BaseDialogProps,
  type GenerateDocumentDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import {
  type DocumentEto,
  EntityType,
} from "@/base/graphql/generated/types.ts";
import { useDialogService } from "@/base/services/DialogService.ts";

const props = defineProps<{
  initialValue?: string;
  readonly?: boolean;
}>();

const { t } = useI18n();

const emits = defineEmits<(event: "update", value: string) => void>();

const dialogService = useDialogService();
const documentService = useDocumentService();
const documentGenerationRunService = useDocumentGenerationRunService();
const router = useRouter();
const showDialog = useBaseDialog();

const allDocuments = computed(() => documentService.getAll());
const isLoading = computed(
  () =>
    documentService.isLoading() ||
    documentService.isLoading(props.initialValue),
);
const generationRun = computed(
  () =>
    props.initialValue &&
    documentGenerationRunService.getById(props.initialValue),
);
const processId = computed(
  () => router.currentRoute.value.params.processId as string,
);

const downloadDocument = async () => {
  if (!generationRun.value) {
    return;
  }
  await documentGenerationRunService.downloadDocuments(generationRun.value.id);
};

const confirmSelection = (document: DocumentEto | undefined) => {
  if (!document) {
    return;
  }
  dialogService.dialogEntityId = document.id;
  dialogService.dialogEntityType = EntityType.Process;
  showGenerateDocumentDialog(false, processId.value);
};

function showGenerateDocumentDialog(
  canChooseDoc: boolean,
  preselectedItem?: string,
) {
  const dialogData: GenerateDocumentDialogData = {
    preselectedItem,
    canChooseDoc,
  };

  const generateDialogProps: BaseDialogProps = {
    header: t("document.generate"),
    props: dialogData,
    onClose: () => {
      if (
        documentService.documentGenerationGenerateStatus ===
          GenerationStatusEnum.SUCCESS ||
        documentService.documentGenerationGenerateStatus ===
          GenerationStatusEnum.WARN
      ) {
        emits("update", documentService.documentGenerationRun?.id ?? "");
      }
      documentService.resetDocumentGeneration();
    },
  };

  showDialog.open(GenerateDocumentDialog, generateDialogProps);
}
</script>

<template>
  <div class="flex flex-row gap-4 items-center">
    <BaseAutoComplete
      v-if="!readonly && !generationRun"
      fluid
      class="grow"
      optionLabel="name"
      :disabled="readonly || isLoading"
      :loading="isLoading"
      :options="allDocuments"
      :placeholder="t('document.field.select')"
      @optionSelect="confirmSelection($event)"
    />

    <div v-if="generationRun">
      <BaseButton
        :label="t('document.generateDialog.download')"
        @click="downloadDocument"
      />
    </div>
  </div>
</template>

import { type RouteRecordRaw } from "vue-router";

import MessageRootView from "@/app/message/views/MessageRootView.vue";
import { i18n } from "@/base/i18n/i18n.ts";

const { t } = i18n.global;

export const messageRoutes: RouteRecordRaw[] = [
  {
    path: "/message",
    component: MessageRootView,
    children: [
      {
        name: "messageList",
        path: "list",
        props: true,
        meta: {
          breadcrumb: t("message.message", 2),
        },
        children: [],
      },
      {
        name: "messageView",
        path: ":messageId",
        props: true,
        meta: {
          breadcrumbParent: "messageList",
          breadcrumb: null,
        },
        children: [
          {
            name: "messageEdit",
            path: "edit",
            props: true,
            meta: {
              breadcrumb: t("action.edit"),
            },
            children: [],
          },
        ],
      },
    ],
  },
];

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import BaseButton from "@/base/components/button/BaseButton.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";

const props = defineProps<{
  menuItem?: BaseMenuItem;
  label?: string;
  icon?: string;
  isExpanded: boolean;
}>();

const router = useRouter();

const isActive = computed(() => {
  const routeHistory = router.currentRoute.value.matched;
  const parentRouteName = router.currentRoute.value.meta.breadcrumbParent;
  return routeHistory.some(
    (route) =>
      props.menuItem?.route &&
      (route.name === props.menuItem?.route ||
        props.menuItem?.route === parentRouteName),
  );
});

const labelClasses = computed(() => {
  const defaultClasses = [
    "opacity-100!",
    "transition-opacity!",
    "duration-300!",
    "ease-out!",
    "text-caeli6/60",
  ];

  if (!props.isExpanded) {
    defaultClasses.push("opacity-0!");
  }

  if (isActive.value) {
    defaultClasses.push("text-primary!");
  }

  return defaultClasses;
});

const rootClasses = computed(() => {
  const defaultClasses = [
    "flex!",
    "flex-row!",
    "gap-6!",
    "justify-start!",
    "items-center!",
    "hover:bg-surface-500/10!",
  ];

  if (isActive.value) {
    defaultClasses.push("bg-surface-300/20!");
  }

  return defaultClasses;
});

const iconClasses = computed(() => {
  const defaultClasses = ["scale-170", "text-caeli6/60"];

  if (isActive.value) {
    defaultClasses.push("text-primary!");
  }

  return defaultClasses;
});
</script>

<template>
  <BaseButton
    severity="secondary"
    fluid
    text
    :as="menuItem?.route && 'RouterLink'"
    :pt:icon="iconClasses"
    :pt:root="rootClasses"
    :pt:label="labelClasses"
    :label="menuItem?.label ?? label"
    :icon="menuItem?.icon ?? icon"
    :to="
      menuItem && {
        name: menuItem.route,
      }
    "
  />
</template>

import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";

import { type BaseToastMessageOptions } from "@/base/components/toast/BaseToast.vue";
import {
  type Prompt,
  useBaseToast,
} from "@/base/composables/notification/useBaseToast.ts";
import { type GraphQLError } from "@/base/composables/useErrors.ts";
import { ValidationCode } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";

const DECAY_MILLIS = 4000;

export enum Action {
  DELETE = "DELETE",
  SAVE = "SAVE",
  RELEASE = "RELEASE",
  REVISION = "REVISION",
  UPGRADE = "UPGRADE",
  UPLOAD = "UPLOAD",
  CHECK_REPLACEMENT_VALUES = "CHECK_REPLACEMENT_VALUES",
  GENERATE = "GENERATE",
  GENERATE_VARIANT = "GENERATE_VARIANT",
}

interface GenericErrorObject {
  response?: {
    errors: GraphQLError[];
  };
  graphQLErrors?: GraphQLError[];
}

export enum PromptSeverity {
  ERROR = "error",
  WARN = "warn",
  SUCCESS = "success",
  INFO = "info",
}

export const usePromptService = defineStore("prompts", () => {
  const prompts = ref<Prompt[]>([]);
  const toastService = useBaseToast();

  function success(action: Action, name?: string) {
    const data = setupData(action, PromptSeverity.SUCCESS, name);
    showToast(data);
  }

  function failure(action: Action, errorReason: string | GenericErrorObject) {
    const reason = extractErrorDescription(errorReason);
    const data = setupData(action, PromptSeverity.ERROR, reason);
    showToast(data);
  }

  function hasSeverity(promptSeverity: PromptSeverity): boolean {
    return prompts.value.some((n) => n.severity === promptSeverity);
  }

  const severity = computed(() => {
    if (hasSeverity(PromptSeverity.ERROR)) {
      return "error";
    }
    if (hasSeverity(PromptSeverity.WARN)) {
      return "warn";
    }
    if (hasSeverity(PromptSeverity.INFO)) {
      return "info";
    }
    if (hasSeverity(PromptSeverity.SUCCESS)) {
      return "success";
    }
    return "secondary";
  });

  function setupData(
    action: Action,
    promptSeverity: PromptSeverity,
    named?: string,
    reason?: string,
  ): BaseToastMessageOptions {
    const prompt: BaseToastMessageOptions = {
      severity: promptSeverity,
      closable: true,
    };
    prompt.life = DECAY_MILLIS;
    if (promptSeverity === PromptSeverity.SUCCESS) {
      return createSuccessPrompt(action, prompt, named);
    }

    if (promptSeverity === PromptSeverity.ERROR) {
      return createErrorPrompt(action, prompt, reason);
    }

    return prompt;
  }

  function createSuccessPrompt(
    action: Action,
    prompt: BaseToastMessageOptions,
    named?: string,
  ): BaseToastMessageOptions {
    prompt.summary = translateEnum("action.notification.success.title", action);
    prompt.detail = translateEnum(
      "action.notification.success.description",
      action,
      { name: named },
    );

    return prompt;
  }

  function createErrorPrompt(
    action: Action,
    prompt: BaseToastMessageOptions,
    reason?: string,
  ): BaseToastMessageOptions {
    prompt.summary = translateEnum("action.notification.fail.title", action);
    prompt.detail = translateEnum(
      "action.notification.fail.description",
      action,
      { reason },
    );

    return prompt;
  }

  function showToast(message: BaseToastMessageOptions) {
    const prompt: Prompt = { id: uuidv4(), ...message };
    add(prompt);
    toastService.showToast(prompt);
  }

  function add(prompt: Prompt) {
    prompts.value.push(prompt);
  }

  function getById(id: string) {
    return prompts.value.find((n) => n.id === id);
  }

  function deleteById(id: string) {
    const promptToDelete = getById(id);
    if (promptToDelete) {
      prompts.value = prompts.value.filter((n) => n.id !== id);
    }
  }

  function clearAll() {
    prompts.value = [];
  }

  function extractErrorDescription(
    reason: string | GenericErrorObject,
  ): string | undefined {
    if (typeof reason === "string") {
      return reason;
    }

    const errors = reason.response?.errors ?? reason.graphQLErrors ?? [];
    const filteredErrors = errors.filter(isValidationError);
    return validationErrorsToDescription(filteredErrors);
  }

  function isValidationError(error: GraphQLError): boolean {
    return Object.values(ValidationCode).includes(
      error.extensions.code as ValidationCode,
    );
  }

  function validationErrorsToDescription(
    validationErrors: GraphQLError[],
  ): string {
    return validationErrors
      .flatMap((error) => {
        const validationCode = Object.values(ValidationCode).find(
          (v) => v === error.extensions.code,
        );
        if (!validationCode) {
          return [];
        }

        return translateEnum("error.validation", validationCode, {
          path: error.path?.[1] ?? "",
          id: error.extensions.id ?? "",
        });
      })
      .join("\n\n");
  }

  return {
    prompts,
    severity,
    deleteById,
    clearAll,
    success,
    failure,
    extractErrorDescription,
  };
});

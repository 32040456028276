<script lang="ts" setup>
import { computed, nextTick, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

import { countries } from "@/app/process/components/field/countries.ts";
import FieldCard from "@/app/process/components/field/FieldCard.vue";
import { parseAddressValue } from "@/app/process/components/field/FieldHelper.ts";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import {
  type FieldKeyDto,
  type FieldValueAddressV1,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const { t } = useI18n();

const emits = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "delete"): void;
}>();

const address: FieldValueAddressV1 = reactive(
  props.fieldValue?.value?.valueJson
    ? JSON.parse(props.fieldValue?.value?.valueJson)
    : {
        _type: "FieldValueAddressV1",
        country: undefined,
        zipCode: undefined,
        city: undefined,
        street: undefined,
      },
);

const addressReadOnly = computed(() => {
  return props.fieldValue.value?.valueJson
    ? parseAddressValue(props.fieldValue)
    : "";
});

// Favorites are on top of the list
const favoriteCountries = ["DE", "AT", "CH", "FR"];

const sortedCountries = computed(() => {
  return countries
    .filter((country) => favoriteCountries.includes(country.value))
    .sort(
      (a, b) =>
        favoriteCountries.indexOf(a.value) - favoriteCountries.indexOf(b.value),
    )
    .concat(
      countries
        .filter((country) => !favoriteCountries.includes(country.value))
        .sort((a, b) => a.title.localeCompare(b.title)),
    );
});
const filteredSortedCountries = ref(sortedCountries.value);
const selectedCountry = computed(() => {
  return sortedCountries.value.find((c) => c.value === address.country);
});

async function emitUpdate(closeCallback?: () => void) {
  const addressJson = JSON.stringify(address);

  emits("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueJson: addressJson,
    },
  });

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  await nextTick();
  setTimeout(() => closeCallback?.(), 300);
}
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <p class="block text-base text-ellipsis overflow-hidden">
        {{ addressReadOnly }}
      </p>
    </template>

    <template #edit="{ closeCallback }">
      <div class="flex flex-col gap-4" @keydown.enter="closeCallback">
        <BaseAutoComplete
          dropdown
          optionLabel="title"
          size="small"
          data-testid="address-field-country-multiselect"
          :modelValue="selectedCountry"
          :options="filteredSortedCountries"
          :virtualScrollerOptions="{
            itemSize: 40,
          }"
          @optionSelect="
            (value) => {
              address.country = value.value;
              emitUpdate();
            }
          "
        />

        <div class="flex flex-row gap-2">
          <BaseTextField
            class="w-3/5"
            data-testid="address-field-zip-input"
            :initialValue="address.zipCode?.toString()"
            :label="t('address.zipCode')"
            @update="
              (value) => {
                const regex = /^(?!0{5})\d{5}$/;
                if (!regex.test(value)) {
                  return;
                }
                address.zipCode = value;
                emitUpdate();
              }
            "
          />

          <BaseTextField
            data-testid="address-field-city-input"
            :initialValue="address.city ?? undefined"
            :label="t('address.city')"
            @update="
              (value) => {
                address.city = value;
                emitUpdate();
              }
            "
          />
        </div>

        <BaseTextField
          data-testid="address-field-street-input"
          :initialValue="address.street ?? undefined"
          :label="t('address.street')"
          @update="
            (value) => {
              address.street = value;
              emitUpdate();
            }
          "
        />
      </div>
    </template>
  </FieldCard>
</template>

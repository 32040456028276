import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { useEntityEventService } from "@/app/process/services/EntityEventService.ts";

const isEntityHistoryDrawerOpen = ref(false);
const entityId = ref<string | undefined>();
const header = ref<string | undefined>();

export function useEntityHistoryDrawer() {
  const entityEventService = useEntityEventService();
  const router = useRouter();

  watch(router.currentRoute, () => {
    isEntityHistoryDrawerOpen.value = false;
  });

  watch(isEntityHistoryDrawerOpen, (value) => {
    if (!value) {
      entityId.value = undefined;
      header.value = undefined;
    }
  });

  const isLoading = computed(() => {
    if (!entityId.value) {
      return true;
    }
    return entityEventService.isLoading(entityId.value);
  });

  const entityEvents = computed(() => {
    if (!entityId.value) {
      return [];
    }
    return entityEventService.getAll(entityId.value);
  });

  function open(id: string, drawerHeader: string) {
    entityId.value = id;
    header.value = drawerHeader;
    entityEventService.markRefetch(id);
    isEntityHistoryDrawerOpen.value = true;
  }

  return {
    isOpen: isEntityHistoryDrawerOpen,
    events: entityEvents,
    isLoading,
    header,
    open,
  };
}

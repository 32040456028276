export class StorageKeys {
  static readonly graph = {
    showMiniMap: {
      key: "graph.showMiniMap",
      storage: localStorage,
    },
    useSimpleMode: {
      key: "graph.useSimpleMode",
      storage: localStorage,
    },
    viewport: {
      key: "graph.viewport",
      storage: sessionStorage,
    },
    processId: {
      key: "graph.processId",
      storage: sessionStorage,
    },
  };

  static readonly app = {
    drawerExpanded: {
      key: "app.drawerExpanded",
      storage: localStorage,
    },
    activeTablePage: {
      key: "app.activeTablePage",
      storage: sessionStorage,
    },
  };

  static readonly process = {
    activityStateFilter: {
      key: "process.activity.list.stateFilter",
      storage: localStorage,
    },
    processStateFilter: {
      key: "process.process.list.stateFilter",
      storage: localStorage,
    },
    closedGroupIds: {
      key: "process.process.entityFields.closedGroupIds",
      storage: localStorage,
    },
    activeTab: {
      key: "process.activeTab",
      storage: sessionStorage,
    },
  };
}

export default {
  baseUrl: import.meta.env.VITE_BASE_URL as string,
  authEnabled: import.meta.env.VITE_AUTH_ENABLED === "true",
  // Application (client) ID of app registration in Azure portal.
  authClientId: import.meta.env.VITE_AUTH_CLIENT_ID as string,
  envIndicator: import.meta.env.VITE_ENV_INDICATOR,
  grafanaUrl: import.meta.env.VITE_GRAFANA_URL as string,
};

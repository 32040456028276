import { useToast } from "primevue/usetoast";

import { type BaseToastMessageOptions } from "@/base/components/toast/BaseToast.vue";

export interface Prompt extends BaseToastMessageOptions {
  id: string;
}

export function useBaseToast() {
  const toast = useToast();

  function showToast(prompt: Prompt) {
    toast.add(prompt);
  }

  function clearAll() {
    toast.removeAllGroups();
  }
  return { showToast, clearAll };
}

<script setup lang="ts">
import { computed } from "vue";

export interface BaseDrawerProps {
  header?: string;
  position?: "left" | "right" | "top" | "bottom" | "full";
}

const props = withDefaults(defineProps<BaseDrawerProps>(), {
  header: undefined,
  position: "right",
});

const model = defineModel<boolean>();

const roundedBorder = computed(() => {
  switch (props.position) {
    case "left":
      return "rounded-r-2xl";
    case "right":
      return "rounded-l-2xl";
    case "top":
      return "rounded-b-2xl";
    case "bottom":
      return "rounded-t-2xl";
    default:
      return "";
  }
});
</script>

<template>
  <PDrawer
    v-model:visible="model"
    :position
    :header
    :pt="{
      root: { class: [roundedBorder] },
      pcCloseButton: { root: { 'data-testid': 'drawer-close-button' } },
    }"
  >
    <template #header>
      <p class="font-bold text-lg truncate" :title="header">{{ header }}</p>
    </template>
    <slot />
  </PDrawer>
</template>

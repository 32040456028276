import {
  breakpointsTailwind,
  useBreakpoints as useVueUseBreakpoints,
} from "@vueuse/core";

const baseBreakpoints = {
  xs: 0,
  ...breakpointsTailwind,
};

export function useBreakpoints(
  type: "greaterOrEqual" | "smaller",
  breakpoint: keyof typeof baseBreakpoints,
) {
  const breakpoints = useVueUseBreakpoints(baseBreakpoints);

  return type === "greaterOrEqual"
    ? breakpoints.greaterOrEqual(breakpoint)
    : breakpoints.smaller(breakpoint);
}

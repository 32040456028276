<script lang="ts" setup>
import BaseCheckbox from "@/base/components/checkbox/BaseCheckbox.vue";

const props = defineProps<{
  tristateValue?: boolean | null;
  readonly?: boolean;
  label?: string;
}>();

const emit = defineEmits<(e: "update", value?: boolean | null) => void>();
</script>

<template>
  <BaseCheckbox
    :indeterminate="
      props.tristateValue === undefined || props.tristateValue === null
    "
    size="large"
    :modelValue="props.tristateValue"
    :disabled="props.readonly"
    :label="label"
    @update:modelValue="(tristate) => emit('update', tristate)"
  />
</template>

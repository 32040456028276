<script lang="ts" setup>
import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import { type ActivityOutputEto } from "@/base/graphql/generated/types.ts";
import { iconifyType } from "@/base/services/utils.ts";

defineProps<{ inputOutputs: ActivityOutputEto[] }>();

const activityService = useActivityService();

function getActivityName(id: string): string | undefined {
  return (
    activityService.getActivity(id, {
      overwriteBreadcrumbs: false,
    })?.name ?? undefined
  );
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <BaseAccordion
      v-for="item in inputOutputs"
      :key="item.id"
      :label="item.name"
      :expandable="false"
      color="none"
      defaultState="collapsed"
    >
      <template #title>
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-4 items-center">
            <BaseAvatar :icon="`mdi ${iconifyType(item.type)}`" class="mx-2" />

            <div class="flex flex-col gap-1 grow">
              <p class="text-sm">{{ item.name }}</p>

              <RouterLink
                class="pointer-events-auto w-fit"
                :to="{
                  name: 'activity',
                  params: { activityId: item.activityId },
                }"
              >
                <p
                  class="text-xs font-thin text-primary-500! hover:text-primary-300! pointer-cursor"
                >
                  {{ getActivityName(item.activityId) }}
                </p>
              </RouterLink>
            </div>
          </div>
        </div>
      </template>
    </BaseAccordion>
  </div>
</template>

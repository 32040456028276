<script lang="ts" setup>
import { sanitizeUrl } from "@braintree/sanitize-url";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";
import { extractDomain } from "@/base/utils/string.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const { t } = useI18n();

const sanitizedUrl = computed(() => {
  const valueString = props.fieldValue?.value?.valueString;

  if (!valueString) {
    return "#";
  }
  return sanitizeUrl(
    valueString?.startsWith("http") ? valueString : `https://${valueString}`,
  );
});
</script>

<template>
  <ReadonlyField :label="props.label">
    <div class="flex flex-row w-100">
      <div
        class="flex flex-row items-center gap-1 text-primary-500 hover:text-primary-300 cursor-pointer"
      >
        <a :href="sanitizedUrl" target="_blank" rel="noopener noreferrer">
          {{
            extractDomain(
              props.fieldValue?.value?.valueString ?? "",
              t("processes.singleView.outputTypes.URL"),
            )
          }}
        </a>
        <i class="mdi mdi-open-in-new" />
      </div>
    </div>
  </ReadonlyField>
</template>

<script setup lang="ts"></script>

<template>
  <div
    class="flex flex-col text-caeli6 gap-4 min-w-xl max-w-3xl"
    data-testid="app-dialog"
  >
    <slot name="header"></slot>

    <div class="mt-2">
      <slot name="content"></slot>
    </div>

    <div class="mt-2">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

import { useThrottleFn } from "@vueuse/core";
import { v4 as uuid } from "uuid";
import {
  computed,
  onBeforeMount,
  onUnmounted,
  ref,
  useAttrs,
  useId,
} from "vue";

import { ValidationCode } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";

export interface GraphQLErrorData {
  response: GraphQLErrorResponse;
}

export interface GraphQLErrorResponse {
  errors: GraphQLError[];
}

export interface GraphQLError {
  message: string;
  path?: string[];
  extensions: GraphQLErrorExtension;
}

export interface GraphQLErrorExtension {
  classification: string;
  code?: string;
  id?: string;
  entityId?: string;
}

export interface MappedAPIError {
  id: string;
  code: string;
  fieldId?: string;
  message: string;
}

const errorData = ref<MappedAPIError[]>([]);
const registeredIds = ref<Set<string>>(new Set());

export default function useErrors() {
  const attrs = useAttrs();
  const fallbackId = useId();
  const throttledResetErrorForId = useThrottleFn(resetErrorForId, 1000);

  const inputId = computed<string>(
    () => (attrs.id as string | undefined) ?? fallbackId,
  );

  onBeforeMount(() => registeredIds.value.add(inputId.value));

  onUnmounted(() => {
    resetErrorForId();
    registeredIds.value.delete(inputId.value);
  });

  const errors = computed<MappedAPIError[]>(() => {
    return errorData.value.filter((e) => e.fieldId === inputId.value);
  });

  function setErrors(
    input: MappedAPIError[] | GraphQLError[],
    callback?: (unknownErrors: MappedAPIError[]) => void,
  ) {
    errorData.value = input.map(mapToCustomError);

    callback?.(
      errors.value.filter(
        (e) =>
          e.code !== "UNKNOWN" && !registeredIds.value.has(e.fieldId ?? e.id),
      ),
    );
  }

  // Helper Functions

  function resetErrorForId() {
    errorData.value = errorData.value.filter(
      (e) => e.fieldId !== inputId.value,
    );
  }

  function mapToCustomError(
    error: MappedAPIError | GraphQLError,
  ): MappedAPIError {
    if ("code" in error) {
      return error;
    } else {
      return {
        id: error.extensions.id ?? uuid(),
        code: error.extensions.code ?? "UNKNOWN",
        fieldId:
          error.extensions.entityId ??
          error.path?.reduce((path, segment) => {
            return path === "" ? segment : `${path}.${segment}`;
          }, ""),
        message: getErrorMessage(error),
      };
    }
  }

  function getErrorMessage(error: GraphQLError): string {
    const validationCode = Object.values(ValidationCode).find(
      (v) => v === error.extensions.code,
    );
    if (!validationCode) {
      return "";
    }

    return translateEnum("error.validation", validationCode, {
      id: error.extensions.id ?? "",
    });
  }

  return {
    id: inputId,
    errors,
    setErrors,
    resetErrorForId: throttledResetErrorForId,
  };
}

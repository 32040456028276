<script setup lang="ts">
import MultiSelect, {
  type MultiSelectPassThroughOptions,
} from "primevue/multiselect";
import { useId } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
/* eslint-disable */

export interface BaseSelectProps {
  options?: any[];
  optionLabel?: string;
  optionValue?: string | ((option: any) => any);
  editable?: boolean;
  pt?: MultiSelectPassThroughOptions;
  fluid?: boolean;
  disabled?: boolean;
  showToggleAll?: boolean;
  showClear?: boolean;
  placeholder?: string;
}

withDefaults(defineProps<BaseSelectProps>(), {
  options: undefined,
  optionLabel: undefined,
  optionValue: undefined,
  editable: false,
  pt: undefined,
  placeholder: undefined,
  disabled: false,
  fluid: false,
  showToggleAll: false,
  showClear: false,
  size: undefined,
});

const { t } = useI18n();
const id = useId();

const model = defineModel<any[]>();
</script>

<template>
  <PFloatLabel variant="on">
    <MultiSelect
      v-model="model"
      :id
      :pt="{
        ...pt,
      }"
      :options="options"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :disabled="disabled"
      :fluid="fluid"
      :showToggleAll="showToggleAll"
      :showClear="showClear && model && model?.length > 0"
    >
      <template #clearicon>
        <slot v-if="showClear && model && model?.length > 0" name="clearicon">
          <div class="self-center">
            <BaseButton
              icon="mdi mdi-close"
              severity="danger"
              text
              rounded
              @click.stop="() => (model = [])"
            />
          </div>
        </slot>
      </template>

      <template #empty>
        <slot name="empty">
          <span>{{ t("ui.noData") }}</span>
        </slot>
      </template>
    </MultiSelect>

    <label
      class="text-sm"
      :class="disabled ? 'bg-transparent text-surface-400!' : ''"
      :for="id"
      >{{ placeholder }}</label
    >
  </PFloatLabel>
</template>

<style scoped></style>

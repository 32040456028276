<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import BaseButton from "@/base/components/button/BaseButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { type ProcessActivityEto } from "@/base/graphql/generated/types.ts";
import { useDialogService } from "@/base/services/DialogService.ts";

const router = useRouter();

const props = defineProps<{
  processActivity: ProcessActivityEto;
}>();

const emits = defineEmits<(event: "beforeDelete", ids: string[]) => void>();

const { t } = useI18n();
const dialogService = useDialogService();
const dialog = useBaseDialog();

function deleteActivity() {
  if (props.processActivity.activity === undefined) {
    return;
  }
  dialogService.deleteNextActivity([props.processActivity.activity]);
}

function showDeleteDialog() {
  if (props.processActivity !== undefined && props.processActivity !== null) {
    const deleteDialogData: DeleteDialogData = {
      deleteDescription: t("processes.listView.deleteActivityText", {
        name: props.processActivity.activity.name,
      }),
    };

    const deleteDialogProps: DeleteDialogProps = {
      header: `${t("processes.listView.deleteActivity")}`,
      props: deleteDialogData,
      onDelete: () => {
        //TODO: Delete not represented in table, but also not working in INT
        const ids = [props.processActivity.activity].map((it) => it.id);
        emits("beforeDelete", ids);
        deleteActivity();
        dialogRef.close();
      },
    };

    const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
  }
}
</script>

<template>
  <div class="flex flex-row items-center gap-2 h-5">
    <BaseButton
      v-tooltip.left="t('ui.edit')"
      text
      icon="mdi mdi-pencil"
      severity="secondary"
      @click.stop.prevent="
        () =>
          router.push({
            name: 'customActivity',
            params: {
              activityId: props.processActivity.activity.id,
              processId: props.processActivity.processId,
            },
          })
      "
    />

    <BaseButton
      v-tooltip.left="t('action.delete')"
      text
      icon="mdi mdi-delete"
      severity="danger"
      @click.stop.prevent="showDeleteDialog"
    />
  </div>
</template>

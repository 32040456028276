<script setup lang="ts">
import { computed } from "vue";

interface BaseAccordionProps {
  label?: string;
  icon?: string;
  defaultState?: "collapsed" | "expanded";
  expandable?: boolean;
  color?: "none" | "default" | "primary";
  disabled?: boolean;
  headerClass?: string;
}

const props = withDefaults(defineProps<BaseAccordionProps>(), {
  defaultState: "expanded",
  expandable: true,
  color: "default",
  label: undefined,
  icon: undefined,
  disabled: false,
  headerClass: "",
});

const headerClass = computed(() => {
  const classes: string[] = [];

  classes.push("p-2!", "transition-all!", "duration-200!", "ease-in!");

  if (!props.expandable) {
    classes.push("pointer-events-none!");
  }

  if (props.color === "primary") {
    classes.push("bg-primary! text-white!");
  } else if (props.color === "default") {
    classes.push("bg-surface-100!");
  } else {
    classes.push("bg-surface-0!");
  }

  if (props.headerClass) {
    classes.push(...props.headerClass.split(" "));
  }

  return classes.join(" ");
});

const toggleIconClass = computed(() => {
  const classes: string[] = [];

  if (!props.expandable) {
    classes.push("invisible!");
  }

  if (props.color === "primary") {
    classes.push("text-white!");
  }

  return classes.join(" ");
});
</script>

<template>
  <PAccordion :value="defaultState === 'expanded' ? 'single' : ''">
    <PAccordionPanel
      :disabled="disabled"
      value="single"
      :pt="{
        root: {
          class: 'overflow-hidden! border-[0.1rem]! rounded-md!',
        },
      }"
    >
      <PAccordionHeader
        :pt="{
          root: {
            class: headerClass,
          },
          toggleicon: {
            class: toggleIconClass,
          },
        }"
      >
        <div class="text-caeli6 w-full mr-2 overflow-hidden">
          <slot name="title">
            <div
              class="flex flex-row gap-2"
              :class="{
                'text-white!': props.color === 'primary',
              }"
            >
              <i v-if="icon" :class="icon" />

              <p
                class="grow"
                :class="{
                  'text-white!': props.color === 'primary',
                }"
              >
                {{ label }}
              </p>

              <div class="mr-4">
                <slot name="end" />
              </div>
            </div>
          </slot>
        </div>
      </PAccordionHeader>

      <PAccordionContent :pt="{ content: 'p-2! border-t-[0.1rem]!' }">
        <slot />
      </PAccordionContent>
    </PAccordionPanel>
  </PAccordion>
</template>

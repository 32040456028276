<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { computed, inject, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import { useDocumentGenerationRunService } from "@/app/document/services/DocumentGenerationRunService.ts";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import type { DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type CellContent } from "@/base/components/filterdatatable/TableTypes.ts";
import type { DocumentGenerationParameterDialogData } from "@/base/composables/dialog/dialogData.ts";
import {
  type DocumentGenerationResult,
  FieldType,
} from "@/base/graphql/generated/types.ts";

const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: DocumentGenerationParameterDialogData = dialogRef?.value.data;

const { t } = useI18n();
const documentGenerationRunService = useDocumentGenerationRunService();

const i18n = {
  columnName: t("documentGenerationRun.parameterView.table.columnName"),
  columnValue: t("documentGenerationRun.parameterView.table.columnValue"),
};

const mandatoryColumns: DataTableColumn[] = [
  {
    key: i18n.columnName,
    name: i18n.columnName,
    type: FieldType.String,
  },
  {
    key: i18n.columnValue,
    name: i18n.columnValue,
    type: FieldType.String,
  },
];

const documentGenerationResults = computed(
  () =>
    documentGenerationRunService.getById(props.dialogEntityId)?.results ?? [],
);

function createRowItems(documentGenerationResult: DocumentGenerationResult) {
  const parsedParameter = JSON.parse(documentGenerationResult.parameter);
  return Object.keys(parsedParameter).map((key) => {
    const cells: Record<string, CellContent> = {
      [i18n.columnName]: {
        content: key,
      },
      [i18n.columnValue]: {
        content: String(parsedParameter[key] ?? ""),
      },
    };

    return {
      key,
      cells,
      tags: [],
    };
  });
}
</script>

<template>
  <div class="w-full">
    <BaseAppDialog class="w-7xl">
      <template #content>
        <div v-for="(result, index) in documentGenerationResults" :key="index">
          <h2 v-if="documentGenerationResults.length > 1">
            {{
              t("documentGenerationRun.parameterView.resultTitle", {
                count: index + 1,
              })
            }}
          </h2>

          <CFilteredDataTable
            clientSide
            :contextKey="`documentGenerationParameter_${index}`"
            :rowItems="createRowItems(result)"
            :exposedColumns="mandatoryColumns"
            :mandatoryColumns="mandatoryColumns"
            :availableTags="[]"
          />
        </div>
      </template>
    </BaseAppDialog>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { parseAddressValue } from "@/app/process/components/field/FieldHelper.ts";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const addressReadOnly = computed(() => {
  return props.fieldValue?.value?.valueJson
    ? parseAddressValue(props.fieldValue)
    : "";
});
</script>

<template>
  <ReadonlyField :label="props.label" :value="addressReadOnly" />
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { getFieldValue } from "@/app/process/components/field/FieldHelper.ts";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyDto,
  FieldType,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const { t } = useI18n();

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();
</script>

<template>
  <ReadonlyField
    :label="props.label"
    :value="
      getFieldValue(
        props.fieldKey.type ?? FieldType.String,
        props.fieldValue,
        t('boolean.yes'),
        t('boolean.no'),
      )
    "
  />
</template>

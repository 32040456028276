<script lang="ts" setup>
import { computed, ref } from "vue";

import { usePersonService } from "@/app/person/services/PersonService.ts";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey?: FieldKeyDto;
  fieldValue?: FieldValueDto;
  initialValue?: string;
  label?: string;
  readonly?: boolean;
}>();

const emit =
  defineEmits<(e: "update", value: FieldValueDto | string) => void>();

const valueOverrides: Partial<FieldValueDto> = {
  // Old values must be cleared, if eg. the field switched to an option field.
  optionId: null,
  value: {
    valueString: null,
    valueDate: null,
    valueNumber: null,
    valueBoolean: null,
    valueEntityId: null,
    valueEntityIds: null,
    valueJson: null,
  },
};

const personService = usePersonService();

const unknownName = "?";
const processOutputPersonId = ref(props.initialValue ?? "");

const isLoading = computed(() => personService.isLoading());

const isOptionField = computed(
  () => (props.fieldKey?.options?.length ?? 0) > 0,
);

const personOptions = computed(() =>
  props.fieldKey?.options?.map((option) => {
    const personOption = allPersons.value.find(
      (it) => it.id === option.value?.valueEntityId,
    );
    return {
      id: option.id,
      title: personOption?.name ?? "",
    };
  }),
);

const allPersons = computed(() => {
  if (isOptionField.value) {
    return personOptions.value;
  }
  return personService.getAll();
});

function getChosenPerson() {
  let personId: string | undefined;

  if (props.fieldValue?.optionId) {
    const chosenOption = props.fieldKey?.options?.find(
      (option) => option.id === props.fieldValue?.optionId,
    );
    personId = chosenOption?.value?.valueEntityId ?? undefined;
  } else {
    personId =
      props.fieldValue?.value?.valueEntityId ?? processOutputPersonId.value;
  }

  return allPersons.value.find((person) => person.id === personId);
}

function emitUpdate(selectedPersonId?: string | null) {
  if (!selectedPersonId || selectedPersonId.length === 0) {
    return;
  }

  if (isOptionField.value) {
    emitOptionUpdate(selectedPersonId);
    return;
  }

  if (props.fieldValue) {
    emit("update", {
      ...props.fieldValue,
      ...valueOverrides,
      value: {
        valueEntityId: selectedPersonId,
      },
    });
  } else {
    emit("update", selectedPersonId);
    processOutputPersonId.value = selectedPersonId;
  }
}

function emitOptionUpdate(selectedOptionId: string) {
  const personOption = props.fieldKey?.options?.find(
    (option) => option.id === selectedOptionId,
  );
  if (personOption && props.fieldValue) {
    emit("update", {
      ...props.fieldValue,
      ...valueOverrides,
      optionId: personOption.id,
    });
  }
}
</script>

<template>
  <div v-if="props.readonly">
    <ReadonlyField :label="props.label ?? ''">
      <div
        class="flex flex-row items-center gap-2 text-primary-500 hover:text-primary-300"
      >
        <div v-if="!getChosenPerson()">
          {{ unknownName }}
        </div>
        <RouterLink
          v-else
          :to="{
            name: 'personView',
            params: { personId: getChosenPerson().id },
          }"
        >
          {{ getChosenPerson()?.name }}
        </RouterLink>
      </div>
    </ReadonlyField>
  </div>

  <BaseAutoComplete
    v-else
    data-testid="person-field-select"
    optionLabel="name"
    fluid
    :modelValue="getChosenPerson()"
    :loading="isLoading"
    :disabled="readonly || isLoading"
    :virtualScrollerOptions="{ itemSize: 40 }"
    :options="allPersons"
    :placeholder="fieldKey?.name ?? label ?? 'Person'"
    @optionSelect="emitUpdate($event.id)"
  />
</template>

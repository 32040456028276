<script lang="ts" setup>
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseMoreButton from "@/base/components/button/BaseMoreButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { useEntityHistoryDrawer } from "@/base/composables/useEntityHistoryDrawer.ts";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const { t } = useI18n();
const baseDialog = useBaseDialog();
const fieldService = useFieldService();
const { open } = useEntityHistoryDrawer();

const moreButtonVisible = ref<boolean>(false);
const moreButtonItems = computed<BaseMenuItem[]>(() => [
  {
    label: t("action.showEntityHistory"),
    icon: "mdi mdi-history",
    command: () => open(props.fieldValue.id, props.fieldKey.name ?? "N/A"),
  },
]);

const inPlacePt = computed(() => {
  const displayClasses = ["w-full", "h-full"];
  return {
    root: "h-full",
    display: [...displayClasses, props.readonly ? "pointer-events-none" : ""],
  };
});

function showDeleteDialog() {
  const deleteDialogData: DeleteDialogData = {
    deleteDescription: t("fields.delete", {
      field:
        fieldService.getFieldKey(props.fieldValue.fieldKeyId ?? "")?.name ?? "",
    }),
  };

  const deleteDialogProps: DeleteDialogProps = {
    header: `${t("fields.deleteTitle")}`,
    props: deleteDialogData,
    onDelete: () => {
      fieldService.deleteFieldValue(props.fieldValue.id);
      dialogRef.close();
    },
  };

  const dialogRef: DynamicDialogInstance = baseDialog.open(
    DeleteDialog,
    deleteDialogProps,
  );
}
</script>

<template>
  <PInplace
    :active="edit"
    :pt="inPlacePt"
    :title="
      (props.fieldKey.name?.length ?? 0) <= 28 ? undefined : props.fieldKey.name
    "
    @mouseenter="moreButtonVisible = true"
    @mouseleave="moreButtonVisible = false"
  >
    <template #display>
      <div class="flex flex-row items-start gap-2">
        <span
          class="mdi text-[2.3rem] opacity-90 mr-2"
          :class="props.fieldKey.prefix ?? 'mdi-help'"
        ></span>

        <div class="flex flex-col overflow-x-hidden w-full">
          <div class="flex flex-row justify-between">
            <p
              class="text-sm mb-1 block font-extrabold text-ellipsis overflow-x-hidden text-nowrap"
            >
              {{ props.fieldKey.name ?? "-" }}
            </p>

            <div
              v-if="moreButtonVisible"
              class="pointer-events-auto! -mt-[0.1rem]"
            >
              <BaseMoreButton
                :model="moreButtonItems"
                noPadding
                appendTo="self"
              />
            </div>
          </div>

          <div
            class="flex flex-row gap-2 text-base text-ellipsis overflow-hidden"
            data-testid="field-value"
          >
            <slot name="display" />
          </div>
        </div>
      </div>
    </template>

    <template #content="{ closeCallback }">
      <BaseCard :pt="{ root: { class: 'h-full' } }">
        <template #title>
          <div class="flex flex-row justify-between">
            <p
              class="mt-2 text-sm font-bold block text-nowrap text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ props.fieldKey.name ?? "-" }}
            </p>
            <div class="flex flex-row">
              <BaseButton
                v-if="!props.readonly && !isInherited"
                icon="mdi mdi-delete-outline"
                data-testid="delete-field-button"
                text
                severity="danger"
                @click="showDeleteDialog"
              />

              <BaseButton
                v-if="!edit"
                icon="mdi mdi-close"
                data-testid="close-field-card-button"
                text
                severity="secondary"
                @click="closeCallback"
              />
            </div>
          </div>
        </template>

        <slot name="edit" :closeCallback="closeCallback" />
      </BaseCard>
    </template>
  </PInplace>
</template>

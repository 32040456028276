<script setup lang="ts">
import Toast from "primevue/toast";

export interface BaseToastMessageOptions {
  severity?: "error" | "secondary" | "info" | "success" | "warn" | "contrast";
  summary?: string;
  detail?: string;
  life?: number;
  closable?: boolean;
}

withDefaults(defineProps<BaseToastMessageOptions>(), {
  severity: undefined,
  summary: undefined,
  life: undefined,
  closable: true,
  detail: undefined,
});
</script>

<template>
  <Toast
    position="top-center"
    :autoZIndex="false"
    :pt="{
      root: {
        class: 'mt-8',
      },
      summary: {
        'data-testid': 'prompt-title',
      },
      detail: {
        'data-testid': 'prompt-description',
      },
    }"
  />
</template>

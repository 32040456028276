<script setup lang="ts">
import Select, { type SelectPassThroughOptions } from "primevue/select";
import { useId } from "vue";
import { useI18n } from "vue-i18n";
/* eslint-disable */

export interface BaseSelectProps {
  name?: boolean;
  fluid?: boolean;
  options?: any[];
  optionLabel?: string;
  optionValue?: string | ((option: any) => any);
  editable?: boolean;
  pt?: SelectPassThroughOptions;
  placeholder?: string;
  disabled?: boolean;
}

withDefaults(defineProps<BaseSelectProps>(), {
  name: undefined,
  options: undefined,
  optionLabel: undefined,
  optionValue: undefined,
  editable: false,
  pt: undefined,
  disabled: false,
  placeholder: undefined,
});

const model = defineModel<any>();

const { t } = useI18n();
const id = useId();
</script>

<template>
  <PFloatLabel variant="on">
    <Select
      v-model="model"
      :id
      :pt="{
        ...pt,
      }"
      :fluid="fluid"
      :options="options"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :disabled="disabled"
      :editable="editable"
    >
      <template #empty>
        <slot name="empty">
          <span>{{ t("ui.noData") }}</span>
        </slot>
      </template>
    </Select>

    <label
      class="text-sm"
      :class="disabled ? 'bg-transparent  text-surface-400!' : ''"
      :for="id"
      >{{ placeholder }}</label
    >
  </PFloatLabel>
</template>

<style scoped></style>

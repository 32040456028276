<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { type RowItem } from "@/base/components/filterdatatable/TableTypes.ts";
import { FieldType } from "@/base/graphql/generated/types.ts";
import { DateFormat } from "@/base/i18n/i18n.ts";

const props = defineProps<{
  data: RowItem;
  column: string;
}>();

const to = computed(
  () => props.data.cells?.[props.column]?.to ?? props.data.to,
);

const { d } = useI18n();
</script>

<template>
  <RouterLink v-if="data.cells[column] && to" class="link" :to="to">
    <Component
      :is="data.cells[column].component"
      v-if="data.cells?.[column]?.component"
      v-bind="data.cells[column].props as Object"
    />
    <span
      v-else-if="data.cells[column].type === FieldType.Date"
      v-bind="data.cells[column].props as Object"
      >{{
        d(data.cells[column].content as string, DateFormat.MEDIUM) ?? ""
      }}</span
    >
    <span v-else v-bind="data.cells[column].props as Object">{{
      data.cells[column].content ?? ""
    }}</span>
  </RouterLink>

  <div v-else-if="data.cells[column]">
    <Component
      :is="data.cells[column].component"
      v-if="data.cells[column].component"
      v-bind="data.cells[column].props as Object"
      v-on="{ ...data.cells[column].on }"
    />
    <p
      v-else-if="data.cells[column].type === FieldType.Date"
      v-bind="data.cells[column].props as Object"
    >
      {{ d(data.cells[column].content as string, DateFormat.MEDIUM) ?? "" }}
    </p>
    <p
      v-else
      v-bind="data.cells[column].props as Object"
      :style="data.style"
      v-on="{ ...data.cells[column].events, ...data.events }"
    >
      {{ data.cells[column].content ?? "" }}

      <i
        v-if="data.cells[column].contentTooltip"
        v-tooltip.right="data.cells[column].contentTooltip"
        class="grid justify-center content-center mdi mdi-information-outline min-w-10 max-w-10"
      />
    </p>
  </div>
</template>

<style scoped>
.link {
  text-decoration: none;
  color: #3c707b;
}
</style>

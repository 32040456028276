<script setup lang="ts">
withDefaults(
  defineProps<{
    align?: "left" | "center" | "right" | "top" | "bottom";
    layout?: "horizontal" | "vertical";
    type?: "solid" | "dashed" | "dotted";
  }>(),
  {
    align: undefined,
    layout: "horizontal",
    type: "solid",
  },
);
</script>

<template>
  <PDivider :align :layout :type />
</template>

<script lang="ts" setup>
import { sanitizeUrl } from "@braintree/sanitize-url";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import COptionField from "@/app/process/components/field/COptionField.vue";
import FieldCard from "@/app/process/components/field/FieldCard.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";
import { extractDomain } from "@/base/utils/string.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const { t } = useI18n();

const emit = defineEmits<(e: "update", value: FieldValueDto) => void>();

const sanitizedUrl = computed(() => {
  const valueString = props.fieldValue?.value?.valueString;

  if (!valueString) {
    return "#";
  }
  return sanitizeUrl(
    valueString?.startsWith("http") ? valueString : `https://${valueString}`,
  );
});

const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <div
        class="flex flex-row items-baseline gap-1 text-primary-500 hover:text-primary-300"
      >
        <a
          class="block text-base text-ellipsis overflow-hidden text-inherit pointer-events-auto"
          target="_blank"
          rel="noopener noreferrer"
          :href="sanitizedUrl"
          @click.stop="(event) => event.stopPropagation()"
        >
          {{
            extractDomain(
              props.fieldValue?.value?.valueString ?? "",
              t("processes.singleView.outputTypes.URL"),
            )
          }}
        </a>
        <i class="mdi mdi-open-in-new" />
      </div>
    </template>

    <template #edit="{ closeCallback }">
      <COptionField
        v-if="isOptionField"
        :fieldKey="props.fieldKey"
        :fieldValue="props.fieldValue"
        :label="props.fieldKey.name ?? undefined"
        itemTitle="valueString"
        @update="
          (value) => emit('update', { ...props.fieldValue, optionId: value })
        "
      />

      <BaseTextField
        v-else
        v-focustrap
        type="url"
        :initialValue="props.fieldValue?.value?.valueString ?? undefined"
        @update="
          (value) => {
            emit('update', {
              ...props.fieldValue,
              value: {
                valueString: value,
              },
            });
            if (!edit) {
              closeCallback();
            }
          }
        "
      />
    </template>
  </FieldCard>
</template>

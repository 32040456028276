<script setup lang="ts">
import { computed, onMounted, reactive } from "vue";
import { useI18n } from "vue-i18n";

import DocumentField from "@/app/process/components/field/DocumentField.vue";
import FileUploadField from "@/app/process/components/field/FileUploadField.vue";
import ObjectField from "@/app/process/components/field/ObjectField.vue";
import PeopleField from "@/app/process/components/field/PeopleField.vue";
import PersonField from "@/app/process/components/field/PersonField.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import TextEditor from "@/base/components/form/TextEditor.vue";
import DateField from "@/base/components/form/value/DateField.vue";
import NumberField from "@/base/components/form/value/NumberField.vue";
import TristateField from "@/base/components/form/value/TristateField.vue";
import UrlField from "@/base/components/form/value/UrlField.vue";
import {
  ActivityOutputType,
  FieldType,
  type FieldValueDto,
  type ProcessOutputDto,
  type ValueDto,
} from "@/base/graphql/generated/types.ts";
import { useFileService } from "@/base/services/file/FileService.ts";
import { withoutTypename } from "@/base/services/utils.ts";

const CACHE_EMPTY_STATE: ValueDto = {
  valueBoolean: null,
  valueString: null,
  valueNumber: null,
  valueDate: null,
  valueDateTime: null,
  valueEntityId: null,
  valueJson: null,
};

const props = defineProps<{
  contextKey: string;
  contextName?: string;
  outputType: ActivityOutputType | FieldType;
  processOutputDto?: ProcessOutputDto;
  readonly?: boolean;
  noLabel?: boolean;
}>();

const { t } = useI18n();

const fileService = useFileService();

const valueCache = reactive<ValueDto>(
  props.processOutputDto?.value ?? structuredClone(CACHE_EMPTY_STATE),
);

const emits = defineEmits<{
  (event: "change", value: Partial<ProcessOutputDto>): void;
  (event: "delete"): void;
}>();

onMounted(
  async () =>
    await fileService.fetch(
      props.processOutputDto?.id,
      props.outputType === ActivityOutputType.File
        ? props.processOutputDto?.value?.valueEntityId
        : undefined,
    ),
);

function isValueNullOrUndefined(value: unknown): boolean {
  return value === null || value === undefined;
}

const valueTypeMapping: Record<ActivityOutputType | FieldType, keyof ValueDto> =
  {
    [ActivityOutputType.Boolean]: "valueBoolean",
    [ActivityOutputType.String]: "valueString",
    [ActivityOutputType.Url]: "valueString",
    [ActivityOutputType.Date]: "valueDate",
    [ActivityOutputType.DateTime]: "valueDateTime",
    [ActivityOutputType.Number]: "valueNumber",
    [ActivityOutputType.File]: "valueEntityId",
    [ActivityOutputType.Document]: "valueEntityId",
    [ActivityOutputType.Person]: "valueEntityId",
    [ActivityOutputType.People]: "valueEntityIds",
    [ActivityOutputType.Json]: "valueJson",
    // These are unused but need to be kept here for Typescript
    [FieldType.Address]: "valueJson",
    [FieldType.BankAccount]: "valueJson",
    [FieldType.Tag]: "valueJson",
    [FieldType.Phase]: "valueString",
  };

const isOutputPresent = computed(() => {
  const valueType = valueTypeMapping[props.outputType];
  return valueType ? !isValueNullOrUndefined(valueCache[valueType]) : false;
});

function updateValue(value: Partial<ValueDto>) {
  Object.assign(valueCache, value);
  emits("change", {
    value: {
      ...withoutTypename(valueCache),
    },
  });
}

function updateComment(value: string | null | undefined) {
  emits("change", {
    comment: value,
  });
}

function deleteOutput() {
  Object.assign(valueCache, CACHE_EMPTY_STATE);
  emits("delete");
}
</script>

<template>
  <div class="flex flex-col gap-6 w-full">
    <TristateField
      v-if="
        outputType === ActivityOutputType.Boolean ||
        outputType === FieldType.Boolean
      "
      :tristateValue="processOutputDto?.value?.valueBoolean"
      :readonly="props.readonly"
      @update="
        (value: boolean | null | undefined) =>
          updateValue({ valueBoolean: value })
      "
    />
    <TextEditor
      v-if="
        (outputType === ActivityOutputType.String ||
          outputType === FieldType.String) &&
        !props.readonly
      "
      :containerId="props.contextKey"
      :previousContent="processOutputDto?.value?.valueString ?? undefined"
      @saveContent="(value: string) => updateValue({ valueString: value })"
    />
    <TextDisplay
      v-if="
        (outputType === ActivityOutputType.String ||
          outputType === FieldType.String) &&
        props.readonly
      "
      :value="processOutputDto?.value?.valueString ?? undefined"
      :markdown="true"
    />
    <NumberField
      v-if="
        outputType === ActivityOutputType.Number ||
        outputType === FieldType.Number
      "
      :placeholder="t('processes.singleView.outputTypes.NUMBER')"
      :initialValue="processOutputDto?.value?.valueNumber ?? undefined"
      :disabled="props.readonly"
      @update="(value: number) => updateValue({ valueNumber: value })"
    />
    <UrlField
      v-if="
        outputType === ActivityOutputType.Url || outputType === FieldType.Url
      "
      :name="contextName"
      :initialValue="processOutputDto?.value?.valueString ?? undefined"
      :readonly="props.readonly"
      @update="(value: string) => updateValue({ valueString: value })"
    />
    <DateField
      v-if="
        outputType === ActivityOutputType.Date || outputType === FieldType.Date
      "
      :dateValue="processOutputDto?.value?.valueDate ?? undefined"
      :readonly="props.readonly"
      :label="t('processes.singleView.outputTypes.DATE')"
      @update="(value: string) => updateValue({ valueDate: value })"
    />
    <DateField
      v-if="outputType === ActivityOutputType.DateTime"
      withTime
      :dateValue="processOutputDto?.value?.valueDateTime ?? undefined"
      :readonly="props.readonly"
      :label="t('processes.singleView.outputTypes.DATE_TIME')"
      @update="(value: string) => updateValue({ valueDateTime: value })"
    />
    <FileUploadField
      v-if="
        outputType === ActivityOutputType.File || outputType === FieldType.File
      "
      :initialFileId="processOutputDto?.value?.valueEntityId ?? undefined"
      :outputId="processOutputDto?.id ?? ''"
      :readonly="props.readonly"
      @uploaded="(fileId) => updateValue({ valueEntityId: fileId })"
      @deleted="deleteOutput"
    />
    <PersonField
      v-if="
        outputType === ActivityOutputType.Person ||
        outputType === FieldType.Person
      "
      :initialValue="processOutputDto?.value?.valueEntityId ?? undefined"
      :readonly="props.readonly"
      @update="
        (value: string | FieldValueDto) =>
          updateValue({ valueEntityId: value as string })
      "
    />
    <PeopleField
      v-if="
        outputType === ActivityOutputType.People ||
        outputType === FieldType.People
      "
      :initialValue="processOutputDto?.value?.valueEntityIds ?? undefined"
      :readonly="props.readonly"
      :label="t('processes.singleView.outputTypes.PEOPLE')"
      @update="
        (value: string[] | FieldValueDto) =>
          updateValue({ valueEntityIds: value as string[] })
      "
      @delete="deleteOutput"
    />
    <ObjectField
      v-if="outputType === ActivityOutputType.Json"
      :initialValue="processOutputDto?.value?.valueJson ?? undefined"
      :readonly="props.readonly"
      @update="
        (value: string | FieldValueDto) =>
          updateValue({ valueJson: value as string })
      "
    />

    <DocumentField
      v-if="outputType === ActivityOutputType.Document"
      :initialValue="processOutputDto?.value?.valueEntityId ?? undefined"
      :readonly="props.readonly"
      @update="
        (value: string) => updateValue({ valueEntityId: value as string })
      "
    />

    <TextDisplay
      v-if="isOutputPresent && props.readonly"
      :value="processOutputDto?.comment ?? undefined"
      :markdown="true"
    />
    <TextEditor
      v-else-if="isOutputPresent"
      :label="t('processes.singleView.commentLabel')"
      :previousContent="processOutputDto?.comment ?? undefined"
      :containerId="props.contextKey + '-comment'"
      @saveContent="(value: string) => updateComment(value)"
    />
  </div>
</template>

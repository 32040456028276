<script setup lang="ts">
export interface BaseSelectButtonOption {
  label: string;
  value: string;
  testId?: string;
}

interface BaseSelectButtonProps {
  options: BaseSelectButtonOption[];
  optionLabel?: string;
  multiple?: boolean;
  size?: "x-small" | "small" | "large";
  disabled?: boolean;
  allowEmpty?: boolean;
}

withDefaults(defineProps<BaseSelectButtonProps>(), {
  optionLabel: undefined,
  size: "x-small",
  allowEmpty: false,
});

const emits =
  defineEmits<(e: "valueChange", value: BaseSelectButtonOption) => void>();

const model = defineModel<BaseSelectButtonOption>();
</script>

<template>
  <PSelectButton
    v-model="model"
    :options
    :optionLabel
    :multiple
    :size="size === 'x-small' ? 'small' : size"
    :disabled
    :allowEmpty
    :pt:root="size === 'x-small' ? 'h-8!' : ''"
    @valueChange="emits('valueChange', $event)"
  >
    <template #option="{ option }">
      <span :data-testid="option.testId">{{ option.label }}</span>
    </template>
  </PSelectButton>
</template>

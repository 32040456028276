<script lang="ts" setup>
import { computed, nextTick } from "vue";
import { useRouter } from "vue-router";

import { usePersonService } from "@/app/person/services/PersonService.ts";
import FieldCard from "@/app/process/components/field/FieldCard.vue";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const emits = defineEmits<{
  (e: "update", value: FieldValueDto | string): void;
  (e: "delete"): void;
}>();

const unknownName = "-";

const valueOverrides: Partial<FieldValueDto> = {
  // Old values must be cleared, if eg. the field switched to an option field.
  optionId: null,
  value: {
    valueString: null,
    valueDate: null,
    valueNumber: null,
    valueBoolean: null,
    valueEntityId: null,
    valueJson: null,
  },
};

const personService = usePersonService();
const router = useRouter();

const allPersons = computed(() => personService.getAll());

const person = computed(() => {
  const id = props.fieldValue?.value?.valueEntityId;

  if (!id) {
    return undefined;
  }

  return personService.getById(id);
});

const personOptions = computed(() => {
  if (!props.fieldKey?.options?.length) {
    return allPersons.value.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  }
  return (
    props.fieldKey?.options?.map((option) => {
      const personOption = allPersons.value.find(
        (it) => it.id === option.value?.valueEntityId,
      );
      return {
        id: option.id,
        name: personOption?.name ?? "",
      };
    }) ?? []
  );
});

async function emitUpdate(
  selectedPersonId?: string | null,
  closeCallback?: () => void,
) {
  if (!selectedPersonId || selectedPersonId.length === 0) {
    return;
  }
  if (props.fieldValue) {
    emits("update", {
      ...props.fieldValue,
      ...valueOverrides,
      value: {
        valueEntityId: selectedPersonId,
      },
    });
  } else {
    emits("update", selectedPersonId);
  }

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  if (!props.edit) {
    await nextTick();
    setTimeout(() => closeCallback?.(), 300);
  }
}

async function navigateToPerson() {
  if (!person.value) {
    console.warn("Person not found");
    return;
  }
  await router.push({
    name: "personView",
    params: {
      personId: person.value.id,
    },
  });
}
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <RouterLink
        v-if="person"
        class="block text-base text-ellipsis overflow-hidden text-primary-500 hover:text-primary-300 pointer-events-auto"
        :to="{
          name: 'personView',
          params: { personId: person.id },
        }"
      >
        <a @click.stop.prevent="navigateToPerson">{{ person.name }}</a>
      </RouterLink>

      <p v-else>
        {{ unknownName }}
      </p>
    </template>

    <template #edit="{ closeCallback }">
      <BaseAutoComplete
        optionLabel="name"
        data-testid="person-field-multiselect"
        :modelValue="person"
        :options="personOptions"
        :virtualScrollerOptions="{
          itemSize: 40,
        }"
        @optionSelect="
          (option) => {
            emitUpdate(option.id, closeCallback);
          }
        "
      />
    </template>
  </FieldCard>
</template>

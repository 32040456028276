<script setup lang="ts">
import { type BreadcrumbPassThroughOptions } from "primevue";
import { type RouteLocationResolvedGeneric } from "vue-router";

import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";

export interface BaseBreadcrumbItem {
  label: string | undefined | null;
  path: RouteLocationResolvedGeneric;
}

defineProps<{
  home: BaseMenuItem;
  model: BaseBreadcrumbItem[];
  pt?: BreadcrumbPassThroughOptions;
}>();
</script>

<template>
  <PBreadCrumb :home :model="model as BaseMenuItem[]" :pt>
    <template #item="titleProps">
      <slot name="item" v-bind="titleProps" />
    </template>
  </PBreadCrumb>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import ProcessOutputValue from "@/app/process/components/output/ProcessOutputValue.vue";
import { useFormattedProcessOutputValue } from "@/app/process/composables/useFormattedProcessOutputValue.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import { type ProcessActivityEto } from "@/base/graphql/generated/types.ts";
import { iconifyType } from "@/base/services/utils.ts";

const props = defineProps<{
  processActivity: ProcessActivityEto;
}>();

const { t } = useI18n();
const activityService = useActivityService();
const processService = useProcessService();

const activityInputs = computed(() => {
  return activityService.getInputs(props.processActivity.activity.id);
});

const inputOutputs = computed(() =>
  activityInputs.value.flatMap(
    (input) => activityService.getOutput(input.outputId) ?? [],
  ),
);

const inboundProcessOutputs = computed(() =>
  processService.getInboundProcessOutputs(
    props.processActivity.processId,
    props.processActivity.activity.id,
  ),
);

const items = computed(() => {
  return inputOutputs.value.map((activityOutput) => {
    const activityName =
      activityService.getActivity(activityOutput.activityId, {
        overwriteBreadcrumbs: false,
      })?.name ?? "-";
    const processOutput = inboundProcessOutputs.value.find(
      (output) => output.activityOutputId === activityOutput.id,
    );
    const value = useFormattedProcessOutputValue(
      processOutput,
      activityOutput.type,
    );

    return {
      id: activityOutput.id,
      activityName,
      activityOutput,
      processOutput,
      value,
      outputName: activityOutput.name,
      icon: `mdi ${iconifyType(activityOutput.type)}`,
    };
  });
});

const allInputsReady = computed(() => {
  if (items.value.length === 0) {
    return false;
  }
  return items.value.every((item) => item.processOutput !== undefined);
});

const setInputsLabel = computed(() => {
  const totalSetInputs = items.value.filter(
    (item) => item.processOutput !== undefined,
  ).length;
  return `${totalSetInputs} / ${items.value.length}`;
});
</script>

<template>
  <BaseAccordion
    v-if="processActivity.activity.inputs.length > 0"
    :label="t('processes.input', 2)"
    :icon="allInputsReady ? 'mdi mdi-check-bold' : 'mdi mdi-download'"
    :color="allInputsReady ? 'primary' : 'default'"
    :defaultState="allInputsReady ? 'collapsed' : 'expanded'"
  >
    <template #end> {{ setInputsLabel }} </template>

    <PDataView
      dataKey="id"
      lazy
      :value="items"
      :sortOrder="1"
      :sortField="(item) => item.outputName"
    >
      <template #list="slotProps">
        <div class="flex flex-col gap-4">
          <div v-for="item in slotProps.items" :key="item.activityOutput.id">
            <BaseAccordion
              defaultState="collapsed"
              color="none"
              :disabled="item.processOutput === undefined"
            >
              <template #title>
                <div class="flex flex-col gap-2">
                  <div class="flex flex-row gap-4 items-center">
                    <BaseAvatar
                      class="mx-2"
                      :icon="`mdi ${iconifyType(item.activityOutput.type)}`"
                      :pt:root="
                        item.processOutput === undefined
                          ? ''
                          : 'bg-primary-500! text-white!'
                      "
                    />

                    <div class="flex flex-col gap-1 grow overflow-hidden">
                      <p class="text-sm">
                        {{ item.outputName }}
                      </p>

                      <p
                        v-if="item.processOutput !== undefined"
                        class="text-sm font-thin opacity-50 truncate"
                      >
                        {{ item.value }}
                      </p>
                    </div>

                    <PTag
                      v-if="item.processOutput === undefined"
                      class="max-h-6! text-xs!"
                      severity="danger"
                      :value="t('processes.singleView.inputNotSetTag')"
                    />
                  </div>
                </div>
              </template>

              <ProcessOutputValue
                class="pl-15!"
                :activityOutput="item.activityOutput"
                :processOutput="item.processOutput"
              />
            </BaseAccordion>
          </div>
        </div>
      </template>
    </PDataView>
  </BaseAccordion>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import StartProcessDialog from "@/app/process/components/action/start/StartProcessDialog.vue";
import { useBlockedActionStore } from "@/app/process/stores/action/BlockedActionStore.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import {
  type BaseDialogProps,
  type DeleteDialogData,
  type DeleteDialogProps,
  type StartProcessDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { useDialogService } from "@/base/services/DialogService.ts";

const props = defineProps<{
  rootActivityMid: string;
}>();

const { t } = useI18n();

const dialogService = useDialogService();
const activityService = useActivityService();
const blockerStore = useBlockedActionStore();

const current = computed(() =>
  activityService.getCurrentVersion(props.rootActivityMid),
);

const lastReleased = computed(() =>
  activityService.getLastReleased(props.rootActivityMid),
);

const allActivities = computed(() =>
  current.value
    ? [
        current.value,
        ...activityService.getDescendantActivities(
          current.value.id ?? "undefined",
        ),
      ]
    : [],
);

const reviseBlocked = computed(() =>
  blockerStore.isBlocked(lastReleased.value?.id ?? ""),
);

const dialog = useBaseDialog();

const emits = defineEmits<(event: "beforeDelete", ids: string[]) => void>();
function showDeleteDialog() {
  const headerTitle =
    allActivities.value.length === 1
      ? t("processes.listView.deleteActivity")
      : t("processes.listView.deleteProcessTemplate");

  const deleteDescription =
    allActivities.value.length === 1
      ? t("processes.listView.deleteActivityText", {
          name: allActivities.value[0].name,
        })
      : t(
          "processes.listView.deleteProcessTemplateText",
          {
            name: allActivities.value[0].name,
            subActivityCount: allActivities.value.length - 1,
          },
          allActivities.value.length - 1,
        );

  const deleteDialogData: DeleteDialogData = { deleteDescription };

  const deleteDialogProps: DeleteDialogProps = {
    header: headerTitle,
    props: deleteDialogData,
    onDelete: () => {
      const ids = allActivities.value.map((it) => it.id);
      emits("beforeDelete", ids);

      dialogService.deleteNextActivity(allActivities.value);
      dialogRef.close();
    },
  };

  const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
}

function showStartProcessDialog() {
  const headerTitle = t("processes.startButton.startNamedProcess", {
    name: lastReleased.value?.name ?? "",
  });

  const startProcessDialogData: StartProcessDialogData = {
    showProcessTemplateSelect: false,
    rootActivity: lastReleased.value,
  };

  const startProcessDialogProps: BaseDialogProps = {
    header: headerTitle,
    props: startProcessDialogData,
  };

  dialog.open(StartProcessDialog, startProcessDialogProps);
}
</script>

<template>
  <div class="flex flex-row items-center gap-2 py-1 h-6">
    <BaseButton
      v-if="current && lastReleased && current.id === lastReleased.id"
      v-tooltip.left="t('action.revise')"
      text
      icon="mdi mdi-pencil"
      :loading="reviseBlocked"
      @click.stop.prevent="
        () => activityService.reviseProcessTemplate(current?.id ?? 'undefined')
      "
    />

    <BaseButton
      v-if="lastReleased"
      v-tooltip.left="t('processes.startButton.startProcess')"
      data-testid="start-process-button"
      variant="text"
      severity="contrast"
      icon="mdi mdi-play"
      @click="showStartProcessDialog"
    />

    <BaseButton
      v-tooltip.left="t('action.delete')"
      text
      severity="danger"
      icon="mdi mdi-delete"
      @click.stop.prevent="showDeleteDialog"
    />
  </div>
</template>

<script setup lang="ts">
import { type InputNumberBlurEvent } from "primevue";
import { nextTick, ref, useId, watch } from "vue";

const props = defineProps<{
  initialValue?: number;
  disabled?: boolean;
  placeholder?: string;
  icon?: string;
}>();

const emits = defineEmits<(event: "update", value: number) => void>();

const id = useId();

const valueNumber = ref(props.initialValue ?? 0);

watch(
  () => props.initialValue,
  (newValue) => {
    valueNumber.value = newValue ?? 0;
  },
);

async function onEditTextFieldBlur(event: InputNumberBlurEvent) {
  if (event.value === valueNumber.value.toString()) {
    return;
  }

  await nextTick();
  emits("update", valueNumber.value);
}
</script>

<template>
  <PIconField>
    <PFloatLabel variant="on">
      <PInputIcon v-if="icon" :class="icon" />
      <PInputNumber
        :id
        v-model="valueNumber"
        fluid
        mode="decimal"
        locale="de-DE"
        size="small"
        :minFractionDigits="undefined"
        :maxFractionDigits="5"
        :disabled="disabled"
        @blur="onEditTextFieldBlur"
      />

      <label
        v-if="placeholder"
        class="text-sm"
        :class="disabled ? 'bg-transparent text-surface-400!' : ''"
        :for="id"
        >{{ placeholder }}</label
      >
    </PFloatLabel>
  </PIconField>
</template>

<script setup lang="ts">
import { Position } from "@vue-flow/core";
import { NodeToolbar } from "@vue-flow/node-toolbar";
import { useRouter } from "vue-router";

import BaseButton from "@/base/components/button/BaseButton.vue";

const props = defineProps<{
  isVisible: boolean;
  activityId: string;
}>();

const router = useRouter();

async function navigateToActivity() {
  await router.push({
    name: "activity",
    params: { activityId: props.activityId },
  });
}
</script>

<template>
  <NodeToolbar
    class="toolbar"
    :isVisible="props.isVisible"
    :position="Position.Top"
  >
    <BaseButton
      icon="mdi mdi-eye"
      severity="secondary"
      raised
      @click="navigateToActivity"
    />
  </NodeToolbar>
</template>

<style scoped>
.toolbar {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
</style>

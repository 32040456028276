<script setup lang="ts">
import PersonListView from "@/app/person/views/PersonListView.vue";
import PersonSingleEditView from "@/app/person/views/PersonSingleEditView.vue";
import PersonView from "@/app/person/views/PersonView.vue";
</script>

<template>
  <RouterView v-slot="{ route }">
    <PersonListView v-if="route.name === 'personList'" />
    <PersonView
      v-else-if="route.name === 'personView'"
      :personId="route.params.personId as string"
    />
    <PersonSingleEditView
      v-else-if="route.name === 'personEdit'"
      :personId="route.params.personId as string"
    />
  </RouterView>
</template>

import { computed, type MaybeRef, onMounted, type Ref, ref, toRef } from "vue";

import { useFileService } from "@/base/services/file/FileService.ts";

export function useFile(fileId?: MaybeRef<string | undefined>) {
  const fileService = useFileService();

  const fileIdRef: Ref<string | undefined> = toRef(fileId ?? undefined);
  const isLoading = ref<boolean>(false);

  const file = computed(() => {
    if (!fileIdRef.value) {
      return undefined;
    }

    return fileService.getById(fileIdRef.value);
  });

  onMounted(async () => {
    if (!fileIdRef.value) {
      return;
    }
    isLoading.value = true;

    try {
      await fileService.fetch(fileIdRef.value, fileIdRef.value);
    } catch (error) {
      console.error("Couldn't fetch file:", error);
    }

    isLoading.value = false;
  });

  async function downloadFile() {
    if (!fileIdRef.value) {
      console.error("No file id given");
      return;
    }

    await fileService.download(fileIdRef.value);
  }

  return {
    isLoading,
    file,
    downloadFile,
  };
}

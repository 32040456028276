import "@/base/assets/styles/main.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";

import { VueQueryPlugin } from "@tanstack/vue-query";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createPinia } from "pinia";
import { ToastService } from "primevue";
import { default as PrimeVue } from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import DialogService from "primevue/dialogservice";
import FocusTrap from "primevue/focustrap";
import KeyFilter from "primevue/keyfilter";
import Tooltip from "primevue/tooltip";
import { createApp } from "vue";

import App from "@/App.vue";
import { i18n } from "@/base/i18n/i18n";
import { apolloClient } from "@/base/plugins/apollo/apollo.ts";
import { primeVueConfig } from "@/base/plugins/primevue/primevue.ts";
import { vueQueryConfig } from "@/base/plugins/vuequery/vuequery.ts";
import router from "@/base/router";
import {
  configureAppForUserAuth,
  getAccessTokenGenerator,
  UserAuthService,
} from "@/base/services/auth/UserAuthService";
import { OpenAPI } from "@/base/services/backend/core/OpenAPI";

const app = createApp(App);

app.provide(DefaultApolloClient, apolloClient);
app.use(createPinia());
app.use(router);
app.use(PrimeVue, primeVueConfig);
app.use(ConfirmationService);
app.use(ToastService);
app.use(DialogService);
app.use(i18n);
app.use(VueQueryPlugin, vueQueryConfig);

app.directive("focustrap", FocusTrap);
app.directive("keyfilter", KeyFilter);
app.directive("tooltip", Tooltip);

OpenAPI.BASE = ""; // PiSys backend is reachable with relative urls
if (UserAuthService.isAuthEnabled()) {
  configureAppForUserAuth(router);
  OpenAPI.TOKEN = getAccessTokenGenerator();
} else {
  console.log("Auth is disabled");
}

// Waiting for the router to be ready prevents race conditions when returning from auth redirects
await router.isReady();
app.mount("#app");

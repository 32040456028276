<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import FieldByOutputType from "@/app/process/components/output/FieldByOutputType.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import { type ActivityOutputType } from "@/base/graphql/generated/types.ts";

const { t } = useI18n();

const props = defineProps<{
  processId: string;
  targetStartActivityId: string;
  processActivityId: string;
  previousOutputId: string;
  previousType?: ActivityOutputType;
  currentType?: ActivityOutputType;
}>();

const processService = useProcessService();
const processOutput = computed(() =>
  processService.getProcessOutput(
    props.processActivityId,
    props.previousOutputId,
  ),
);
const migrationFields = computed(() =>
  processService
    .getAllMigrationFields(props.processId, props.targetStartActivityId)
    .get(processOutput.value?.id ?? "undefined"),
);
const migrationState = computed(() =>
  processOutput.value
    ? processService.getMigrationState(
        props.processId,
        props.targetStartActivityId,
        processOutput.value.id,
      )
    : false,
);
</script>

<template>
  <div
    v-if="
      props.previousType &&
      props.currentType &&
      processOutput &&
      processOutput.id
    "
    class="flex flex-row w-100 gap-3"
  >
    <div class="flex flex-col w-33 gap-2">
      <span class="text-xs pr-3">{{
        t("processes.upgrade.previousValue")
      }}</span>

      <FieldByOutputType
        :values="processOutput"
        :contextKey="processOutput.id"
        :processOutputId="processOutput.id"
        :outputType="props.previousType!"
        :readonly="true"
        :noLabel="true"
      />
    </div>
    <div class="flex flex-col w-66 gap-2">
      <span class="text-xs pr-3">{{ t("processes.upgrade.newValue") }}</span>

      <div class="flex flex-row">
        <FieldByOutputType
          :values="migrationFields"
          :contextKey="previousOutputId"
          :outputType="props.currentType!"
          :processOutputId="previousOutputId"
          :readonly="migrationState"
          :noLabel="true"
          @change="
            (changedFields) => {
              processService.updateMigrationFields(
                props.processId,
                props.targetStartActivityId,
                { id: processOutput!.id, ...changedFields },
              );
            }
          "
          @delete="
            processService.clearMigrationFields(
              props.processId,
              props.targetStartActivityId,
              processOutput!.id,
            )
          "
        />

        <BaseButton
          text
          :icon="migrationState ? 'mdi mdi-pencil' : 'mdi mdi-check'"
          @click="
            () => {
              processService.updateMigrationState(
                props.processId,
                props.targetStartActivityId,
                processOutput!.id,
                !migrationState,
              );
            }
          "
        />
      </div>
    </div>
  </div>
</template>

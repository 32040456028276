<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import ActivityOutputTypeSelect from "@/app/process/components/output/ActivityOutputTypeSelect.vue";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseMoreButton from "@/base/components/button/BaseMoreButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import TextEditor from "@/base/components/form/TextEditor.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { iconifyType } from "@/base/services/utils.ts";

const props = defineProps<{
  activityId: string;
  outputId: string;
  readonly?: boolean;
}>();

const { t } = useI18n();
const dialog = useBaseDialog();
const activityService = useActivityService();

const output = computed(() => activityService.getOutput(props.outputId));

const isOutputImportant = computed(() => {
  return output.value?.important ?? false;
});

const moreButtonItems = computed<BaseMenuItem[]>(() => [
  {
    visible: output.value !== undefined,
    label: t("processes.outputFavorite"),
    severity: "warn",
    icon: isOutputImportant.value ? "mdi mdi-star" : "mdi mdi-star-outline",
    command: () => toggleImportant(),
  },
  {
    separator: true,
    visible: !props.readonly,
  },
  {
    visible: !props.readonly,
    label: t("action.delete"),
    icon: "mdi mdi-trash-can-outline",
    severity: "danger",
    command: () => showDeleteDialog(),
  },
]);

function toggleImportant() {
  if (!output.value || props.readonly) {
    return;
  }
  activityService
    .createOrUpdateOutput(
      {
        id: output.value?.id,
        important: !isOutputImportant.value,
      },
      props.activityId,
    )
    .catch((reason) => {
      console.error(reason);
    });
}

function showDeleteDialog() {
  const deleteDescription = t("action.actionPrompt", {
    action: t("action.delete").toLowerCase(),
    target: output?.value?.name,
  });

  const deleteDialogData: DeleteDialogData = {
    deleteDescription,
  };

  const deleteDialogProps: DeleteDialogProps = {
    header: `${t("processes.outputDeleteHeader")}`,
    props: deleteDialogData,
    onDelete: async () => {
      await activityService.removeOutput(props.outputId);
      dialogRef.close();
    },
  };
  const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
}
</script>

<template>
  <BaseAccordion
    v-if="output"
    color="none"
    defaultState="collapsed"
    :expandable="!readonly || !!output?.description || !!output?.key"
    :headerClass="isOutputImportant ? 'border-l-3! border-l-orange-400!' : ''"
  >
    <template #title>
      <div class="flex flex-row gap-2 items-center">
        <BaseAvatar
          class="transition-all ease-in duration-200"
          :class="isOutputImportant ? 'mr-2 ml-1' : 'mx-2'"
          :icon="`mdi ${iconifyType(output.type)}`"
        />

        <div class="flex flex-row grow">
          <div v-if="output" class="flex flex-row gap-2 items-center">
            <i v-if="isOutputImportant" class="mdi mdi-star text-orange-500" />
            <p class="text-sm">
              {{ output.name }}
            </p>
          </div>
        </div>

        <PTag
          :value="t('processes.output', 1)"
          severity="primary"
          class="w-fit h-6 text-xs!"
        />

        <BaseMoreButton :model="moreButtonItems" />
      </div>
    </template>

    <div class="flex flex-col gap-4 px-2! my-2">
      <div v-if="!readonly" class="flex gap-4 flex-col md:flex-row">
        <BaseTextField
          class="w-full"
          data-testid="activity-output-name"
          :label="t('processes.nameLabel')"
          :initialValue="output?.name"
          :disabled="readonly"
          @update="
            (value: string) =>
              activityService.createOrUpdateOutput(
                { id: props.outputId, name: value },
                props.activityId,
              )
          "
        />

        <ActivityOutputTypeSelect :outputId="props.outputId" />
      </div>

      <TextEditor
        v-if="!readonly"
        data-testid="activity-output-description"
        :containerId="props.outputId"
        :previousContent="output?.description ?? ''"
        :label="t('processes.singleView.descriptionLabel')"
        @saveContent="
          (content: string) =>
            activityService.createOrUpdateOutput(
              {
                id: props.outputId,
                description: content,
              },
              props.activityId,
            )
        "
      />

      <TextDisplay
        v-else-if="output?.description"
        :label="t('processes.singleView.descriptionLabel')"
        :value="output?.description ?? ''"
        markdown
      />

      <BaseTextField
        v-if="!readonly && !output?.key"
        :label="t('processes.techNameLabel')"
        @update="
          (value: string) =>
            activityService.createOrUpdateOutput(
              { id: props.outputId, key: value },
              props.activityId,
            )
        "
      />

      <TextDisplay
        v-else-if="output?.key"
        :value="output?.key ?? ''"
        :label="t('processes.techNameLabel')"
      />
    </div>
  </BaseAccordion>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import BasePanel from "@/base/components/panel/BasePanel.vue";
import BaseMultiSelect from "@/base/components/select/BaseMultiSelect.vue";
import BaseSelect from "@/base/components/select/BaseSelect.vue";
import { EntityEventAction } from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";

defineProps<{
  numberOfEntries: number;
}>();

const filterModel = defineModel<EntityEventAction[]>("filter", { default: [] });
const sortModel = defineModel<"ASC" | "DSC">("sort", { default: [] });

const { t } = useI18n();

const filterOptions = ref(
  Object.values(EntityEventAction).map((item) => ({
    label: translateEnum("entityEvent.actions", item),
    value: item,
  })),
);

const dateSortOptions = ref(
  Object.values(["ASC", "DSC"]).map((item) => ({
    label: translateEnum("entityEvent.dateSort", item),
    value: item,
  })),
);
</script>

<template>
  <BasePanel header="Filter">
    <template #header>
      <div class="flex flex-row items-baseline gap-2">
        <p class="font-bold text-sm">{{ t("processes.filterLabel") }}</p>

        <p class="text-center text-xs">
          {{ `(${t("table.entries", { n: numberOfEntries })})` }}
        </p>
      </div>
    </template>

    <div class="flex flex-col gap-8">
      <BaseMultiSelect
        v-model="filterModel"
        fluid
        optionValue="value"
        optionLabel="label"
        :options="filterOptions"
        :placeholder="t('entityEvent.actionMultiSelectPlaceholder')"
      />

      <BaseSelect
        v-model="sortModel"
        fluid
        optionValue="value"
        optionLabel="label"
        :options="dateSortOptions"
        :placeholder="t('entityEvent.dateSortSelectPlaceholder')"
      />
    </div>
  </BasePanel>
</template>

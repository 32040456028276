<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { inject, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import { type ResetListFilterDialogData } from "@/base/composables/dialog/dialogData.ts";

const { t } = useI18n();
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: ResetListFilterDialogData = dialogRef?.value.data;

function resetFilter() {
  localStorage.removeItem(`filterState_${props.contextKey}`);
  dialogRef?.value.close(true);
}
</script>

<template>
  <BaseAppDialog>
    <template #content>
      {{ t("dialogs.resetListFilterDialog.content") }}
    </template>

    <template #footer>
      <div class="flex flex-row w-full justify-end">
        <BaseButton
          severity="danger"
          :label="t('dialogs.resetListFilterDialog.confirmButton')"
          @click="resetFilter"
        />
      </div>
    </template>
  </BaseAppDialog>
</template>

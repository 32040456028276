<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import { useValidators } from "@/base/components/form/validators.ts";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { extractDomain } from "@/base/utils/string.ts";

const props = defineProps<{
  initialValue?: string;
  name?: string;
  readonly?: boolean;
}>();

const emits = defineEmits<(event: "update", value: string) => void>();

const { t } = useI18n();
const { isWebUrl } = useValidators();

const currentValue = ref<string | undefined>(props.initialValue ?? "");
const lastEmittedValue = ref<string | undefined>(props.initialValue ?? "");

watch(
  () => props.initialValue,
  (current) => {
    lastEmittedValue.value = current ?? "";
  },
);

function checkUrlAndEmit(value: string) {
  currentValue.value = value;

  if (lastEmittedValue.value === value) {
    return;
  }

  if (!isWebUrl(value)) {
    return;
  }

  emits("update", value);
  lastEmittedValue.value = value;
}
</script>

<template>
  <div class="flex flex-col items gap-4">
    <BaseTextField
      v-if="!lastEmittedValue"
      class="w-full"
      :invalid="currentValue?.length !== 0 && !isWebUrl(currentValue)"
      :initialValue="lastEmittedValue"
      :disabled="props.readonly || isWebUrl(lastEmittedValue)"
      :label="t('processes.singleView.outputTypes.URL')"
      @update="checkUrlAndEmit"
    />

    <BaseButton
      v-else
      :label="extractDomain(lastEmittedValue, 'Link')"
      iconPos="right"
      icon="mdi mdi-open-in-new"
      as="a"
      :href="lastEmittedValue"
      target="_blank"
    />
  </div>
</template>

import dayjs from "dayjs";
import {
  computed,
  type ComputedRef,
  type MaybeRef,
  type Ref,
  toRef,
} from "vue";

import { usePersonService } from "@/app/person/services/PersonService.ts";
import {
  ActivityOutputType,
  type ProcessOutputDto,
  type ValueDto,
} from "@/base/graphql/generated/types.ts";
import { i18n } from "@/base/i18n/i18n.ts";

const { t, n } = i18n.global;

const textConverterByActivityOutputType = {
  [ActivityOutputType.Boolean]: (value: ValueDto | null | undefined) => {
    return value?.valueBoolean ? t("boolean.yes") : t("boolean.no");
  },
  [ActivityOutputType.Number]: (value: ValueDto | null | undefined) => {
    return value?.valueNumber ? n(value.valueNumber) : "";
  },
  [ActivityOutputType.Date]: (value: ValueDto | null | undefined) => {
    return value?.valueDate ? dayjs(value.valueDate).format("DD.MM.YYYY") : "";
  },
  [ActivityOutputType.DateTime]: (value: ValueDto | null | undefined) => {
    return value?.valueDateTime
      ? dayjs(value.valueDateTime).format("DD.MM.YYYY, hh:mm")
      : "";
  },
  [ActivityOutputType.Person]: (value: ValueDto | null | undefined) => {
    if (!value?.valueEntityId) {
      return "";
    }
    return usePersonService().getById(value.valueEntityId)?.name ?? "";
  },
  [ActivityOutputType.Json]: (value: ValueDto | null | undefined) => {
    return value?.valueJson ? t("boolean.yes") : t("boolean.no");
  },
};

export function useFormattedProcessOutputValue(
  processOutput: MaybeRef<ProcessOutputDto | undefined>,
  activityOutputType: MaybeRef<ActivityOutputType | undefined>,
): ComputedRef<string | undefined> {
  const processOutputRef: Ref<ProcessOutputDto | undefined> =
    toRef(processOutput);
  const activityOutputTypeRef: Ref<ActivityOutputType | undefined> =
    toRef(activityOutputType);

  return computed((): string | undefined => {
    if (!processOutputRef.value) {
      return undefined;
    }

    const defaultText = processOutputRef.value.value?.valueString ?? "";
    if (activityOutputTypeRef.value) {
      const textConverter =
        textConverterByActivityOutputType[activityOutputTypeRef.value];
      return textConverter
        ? textConverter(processOutputRef.value.value)
        : defaultText;
    }

    return defaultText;
  });
}

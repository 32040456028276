<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseMoreButton from "@/base/components/button/BaseMoreButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import TextEditor from "@/base/components/form/TextEditor.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";

const props = defineProps<{
  id: string;
  activityId: string;
  readonly?: boolean;
}>();

const { t } = useI18n();

const activityService = useActivityService();

const task = computed(() => activityService.getTask(props.id));

const moreButtonItems = computed<BaseMenuItem[]>(() => [
  {
    visible: !props.readonly,
    label: t("action.delete"),
    icon: "mdi mdi-trash-can-outline",
    severity: "danger",
    command: () => showDeleteDialog(),
  },
]);

const dialog = useBaseDialog();
function showDeleteDialog() {
  const deleteDialogData: DeleteDialogData = {
    deleteDescription: `${t("processes.task")}${task?.value?.title ? ` ${t("quoted", { expression: task?.value?.title })}` : ""}`,
  };

  const deleteDialogProps: DeleteDialogProps = {
    header: `${t("message.deleteMessage")}`,
    props: deleteDialogData,
    onDelete: async () => {
      await activityService.removeTask(task?.value?.id ?? "undefined");
      dialogRef.close();
    },
  };

  const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
}
</script>

<template>
  <BaseAccordion v-if="task" color="none" defaultState="collapsed">
    <template #title>
      <div class="flex flex-row gap-2 items-center">
        <BaseAvatar icon="mdi mdi-pencil" class="mx-2" />

        <div class="flex flex-row grow items-center">
          <p class="text-sm grow">{{ task.title }}</p>

          <PTag
            :value="t('processes.task', 1)"
            severity="info"
            class="w-fit h-6 text-xs!"
          />
        </div>

        <BaseMoreButton :model="moreButtonItems" />
      </div>
    </template>

    <div class="flex flex-col gap-4 px-2! my-2">
      <BaseTextField
        v-if="!readonly"
        :label="t('processes.nameLabel')"
        :initialValue="task?.title"
        data-testid="activity-task-name"
        @update="
          (title: string) =>
            activityService.createOrUpdateTask(
              { id: props.id, title },
              props.activityId,
            )
        "
      />

      <TextDisplay
        v-else
        :value="task?.title ?? ''"
        :label="t('processes.nameLabel')"
      />

      <TextEditor
        v-if="!readonly"
        :containerId="props.id"
        :previousContent="task?.description ?? ''"
        :label="t('processes.singleView.descriptionLabel')"
        data-testid="activity-task-description"
        @saveContent="
          (description: string) =>
            activityService.createOrUpdateTask(
              { id: props.id, description },
              props.activityId,
            )
        "
      />

      <TextDisplay
        v-else
        :label="t('processes.singleView.descriptionLabel')"
        :value="task?.description ?? ''"
        markdown
      />
    </div>
  </BaseAccordion>
</template>

<script setup lang="ts">
import ActivitySingleView from "@/app/activity/views/ActivitySingleView.vue";
import GraphViewerView from "@/app/graphviewer/views/GraphViewerView.vue";
import ProcessActivitySingleView from "@/app/process/views/ProcessActivitySingleView.vue";
import ProcessListView from "@/app/process/views/ProcessListView.vue";
import ProcessSingleView from "@/app/process/views/ProcessSingleView.vue";
</script>

<template>
  <RouterView v-slot="{ route }">
    <ProcessListView v-if="route.name === 'processList'" />
    <ProcessSingleView
      v-else-if="route.name === 'processView'"
      :processId="route.params.processId as string"
    />
    <ProcessActivitySingleView
      v-else-if="route.name === 'processActivity'"
      :processId="route.params.processId as string"
      :processActivityId="route.params.processActivityId as string"
    />
    <GraphViewerView
      v-else-if="route.name === 'processGraph'"
      :processId="route.params.processId as string"
      :rootActivityId="route.params.rootActivityId as string"
    />
    <ActivitySingleView
      v-else-if="route.name === 'customActivity'"
      :processId="route.params.processId as string"
      :activityId="route.params.activityId as string"
    />
  </RouterView>
</template>

<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { computed, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";

import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseDivider from "@/base/components/divider/BaseDivider.vue";
import PromptHeaderButton from "@/base/components/header/PromptHeaderButton.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import BasePopover from "@/base/components/popover/BasePopover.vue";
import { UserAuthService } from "@/base/services/auth/UserAuthService.ts";

const { t } = useI18n();
const { copy, copied } = useClipboard();

const profilePopoverRef = useTemplateRef("profilePopoverRef");

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    label: t("user.settings.deleteLocalStorage"),
    severity: "danger",
    icon: "mdi mdi-delete-outline",
    command: () => localStorage.clear(),
  },
]);

async function urlToClipboard() {
  const url = window.location.href;
  await copy(url);
}
</script>

<template>
  <div class="flex flex-row gap-4 mr-2">
    <BaseButton
      v-tooltip.bottom="t('action.copyToClipboardTooltip')"
      class="transition-all ease-out duration-200"
      :icon="copied ? 'mdi mdi-check-circle' : 'mdi mdi-share-variant'"
      :severity="copied ? 'primary' : 'secondary'"
      :variant="copied ? undefined : 'text'"
      @click="urlToClipboard"
    />

    <PromptHeaderButton />

    <BaseButton
      v-tooltip.bottom="t('user.profileTooltip')"
      icon="mdi mdi-account"
      text
      severity="secondary"
      @click="profilePopoverRef?.toggle($event)"
    />

    <BasePopover ref="profilePopoverRef">
      <div class="flex flex-col">
        <div class="flex flex-col gap-2">
          <div class="flex flex-row items-center gap-3">
            <BaseAvatar :label="UserAuthService.getUserFullName()?.[0]" />

            <p class="text-md font-bold">
              {{ UserAuthService.getUserFullName() }}
            </p>

            <BaseActionsButton
              icon="mdi mdi-dots-vertical"
              severity="secondary"
              hideLabel
              text
              :model="actionsMenuItems"
            />
          </div>

          <p class="text-sm font-thin">
            {{ UserAuthService.getUserMail() }}
          </p>
        </div>

        <BaseDivider />

        <BaseButton
          fluid
          text
          severity="danger"
          :label="t('user.logout')"
          @click="UserAuthService.logOutUser"
        />
      </div>
    </BasePopover>
  </div>
</template>

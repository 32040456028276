<script setup lang="ts">
import { useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import { getSeverityColor } from "@/base/components/button/button.utils.ts";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import BaseTieredMenu from "@/base/components/tieredmenu/BaseTieredMenu.vue";

withDefaults(
  defineProps<{
    model: BaseMenuItem[];
    disabled?: boolean;
    loading?: boolean;
    outlined?: boolean;
    icon?: string;
    label?: string;
    text?: boolean;
    severity?:
      | "primary"
      | "secondary"
      | "success"
      | "info"
      | "warn"
      | "help"
      | "danger"
      | "contrast";
    hideLabel?: boolean;
  }>(),
  {
    disabled: false,
    loading: false,
    outlined: false,
    text: false,
    icon: "mdi mdi-chevron-down",
    severity: "primary",
    label: undefined,
  },
);

const { t } = useI18n();

const actionsMenuRef = useTemplateRef("actionsMenuRef");
</script>

<template>
  <div>
    <BaseButton
      data-testid="actions-button"
      iconPos="right"
      :text="text"
      :icon="icon"
      :outlined="outlined"
      :disabled="disabled"
      :loading="loading"
      :severity="severity"
      :label="hideLabel ? undefined : (label ?? t('ui.actions'))"
      @click="actionsMenuRef?.toggle($event)"
    />

    <BaseTieredMenu
      ref="actionsMenuRef"
      :model="model"
      :pt="{
        itemIcon: ({ context }) => ({
          class: getSeverityColor(context.item.severity),
        }),
        itemLabel: ({ context }) => ({
          class: getSeverityColor(context.item.severity),
        }),
        item: ({ context }) => ({
          'data-testid': context.item.testId,
        }),
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { type ButtonPassThroughOptions } from "primevue";
import { type RouteLocationRaw, useRouter } from "vue-router";

export interface BaseButtonProps {
  size?: "small" | "large";
  disabled?: boolean;
  icon?: string;
  severity?:
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warn"
    | "help"
    | "danger"
    | "contrast";
  text?: boolean;
  outlined?: boolean;
  label?: string;
  raised?: boolean;
  loading?: boolean;
  rounded?: boolean;
  plain?: boolean;
  fluid?: boolean;
  badge?: string;
  variant?: "outlined" | "text" | "link";
  to?: RouteLocationRaw;
  iconPos?: "left" | "right";
  pt?: ButtonPassThroughOptions;
  badgeSeverity?:
    | "warn"
    | "success"
    | "info"
    | "danger"
    | "secondary"
    | "contrast"
    | undefined;
}

const props = withDefaults(defineProps<BaseButtonProps>(), {
  size: "small",
  severity: "primary",
  icon: "",
  label: undefined,
  iconPos: "left",
  to: undefined,
  pt: undefined,
  badge: undefined,
  variant: undefined,
  badgeSeverity: undefined,
});

const router = useRouter();
</script>

<template>
  <PButton
    :disabled
    :icon
    :iconPos
    :label
    :loading
    :outlined="variant === 'outlined' || outlined"
    :severity
    :raised
    :rounded
    :size
    :text="variant === 'text' || text"
    :plain
    :fluid
    :badge
    :badgeSeverity
    :variant
    :as="props.to ? 'router-link' : 'button'"
    :to="props.to && router.resolve(props.to).href"
    :pt="{
      root: { class: props.to ? 'no-underline!' : undefined },
      icon: { class: 'scale-120' },
      ...pt,
    }"
  >
    <slot />
  </PButton>
</template>

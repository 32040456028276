<script setup lang="ts">
import config from "@/config.ts";

const environmentIndicator = (): string | undefined => {
  const value = config.envIndicator;
  if (!value || value.length === 0 || value.toLowerCase() === "prod") {
    return undefined;
  }
  return value.toUpperCase();
};
</script>

<template>
  <div v-if="environmentIndicator()" class="indicator">
    <p class="indicator-text">
      {{ environmentIndicator() }}
    </p>
  </div>
</template>

<style scoped>
.indicator {
  background-color: red;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 2000;
  border-radius: 0 0 4px 4px;
}

.indicator-text {
  padding: 0.15rem;
  color: white;
}
</style>

<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { inject, type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import type { CreateTemplateDialogData } from "@/base/composables/dialog/dialogData.ts";

const { t } = useI18n();

const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const templateName = ref<string>("");
const props: CreateTemplateDialogData = dialogRef?.value.data;
const emit = defineEmits<(e: "create", value: string) => void>();
</script>

<template>
  <BaseAppDialog>
    <template #content>
      <div class="w-full h-full">
        <BaseTextField
          data-testid="create-process-template-dialog-name"
          class="w-full mt-2"
          type="text"
          :label="props.createDescription"
          @update="templateName = $event"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row justify-end pt-4 gap-4">
        <BaseButton
          data-testid="create-process-template-dialog-create-button"
          severity="primary"
          :label="t('action.create')"
          @click="emit('create', templateName)"
        />
      </div>
    </template>
  </BaseAppDialog>
</template>

<style scoped></style>

<script lang="ts" setup>
import { computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";

import { usePersonService } from "@/app/person/services/PersonService.ts";
import FieldCard from "@/app/process/components/field/FieldCard.vue";
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyDto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const emit =
  defineEmits<(e: "update", value: FieldValueDto | string[]) => void>();

const valueOverrides: Partial<FieldValueDto> = {
  // Old values must be cleared, if eg. the field switched to an option field.
  optionId: null,
  value: {
    valueString: null,
    valueDate: null,
    valueNumber: null,
    valueBoolean: null,
    valueEntityId: null,
    valueJson: null,
  },
};

const personService = usePersonService();
const { t } = useI18n();

const allPersons = computed(() => personService.getAll());
const selectedIds = computed(
  () => props.fieldValue?.value?.valueEntityIds ?? [],
);

const selectedPersons = computed(() =>
  selectedIds.value
    .map((id) => personService.getById(id))
    .sort((a, b) => (a?.name ?? "").localeCompare(b?.name ?? "")),
);

async function emitUpdate(
  selectedPersonIds?: string[],
  closeCallback?: () => void,
) {
  if (!selectedPersonIds || selectedPersonIds.length === 0) {
    return;
  }
  if (props.fieldValue) {
    emit("update", {
      ...props.fieldValue,
      ...valueOverrides,
      value: {
        valueEntityIds: selectedPersonIds,
      },
    });
  } else {
    emit("update", selectedPersonIds);
  }

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  await nextTick();
  setTimeout(() => closeCallback?.(), 300);
}
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <p class="block text-base text-ellipsis overflow-hidden">
        {{ t("person.personsSelected", selectedIds.length) }}
      </p>
    </template>

    <template #edit>
      <PFloatLabel v-if="!props.readonly" :variant="'on'">
        <PMultiSelect
          id="person-multiselect"
          data-testid="person-field-multiselect"
          class="w-70"
          filter
          size="small"
          :disabled="readonly"
          :filterMatchMode="'contains'"
          :maxSelectedLabels="3"
          :modelValue="selectedIds"
          :options="allPersons"
          :resetFilterOnHide="true"
          :optionLabel="(option) => option.name"
          :optionValue="(option) => option.id"
          :showToggleAll="false"
          :showClear="false"
          :virtualScrollerOptions="{ itemSize: 38 }"
          @update:modelValue="emitUpdate($event)"
        >
          <template #value>
            <div class="flex flex-row gap-2">
              <span class="mdi mdi-human-greeting"></span>
              <span class="ml-1">
                {{ t("person.personsSelected", selectedIds.length) }}
              </span>
            </div>
          </template>
        </PMultiSelect>
        <label for="person-multiselect">{{ fieldKey.name }}</label>
      </PFloatLabel>

      <ReadonlyField
        v-if="selectedIds.length > 0"
        :label="fieldKey.name ?? ''"
        class="mt-2"
      >
        <div class="flex flex-wrap gap-1">
          <PSplitButton
            v-for="person in selectedPersons"
            :key="person?.id"
            size="small"
            severity="secondary"
            dropdownIcon="mdi mdi-close"
            rounded
            data-testid="person-field-chip"
            :pt="{
              pcButton: {
                root: 'border-1! border-surface! border-r-0!',
              },
              pcDropdown: {
                root: {
                  class: '-ml-1! border-1! border-surface!',
                  onClick: () => {
                    emitUpdate(selectedIds.filter((id) => id !== person?.id));
                  },
                },
              },
            }"
          >
            <RouterLink
              class="text-start"
              :to="{
                name: 'personView',
                params: { personId: person?.id },
              }"
            >
              <span class="text-xs">{{ person?.name }}</span>
            </RouterLink>

            <template #dropdownicon>
              <i class="mdi mdi-close mr-1"></i>
            </template>
          </PSplitButton>
        </div>
      </ReadonlyField>
    </template>
  </FieldCard>
</template>

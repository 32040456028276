<script setup lang="ts">
import { computed, nextTick, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import ActivitySingleViewHeader from "@/app/activity/views/ActivitySingleViewHeader.vue";
import ActivitySingleViewInformation from "@/app/activity/views/ActivitySingleViewInformation.vue";
import ActivityInputsCard from "@/app/process/components/input/ActivityInputsCard.vue";
import ActivityOutputEditCard from "@/app/process/components/output/ActivityOutputEditCard.vue";
import ActivityTaskCard from "@/app/process/components/task/ActivityTaskCard.vue";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import useBreadcrumbs from "@/base/composables/useBreadcrumbs.ts";
import {
  type ActivityOutputEto,
  type ActivityTaskEto,
  EntityType,
} from "@/base/graphql/generated/types.ts";
import { compareBySortOrder } from "@/base/services/utils.ts";

const props = defineProps<{
  activityId: string;
  processId?: string;
}>();

const activityService = useActivityService();
const { overwriteWithLoadingState } = useBreadcrumbs();

const { t } = useI18n();
const hasFields = ref<boolean>(false);

watchEffect(() => {
  const rootActivity = activityService.getRootActivity(props.activityId);

  overwriteWithLoadingState(
    ["processTemplate"],
    rootActivity?.name,
    activityService.isLoading(props.activityId),
  );
});

const activity = computed(() => {
  const item = activityService.getActivity(props.activityId, {
    overwriteBreadcrumbs: false,
  });

  overwriteWithLoadingState(
    ["activity"],
    item?.name,
    activityService.isLoading(props.activityId),
  );

  return item;
});

const isReleased = computed(() => {
  return activityService.isReleased(props.activityId);
});

const inputs = computed(() => {
  return activityService.getInputs(props.activityId);
});

const inputOutputs = computed(() =>
  inputs.value.flatMap(
    (input) => activityService.getOutput(input.outputId) ?? [],
  ),
);

const isRootActivity = computed(() => {
  return !inputs.value.length && !activity.value?.custom;
});

const entityTypes = computed(() => {
  return isRootActivity.value
    ? [EntityType.Process, EntityType.Activity]
    : [EntityType.Activity];
});

const outputs = computed(() => {
  if (!activity.value?.id) {
    return [];
  }
  return activityService
    .getOutputs(activity.value.id)
    .filter((output) => !output.archived);
});

const tasks = computed(() => {
  if (!activity.value?.id) {
    return [];
  }
  return activityService
    .getTasks(activity.value.id)
    .filter((task) => !task.archived);
});

const sortedTasksAndOutputs = computed({
  get: () => [...tasks.value, ...outputs.value].sort(compareBySortOrder),
  set: async (newValue) => await updateSortOrder(newValue),
});

async function updateSortOrder(list: (ActivityOutputEto | ActivityTaskEto)[]) {
  if (!activity.value?.id) {
    return;
  }

  for (const taskOrOutput of list) {
    const index = list.indexOf(taskOrOutput);
    if ("type" in taskOrOutput) {
      await activityService
        .createOrUpdateOutput(
          {
            id: taskOrOutput.id,
            sortOrder: index * 10,
          },
          activity.value.id,
          true,
        )
        .catch((error) => console.error(error));
    } else {
      await activityService
        .createOrUpdateTask(
          {
            id: taskOrOutput.id,
            sortOrder: index * 10,
          },
          activity.value.id,
          true,
        )
        .catch((error) => console.error(error));
    }
    await nextTick();
  }
}
</script>

<template>
  <BaseCard
    v-if="activity !== undefined"
    class="h-full p-4!"
    flat
    noShadow
    :rounded="false"
  >
    <template #title>
      <ActivitySingleViewHeader :activity :entityTypes />
    </template>

    <div class="grid grid-cols-1 lg:grid-cols-6 gap-4 mt-2">
      <div
        :class="['space-y-4', hasFields ? 'lg:col-span-4' : 'lg:col-span-6']"
      >
        <ActivitySingleViewInformation
          :activity="activity"
          :readonly="isReleased"
        />

        <BaseAccordion
          v-if="!isRootActivity && !activity.custom && inputOutputs.length > 0"
          :label="t('processes.input', 2)"
          icon="mdi mdi-download"
        >
          <ActivityInputsCard :inputOutputs="inputOutputs" />
        </BaseAccordion>

        <BaseAccordion
          v-if="sortedTasksAndOutputs.length > 0"
          :label="t('processes.output', 2)"
          icon="mdi mdi-upload"
        >
          <div class="flex flex-col gap-4">
            <div
              v-for="taskOrOutput in sortedTasksAndOutputs.slice()"
              :key="taskOrOutput.id"
            >
              <ActivityOutputEditCard
                v-if="'type' in taskOrOutput"
                :outputId="taskOrOutput.id"
                :activityId="activity.id"
                :readonly="isReleased"
              />

              <ActivityTaskCard
                v-else
                :id="taskOrOutput.id"
                :activityId="activity.id"
                :readonly="isReleased"
              />
            </div>
          </div>
        </BaseAccordion>
      </div>

      <div v-show="hasFields" class="lg:col-span-2">
        <BaseAccordion
          :label="t('processes.field', 2)"
          :expandable="false"
          icon="mdi mdi-tag-multiple"
        >
          <EntityFieldsCard
            cards
            :entityType="entityTypes"
            :entityId="props.activityId"
            @hasFields="hasFields = $event ?? false"
          />
        </BaseAccordion>
      </div>
    </div>
  </BaseCard>
</template>

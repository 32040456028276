import { computed, type MaybeRef, reactive, toRef } from "vue";
import { type RouteLocationNormalizedLoaded, useRouter } from "vue-router";

import { type BaseBreadcrumbItem } from "@/base/components/breadcrumbs/BaseBreadcrumbs.vue";

type Route = RouteLocationNormalizedLoaded | string;

/**
 * State object of useBreadcrumbs overwrite feature
 */
const breadcrumbOverwriteMap = reactive(new Map<string, string | null>());

/**
 * Manages breadcrumb creation, customization, and retrieval for routes.
 *
 * @return {Object} An object containing the following properties and methods:
 * - breadcrumbs: A computed array of breadcrumb items for the current route.
 * - overwrite: A function to set or overwrite a breadcrumb title for a specific route.
 * - remove: A function to remove a breadcrumb overwrite for a specific route.
 */
export default function useBreadcrumbs() {
  const router = useRouter();

  const breadcrumbs = computed<BaseBreadcrumbItem[]>(createBreadcrumbs);

  function createBreadcrumbs() {
    const route = router.currentRoute.value;

    const parentRoute = router
      .getRoutes()
      .find((r) => r.name === route.meta.breadcrumbParent);

    const parentBreadcrumb: BaseBreadcrumbItem[] = parentRoute?.meta.breadcrumb
      ? [
          {
            label: parentRoute.meta.breadcrumb,
            path: router.resolve(parentRoute),
          },
        ]
      : [];

    const childBreadcrumbs: BaseBreadcrumbItem[] = route.matched
      .filter((matchedRoute) => matchedRoute.meta.breadcrumb !== undefined)
      .map((matchedRoute) => {
        return {
          label:
            get(matchedRoute.name as string) ?? matchedRoute.meta.breadcrumb,
          path: router.resolve(matchedRoute),
        };
      });

    return parentBreadcrumb.concat(childBreadcrumbs);
  }

  function overwriteWithLoadingState(
    route: Route | Route[],
    title: string | undefined,
    isLoading: MaybeRef<boolean>,
  ) {
    const loading = toRef(isLoading);

    if (loading.value && !title) {
      if (Array.isArray(route)) {
        route.forEach((item) => overwrite(item, null));
      } else {
        overwrite(route, null);
      }
    } else {
      if (title) {
        if (Array.isArray(route)) {
          route.forEach((item) => overwrite(item, title));
        } else {
          overwrite(route, title);
        }
      }
    }
  }

  function overwrite(route: Route, title: string | null) {
    if (typeof route === "string") {
      breadcrumbOverwriteMap.set(route, title);
    } else {
      if (!route.name) {
        return;
      }
      const name = route.name as string;
      breadcrumbOverwriteMap.set(name, title);
    }
  }

  function remove(route: Route) {
    if (typeof route === "string") {
      breadcrumbOverwriteMap.delete(route);
    } else {
      if (!route.name) {
        return;
      }
      const name = route.name as string;
      breadcrumbOverwriteMap.delete(name);
    }
  }

  function get(route: Route): string | undefined | null {
    if (typeof route === "string") {
      return breadcrumbOverwriteMap.get(route);
    } else {
      if (!route.name) {
        return undefined;
      }
      const name = route.name as string;
      return breadcrumbOverwriteMap.get(name);
    }
  }

  return {
    breadcrumbs,
    overwrite,
    overwriteWithLoadingState,
    remove,
  };
}

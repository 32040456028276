<script setup lang="ts">
import { computed } from "vue";

export interface BasePanelProps {
  header: string;
  color?: "default" | "none";
}

const props = withDefaults(defineProps<BasePanelProps>(), {
  color: "default",
});

const bgClass = computed(() => {
  if (!props.color || props.color === "default") {
    return "bg-surface-50!";
  }
  return "";
});
</script>

<template>
  <PPanel :header :class="[bgClass]" :pt="{ title: 'text-caeli6!' }">
    <template #header>
      <slot name="header" />
    </template>

    <slot />
  </PPanel>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { useFile } from "@/app/process/composables/useFile.ts";
import { useFileService } from "@/base/services/file/FileService.ts";
import {
  Action,
  usePromptService,
} from "@/base/services/notification/PromptService.ts";

const props = defineProps<{
  outputId?: string;
  initialFileId?: string;
  readonly?: boolean;
  noLabel?: boolean;
}>();

const { t } = useI18n();

const emits = defineEmits<{
  (event: "deleted"): void;
  (event: "uploaded", fileId: string): void;
}>();

const fileService = useFileService();
const promptService = usePromptService();
const { file } = useFile(props.initialFileId);

function uploadFile(value: File | File[]): void {
  if (value instanceof Array) {
    value = value[0];
  }

  fileService.upload(value).then(
    (fileEto) => {
      if (fileEto === undefined) {
        return;
      }
      emits("uploaded", fileEto.id);
      fileService.add(props.outputId, fileEto);
      promptService.success(Action.UPLOAD);
    },
    (reason) => promptService.failure(Action.UPLOAD, reason),
  );
}
</script>

<template>
  <div class="flex flex-row gap-4 items-center">
    <PFileUpload
      mode="basic"
      auto
      class="w-full"
      :disabled="readonly"
      :modelValue="file ? [file] : []"
      :chooseLabel="t('ui.selectFile')"
      :fileLimit="1"
      :chooseButtonProps="{
        size: 'small',
        severity: 'secondary',
      }"
      :customUpload="true"
      :pt="{
        root: 'justify-start!',
      }"
      @select="uploadFile($event.files)"
    />

    <p class="text-sm">
      {{ file?.name }}
    </p>
  </div>
</template>

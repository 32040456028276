import { type ColumnPassThroughOptions } from "primevue";

export const columnPt: Record<string, ColumnPassThroughOptions> = {
  column: {
    pcFilterAddRuleButton: {
      icon: { class: "text-primary!" },
      label: { class: "text-primary!" },
    },
  },
};

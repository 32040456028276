<script lang="ts" setup>
import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();
</script>

<template>
  <ReadonlyField
    :label="props.label"
    :value="props.fieldValue?.value?.valueString ?? ''"
  />
</template>

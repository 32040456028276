<script lang="ts" setup>
import dayjs from "dayjs";
import { computed } from "vue";

import ReadonlyField from "@/app/process/components/field/ReadonlyField.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const dateFormatted = computed(() => {
  return formatDate(props.fieldValue?.value?.valueDate ?? undefined);
});

function formatDate(dateString?: string | null) {
  if (!dateString) {
    return "";
  }
  return dayjs(dateString).format("YYYY-MM-DD");
}
</script>

<template>
  <ReadonlyField :label="props.label" :value="dateFormatted" />
</template>

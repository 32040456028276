<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useDocumentService } from "@/app/document/services/DocumentService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type EditableDataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type CellContent } from "@/base/components/filterdatatable/TableTypes.ts";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import {
  type DocumentParameterEto,
  FieldType,
} from "@/base/graphql/generated/types.ts";

const { t } = useI18n();
const documentService = useDocumentService();

const props = defineProps<{
  entityId: string;
  editable: boolean;
}>();

const i18n = {
  nameTitle: t("document.parameterTable.name"),
  descriptionTitle: t("document.parameterTable.description"),
  parameterDefinitionTitle: t("document.parameterTable.definition"),
};

const isEditable = ref<boolean>(props.editable);

const documentParameters = computed(() =>
  documentService.getParametersForDocument(props.entityId),
);

const rowItems = computed(() => {
  if (!documentParameters.value) {
    return [];
  }

  return documentParameters.value.map((parameter) => {
    const cells: Record<string, CellContent> = {
      ...createNameCell(parameter),
      ...createDescriptionCell(parameter),
      ...createParameterDefinitionCell(parameter),
    };

    return {
      key: parameter.id,
      cells,
      tags: [],
    };
  });
});

function createNameCell(parameter: DocumentParameterEto) {
  return {
    [i18n.nameTitle]: {
      content: parameter.name,
    },
  };
}

function createDescriptionCell(parameter: DocumentParameterEto) {
  return {
    [i18n.descriptionTitle]: {
      content: parameter.description ?? "",
    },
  };
}

function createParameterDefinitionCell(parameter: DocumentParameterEto) {
  return {
    [i18n.parameterDefinitionTitle]: {
      content: parameter.technicalParameter,
    },
  };
}

const mandatoryColumns: EditableDataTableColumn<DocumentParameterEto>[] = [
  {
    key: i18n.nameTitle,
    name: i18n.nameTitle,
    type: FieldType.String,
    editKey: "name",
    autofocus: true,
  },
  {
    key: i18n.descriptionTitle,
    name: i18n.descriptionTitle,
    type: FieldType.String,
    editKey: "description",
    width: "small",
    dynamicWidth: true,
  },
  {
    key: i18n.parameterDefinitionTitle,
    name: i18n.parameterDefinitionTitle,
    type: FieldType.String,
    editKey: "technicalParameter",
    dynamicWidth: true,
  },
];

const addParameter = () => {
  const documentParameterDto = {
    id: uuidv4(),
    name: "Neuer Parameteralias",
    description: "Beschreibung",
    technicalParameter: "technischer.parameter",
    documentId: props.entityId,
  };
  documentService.createOrUpdateParameter(documentParameterDto);
};

const dialog = useBaseDialog();
function showDeleteDialog(id: string | undefined) {
  const parameterName = documentParameters.value.find(
    (param) => param.id === id,
  )?.name;

  if (!parameterName) {
    return;
  }

  const deleteDialogData: DeleteDialogData = {
    deleteDescription: t("document.deleteDocumentParameterText", {
      name: parameterName,
    }),
  };
  const deleteDialogProps: DeleteDialogProps = {
    header: `${t("document.deleteDocumentParameter")}`,
    props: deleteDialogData,
    onDelete: () => {
      documentService.deleteParameter(id);
      dialogRef.close();
    },
  };

  const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
}
</script>

<template>
  <CFilteredDataTable
    class="mt-4!"
    clientSide
    :paginatorPosition="rowItems.length <= 10 ? 'bottom' : 'both'"
    :withEditor="isEditable"
    :contextKey="`${DataTableContextKeys.documentParameterList}-${props.entityId}`"
    :rowItems="rowItems"
    :mandatoryColumns="mandatoryColumns"
    :exposedColumns="mandatoryColumns"
    :availableTags="[]"
    @update:row="documentService.createOrUpdateParameter"
    @delete:row="showDeleteDialog"
  >
    <template #afterHeader>
      <BaseButton
        v-if="editable"
        data-testid="add-parameter-button"
        text
        icon="mdi mdi-plus"
        :label="
          t('action.addSomething', {
            name: t('documentGenerationRun.parameterView.table.columnName'),
          })
        "
        @click="addParameter"
      />
    </template>
  </CFilteredDataTable>
</template>

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDialog } from "primevue/usedialog";

import {
  isCreateDialog,
  isDeleteDialog,
  type ValidDialogProps,
} from "@/base/composables/dialog/dialogData.ts";

export function useBaseDialog() {
  const dialog = useDialog();

  function open<T extends ValidDialogProps>(
    dialogContent: any,
    dialogProps: T,
  ) {
    return dialog.open(dialogContent, {
      props: {
        header: dialogProps.header,
        draggable: dialogProps.draggable ?? false,
        dismissableMask: dialogProps.dismissableMask ?? true,
        modal: dialogProps.modal ?? true,
        baseZIndex: dialogProps.baseZIndex ?? 10000,
        maximizable: dialogProps.maximizable,
      },
      data: dialogProps.props,
      onClose: dialogProps.onClose,
      emits: {
        onCancel: dialogProps.onCancel,
        onCreate: isCreateDialog(dialogProps)
          ? dialogProps.onCreate
          : undefined,
        onDelete: isDeleteDialog(dialogProps)
          ? dialogProps.onDelete
          : undefined,
      },
    });
  }

  return {
    open,
  };
}

<script lang="ts" setup>
import { useStorage } from "@vueuse/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseButton from "@/base/components/button/BaseButton.vue";
import AddFieldDialog from "@/base/components/fields/AddFieldDialog.vue";
import { type EntityFieldsCardItem } from "@/base/components/fields/EntityFieldsCard.types.ts";
import EntityFieldsCardContent from "@/base/components/fields/EntityFieldsCardContent.vue";
import {
  type AddFieldDialogData,
  type BaseDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import {
  type EntityType,
  type FieldKeyEto,
  type FieldKeyGroupEto,
  FieldType,
  type FieldValueEto,
} from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { StorageKeys } from "@/config.ts";

export interface EntityFieldsCardProps {
  entityId: string;
  ancestorIds?: string[];
  entityType: EntityType[];
  readonly?: boolean;
  cards?: boolean;
  grouped?: boolean;
  edit?: boolean;
}

const props = defineProps<EntityFieldsCardProps>();
const emits = defineEmits<{
  hasFields: [hasFields: boolean];
}>();

const closedGroupIds = useStorage<string[]>(
  StorageKeys.process.closedGroupIds.key,
  [],
);
const { t } = useI18n();
const fieldService = useFieldService();
const dialog = useBaseDialog();

const ungroupedGroupId = "dbe3815b-f13d-46bb-b719-742756094f64";
const groupIds = ref<string[]>([]);
const openedGroups = ref<string[]>([]);

const fields = computed(() => {
  const valueFieldKeysMap = new Map<FieldKeyEto, FieldValueEto[]>();

  [...(props.ancestorIds ?? []), props.entityId].forEach((entityId) => {
    const fieldKeysWithValues = fieldService.getFieldKeysWithValues(entityId);

    fieldKeysWithValues.forEach((value, key) => {
      if (key.type === FieldType.Tag) {
        return;
      }

      valueFieldKeysMap.set(key, value);
    });
  });

  const uneditableKeys = Array.from(valueFieldKeysMap.keys()).filter(
    (key) => !key.entityTypes.some((et) => props.entityType.includes(et)),
  );

  const groupedResults = new Map<
    string,
    { group: FieldKeyGroupEto; items: EntityFieldsCardItem[] }
  >();

  const ungroupedGroup: FieldKeyGroupEto = {
    id: ungroupedGroupId,
    name: t("processes.singleView.ungroupedFieldsTitle"),
  };

  valueFieldKeysMap.forEach((fieldValues, fieldKey) => {
    fieldValues.forEach((fieldValue) => {
      const group = fieldKey.group ?? ungroupedGroup;
      const groupId = group.id;

      if (!groupedResults.has(groupId)) {
        groupedResults.set(groupId, { group, items: [] });
      }

      groupedResults.get(groupId)?.items.push({
        key: fieldKey,
        value: fieldValue,
        readonly: uneditableKeys.includes(fieldKey),
      });
    });

    groupIds.value = Array.from(groupedResults.keys());
    openedGroups.value = Array.from(groupedResults.keys()).filter(
      (groupId) => !closedGroupIds.value.includes(groupId),
    );
  });

  const compareByFieldType = (aType: FieldType, bType: FieldType): number => {
    const typeOrder = [FieldType.Number, FieldType.Person, FieldType.String];
    return typeOrder.indexOf(aType) - typeOrder.indexOf(bType);
  };

  const compareByTypeAndReadOnly = (
    a: EntityFieldsCardItem,
    b: EntityFieldsCardItem,
  ): number => {
    const typeComparison = compareByFieldType(
      a.key.type ?? FieldType.String,
      b.key.type ?? FieldType.String,
    );
    if (typeComparison !== 0) {
      return typeComparison;
    }

    if (a.readonly === b.readonly) {
      return (a.key.name ?? "").localeCompare(b.key.name ?? "");
    }

    return a.readonly ? -1 : 1;
  };

  groupedResults.forEach((group) => {
    group.items.sort(compareByTypeAndReadOnly);
  });

  const finalResults = new Map<FieldKeyGroupEto, EntityFieldsCardItem[]>();
  if (groupedResults.has(ungroupedGroupId)) {
    finalResults.set(
      ungroupedGroup,
      groupedResults.get(ungroupedGroupId)?.items ?? [],
    );
    groupedResults.delete(ungroupedGroupId);
  }

  groupedResults.forEach((group) => {
    finalResults.set(group.group, group.items);
  });

  emits("hasFields", finalResults.size > 0);

  return Array.from(finalResults.entries()).reverse();
});

function onPanelChanged(openGroupIds: string | string[] | null | undefined) {
  if (openGroupIds == null) {
    closedGroupIds.value = groupIds.value;
    return;
  }

  const normalizedOpenGroupIds = Array.isArray(openGroupIds)
    ? openGroupIds
    : [openGroupIds];
  closedGroupIds.value = groupIds.value.filter(
    (groupId) => !normalizedOpenGroupIds.includes(groupId),
  );
}

function showAddFieldDialog(entityId: string, entityType: EntityType[]) {
  const addFieldDialogData: AddFieldDialogData = { entityId, entityType };

  const showAddFieldDialogProps: BaseDialogProps = {
    header: `${t("action.addSomething", { name: t("processes.field") })}`,
    props: addFieldDialogData,
  };
  dialog.open(AddFieldDialog, showAddFieldDialogProps);
}
</script>

<template>
  <div v-if="fields.length > 0">
    <div v-if="!grouped">
      <EntityFieldsCardContent
        :entityId="props.entityId"
        :items="fields.map(([_key, value]) => [...value.values()]).flat()"
        :readonly="props.readonly"
        :cards="props.cards"
        :edit="false"
      />
    </div>

    <div v-else class="flex flex-col gap-4 justify-between">
      <PAccordion
        :value="openedGroups"
        multiple
        :pt="{ root: 'flex! flex-col! gap-4!' }"
        @update:value="onPanelChanged"
      >
        <PAccordionPanel
          v-for="[group, items] in fields"
          :key="group.id"
          :value="group.id"
          :pt="{
            root: {
              class: 'overflow-hidden! border-[0.1rem]! rounded-md! mt-2!',
            },
          }"
        >
          <PAccordionHeader
            :pt="{
              root: {
                class: 'bg-surface-100! border-b-[0.1rem]! h-auto! p-2!',
              },
            }"
          >
            <p class="text-caeli6">
              {{ group.name }}
            </p>
          </PAccordionHeader>

          <PAccordionContent>
            <EntityFieldsCardContent
              class="mt-4"
              :entityId="entityId"
              :items="items"
              :readonly="readonly"
              :cards="cards"
              :edit="edit"
            />
          </PAccordionContent>
        </PAccordionPanel>
      </PAccordion>
    </div>
  </div>

  <div v-if="!props.readonly && !cards">
    <BaseButton
      outlined
      icon="mdi mdi-plus"
      data-testid="entity-add-field"
      :label="t('action.addSomething', { name: t('processes.field') })"
      @click="showAddFieldDialog(entityId, entityType)"
    />
  </div>
</template>

<script setup lang="ts">
import { loadDevMessages } from "@apollo/client/dev";
import { BrowserAuthError, BrowserAuthErrorCodes } from "@azure/msal-browser";
import { usePrimeVue } from "primevue";
import { onErrorCaptured, watch } from "vue";
import { useI18n } from "vue-i18n";
import { RouterView, useRouter } from "vue-router";

import BaseConfirmDialog from "@/base/components/dialog/BaseConfirmDialog.vue";
import BaseDynamicDialog from "@/base/components/dialog/BaseDynamicDialog.vue";
import ErrorDialog from "@/base/components/dialog/ErrorDialog.vue";
import EntityHistoryDrawer from "@/base/components/drawer/entityevent/EntityHistoryDrawer.vue";
import EnvironmentIndicator from "@/base/components/header/EnvironmentIndicator.vue";
import BasePage from "@/base/components/page/BasePage.vue";
import BaseToast from "@/base/components/toast/BaseToast.vue";
import { type BaseDialogProps } from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { ApiError } from "@/base/services/backend/core/ApiError";

const { t } = useI18n();
const router = useRouter();
const primevue = usePrimeVue();
const dialog = useBaseDialog();

function showErrorDialog(error: Error) {
  const dialogProps: BaseDialogProps = {
    header: t("error.title"),
    props: { capturedError: error },
  };

  dialog.open(ErrorDialog, dialogProps);
}

onErrorCaptured((capturedError, _instance, info) => {
  console.error("Error occurred from", info, "\n", capturedError);

  //if error is an ApiError, go to ErrorPage
  if (capturedError instanceof ApiError) {
    const href = router.resolve({
      path: "/error",
      query: { error: capturedError.status, message: capturedError.message },
    });
    void router.push(href);
  } else if (
    capturedError instanceof BrowserAuthError &&
    capturedError.errorCode === BrowserAuthErrorCodes.interactionInProgress
  ) {
    // TODO: For now we ignore this error in the dialog but we should reimplement the login flow
    router.go(0);
    return;
  } else {
    showErrorDialog(capturedError);
  }
});

if (!import.meta.env.PROD) {
  loadDevMessages();
}

watch(
  () => primevue.config.locale,
  () => {
    if (!primevue.config.locale) {
      return;
    }
    primevue.config.locale.addRule = t("table.filter.addRule");
    primevue.config.locale.removeRule = t("table.filter.removeRule");
    primevue.config.locale.clear = t("table.filter.clear");
    primevue.config.locale.apply = t("table.filter.apply");
    primevue.config.locale.contains = t("table.filter.contains");
    primevue.config.locale.notContains = t("table.filter.notContains");
    primevue.config.locale.startsWith = t("table.filter.startsWith");
    primevue.config.locale.endsWith = t("table.filter.endsWith");
    primevue.config.locale.matchAll = t("table.filter.matchAll");
    primevue.config.locale.matchAny = t("table.filter.matchAny");
    primevue.config.locale.equals = t("table.filter.equals");
    primevue.config.locale.notEquals = t("table.filter.notEquals");
    primevue.config.locale.emptyMessage = t("table.emptySelection");
  },
  { immediate: true },
);
</script>

<template>
  <BaseToast />
  <BaseDynamicDialog />
  <BaseConfirmDialog />

  <EntityHistoryDrawer />

  <EnvironmentIndicator />

  <BasePage>
    <RouterView />
  </BasePage>
</template>

<script setup lang="ts">
import MessageListView from "@/app/message/views/MessageListView.vue";
import MessageSingleEditView from "@/app/message/views/MessageSingleEditView.vue";
import MessageView from "@/app/message/views/MessageView.vue";
</script>

<template>
  <RouterView v-slot="{ route }">
    <MessageListView v-if="route.name === 'messageList'" />
    <MessageView
      v-else-if="route.name === 'messageView'"
      :messageId="route.params.messageId as string"
    />
    <MessageSingleEditView
      v-else-if="route.name === 'messageEdit'"
      :messageId="route.params.messageId as string"
    />
  </RouterView>
</template>

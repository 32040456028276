import { useTitle as useVueUseTitle } from "@vueuse/core";
import { computed, type MaybeRef, toRef } from "vue";
import { useI18n } from "vue-i18n";

import config from "@/config.ts";

export enum TitleMode {
  EDIT,
}

export interface UseTitleParams {
  titleMode: TitleMode;
}

export function useTitle(input?: MaybeRef<string>, params?: UseTitleParams) {
  const { t } = useI18n();
  const inputRef = toRef(input);
  const computedTitle = computed(() => {
    return `${getPrefix()}${inputRef.value} | ${getAppTitle()}`;
  });

  function getAppTitle() {
    const appTitle = t("app.title");
    const environment = config.envIndicator;

    if (
      !environment ||
      environment.length === 0 ||
      environment.toLowerCase() === "prod"
    ) {
      return appTitle;
    }

    return `${appTitle} [${config.envIndicator}]`;
  }

  function getPrefix(): string {
    if (params?.titleMode === TitleMode.EDIT) {
      return "✏️ ";
    }
    return "";
  }

  return useVueUseTitle(input && computedTitle, { observe: true });
}

import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { reactive, ref } from "vue";

import { type FileEto } from "@/base/graphql/generated/types.ts";
import { FileControllerService } from "@/base/services/backend/services/FileControllerService.ts";

export const useFileService = defineStore("files", () => {
  const fileLookup = reactive(new Map<string, File>());
  const isLoading = ref<boolean>(false);

  async function fetch(id?: string, fileId?: string | null) {
    if (!id || !fileId) {
      return;
    }

    try {
      add(id, await FileControllerService.getFileMetadata(fileId));
    } catch {
      return;
    }
  }

  function add(id: string | undefined, fileEto: FileEto): void {
    if (!id) {
      return;
    }
    const file = new File([], fileEto.name ?? "file", {
      type: fileEto.contentType ?? "application/octet-stream",
    });

    fileLookup.set(id, file);
  }

  function getById(id: string): File | undefined {
    return fileLookup.get(id);
  }

  async function upload(file: File | undefined): Promise<FileEto | undefined> {
    if (!file) {
      return undefined;
    }

    try {
      isLoading.value = true;
      const fileId: string = uuidv4();

      if (file.size === 0) {
        return undefined;
      }

      const fileEto = await FileControllerService.upload(fileId, file);
      console.debug("File upload successful", fileEto);
      return fileEto;
    } catch (e) {
      console.error("File upload failed", file, e);
      return undefined;
    } finally {
      isLoading.value = false;
    }
  }

  async function downloadMultiple(ids: string[]): Promise<void> {
    if (!ids.length) {
      return Promise.reject(
        new Error("No files to download. Please select at least one file."),
      );
    }

    try {
      isLoading.value = true;

      const [metadata, zipFileBlob] = await Promise.all([
        FileControllerService.getFileMetadata(ids[0]),
        FileControllerService.downloadMultiple(ids),
      ]);

      if (!metadata.name) {
        return Promise.reject(new Error("Metadata not found."));
      }

      const a = document.createElement("a");
      a.href = URL.createObjectURL(zipFileBlob);
      a.download = `${metadata.name.split(".")[0]}.zip`;
      a.click();
      return Promise.resolve();
    } catch (e) {
      console.error("File download failed", e);
      return Promise.reject(new Error());
    } finally {
      isLoading.value = false;
    }
  }

  async function download(id: string): Promise<void> {
    if (id.length === 0) {
      return Promise.reject(new Error("No file to download."));
    }

    try {
      isLoading.value = true;

      const metadata = await FileControllerService.getFileMetadata(id);
      const file = await FileControllerService.download(id);

      if (!metadata.contentType || !metadata.name) {
        return Promise.reject(new Error("Metadata not found."));
      }

      const a = document.createElement("a");
      a.href = URL.createObjectURL(
        new Blob([file], {
          type: metadata.contentType,
        }),
      );
      a.download = metadata.name;
      a.click();
      return Promise.resolve();
    } catch (e) {
      console.error("File download failed", e);
      return Promise.reject(new Error());
    } finally {
      isLoading.value = false;
    }
  }

  async function loadFileAsObjectUrl(id: string): Promise<string> {
    if (id.length === 0) {
      return Promise.reject(new Error("File id must not be empty"));
    }

    try {
      const metadata = await FileControllerService.getFileMetadata(id);
      const file = await FileControllerService.download(id);
      if (!metadata.contentType || !metadata.name) {
        return Promise.reject(new Error("File metadata is missing"));
      }

      return URL.createObjectURL(
        new Blob([file], {
          type: metadata.contentType,
        }),
      );
    } finally {
      isLoading.value = false;
    }
  }

  function removeById(id: string): void {
    try {
      isLoading.value = true;
      fileLookup.delete(id);
    } catch (e) {
      console.error("File remove failed", e);
      return;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    fetch,
    upload,
    download,
    loadFileAsObjectUrl,
    downloadMultiple,
    removeById,
    getById,
    add,
  };
});

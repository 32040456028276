<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import TextEditor from "@/base/components/form/TextEditor.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type ActivityEto } from "@/base/graphql/generated/types.ts";

defineProps<{
  activity: ActivityEto;
  readonly?: boolean;
}>();

const { t } = useI18n();
const activityService = useActivityService();
</script>

<template>
  <BaseAccordion
    v-if="activity.description || activity.goal || !readonly"
    :label="t('processes.singleView.information')"
    icon="mdi mdi-information-outline"
  >
    <div class="flex flex-col gap-4">
      <TextEditor
        v-if="!readonly"
        :containerId="activity.id"
        :previousContent="activity.description ?? ''"
        :label="t('processes.singleView.descriptionLabel')"
        data-testid="activity-single-view-description"
        @saveContent="
          (content: string) =>
            activityService.createOrUpdateActivity({
              id: activity.id,
              description: content,
            })
        "
      />

      <TextDisplay
        v-else-if="activity.description"
        :label="t('processes.singleView.descriptionLabel')"
        :value="activity.description ?? ''"
        markdown
      />

      <BaseTextField
        v-if="!readonly"
        data-testid="activity-single-view-goal"
        :initialValue="activity.goal ?? undefined"
        :label="t('processes.singleView.goalLabel')"
        @update="
          (goal: string) =>
            activityService.createOrUpdateActivity({
              id: activity.id,
              goal,
            })
        "
      />

      <TextDisplay
        v-else-if="activity.goal"
        :value="activity.goal ?? ''"
        :label="t('processes.singleView.goalLabel')"
      />
    </div>
  </BaseAccordion>
</template>

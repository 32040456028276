<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useDocumentService } from "@/app/document/services/DocumentService";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import { useTitle } from "@/base/composables/useTitle";

const { t } = useI18n();
const router = useRouter();
const documentService = useDocumentService();

useTitle(t("document.document", 2));

const filters = ref({});

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "create-document",
    label: t("document.create"),
    icon: "mdi mdi-plus",
    command: createDocumentClicked,
  },
]);

const treeNodes = computed(() => documentService.getTreeNodes());

const isLoading = computed(() => documentService.isLoading());

const expandedKeys = ref({});
const expandTreeNodeIfDocumentFound = (treeNode, document): boolean => {
  if (treeNode.key === document.id) {
    expandedKeys.value[treeNode.key] = true;
    return true;
  } else {
    const childExpanded = treeNode.children.some((child) =>
      expandTreeNodeIfDocumentFound(child, document),
    );
    if (childExpanded) {
      expandedKeys.value[treeNode.key] = true;
    }
    return childExpanded;
  }
};

watch(
  computed(() => documentService.documentVariantGenerationResult),
  (value) => {
    if (value.document) {
      treeNodes.value.forEach((node) =>
        expandTreeNodeIfDocumentFound(node, value.document),
      );
      delete value.document;
    }
  },
);

const initialColumns = [
  {
    key: "name",
    fieldName: "name",
    expander: true,
    displayHeader: t("document.name"),
  },
  {
    key: "entityType",
    fieldName: "entityType",
    expander: false,
    displayHeader: t("document.entityType"),
  },
];

const createDocumentClicked = async () => {
  await router.push({
    name: "documentEdit",
    params: { documentId: uuidv4() },
  });
};
</script>

<template>
  <BaseCard class="h-full p-4!" flat noShadow :rounded="false">
    <template #title>
      <div class="flex flex-row justify-between items-start">
        <PIconField>
          <PInputIcon class="mdi mdi-magnify" />
          <PInputText
            data-testid="full-text-search"
            size="small"
            fluid
            :placeholder="t('table.search')"
            @valueChange="filters['global'] = $event"
          />
        </PIconField>

        <BaseActionsButton :model="actionsMenuItems" />
      </div>
    </template>

    <div class="h-1 scale-y-10">
      <PProgressbar v-if="isLoading" mode="indeterminate" />
    </div>

    <PTreeTable
      v-model:expandedKeys="expandedKeys"
      rowHover
      size="small"
      selectionMode="single"
      sortMode="single"
      sortField="name"
      :sortOrder="1"
      removableSort
      :filters="filters"
      :value="treeNodes"
    >
      <PColumn
        v-for="col of initialColumns"
        :key="col.key"
        :sortable="true"
        :expander="col.expander"
        :field="col.fieldName"
        :pt="{
          columnHeaderContent: {
            class: 'h-6 text-[#3C707B] text-nowrap',
          },
          columnTitle: { class: 'grow' },
        }"
      >
        <template #header>
          <p class="pl-1 font-bold text-sm">
            {{ col.displayHeader }}
          </p>
        </template>

        <template #body="{ node }">
          <RouterLink
            class="w-full"
            :to="{ name: 'documentView', params: { documentId: node.key } }"
          >
            <p class="text-sm cursor-pointer">
              {{ node.data[col.fieldName] }}
            </p>
          </RouterLink>
        </template>
      </PColumn>
    </PTreeTable>
  </BaseCard>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useMessageServiceV2 } from "@/app/message/services/MessageServiceV2.ts";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import { useTitle } from "@/base/composables/useTitle.ts";
import { SortDirection } from "@/base/graphql/generated/types.ts";

const { t } = useI18n();
const messageService = useMessageServiceV2();

useTitle(t("message.message", 2));

const { availableTags, displayColumns, listRowItems, isLoading } =
  storeToRefs(messageService);

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "create-message",
    label: t("message.create"),
    icon: "mdi mdi-plus",
    command: async () => {
      await messageService.createOrUpdate(
        { id: uuidv4(), title: "Neu" },
        { navigateToEditView: true },
      );
    },
  },
]);
</script>

<template>
  <CFilteredDataTable
    class="p-4!"
    stripedRows
    :contextKey="DataTableContextKeys.messageList"
    :sortField="messageService.mandatoryColumns[0].key"
    :sortDirection="SortDirection.Desc"
    :rowItems="listRowItems"
    :availableTags="availableTags"
    :exposedColumns="displayColumns"
    :mandatoryColumns="messageService.mandatoryColumns"
    :isLoading="isLoading"
  >
    <template #afterHeader>
      <BaseActionsButton :model="actionsMenuItems" />
    </template>
  </CFilteredDataTable>
</template>

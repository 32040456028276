<script setup lang="ts">
import { useI18n } from "vue-i18n";

import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { type ProcessActivityEto } from "@/base/graphql/generated/types.ts";

defineProps<{
  processActivity: ProcessActivityEto;
}>();

const { t } = useI18n();
</script>

<template>
  <div v-if="processActivity.activity.description" class="flex flex-col gap-6">
    <BaseAccordion
      :label="t('processes.singleView.information')"
      icon="mdi mdi-information-outline"
    >
      <div class="flex flex-col gap-4">
        <div
          v-if="processActivity.activity.goal"
          class="flex flex-col gap-1 text-sm"
        >
          <p class="font-bold">{{ t("processes.singleView.goalLabel") }}:</p>
          <p>
            {{ processActivity.activity.goal }}
          </p>
        </div>

        <TextDisplay
          markdown
          :label="`${t('processes.singleView.descriptionLabel')}:`"
          :value="processActivity.activity.description ?? ''"
        />
      </div>
    </BaseAccordion>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type FieldKeyDto } from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";

const props = defineProps<{ fieldKey: FieldKeyDto }>();
const emits = defineEmits<(e: "delete", id: string) => void>();

const fieldService = useFieldService();

const editName = ref<string | undefined>(props.fieldKey.name ?? undefined);
</script>

<template>
  <div class="flex items-center" data-testid="rename-tag-entry-enclosing-div">
    <BaseTextField
      class="grow"
      :initialValue="editName"
      @update="
        (value) =>
          fieldService.createOrUpdateFieldKey({
            id: props.fieldKey.id,
            name: value,
          })
      "
    />

    <BaseButton
      icon="mdi mdi-delete-outline"
      text
      severity="danger"
      :data-testid="`rename-tag-entry-delete-tag-${editName}`"
      @click="() => emits('delete', props.fieldKey.id)"
    />
  </div>
</template>

<script setup lang="ts">
import { type EdgeProps } from "@vue-flow/core";
import { BezierEdge } from "@vue-flow/core";

interface EdgeData {
  isDone: boolean;
}

defineProps<EdgeProps<EdgeData>>();
</script>

<template>
  <BezierEdge
    :sourceX="sourceX"
    :sourceY="sourceY"
    :targetX="targetX"
    :targetY="targetY"
    :sourcePosition="sourcePosition"
    :targetPosition="targetPosition"
    :style="{
      stroke: data.isDone ? 'rgb(var(--color-caeli5))' : 'gray',
      strokeWidth: '0.15rem',
    }"
  />
</template>

<script setup lang="ts">
/* eslint-disable */
import type {
  AutoCompleteCompleteEvent,
  AutoCompleteOptionSelectEvent,
  AutoCompletePassThroughOptions,
  VirtualScrollerProps,
} from "primevue";
import { ref, toRaw, useId } from "vue";
import { useI18n } from "vue-i18n";

export interface BaseAutoCompleteProps {
  options: any[];
  dropdown?: boolean;
  optionLabel?: string;
  pt?: AutoCompletePassThroughOptions;
  fluid?: boolean;
  placeholder?: string;
  size?: "small" | "large";
  dropdownMode?: "blank" | "current";
  disabled?: boolean;
  loading?: boolean;
  virtualScrollerOptions?: VirtualScrollerProps;
  multiple?: boolean;
}

export interface BaseAutoCompleteEmits {
  optionSelect: [value: any];
}

const props = withDefaults(defineProps<BaseAutoCompleteProps>(), {
  dropdown: true,
  optionLabel: undefined,
  pt: undefined,
  fluid: false,
  placeholder: undefined,
  size: "small",
  dropdownMode: "blank",
  disabled: false,
  loading: false,
  multiple: false,
});

const emits = defineEmits<BaseAutoCompleteEmits>();

const model = defineModel<any>();

const { t } = useI18n();
const id = useId();

const filteredItems = ref<any[]>([]);

function search(event: AutoCompleteCompleteEvent) {
  if (event.query.trim().length > 0) {
    filteredItems.value = props.options.filter((item) => {
      if (props.optionLabel) {
        return item[props.optionLabel]
          ?.toLocaleLowerCase()
          .includes(event.query.toLocaleLowerCase());
      }
      return item
        ?.toLocaleLowerCase()
        .includes(event.query.toLocaleLowerCase());
    });
  } else {
    filteredItems.value = [...props.options];
  }
}

function optionSelect(event: AutoCompleteOptionSelectEvent) {
  if (!event.value) {
    return;
  }
  emits("optionSelect", toRaw(event.value));
}

function setOptionLabel(option: any): string | undefined {
  if (!props.optionLabel) {
    return option;
  }
  return option[props.optionLabel];
}
</script>

<template>
  <PFloatLabel variant="on">
    <PAutoComplete
      v-model="model"
      :id
      completeOnFocus
      forceSelection
      autoOptionFocus
      :multiple="multiple"
      :size="size"
      :optionLabel="setOptionLabel"
      :suggestions="filteredItems"
      :dropdown="dropdown"
      :pt="pt"
      :fluid="fluid"
      :disabled="disabled"
      :dropdownMode="dropdownMode"
      :loading="loading"
      :virtualScrollerOptions="virtualScrollerOptions"
      @focus="search({ query: '', originalEvent: $event })"
      @complete="search"
      @optionSelect="optionSelect"
    >
      <template #empty>
        <slot name="empty">
          <span>{{ t("ui.noData") }}</span>
        </slot>
      </template>
    </PAutoComplete>

    <label
      class="text-sm"
      :class="disabled ? 'bg-transparent text-surface-400!' : ''"
      :for="id"
      >{{ placeholder }}</label
    >
  </PFloatLabel>
</template>

<style scoped></style>

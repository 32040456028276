<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { useDialogService } from "@/base/services/DialogService.ts";

const props = defineProps<{
  activityId: string;
  isRoot?: boolean;
}>();

const { t } = useI18n();

const dialog = useBaseDialog();
const router = useRouter();
const activityService = useActivityService();
const dialogService = useDialogService();

const activity = computed(() => activityService.getActivity(props.activityId));
const outputs = computed(() => activityService.getOutputs(props.activityId));
const isActivityReleased = computed(() =>
  activityService.isReleased(props.activityId),
);
const emits = defineEmits<(event: "beforeDelete", ids: string[]) => void>();

function deleteActivity() {
  if (activity.value === undefined) {
    return;
  }

  emits("beforeDelete", [activity.value.id]);

  dialogService.deleteNextActivity([activity.value]);
}

function showDeleteDialog() {
  if (activity?.value !== undefined) {
    const deleteDialogData: DeleteDialogData = {
      deleteDescription: t("processes.listView.deleteActivityText", {
        name: activity.value.name,
      }),
    };

    const deleteDialogProps: DeleteDialogProps = {
      header: t("processes.listView.deleteActivity"),
      props: deleteDialogData,
      onDelete: async () => {
        deleteActivity();
        if (props.isRoot) {
          await router.push({ name: "processTemplateList" });
        }
        dialogRef.close();
      },
    };
    const dialogRef = dialog.open(DeleteDialog, deleteDialogProps);
  }
}

async function extend() {
  try {
    const newActivityId = await activityService.extendActivity(
      props.activityId,
    );
    await router.push({
      name: "activity",
      params: {
        activityId: newActivityId,
      },
    });
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <div v-if="!isActivityReleased" class="flex flex-row items-center gap-2 h-4">
    <BaseButton
      v-if="outputs.length > 0"
      v-tooltip.left="t('processes.listView.addActivity')"
      data-testid="add-activity-button"
      icon="mdi mdi-plus-box-multiple-outline"
      text
      severity="primary"
      @click.stop.prevent="extend"
    />

    <BaseButton
      v-tooltip.left="t('action.delete')"
      data-testid="delete-activity-button"
      icon="mdi mdi-delete"
      text
      severity="danger"
      @click.stop.prevent="() => (activity ? showDeleteDialog() : undefined)"
    />
  </div>
</template>

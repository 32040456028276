<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { v4 as uuidv4 } from "uuid";
import { computed, type ComputedRef, h, inject, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import type { DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import { type RowItem } from "@/base/components/filterdatatable/TableTypes.ts";
import type { AddFieldDialogData } from "@/base/composables/dialog/dialogData.ts";
import {
  type FieldKeyDto,
  FieldType,
  type FieldValueEto,
  SortDirection,
} from "@/base/graphql/generated/types.ts";
import { translateEnum } from "@/base/i18n/i18n.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { uniqueById } from "@/base/services/utils.ts";

const { t } = useI18n();
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: AddFieldDialogData = dialogRef?.value.data;

const fieldService = useFieldService();

const columnNames = {
  referenceName: t("fields.referenceName"),
  referenceType: t("fields.referenceType"),
  referenceUnique: t("fields.referenceUnique"),
};

const columnValueTranslation = {
  booleanYes: t("boolean.yes"),
  booleanNo: "-",
};

function addFieldValue(fieldKey: FieldKeyDto) {
  const eto: FieldValueEto = {
    id: uuidv4(),
    entityId: props.entityId,
    fieldKeyId: fieldKey.id,
  };
  fieldService.createOrUpdateFieldValue(eto);
  dialogRef?.value.close();
}

const rowItems: ComputedRef<RowItem[]> = computed(() => {
  const keysForAllEntityTypes = props.entityType
    .flatMap((type) => fieldService.getNonTagFieldKeys(type))
    .filter(uniqueById);

  function isUniqueKeyAlreadyUsed(fieldKey: FieldKeyDto) {
    return fieldService.isUniqueKeyAlreadySet(fieldKey, props.entityId);
  }

  return keysForAllEntityTypes.map(
    (field) =>
      ({
        cells: {
          [columnNames.referenceName]: {
            content: field.name,
          },
          [columnNames.referenceUnique]: {
            content: field.unique
              ? columnValueTranslation.booleanYes
              : columnValueTranslation.booleanNo,
          },
          [columnNames.referenceType]: {
            content: field.type?.toString() ?? "",
            component: h(
              "p",
              {},
              translateEnum("processes.singleView.outputTypes", field.type),
            ),
          },
          id: {
            content: field.id,
          },
        },
        style: {
          cursor: "pointer",
          pointerEvents: isUniqueKeyAlreadyUsed(field) ? "none" : "inherit",
          opacity: isUniqueKeyAlreadyUsed(field) ? "0.5" : "1",
        },
        tags: [],
        key: field.id,
        events: {
          click: () => {
            addFieldValue(field);
          },
        },
      }) as RowItem,
  );
});

const displayColumns: DataTableColumn[] = [
  {
    name: columnNames.referenceName,
    key: columnNames.referenceName,
    type: FieldType.String,
  },
  {
    name: columnNames.referenceUnique,
    key: columnNames.referenceUnique,
    type: FieldType.Boolean,
    width: "small",
  },
  {
    name: columnNames.referenceType,
    key: columnNames.referenceType,
    type: FieldType.String,
    width: "small",
    options: Object.entries(FieldType).map(([key, value]) => ({
      id: key,
      value: translateEnum("processes.singleView.outputTypes", value),
    })),
  },
];
</script>

<template>
  <BaseAppDialog data-testid="field-dialog">
    <template #content>
      <CFilteredDataTable
        clientSide
        paginatorPosition="bottom"
        :sortField="columnNames.referenceName"
        :sortDirection="SortDirection.Asc"
        :exposedColumns="displayColumns"
        :mandatoryColumns="displayColumns"
        :contextKey="DataTableContextKeys.fieldDialog"
        :rowItems="rowItems"
        :availableTags="[]"
        :sortBy="[{ key: columnNames.referenceName, order: 'asc' }]"
      />
    </template>
  </BaseAppDialog>
</template>

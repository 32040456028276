<script setup lang="ts">
import { useTemplateRef } from "vue";

import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseMessage from "@/base/components/message/BaseMessage.vue";
import BaseOverlayBadge from "@/base/components/overlaybadge/BaseOverlayBadge.vue";
import BasePopover from "@/base/components/popover/BasePopover.vue";
import { useBaseToast } from "@/base/composables/notification/useBaseToast.ts";
import { usePromptService } from "@/base/services/notification/PromptService.ts";

const promptService = usePromptService();
const toast = useBaseToast();
const notificationPopoverRef = useTemplateRef("notificationPopoverRef");
</script>

<template>
  <BaseOverlayBadge
    size="small"
    :value="promptService.prompts.length.toString()"
    :severity="
      promptService.severity === 'error' ? 'danger' : promptService.severity
    "
    :pt="{
      root: {
        class: [
          'text-xs! font-mono! font-thin! mt-2! scale-80!',
          { 'invisible!': promptService.prompts.length === 0 },
        ],
      },
    }"
  >
    <BaseButton
      :disabled="promptService.prompts.length === 0"
      icon="mdi mdi-bell"
      text
      severity="secondary"
      data-testid="prompt-list-button"
      :pt="{
        pcBadge: {
          root: {
            class: 'text-xs! font-mono! font-thin!',
          },
        },
      }"
      @click="notificationPopoverRef?.toggle($event)"
    />

    <BasePopover
      ref="notificationPopoverRef"
      class="min-w-xl max-w-xl max-h-96 overflow-x-hidden"
    >
      <div class="flex flex-col">
        <div class="flex flex-row justify-end items-center mb-1">
          <BaseButton
            data-testid="prompt-list-button-clear-all"
            :disabled="promptService.prompts.length === 0"
            icon="mdi mdi-delete-outline"
            variant="text"
            severity="danger"
            @click="
              () => {
                notificationPopoverRef?.hide();
                toast.clearAll();
                promptService.clearAll();
              }
            "
          />
        </div>
      </div>

      <div
        v-for="prompt in [...promptService.prompts].reverse()"
        :key="prompt.id"
      >
        <BaseMessage
          class="mb-2 w-full"
          :severity="prompt.severity"
          :closable="true"
          @close="
            () => {
              promptService.deleteById(prompt.id);
              if (promptService.prompts.length === 0) {
                notificationPopoverRef?.hide();
              }
            }
          "
        >
          <div class="text-sm font-bold break-words">
            {{ prompt.summary }}
          </div>
          <div class="text-sm break-words">
            {{ prompt.detail }}
          </div>
        </BaseMessage>
      </div>
    </BasePopover>
  </BaseOverlayBadge>
</template>

<script lang="ts" setup>
import FieldCard from "@/app/process/components/field/FieldCard.vue";
import ObjectField from "@/app/process/components/field/ObjectField.vue";
import {
  type FieldKeyEto,
  type FieldValueDto,
} from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  readonly?: boolean;
  isInherited?: boolean;
  edit?: boolean;
}>();

const emits = defineEmits<(e: "update", value: FieldValueDto) => void>();
</script>

<template>
  <FieldCard v-bind="props">
    <template #display>
      <p class="max-h-12 block text-base text-ellipsis overflow-hidden">
        <ObjectField
          data-testid="json-field-input"
          class="pointer-events-auto! w-fit!"
          :initialValue="props.fieldValue?.value?.valueJson ?? undefined"
          :readonly="true"
        />
      </p>
    </template>

    <template #edit="{ closeCallback }">
      <ObjectField
        class="-mb-2!"
        data-testid="json-field-input"
        :initialValue="props.fieldValue?.value?.valueJson ?? undefined"
        @update="
          (value) => {
            emits('update', {
              ...props.fieldValue,
              value: { valueJson: value },
            });
            if (!edit) {
              closeCallback();
            }
          }
        "
      />
    </template>
  </FieldCard>
</template>

<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { inject, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import type { ErrorDialogData } from "@/base/composables/dialog/dialogData.ts";

const { t } = useI18n();
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: ErrorDialogData = dialogRef?.value.data;
</script>

<template>
  <BaseAppDialog>
    <template #content>
      <div>
        <p>
          {{ t("error.intro") }}
        </p>
        <p>
          {{ props.capturedError }}
        </p>
      </div>
    </template>
  </BaseAppDialog>
</template>

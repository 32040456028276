import { type RouteRecordRaw } from "vue-router";

import PersonRootView from "@/app/person/views/PersonRootView.vue";
import { i18n } from "@/base/i18n/i18n.ts";

const { t } = i18n.global;

export const personRoutes: RouteRecordRaw[] = [
  {
    path: "/person",
    component: PersonRootView,
    children: [
      {
        name: "personList",
        path: "list",
        meta: {
          breadcrumb: t("person.person", 2),
        },
        children: [],
      },
      {
        name: "personView",
        path: ":personId",
        props: true,
        meta: {
          breadcrumbParent: "personList",
          breadcrumb: null,
        },
        children: [
          {
            name: "personEdit",
            path: "edit",
            props: true,
            meta: {
              breadcrumb: t("action.edit"),
            },
            children: [],
          },
        ],
      },
    ],
  },
];

<script setup lang="ts">
import { storeToRefs } from "pinia";
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { inject, type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import type { StartProcessDialogData } from "@/base/composables/dialog/dialogData.ts";
import { type ActivityEto } from "@/base/graphql/generated/types.ts";

const { t } = useI18n();
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: StartProcessDialogData = dialogRef?.value.data;
const processService = useProcessService();
const router = useRouter();
const isStartingProcess = ref<boolean>(false);
const processName = ref("");
const activityService = useActivityService();
const { lastReleasedRootActivities } = storeToRefs(activityService);

const rootActivity = ref<ActivityEto | undefined>(props.rootActivity);

async function startProcess() {
  if (!rootActivity.value) {
    return;
  }

  await startProcessAndNavigate(rootActivity.value.id, processName.value);

  dialogRef?.value.close();
}

async function startProcessAndNavigate(
  rootActivityId: string,
  procName: string,
) {
  isStartingProcess.value = true;

  const processId = await processService.startProcess(rootActivityId, procName);

  if (!processId) {
    console.error("Missing process ID after start");
    return;
  }

  const rootProcessActivity = processService.getProcessActivityByTemplate(
    processId,
    rootActivityId,
  );

  if (!rootProcessActivity) {
    console.error("Missing root process activity after start");
    return;
  }

  await router.push({
    name: "processActivity",
    params: { processId, processActivityId: rootProcessActivity?.id },
  });
  isStartingProcess.value = false;
}

const processNames = ref(lastReleasedRootActivities.value);
</script>

<template>
  <BaseAppDialog class="w-full" data-testid="start-process-dialog">
    <template #content>
      <div class="w-full pt-2">
        <BaseAutoComplete
          v-if="props.showProcessTemplateSelect"
          v-model="rootActivity"
          dropdown
          fluid
          optionLabel="name"
          :disabled="isStartingProcess"
          :options="processNames"
          :placeholder="t('processes.startButton.processSelectPlaceholder')"
        >
          <template #empty>
            <span>{{ t("ui.noData") }}</span>
          </template>
        </BaseAutoComplete>

        <BaseTextField
          class="w-full mt-4"
          data-testid="start-process-name-input"
          :disabled="isStartingProcess"
          :label="t('processes.startButton.nameLabel')"
          @update="(value) => (processName = value)"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row justify-end pt-4 gap-4">
        <BaseButton
          data-testid="start-process-button"
          severity="primary"
          :loading="isStartingProcess"
          :label="t('processes.startButton.startButtonLabel')"
          :disabled="
            (!rootActivity && props.showProcessTemplateSelect) ||
            processName.length === 0 ||
            isStartingProcess
          "
          @click="startProcess"
        />
      </div>
    </template>
  </BaseAppDialog>
</template>

<style scoped></style>

import { type RouteRecordRaw } from "vue-router";

import ProcessActivityListView from "@/app/process/views/ProcessActivityListView.vue";
import ProcessRootView from "@/app/process/views/ProcessRootView.vue";
import { i18n } from "@/base/i18n/i18n.ts";

const { t } = i18n.global;

export const processRoutes: RouteRecordRaw[] = [
  {
    path: "/process",
    component: ProcessRootView,
    children: [
      {
        name: "processList",
        path: "list",
        meta: {
          breadcrumb: t("processes.process", 2),
        },
        children: [],
      },
      {
        name: "processView",
        path: ":processId",
        props: true,
        meta: {
          breadcrumbParent: "processList",
          breadcrumb: null,
        },
        children: [
          {
            name: "processActivity",
            path: "activity/:processActivityId",
            props: true,
            meta: {
              breadcrumb: null,
            },
            children: [],
          },
          {
            name: "processGraph",
            path: "graph/:rootActivityId",
            props: true,
            meta: {
              breadcrumb: t("processes.graphViewer.title"),
            },
            children: [],
          },
          {
            name: "customActivity",
            path: "activity/custom/:activityId",
            props: true,
            meta: {
              breadcrumb: t("processes.createCustomActivity"),
            },
            children: [],
          },
        ],
      },
    ],
  },
  {
    name: "processActivityList",
    path: "/process/activity/list",
    component: ProcessActivityListView,
    meta: {
      breadcrumb: t("linkTranslationKey.processActivityList"),
    },
    children: [],
  },
];

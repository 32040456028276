<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import Message from "primevue/message";
import ToggleSwitch from "primevue/toggleswitch";
import { validate as uuidValidate } from "uuid";
import { computed, inject, onMounted, type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useDocumentService } from "@/app/document/services/DocumentService.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import { DataTableContextKeys } from "@/base/components/filterdatatable/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/base/components/filterdatatable/table/CFilteredDataTable.vue";
import type { DataTableColumn } from "@/base/components/filterdatatable/table/CFilteredDataTableUtils.ts";
import type { CellContent } from "@/base/components/filterdatatable/TableTypes.ts";
import BaseLazyImage from "@/base/components/lazyimage/BaseLazyImage.vue";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import type { DocumentGenerationPlaceholderReplacementValuesCheckDialogData } from "@/base/composables/dialog/dialogData.ts";
import {
  type DocumentEto,
  FieldType,
  type PlaceholderReplacementInformation,
} from "@/base/graphql/generated/types.ts";
import { useDialogService } from "@/base/services/DialogService.ts";
import { useFileService } from "@/base/services/file/FileService.ts";

/* Props */
const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: DocumentGenerationPlaceholderReplacementValuesCheckDialogData =
  dialogRef?.value.data;

/* Emits */
defineEmits(["close"]);

/* Composables (use...) */
const documentService = useDocumentService();
const dialogService = useDialogService();
const fileService = useFileService();
const processService = useProcessService();

const { t } = useI18n();

const i18n = {
  aliasTitle: t("document.checkValuesDialog.aliasAndValuesTable.aliasTitle"),
  replacementValueTitle: t(
    "document.checkValuesDialog.aliasAndValuesTable.replacementValueTitle",
  ),
};

const mandatoryColumns: DataTableColumn[] = [
  {
    key: i18n.aliasTitle,
    name: i18n.aliasTitle,
    type: FieldType.String,
  },
  {
    key: i18n.replacementValueTitle,
    name: i18n.replacementValueTitle,
    type: FieldType.String,
    dynamicWidth: true,
  },
];

/* Refs */
const chosenItemId = ref<string | undefined | null>(props.preselectedItem);
const displayOnlyMissingReplacementValues = ref(true);

/* Computeds */
const placeholderReplacementValuesCheck = computed(
  () => documentService.placeholderReplacementValuesCheckResult,
);
const placeholdersWithoutReplacementValues = computed(
  () =>
    placeholderReplacementValuesCheck.value
      ?.filter((d) => !hasPlaceholderReplacementValue(d))
      .map((placeholderReplacementInformation) => {
        const cells: Record<string, CellContent> = {
          ...createNameCell(placeholderReplacementInformation, true),
          ...createValueCell(placeholderReplacementInformation),
        };

        return {
          key: placeholderReplacementInformation.placeholder,
          cells,
          tags: [],
        };
      }) ?? [],
);
const placeholdersWithReplacementValues = computed(
  () =>
    placeholderReplacementValuesCheck.value
      ?.filter((d) => hasPlaceholderReplacementValue(d))
      .map((placeholderReplacementInformation) => {
        const cells: Record<string, CellContent> = {
          ...createNameCell(placeholderReplacementInformation),
          ...createValueCell(placeholderReplacementInformation),
        };

        return {
          key: placeholderReplacementInformation.placeholder,
          cells,
          tags: [],
        };
      }) ?? [],
);

const rowItems = computed(() => {
  if (!placeholderReplacementValuesCheck.value) {
    return [];
  }

  return [
    ...placeholdersWithoutReplacementValues.value,
    ...(displayOnlyMissingReplacementValues.value
      ? []
      : placeholdersWithReplacementValues.value),
  ].sort((a, b) => a.key.localeCompare(b.key));
});

const documentItems = computed(() =>
  documentService.getAll().map((item: DocumentEto) => ({
    id: item.id,
    name: item.name,
  })),
);

const selectedDocumentId = computed({
  get: () => dialogService.dialogEntityId,
  set: (newVal) => (dialogService.dialogEntityId = newVal),
});

const processItems = computed(() => {
  return processService
    .getProcesses()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => ({
      value: item.id,
      text: item.name,
    }));
});
const loadingProcesses = computed(() => !processItems.value.length);

/* Lifecycle (OnMounted, Watch, ..) */
onMounted(async () => {
  if (props.preselectedItem !== undefined && !props.canChooseDoc) {
    await documentService.startPlaceholderReplacementValuesCheck(
      props.preselectedItem,
    );
  }
});

/* Functions */
function hasPlaceholderReplacementValue(
  placeholderReplacementInformation: PlaceholderReplacementInformation,
) {
  return !(
    placeholderReplacementInformation.value == null ||
    (Array.isArray(placeholderReplacementInformation.value) &&
      !placeholderReplacementInformation.value.length) ||
    (typeof placeholderReplacementInformation.value === "object" &&
      !Object.keys(placeholderReplacementInformation.value).length)
  );
}

function createNameCell(
  parameter: PlaceholderReplacementInformation,
  error?: true,
): Record<string, CellContent> {
  return {
    [i18n.aliasTitle]: {
      content: parameter.placeholder,
      contentTooltip: parameter.descriptions?.join("\n\n") ?? undefined,
      props: {
        class: `${error ? "text-red-500! font-bold" : ""} flex justify-between`,
      },
    },
  };
}

function createValueCell(
  parameter: PlaceholderReplacementInformation,
): Record<string, CellContent> {
  const cellContent: CellContent = {};

  if (uuidValidate(parameter.value)) {
    cellContent.component = BaseLazyImage;
    cellContent.props = {
      loadLabel: t("document.checkValuesDialog.aliasAndValuesTable.showImage"),
      content: ref(null),
      errorMessage: ref(false),
    };
    cellContent.on = {
      load: async () => {
        if ((cellContent.props?.content as Ref).value) {
          return;
        }
        try {
          (cellContent.props?.content as Ref).value =
            await fileService.loadFileAsObjectUrl(parameter.value);
          (cellContent.props?.errorMessage as Ref).value = null;
        } catch {
          (cellContent.props?.errorMessage as Ref).value = t(
            "lazyImage.error.text",
          );
        }
      },
    };
  } else {
    cellContent.content = Array.isArray(parameter.value)
      ? parameter.value
          .map((s) => s ?? t("common.n/a"))
          .map((v, idx) => `${idx + 1}) ${v}`)
          .join(" ")
      : parameter.value;
  }
  return {
    [i18n.replacementValueTitle]: cellContent,
  };
}
</script>

<template>
  <BaseAppDialog>
    <template #content>
      <div class="flex flex-col gap-y-4 w-full">
        <div
          v-if="props.preselectedItem === undefined || props.canChooseDoc"
          class="flex flex-col gap-y-2 h-full overflow-y-hidden overflow-x-hidden"
        >
          <p class="text-caeli6">
            {{ t("document.checkValuesDialog.description") }}
          </p>

          <div class="flex flex-col gap-y-4">
            <BaseAutoComplete
              v-if="props.preselectedItem === undefined"
              fluid
              dropdown
              data-testid="document-entity-select"
              optionLabel="text"
              :options="processItems"
              :placeholder="documentService.entityTypeLabel"
              :loading="
                loadingProcesses ||
                documentService.placeholderReplacementValuesCheckIsLoading
              "
              :disabled="
                loadingProcesses ||
                documentService.placeholderReplacementValuesCheckIsLoading
              "
              :modelValue="
                documentService.entityItems.find(
                  (p) => p.value === props.preselectedItem,
                )
              "
              @optionSelect="
                (option) => {
                  chosenItemId = option.value;
                  documentService.startPlaceholderReplacementValuesCheck(
                    chosenItemId,
                  );
                }
              "
            />

            <BaseAutoComplete
              v-if="props.canChooseDoc"
              fluid
              dropdown
              data-testid="document-template-select"
              optionLabel="name"
              :options="documentItems"
              :placeholder="t('document.field.select')"
              :modelValue="
                documentItems.find((p) => p.id === dialogService.dialogEntityId)
              "
              @optionSelect="
                (option) => {
                  selectedDocumentId = option.id;
                  documentService.startPlaceholderReplacementValuesCheck(
                    chosenItemId,
                  );
                }
              "
            />
          </div>
        </div>

        <Message
          v-if="
            placeholderReplacementValuesCheck &&
            !placeholdersWithoutReplacementValues.length &&
            !documentService.placeholderReplacementValuesCheckIsLoading
          "
          severity="success"
        >
          {{
            t(
              "document.checkValuesDialog.success.allPlaceholdersHaveReplacementValues",
            )
          }}
        </Message>

        <Message
          v-if="
            placeholderReplacementValuesCheck &&
            placeholdersWithoutReplacementValues.length &&
            !documentService.placeholderReplacementValuesCheckIsLoading
          "
          severity="error"
        >
          {{
            t(
              "document.checkValuesDialog.error.placeholdersHaveNoReplacementValues",
              { count: placeholdersWithoutReplacementValues.length },
            )
          }}
        </Message>

        <CFilteredDataTable
          v-if="
            rowItems.length &&
            !documentService.placeholderReplacementValuesCheckIsLoading
          "
          clientSide
          :paginatorPosition="rowItems.length <= 10 ? 'bottom' : 'both'"
          :rows="10"
          :contextKey="`${DataTableContextKeys.documentPlaceholderReplacementValuesCheck}-${props.preselectedItem}`"
          :rowItems="rowItems"
          :mandatoryColumns="mandatoryColumns"
          :exposedColumns="mandatoryColumns"
          :availableTags="[]"
        >
          <template #afterHeader>
            <div
              v-if="
                placeholderReplacementValuesCheck &&
                placeholdersWithoutReplacementValues.length !==
                  placeholderReplacementValuesCheck.length
              "
              class="flex flex-row flex-wrap items-center gap-2"
            >
              <ToggleSwitch
                v-model="displayOnlyMissingReplacementValues"
                data-testid="display-only-missing-replacement-values-button"
              />
              <p class="text-sm">
                {{
                  t(
                    "document.checkValuesDialog.onlyShowAliasesWithoutReplacementValue",
                  )
                }}
              </p>
            </div>
          </template>
        </CFilteredDataTable>
      </div>
    </template>
  </BaseAppDialog>
</template>

<style scoped>
.p-dialog-maximized .max-w-3xl {
  max-width: unset;
}
</style>

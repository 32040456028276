<script setup lang="ts">
import DocumentListView from "@/app/document/views/DocumentListView.vue";
import DocumentSingleEditView from "@/app/document/views/DocumentSingleEditView.vue";
import DocumentView from "@/app/document/views/DocumentView.vue";
</script>

<template>
  <RouterView v-slot="{ route }">
    <DocumentListView v-if="route.name === 'documentList'" />
    <DocumentView
      v-else-if="route.name === 'documentView'"
      :documentId="route.params.documentId as string"
    />
    <DocumentSingleEditView
      v-else-if="route.name === 'documentEdit'"
      :documentId="route.params.documentId as string"
    />
  </RouterView>
</template>

<script setup lang="ts">
import { useId } from "vue";

export interface BaseCheckboxProps {
  label?: string;
  size?: "small" | "large";
  disabled?: boolean;
  indeterminate?: boolean;
}

withDefaults(defineProps<BaseCheckboxProps>(), {
  label: undefined,
  size: "small",
  disabled: false,
  indeterminate: false,
});

const id = useId();
const model = defineModel<boolean | undefined | null>();
</script>

<template>
  <div class="flex flex-row gap-2 items-center">
    <PCheckBox
      v-model="model"
      binary
      :inputId="id"
      :disabled
      :size
      :indeterminate
      :pt="{
        root: size === 'small' ? 'w-[16px]! h-[16px]!' : 'w-[20px]! h-[20px]!',
        box: size === 'small' ? 'w-[16px]! h-[16px]!' : 'w-[20px]! h-[20px]!',
        icon: size === 'small' ? 'w-[8px]! h-[8px]!' : 'w-[12px]! h-[12px]!',
      }"
    />
    <label v-if="label" :class="disabled ? 'text-surface-400' : ''" :for="id">{{
      label
    }}</label>
  </div>
</template>

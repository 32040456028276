<script setup lang="ts">
import { type NodeProps, Position } from "@vue-flow/core";
import { onClickOutside } from "@vueuse/core";
import { computed, ref } from "vue";

import GraphHandle from "@/app/graphviewer/components/GraphHandle.vue";
import GraphNodeToolbar from "@/app/graphviewer/components/GraphNodeToolbar.vue";
import { type GraphHandleData } from "@/app/graphviewer/service/GraphViewerService.ts";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import {
  type ProcessActivityEto,
  ProcessActivityState,
} from "@/base/graphql/generated/types.ts";

interface NodeData {
  label: string;
  inputs: GraphHandleData[];
  outputs: GraphHandleData[];
  isSimpleMode: boolean;
  processActivity?: ProcessActivityEto;
}

const props = defineProps<NodeProps<NodeData>>();

const nodeRef = ref(null);
const isToolbarVisible = ref(false);

const sortedInputs = [...props.data.inputs].sort((a, b) =>
  a.name.localeCompare(b.name),
);

const sortedOutputs = [...props.data.outputs].sort((a, b) =>
  a.name.localeCompare(b.name),
);

const isSkippedActivity = computed(
  () =>
    props.data.processActivity?.status?.state === ProcessActivityState.Skipped,
);

const nodeColor = computed(() => {
  const state = props.data.processActivity?.status?.state;
  switch (state) {
    case ProcessActivityState.Done:
      return "bg-primary-50!";
    default:
      return "bg-surface-100!";
  }
});

const nodeAvatarColor = computed(() => {
  const state = props.data.processActivity?.status?.state;
  switch (state) {
    case ProcessActivityState.Ready:
      return "bg-[#E5D58A]!";
    case ProcessActivityState.InProgress:
      return "bg-sky-200!";
    case ProcessActivityState.Done:
      return "bg-primary-200!";
    default:
      return "bg-surface-200!";
  }
});

const nodeBorderColor = computed(() => {
  const state = props.data.processActivity?.status?.state;
  switch (state) {
    case ProcessActivityState.Ready:
      return "inset-ring-[#E5D58A]!";
    case ProcessActivityState.InProgress:
      return "inset-ring-sky-200!";
    case ProcessActivityState.Done:
      return "inset-ring-primary-200!";
    default:
      return "inset-ring-surface-200!";
  }
});

const nodeIcon = computed(() => {
  const state = props.data.processActivity?.status?.state;
  switch (state) {
    case ProcessActivityState.NotReady:
      return "mdi mdi-timer-sand";
    case ProcessActivityState.PartiallyReady:
      return "mdi mdi-timer-sand-complete";
    case ProcessActivityState.Ready:
      return "mdi mdi-emoticon-outline";
    case ProcessActivityState.InProgress:
      return "mdi mdi-play";
    case ProcessActivityState.Done:
      return "mdi mdi-check-bold";
    default:
      return "mdi mdi-skip";
  }
});

onClickOutside(nodeRef, () => (isToolbarVisible.value = false));
</script>

<template>
  <div ref="nodeRef" @click="isToolbarVisible = true">
    <div
      class="node rounded-[1rem] inset-ring-8! hover:inset-ring-black!"
      :class="[nodeColor, nodeBorderColor]"
    >
      <GraphNodeToolbar :activityId="props.id" :isVisible="isToolbarVisible" />

      <div class="flex flex-row gap-6 items-center mx-8 mt-8">
        <BaseAvatar
          class="min-h-24 min-w-24"
          size="xlarge"
          iconClass="scale-175 text-black"
          :class="nodeAvatarColor"
          :icon="nodeIcon"
        />

        <div :class="isSkippedActivity ? 'label-skipped' : 'label'">
          {{ props.data.label }}
        </div>
      </div>

      <div class="handle-container">
        <div class="leftHandles">
          <GraphHandle
            v-for="input in sortedInputs"
            :id="input.id"
            :key="`input-${input.id}`"
            :label="input.name"
            :position="Position.Left"
            :skipped="input.skipped"
          />
        </div>

        <div class="rightHandles">
          <GraphHandle
            v-for="output in sortedOutputs"
            :id="output.id"
            :key="`outputs-${output.id}`"
            :label="output.name"
            :position="Position.Right"
            :skipped="output.skipped"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.node {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
}

.handle-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 80px;
  cursor: move;
}

.label {
  width: 100%;
  max-width: 500px;
  font-weight: bold;
  font-size: 2.5rem;
  padding: 0.5rem;
  margin: 0;
  color: black;
  border-start-start-radius: 0.9rem;
  border-start-end-radius: 0.9rem;
}

.label-skipped {
  @extend .label;
  background-color: lightgrey;
}

.leftHandles {
  padding: 10px 0 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  max-width: 250px;
}

.rightHandles {
  padding: 10px 0 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  max-width: 250px;
  text-align: right;
  word-wrap: anywhere;
}
</style>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from "vue";
import { useI18n } from "vue-i18n";

import ProcessInputPanel from "@/app/process/components/input/ProcessInputPanel.vue";
import ProcessOutputPanel from "@/app/process/components/output/ProcessOutputPanel.vue";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import ProcessActivitySingleViewHeader from "@/app/process/views/ProcessActivitySingleViewHeader.vue";
import ProcessActivitySingleViewInformation from "@/app/process/views/ProcessActivitySingleViewInformation.vue";
import BaseAccordion from "@/base/components/accordion/BaseAccordion.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import { useBreakpoints } from "@/base/composables/useBreakpoints.ts";
import { useTitle } from "@/base/composables/useTitle.ts";
import { EntityType } from "@/base/graphql/generated/types.ts";

const props = defineProps<{
  processId: string;
  processActivityId: string;
}>();

const { t } = useI18n();
const processService = useProcessService();
const isMobile = useBreakpoints("smaller", "lg");

useTitle(t("processes.activity", 1));

onBeforeMount(() => {
  processService.markProcessActivityRefetch(props.processActivityId);
  processService.markProcessRefetch(props.processId);
});

const hasFields = ref<boolean>(false);

const process = computed(() => {
  return processService.getProcess(props.processId);
});

const processActivity = computed(() => {
  return processService.getProcessActivity(props.processActivityId);
});
</script>

<template>
  <BaseCard
    v-if="process !== undefined && processActivity !== undefined"
    class="h-full p-4!"
    flat
    noShadow
    :rounded="false"
  >
    <template #title>
      <ProcessActivitySingleViewHeader :processActivity />
    </template>

    <template #subtitle>
      <div class="flex flex-row gap-4">
        <RouterLink
          :to="{ name: 'processView', params: { processId: process.id } }"
        >
          <p class="text-sm hover:text-primary-300!">
            {{ process.name }}
          </p>
        </RouterLink>
      </div>
    </template>

    <div class="grid grid-cols-1 lg:grid-cols-6 gap-4 mt-2">
      <div
        :class="['space-y-4', hasFields ? 'lg:col-span-4' : 'lg:col-span-6']"
      >
        <ProcessActivitySingleViewInformation :processActivity />

        <ProcessInputPanel :processActivity />

        <ProcessOutputPanel :processActivity />
      </div>

      <div v-show="hasFields" class="lg:mt-0 lg:col-span-2">
        <BaseAccordion
          :label="t('processes.field', 2)"
          :expandable="isMobile"
          icon="mdi mdi-tag-multiple"
        >
          <EntityFieldsCard
            cards
            :entityId="processActivityId"
            :ancestorIds="[processActivity.activity.id]"
            :entityType="[EntityType.Activity]"
            @hasFields="hasFields = $event ?? false"
          />
        </BaseAccordion>
      </div>
    </div>
  </BaseCard>
</template>

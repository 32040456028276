import { useI18n } from "vue-i18n";

import GenerateDocumentDialog from "@/app/document/components/dialog/GenerateDocumentDialog.vue";
import { useDocumentService } from "@/app/document/services/DocumentService.ts";
import {
  type BaseDialogProps,
  type GenerateDocumentDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { type EntityType } from "@/base/graphql/generated/types.ts";
import { useDialogService } from "@/base/services/DialogService.ts";

export function useShowGenerateDocumentDialog(
  canChooseDoc: boolean,
  preselectedItem?: string,
  entityType?: EntityType,
) {
  const { t } = useI18n();
  const dialogService = useDialogService();
  const documentService = useDocumentService();
  const dialog = useBaseDialog();

  const dialogData: GenerateDocumentDialogData = {
    preselectedItem,
    canChooseDoc,
  };

  const generateDialogProps: BaseDialogProps = {
    header: t("document.generate"),
    props: dialogData,

    onClose: () => {
      documentService.resetDocumentGeneration();
    },
  };

  return {
    open: () => {
      if (entityType) {
        dialogService.dialogEntityType = entityType;
      }

      dialog.open(GenerateDocumentDialog, generateDialogProps);
    },
  };
}

<script setup lang="ts">
import { computed, reactive, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import DocumentParameterTable from "@/app/document/components/DocumentParameterTable.vue";
import { useDocumentService } from "@/app/document/services/DocumentService";
import DocumentFileField from "@/app/process/components/field/DocumentFileField.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import TextEditor from "@/base/components/form/TextEditor.vue";
import BaseTextField from "@/base/components/form/value/BaseTextField.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import BaseAutoComplete from "@/base/components/select/BaseAutoComplete.vue";
import {
  type DeleteDialogData,
  type DeleteDialogProps,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { TitleMode, useTitle } from "@/base/composables/useTitle";
import { type DocumentDto, EntityType } from "@/base/graphql/generated/types";
import { translateEnum } from "@/base/i18n/i18n";

const props = defineProps<{
  documentId: string;
}>();

const { t } = useI18n();
const router = useRouter();
const documentService = useDocumentService();
const showDialog = useBaseDialog();

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "document-delete",
    label: t("action.delete"),
    icon: "mdi mdi-trash-can-outline",
    severity: "danger",
    command: () => showDeleteDialog(),
  },
]);

const pendingDocument = reactive<DocumentDto>({
  id: props.documentId,
  name: "Neu",
  entityType: null,
  templateFileId: null,
});

const existingDocument = computed(() =>
  documentService.getById(props.documentId),
);

useTitle(
  existingDocument.value?.name ??
    pendingDocument.name ??
    t("document.document", 1),
  { titleMode: TitleMode.EDIT },
);

const entityTypeOptions = computed(() => {
  return Object.values(EntityType).map((value) => ({
    id: value,
    title: translateEnum("entityTypes", value),
  }));
});

const pendingDocumentEntityType = computed({
  get: () =>
    entityTypeOptions.value.find(
      (option) => option.id === pendingDocument.entityType,
    ),
  set: (value) => {
    const entityType = value?.id;
    updatePendingDocument({ entityType });
  },
});

const isValidDocument = computed(() => {
  return Object.entries(pendingDocument).every(([key, value]) => {
    if (key === "description") {
      return true;
    }
    if (key === "iterator") {
      return true;
    }
    return value !== undefined && value !== null && value !== "";
  });
});

watchEffect(() => {
  Object.assign(pendingDocument, documentService.getById(props.documentId));
});

watch([isValidDocument, pendingDocument], async ([isValid]) => {
  // Auto-Save on blur only when the document is valid
  if (!isValid) {
    return;
  }
  await createOrUpdateDocument();
});

function updatePendingDocument(update: Partial<DocumentDto>) {
  Object.assign(pendingDocument, update);
}

async function createOrUpdateDocument(params?: { goBack: boolean }) {
  documentService.createOrUpdate(pendingDocument);

  if (params?.goBack) {
    await router.push({
      name: "documentView",
      params: { documentId: pendingDocument.id },
    });
  }
}

function isDocumentExisting() {
  return documentService.getById(props.documentId) !== undefined;
}

function showDeleteDialog() {
  const deleteDialogData: DeleteDialogData = {
    deleteDescription: t("document.deleteDocumentText", {
      name: pendingDocument.name,
    }),
  };

  const deleteDialogProps: DeleteDialogProps = {
    header: `${t("document.deleteDocument")}`,
    props: deleteDialogData,
    onDelete: () => {
      documentService.deleteAndGoToList(props.documentId);
      dialogRef.close();
    },
  };

  const dialogRef = showDialog.open(DeleteDialog, deleteDialogProps);
}
</script>

<template>
  <BaseCard class="h-full p-4!" flat noShadow :rounded="false">
    <template #title>
      <div class="flex flex-row gap-4 justify-between items-center">
        <BaseTextField
          data-testid="document-name"
          class="w-full"
          :label="t('document.name')"
          :initialValue="pendingDocument.name ?? undefined"
          @update="(value) => updatePendingDocument({ name: value })"
        />

        <div class="flex flex-row gap-4 items-center">
          <BaseActionsButton outlined :model="actionsMenuItems" />
          <BaseButton
            data-testid="save-document"
            icon="mdi mdi-content-save-outline"
            :label="t('ui.save')"
            :disabled="!isValidDocument"
            @click="createOrUpdateDocument({ goBack: true })"
          />
        </div>
      </div>
    </template>

    <PAccordion
      multiple
      :value="['data', 'parameters']"
      :pt="{
        root: 'flex flex-col gap-6',
      }"
    >
      <PAccordionPanel
        value="data"
        :pt="{
          root: {
            class: 'overflow-hidden! border-[0.1rem]! rounded-md! mt-2!',
          },
        }"
      >
        <PAccordionHeader
          :pt="{
            root: {
              class:
                'bg-surface-100! border-b-[0.1rem]! h-auto! p-2! gap-4! pointer-events-none!',
            },
            toggleicon: 'invisible',
          }"
        >
          <p class="text-caeli6">
            {{ t("document.documentInformation") }}
          </p>
        </PAccordionHeader>

        <PAccordionContent>
          <div class="flex flex-col gap-6 mt-4">
            <BaseAutoComplete
              v-model="pendingDocumentEntityType"
              data-testid="document-entity-type"
              fluid
              optionLabel="title"
              :options="entityTypeOptions"
              :placeholder="t('document.entityType')"
            />

            <BaseTextField
              v-tooltip.bottom="t('document.iterator.tooltip')"
              data-testid="document-iterator"
              :initialValue="pendingDocument.iterator ?? undefined"
              :label="t('document.iterator.label')"
              @update="
                (iterator) => updatePendingDocument({ iterator: iterator })
              "
            />

            <TextEditor
              data-testid="document-description"
              class="mb-1 -mt-5"
              :containerId="props.documentId"
              :label="t('document.description')"
              :previousContent="pendingDocument.description ?? undefined"
              @saveContent="
                (content) => updatePendingDocument({ description: content })
              "
            />

            <DocumentFileField
              data-testid="document-template-file"
              :entityId="pendingDocument.id"
              :fileId="pendingDocument.templateFileId ?? undefined"
              @update="
                (fileId) => updatePendingDocument({ templateFileId: fileId })
              "
            />
          </div>
        </PAccordionContent>
      </PAccordionPanel>

      <PAccordionPanel
        v-if="isDocumentExisting()"
        value="parameters"
        :pt="{
          root: {
            class: 'overflow-hidden! border-[0.1rem]! rounded-md! mt-2!',
          },
        }"
      >
        <PAccordionHeader
          :pt="{
            root: {
              class: 'bg-surface-100! border-b-[0.1rem]! h-auto! p-2! gap-4!',
            },
          }"
        >
          <p class="text-caeli6">
            {{ t("document.documentParameter") }}
          </p>
        </PAccordionHeader>

        <PAccordionContent>
          <DocumentParameterTable
            class="mt-2"
            :entityId="pendingDocument.id"
            :editable="true"
          />
        </PAccordionContent>
      </PAccordionPanel>
    </PAccordion>
  </BaseCard>
</template>

<script setup lang="ts">
/* eslint-disable */
// @ts-nocheck

import { computed } from "vue";

import { useFieldService } from "@/base/services/FieldService.ts";
import {
  EntityType,
  FieldType,
  type FieldValueEto,
} from "@/base/graphql/generated/types.ts";

const fieldService = useFieldService();
const entityTypes = Object.values(EntityType);
const isLoading = computed(
  () => fieldService.isLoadingFieldKey() || fieldService.isLoadingFieldValue(),
);
const fieldKeysPerType = computed(() => {
  return entityTypes.map((entityType) => {
    const fieldKeys = fieldService.getNonTagFieldKeys(entityType);
    return {
      name: entityType.toString(),
      values: fieldKeys,
    };
  });
});

function sortedFieldKeysPerType(fieldKeysPerTypeValue: values) {
  return fieldKeysPerTypeValue.sort((a, b) => a.name.localeCompare(b.name));
}

function mapOptions(valueType: FieldType, options: FieldValueEto[]) {
  let name: string;

  switch (valueType) {
    case FieldType.Boolean:
      name = "valueBoolean";
      break;
    case FieldType.Date:
      name = "valueDate";
      break;
    case FieldType.Number:
      name = "valueNumber";
      break;
    case FieldType.Url:
      name = "valueUrl";
      break;
    case FieldType.Person:
    case FieldType.File:
      name = "valueEntityId";
      break;
    case FieldType.Address:
    case FieldType.Json:
    case FieldType.BankAccount:
      name = "valueJson";
      break;
    default:
      name = "valueString";
  }

  return options
    .map((option: FieldValueEto) => {
      return {
        id: option.id,
        name: option.value[name as keyof FieldValueEto],
      };
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
}
</script>

<template>
  <div class="h-1 scale-y-10">
    <PProgressbar v-if="isLoading" mode="indeterminate" />
  </div>

  <PAccordion class="mt-2" value="content">
    <PAccordionPanel
      v-for="fieldKeyType in fieldKeysPerType"
      :key="fieldKeyType.name"
      :value="fieldKeyType.name"
      :pt="{
        root: {
          class: 'overflow-hidden! border-[0.1rem]! rounded-md! mt-4!',
        },
      }"
    >
      <PAccordionHeader
        :pt="{
          root: {
            class: 'bg-surface-100! border-b-[0.1rem]! h-auto! p-2! gap-4!',
          },
        }"
      >
        <p class="text-caeli6 flex-1">
          {{ `EntityType.${fieldKeyType.name}` }}
        </p>
        <p class="text-caeli6 font-thin">
          {{ `${fieldKeyType.values.length} keys` }}
        </p>
      </PAccordionHeader>

      <PAccordionContent>
        <PDataTable
          size="small"
          filterDisplay="menu"
          stripedRows
          rowHover
          scrollable
          class="mt-4 text-sm"
          :value="sortedFieldKeysPerType(fieldKeyType.values)"
          :pt="{
            mask: { class: 'opacity-0!' },
            bodyRow: {
              'data-testid': 'filtered-data-table-row',
            },
            pcPaginator: {
              paginatorContainer: 'border-0!',
            },
          }"
        >
          <PColumn field="unique" header="Unique">
            <template #body="{ data }">
              <i
                v-if="data.unique"
                class="flex flex-row justify-center mdi mdi-star"
              ></i>
            </template>
          </PColumn>
          <PColumn field="name" header="Name" />
          <PColumn field="key" header="Key" />
          <PColumn field="prefix" header="Prefix">
            <template #body="{ data }">
              <i :class="`mdi ${data.prefix}`"></i>
            </template>
          </PColumn>
          <PColumn
            field="description"
            header="Description"
            :class="
              sortedFieldKeysPerType(fieldKeyType.values).some(
                (item) => item?.description?.length,
              )
                ? 'min-w-100'
                : ''
            "
          />
          <PColumn field="id" header="Id" />
          <PColumn field="type" header="Type" />
        </PDataTable>
      </PAccordionContent>
    </PAccordionPanel>
  </PAccordion>
</template>

<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useMessageServiceV2 } from "@/app/message/services/MessageServiceV2.ts";
import { usePersonServiceV2 } from "@/app/person/services/PersonServiceV2.ts";
import TagsPopoverButton from "@/app/process/components/field/TagsPopoverButton.vue";
import BaseAvatar from "@/base/components/avatar/BaseAvatar.vue";
import BaseActionsButton from "@/base/components/button/BaseActionsButton.vue";
import BaseCard from "@/base/components/card/BaseCard.vue";
import EntityFieldsCard from "@/base/components/fields/EntityFieldsCard.vue";
import TextDisplay from "@/base/components/form/TextDisplay.vue";
import { type BaseMenuItem } from "@/base/components/menu/BaseMenuItem.ts";
import { useTitle } from "@/base/composables/useTitle.ts";
import { EntityType, type PersonEto } from "@/base/graphql/generated/types.ts";

const router = useRouter();

const props = defineProps<{
  messageId: string;
}>();

const { t } = useI18n();
const personService = usePersonServiceV2();
const messageService = useMessageServiceV2();

const { selectedMessage: message } = storeToRefs(messageService);

useTitle(t("message.message", 1));
const { copy, copied } = useClipboard();

const author = computed<PersonEto | undefined>(() => {
  if (!message.value?.authorId) {
    return undefined;
  }
  return personService.getById(message.value.authorId).value;
});

const actionsMenuItems = computed<BaseMenuItem[]>(() => [
  {
    testId: "message-edit",
    label: t("action.edit"),
    icon: "mdi mdi-pencil",
    command: navigateToEditPage,
  },
]);

async function navigateToEditPage() {
  await router.push({
    name: "messageEdit",
    params: { messageId: props.messageId },
  });
}
</script>

<template>
  <BaseCard class="h-full p-4!" flat noShadow :rounded="false">
    <template #title>
      <div class="flex flex-row justify-between items-start gap-4">
        <div class="flex flex-row gap-4 items-start text-nowrap truncate">
          <BaseAvatar
            v-tooltip.bottom="t('action.copyIdTooltip')"
            class="mt-1 shrink-0 text-caeli6 cursor-pointer transition-all duration-400 ease-out"
            :icon="copied ? 'mdi mdi-check' : 'mdi mdi-email'"
            :pt:root="copied ? 'bg-primary-400!' : undefined"
            @click="copy(messageId)"
          />

          <p
            data-testid="message-view-title"
            class="text-3xl/10 font-bold truncate"
            :title="message?.title ?? ''"
          >
            {{ message?.title ?? "" }}
          </p>
        </div>

        <div class="flex flex-row gap-6 items-center">
          <TagsPopoverButton
            readonly
            :entityId="props.messageId"
            :entityType="EntityType.Person"
          />
          <BaseActionsButton :model="actionsMenuItems" />
        </div>
      </div>
    </template>

    <div class="flex flex-col gap-4">
      <EntityFieldsCard
        cards
        grouped
        :entityType="[EntityType.Message]"
        :entityId="props.messageId"
        readonly
      />

      <PAccordion class="mt-2" value="content">
        <PAccordionPanel
          value="content"
          :pt="{
            root: {
              class: 'overflow-hidden! border-[0.1rem]! rounded-md!',
            },
          }"
        >
          <PAccordionHeader
            :pt="{
              root: {
                class: 'bg-surface-100! border-b-[0.1rem]! h-auto! p-2! gap-4!',
              },
            }"
          >
            <p class="text-caeli6 flex-1">
              {{ t("message.body") }}
            </p>

            <div v-if="author?.name" class="text-caeli6 flex flex-row gap-2">
              <i class="mdi mdi-account"></i>
              <p>{{ `Autor: ${author.name}` }}</p>
            </div>
          </PAccordionHeader>

          <PAccordionContent>
            <TextDisplay
              :value="message?.body ?? ''"
              class="mt-2"
              data-testid="message-view-body"
              markdown
            />
          </PAccordionContent>
        </PAccordionPanel>
      </PAccordion>
    </div>
  </BaseCard>
</template>

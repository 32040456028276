<script setup lang="ts">
// This is required for the import due to a PrimeVue Bug
/* eslint-disable canonical/prefer-inline-type-import */
import type { DynamicDialogInstance } from "primevue/dynamicdialogoptions";
import { v4 as uuidv4 } from "uuid";
import { computed, inject, type Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

import RenameTagEntry from "@/app/process/components/field/action/RenameTagEntry.vue";
import BaseButton from "@/base/components/button/BaseButton.vue";
import BaseAppDialog from "@/base/components/dialog/BaseAppDialog.vue";
import DeleteDialog from "@/base/components/dialog/DeleteDialog.vue";
import BaseDivider from "@/base/components/divider/BaseDivider.vue";
import BaseMessage from "@/base/components/message/BaseMessage.vue";
import type {
  DeleteDialogData,
  DeleteDialogProps,
  RenameTagDialogData,
} from "@/base/composables/dialog/dialogData.ts";
import { useBaseDialog } from "@/base/composables/dialog/useBaseDialog.ts";
import { FieldType } from "@/base/graphql/generated/types.ts";
import { useFieldService } from "@/base/services/FieldService.ts";
import { lowerFirstLetter } from "@/base/utils/string.ts";

const dialogRef: Ref<DynamicDialogInstance> | undefined = inject("dialogRef");
const props: RenameTagDialogData = dialogRef?.value.data;
const { t } = useI18n();
const deleteDialog = useBaseDialog();
const fieldService = useFieldService();

const newTagName = ref<string>();

const availableTags = computed(() => {
  return fieldService.getTagFieldKeys(props.entityType);
});

function createTag() {
  if (!props.entityType) {
    return;
  }

  fieldService.createOrUpdateFieldKey({
    id: uuidv4(),
    name: newTagName.value,
    type: FieldType.Tag,
    entityTypes: [props.entityType],
  });
  newTagName.value = "";
}

function deleteTag(id: string) {
  const deleteDialogData: DeleteDialogData = {
    deleteDescription: t("action.actionPrompt", {
      target: fieldService.getFieldKey(id)?.name,
      action: lowerFirstLetter(t("action.delete")),
    }),
  };

  const deleteDialogProps: DeleteDialogProps = {
    header: t("action.delete"),
    props: deleteDialogData,
    onDelete: async () => {
      await fieldService.deleteFieldWithInstances(id);
      deleteDialogRef.close();
    },
  };

  const deleteDialogRef = deleteDialog.open(DeleteDialog, deleteDialogProps);
}
</script>

<template>
  <BaseAppDialog class="w-full">
    <template #content>
      <div v-if="availableTags.length > 0" class="flex flex-col gap-3">
        <RenameTagEntry
          v-for="fieldKey in availableTags"
          :key="fieldKey.id"
          :fieldKey="fieldKey"
          @delete="deleteTag"
        />
      </div>

      <BaseMessage v-else class="mt-1" severity="info">
        {{ t("processes.noTagsAvailable") }}
      </BaseMessage>
    </template>

    <template #footer>
      <BaseDivider class="my-2 mb-4" />

      <span class="text-[#334155]! font-bold text-lg">{{
        t("processes.createTag")
      }}</span>

      <div class="flex flex-row gap-4 mt-4">
        <PFloatLabel class="grow" variant="on">
          <PInputText
            id="create-tag-input"
            v-model="newTagName"
            data-testid="create-tag-input"
            fluid
            class="grow"
            size="small"
          />

          <label for="create-tag-input">{{ t("processes.tagName") }}</label>
        </PFloatLabel>

        <BaseButton
          data-testid="create-tag-button"
          icon="mdi mdi-plus"
          :label="t('ui.add')"
          :severity="!newTagName ? 'secondary' : 'primary'"
          :disabled="!newTagName"
          @click="createTag"
        />
      </div>
    </template>
  </BaseAppDialog>
</template>

<style scoped></style>

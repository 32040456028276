import {
  type Edge,
  type Elements,
  type Node,
  useVueFlow,
  type ViewportTransform,
} from "@vue-flow/core";
import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { useActivityService } from "@/app/activity/services/ActivityService.ts";
import { useGraphLayout } from "@/app/graphviewer/service/useGraphLayout.ts";
import { useProcessService } from "@/app/process/services/ProcessService.ts";
import {
  type ActivityEto,
  type ProcessActivityEto,
  ProcessActivityState,
  ProcessOutputState,
} from "@/base/graphql/generated/types.ts";
import { StorageKeys } from "@/config.ts";

export interface GraphHandleData {
  id: string;
  name: string;
  skipped: boolean;
}

export const useGraphService = defineStore("GraphService", () => {
  const processService = useProcessService();
  const activityService = useActivityService();
  const { layout } = useGraphLayout();
  const { viewport, onViewportChangeEnd, zoomTo, fitView, onNodesInitialized } =
    useVueFlow("vueflow");
  const cachedViewport = useStorage<ViewportTransform | undefined>(
    StorageKeys.graph.viewport.key,
    undefined,
    StorageKeys.graph.viewport.storage,
  );
  const cachedProcessId = useStorage<string | undefined>(
    StorageKeys.graph.processId.key,
    undefined,
    StorageKeys.graph.processId.storage,
  );

  const nodes = ref<Node[]>([]);
  const edges = ref<Edge[]>([]);
  const model = computed<Elements>(() => [...nodes.value, ...edges.value]);
  const currentProcessId = ref<string>();

  onViewportChangeEnd((vp) => {
    cachedViewport.value = vp;
  });

  onNodesInitialized(async (graphNodes) => {
    if (cachedViewport.value) {
      viewport.value = cachedViewport.value;
    }
    if (cachedProcessId.value !== currentProcessId.value) {
      await fitView({ nodes: graphNodes.slice(0, 1).map((node) => node.id) });
      await zoomTo(0.3);
      currentProcessId.value = cachedProcessId.value;
    }
  });

  function fetchGraph({
    rootActivityId,
    processId,
    useSimpleMode,
  }: {
    rootActivityId: string;
    processId?: string;
    useSimpleMode: boolean;
  }) {
    nodes.value = createNodes(rootActivityId, useSimpleMode, processId);
    edges.value = createEdges(rootActivityId, useSimpleMode);
    nodes.value = layout({
      nodes: nodes.value,
      edges: edges.value,
    });
    cachedProcessId.value = processId;
  }

  function createNodes(
    rootActivityId: string,
    useSimpleMode: boolean,
    processId?: string,
  ) {
    const processActivities = processId
      ? processService.getProcessActivities(processId)
      : [];

    const usedOutputs = processActivities.flatMap((pa) => {
      return pa.activity.inputs.map((input) => input.outputId);
    });

    return (
      activityService.getGraph(rootActivityId)?.map((activity) => {
        const processActivity = processActivities.find(
          (p) => p.activity.id === activity.id,
        );
        const { inputs, outputs } = createHandleData(
          activity,
          useSimpleMode,
          processActivity,
        );

        return {
          id: activity.id,
          data: {
            label: activity.name,
            inputs,
            outputs: useSimpleMode
              ? outputs
              : outputs.filter((output) => usedOutputs.includes(output.id)),
            isSimpleMode: useSimpleMode,
            processActivity,
          },
          position: { x: 0, y: 0 },
          type: "graphNode",
        };
      }) ?? []
    );
  }

  function createEdges(rootActivityId: string, useSimpleMode: boolean) {
    const items =
      activityService
        .getGraph(rootActivityId)
        ?.map((activity) => {
          return activity.inputs.map((input) => {
            const outputActivityId =
              activityService.getOutputActivity(input.outputId)?.id ?? "";
            return {
              id: `${activity.id}-${input.id}`,
              source: outputActivityId,
              target: activity.id,
              sourceHandle: useSimpleMode ? "output" : input.outputId,
              targetHandle: useSimpleMode ? "input" : input.id,
              animated: false,
              type: "graphEdge",
              data: {
                isDone: false,
              },
            };
          });
        })
        .flat() ?? [];

    // In simple mode we need to make the edges distinct by source and target
    // to avoid multiple edges between the same nodes
    if (useSimpleMode) {
      return makeDistinctByKeys(items, ["source", "target"]);
    }

    return items;
  }

  function createHandleData(
    activity: ActivityEto,
    useSimpleMode: boolean,
    processActivity?: ProcessActivityEto,
  ) {
    const skippedProcessActivity =
      processActivity?.status?.state === ProcessActivityState.Skipped;
    if (useSimpleMode) {
      return {
        inputs:
          activity.inputs.length === 0
            ? []
            : [{ id: "input", name: "Quellen", skipped: false }],
        outputs:
          activity.outputs.length === 0
            ? []
            : [
                {
                  id: "output",
                  name: "Ergebnisse",
                  skipped: skippedProcessActivity,
                },
              ],
      };
    }

    const inputs = activity.inputs.map((input) => {
      return {
        id: input.id,
        name: activityService.getOutput(input.outputId)?.name ?? "N/A",
        skipped: false,
      } as GraphHandleData;
    });

    const processOutputs =
      processActivity && !skippedProcessActivity
        ? processService.getOutputs(processActivity.id)
        : [];

    const outputs = activity.outputs.map((output) => {
      const skippedOutput =
        skippedProcessActivity ||
        processOutputs.find((o) => o.activityOutputId === output.id)?.state ===
          ProcessOutputState.Skipped;

      return {
        id: output.id,
        name: output.name,
        skipped: skippedOutput,
      } as GraphHandleData;
    });

    return { inputs, outputs };
  }

  function makeDistinctByKeys<T>(list: T[], keys: (keyof T)[]): T[] {
    const getKey = (obj: T) => keys.map((key) => obj[key]).join("|");

    const uniqueObjects: Record<string, T> = {};

    list.forEach((obj) => {
      const valueKey = getKey(obj);
      if (!uniqueObjects[valueKey]) {
        uniqueObjects[valueKey] = obj;
      }
    });

    return Object.values(uniqueObjects);
  }

  return {
    fetchGraph,
    model,
  };
});

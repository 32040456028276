import { useRouteQuery } from "@vueuse/router";
import {
  computed,
  type MaybeRef,
  onBeforeMount,
  reactive,
  type Ref,
  toRef,
  watch,
} from "vue";
import { useRouter } from "vue-router";

import {
  type FilterEntryDto,
  type ListFilterDto,
  type SortDirection,
} from "@/base/graphql/generated/types.ts";
import { type PaginationInfo } from "@/base/plugins/apollo/graphQlClient.ts";

const matchModeMap = {
  contains: "CONTAINS",
  notContains: "NOT_CONTAINS",
  equals: "EQUALS",
  notEquals: "NOT_EQUALS",
  startsWith: "STARTS_WITH",
  endsWith: "ENDS_WITH",
};

export function mapMatchModeForDto(matchMode: string) {
  return matchModeMap[matchMode] ?? matchMode.toUpperCase();
}

export function mapMatchModeForDataTable(matchMode: string) {
  return (
    Object.keys(matchModeMap).find((key) => matchModeMap[key] === matchMode) ??
    "contains"
  );
}

const operatorMap = {
  and: "AND",
  or: "OR",
};

export function mapOperatorForDto(operator: string) {
  return operatorMap[operator] ?? operator.toUpperCase();
}

export function mapOperatorForDataTable(operator: string) {
  return (
    Object.keys(operatorMap).find((key) => operatorMap[key] === operator) ??
    "or"
  );
}

export type FilterEntry = Omit<FilterEntryDto, "constraints" | "operator"> & {
  operator: string;
  constraints: {
    value: string;
    matchMode: string;
  }[];
};

export type MappedListFilterDto = Omit<ListFilterDto, "filterEntries"> & {
  filterEntries: FilterEntry[];
};

const filterState = reactive(new Map<string, MappedListFilterDto>());
const totalElementsState = reactive(new Map<string, { value: number }>());

function initializeContextKey(
  contextKey: string,
  selectedColumns: MaybeRef<string[]>,
) {
  const columns = toRef(selectedColumns);

  if (!filterState.has(contextKey)) {
    const savedFilterState = localStorage.getItem(`filterState_${contextKey}`);
    if (savedFilterState) {
      filterState.set(contextKey, reactive(JSON.parse(savedFilterState)));
    } else {
      filterState.set(
        contextKey,
        reactive({
          filterEntries: [],
          fullTextSearch: "",
          pagination: {
            pageNumber: 0,
            pageSize: 25,
            totalElements: 0,
            totalPages: 0,
          },
          sort: [],
          tags: [],
        }),
      );
    }

    totalElementsState.set(contextKey, reactive({ value: 0 }));
  }

  if (columns.value.length === 0) {
    return;
  }

  const savedColumns = localStorage.getItem(`selectedColumns_${contextKey}`);
  if (savedColumns) {
    columns.value = JSON.parse(savedColumns);
  }
}

watch(
  () => filterState,
  (newFilterState) => {
    newFilterState.forEach((state, key) => {
      localStorage.setItem(`filterState_${key}`, JSON.stringify(state));
    });
  },
  { deep: true },
);

export function useApplyPaginationInfo(contextKey: string) {
  initializeContextKey(contextKey, []);

  function applyPaginationInfo(paginationInfo: PaginationInfo | undefined) {
    totalElementsState.get(contextKey)!.value =
      paginationInfo?.totalElements ?? 0;
  }

  return {
    applyPaginationInfo,
  };
}

export function useGetListFilter(contextKey: string) {
  initializeContextKey(contextKey, []);

  const router = useRouter();
  const listFilter = computed(() => filterState.get(contextKey)!);

  async function resetListFilter() {
    filterState.set(
      contextKey,
      reactive({
        filterEntries: [],
        fullTextSearch: "",
        pagination: {
          pageNumber: 0,
          pageSize: 25,
          totalElements: 0,
          totalPages: 0,
        },
      }),
    );

    await router.replace({
      name: router.currentRoute.value.name,
      params: router.currentRoute.value.params,
    });
  }

  return {
    listFilter,
    resetListFilter,
  };
}

export function useDataTableFilter(
  contextKey: string,
  selectedColumns: Ref<string[]>,
) {
  initializeContextKey(contextKey, selectedColumns);

  const router = useRouter();
  const urlPageNumberParam = useRouteQuery<number>("page", 0, {
    transform: Number,
    mode: "replace",
  });
  const urlPageSizeParam = useRouteQuery<number>("size", 25, {
    transform: Number,
    mode: "replace",
  });
  const urlSelectedColumnsParam = useRouteQuery<string[]>(
    "columns",
    selectedColumns,
    {
      transform: (val) => (Array.isArray(val) ? val : [val]),
      mode: "replace",
    },
  );
  const urlFullTextSearchParam = useRouteQuery<string>("search", "", {
    mode: "replace",
  });
  const urlSortParam = useRouteQuery<string[]>("sort", [], {
    transform: (val) => (Array.isArray(val) ? val : [val]),
    mode: "replace",
  });
  const urlTagsParam = useRouteQuery<string[]>("tags", [], {
    transform: (val) => (Array.isArray(val) ? val : [val]),
    mode: "replace",
  });
  const urlFilterEntriesParam = useRouteQuery<string[]>("filterEntry", [], {
    transform: (val) => (Array.isArray(val) ? val : [val]),
    mode: "replace",
  });

  const currentFilterState = filterState.get(contextKey)!;
  const currentTotalElements = totalElementsState.get(contextKey)!;

  onBeforeMount(async () => {
    await readUrlParams();
  });

  if (selectedColumns) {
    watch(selectedColumns, (newColumns) => {
      saveUrlParams();
      localStorage.setItem(
        `selectedColumns_${contextKey}`,
        JSON.stringify(newColumns),
      );
    });
  }

  function applyFilter(partialFilter: Partial<ListFilterDto>) {
    Object.assign(currentFilterState, partialFilter);

    if (partialFilter.filterEntries) {
      currentFilterState.filterEntries = partialFilter.filterEntries.map(
        (entry) => ({
          ...entry,
          operator: entry.operator,
          constraints: entry.constraints.map((constraint) => ({
            ...constraint,
            matchMode: constraint.matchMode,
          })),
        }),
      );
    }

    currentFilterState.pagination =
      partialFilter.pagination ?? currentFilterState.pagination;

    saveUrlParams();
  }

  function clear(callback?: () => void) {
    currentFilterState.filterEntries = [];
    currentFilterState.fullTextSearch = "";
    currentFilterState.tags = [];
    currentFilterState.sort = [];
    saveUrlParams();
    callback?.();
  }

  function saveUrlParams() {
    urlPageNumberParam.value = currentFilterState.pagination?.pageNumber ?? 0;
    urlPageSizeParam.value = currentFilterState.pagination?.pageSize ?? 25;
    urlSelectedColumnsParam.value = selectedColumns?.value;
    urlFullTextSearchParam.value = currentFilterState.fullTextSearch ?? "";
    urlSortParam.value =
      currentFilterState.sort?.map((sort) => {
        return `${sort.key}$${sort.direction}`;
      }) ?? [];
    urlTagsParam.value = currentFilterState.tags ?? [];
    urlFilterEntriesParam.value = currentFilterState.filterEntries?.map(
      (entry) => {
        return JSON.stringify({
          ...entry,
          operator: entry.operator,
          constraints: entry.constraints.map((constraint) => ({
            ...constraint,
            matchMode: constraint.matchMode,
          })),
        });
      },
    );
  }

  async function readUrlParams() {
    await router.replace({
      name: router.currentRoute.value.name,
      params: router.currentRoute.value.params,
      query: undefined,
    });

    if (selectedColumns && urlSelectedColumnsParam.value) {
      selectedColumns.value = urlSelectedColumnsParam.value;
    }

    currentFilterState.pagination = {
      ...currentFilterState.pagination,
      pageNumber:
        urlPageNumberParam.value ?? currentFilterState.pagination?.pageNumber,
      pageSize:
        urlPageSizeParam.value ?? currentFilterState.pagination?.pageSize,
    };

    if (urlFullTextSearchParam.value) {
      currentFilterState.fullTextSearch = urlFullTextSearchParam.value;
    }

    if (urlSortParam.value.length) {
      currentFilterState.sort = urlSortParam.value.map((sort) => {
        const [key, direction] = sort.split("$");
        return { key, direction: direction as SortDirection };
      });
    }

    if (urlTagsParam.value.length) {
      currentFilterState.tags = [...urlTagsParam.value];
    }

    if (urlFilterEntriesParam.value.length) {
      currentFilterState.filterEntries = urlFilterEntriesParam.value.map(
        (entry) => {
          const parsedEntry = JSON.parse(entry);
          return {
            ...parsedEntry,
            operator: parsedEntry.operator,
            constraints: parsedEntry.constraints.map((constraint) => ({
              ...constraint,
              matchMode: constraint.matchMode,
            })),
          };
        },
      );
    }

    saveUrlParams();
  }

  return {
    listFilter: computed(() => currentFilterState),
    totalElements: computed(() => currentTotalElements.value),
    applyFilter,
    clear,
  };
}
